var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"actions-center"},[_c('campaign-info-popup',{ref:"campaignMediaPopup",attrs:{"campaign":_vm.selectedCampaign}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1",attrs:{"color":"grey darken-1"},on:{"click":_vm.showCampaignMediaPopup}},on),[_vm._v(" visibility ")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('open'))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.isCampaignEditable)?_c('v-icon',_vm._g({staticClass:"mx-1",attrs:{"color":"grey darken-1"},on:{"click":_vm.editCampaign}},on),[_vm._v(" edit ")]):_vm._e()]}}])},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")]),(_vm.canChangeMedia)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"upload-media-section"},on),[_c('change-campaign-media',{staticClass:"mx-1",attrs:{"campaign":_vm.campaignRow},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-icon',{attrs:{"color":"grey darken-1"}},[_vm._v(" cloud_upload ")])]},proxy:true}],null,true)})],1)]}}],null,false,1326833444)},[_vm._v(" "+_vm._s(_vm.$t('change_media'))+" ")]):_vm._e(),(_vm.campaignRow.INVOICE_NUMBER)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('download-invoice',{attrs:{"campaign":_vm.campaignRow},scopedSlots:_vm._u([{key:"button",fn:function(ref){
var loading = ref.loading;
return [(loading)?_c('v-progress-circular',{attrs:{"rotate":-90,"size":18,"width":2,"value":loading,"color":"black"}},[_c('v-icon',{staticClass:"download-icon",attrs:{"color":"black"}},[_vm._v("get_app")])],1):_c('v-icon',_vm._g({attrs:{"color":"grey darken-1"}},on),[_vm._v("receipt_long")])]}}],null,true)})]}}],null,false,2025122247)},[_vm._v(" "+_vm._s(_vm.$t('invoice'))+" ")]):(_vm.campaignRow.ESTIMATE_NUMBER)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('download-estimate',{attrs:{"campaign":_vm.campaignRow},scopedSlots:_vm._u([{key:"button",fn:function(ref){
var loading = ref.loading;
return [(loading)?_c('v-progress-circular',{attrs:{"rotate":-90,"size":18,"width":2,"value":loading,"color":"black"}},[_c('v-icon',{staticClass:"download-icon",attrs:{"color":"black"}},[_vm._v("get_app")])],1):_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v("receipt_long")])]}}],null,true)})]}}])},[_vm._v(" "+_vm._s(_vm.$t('quotation'))+" ")]):_vm._e(),(_vm.isCampaignDeletable)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('delete-campaign',{attrs:{"campaign":_vm.campaignRow},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-icon',_vm._g({attrs:{"color":"grey darken-1"}},on),[_vm._v(" delete ")])]},proxy:true}],null,true)})]}}],null,false,666785526)},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }