









import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { SUPPORTED_TYPES } from '@/app/shared/utilities/object-factory';
import { Nullable } from '@/app/shared/utilities/static-types';

@Component
export default class PreviewThumbnail extends Vue {
  @Prop({ required: true }) public media!: Nullable<string>;
  @Prop({ required: true }) public playable!: boolean;
  public isVideo: boolean = false;
  public SUPPORTED_TYPES = SUPPORTED_TYPES;

  @Watch('media')
  public async mediaChanged(): Promise<void> {
    this.isVideo = await this.checkIsVideo();
  }

  public imageBackground() {
    return this.media
      ? `background-image: url('${this.media}')`
      : `background-image: url('https://user-images.githubusercontent.com/194400/49571717-f392d480-f931-11e8-96f2-a8d10cfe375e.png'); background-size: auto;`;
  }

  public async mounted() {
    this.isVideo = await this.checkIsVideo();
  }

  private async checkIsVideo(): Promise<boolean> {
    try {
      if (this.media && this.media.includes('blob')) {
        const res = await fetch(this.media, { mode: 'no-cors'});
        const blob = await res.blob();
        return blob.type === this.SUPPORTED_TYPES.MP4;
      }
      return Boolean(this.media && this.media.includes('mp4'));
    } catch (error) {
      return false;
    }
  }
}
