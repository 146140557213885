import { SystemFeature } from '@/app/shared/utilities/static-types';
import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';

/**
 * Adds a new System Feature
 * @param systemFeature Input feature.
 */
export const addNewSystemFeature = async (systemFeature: SystemFeature) => {
  const dataRef = await FirebaseAppFirestore
    .collection(firebaseNames.SYSTEM_FEATURES).doc();
  const uid = dataRef.id;
  systemFeature.ID = uid;
  return dataRef.set(systemFeature);
};
