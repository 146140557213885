























import { Prop, Emit, Vue, Watch } from 'vue-property-decorator';

export default class AdSpaceMediaUpload extends Vue {
  @Prop({ required: true, default: [] }) public selectedMedia!: File[];
  public filesRecords = [];

  @Emit('update:selectedMedia')
  public onSelectFiles(fileRecordsNewlySelected: Array<{ file: File }>) {
    const files = this.filesRecords.map(({ file }) => file);
    return files;
  }

  public onBeforeDelete(fileRecord: { file: File }) {
    // @ts-ignore
    const k = this.filesRecords.indexOf(fileRecord);
    if (k !== -1) { this.filesRecords.splice(k, 1); }
    // @ts-ignore
    this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
  }

  @Emit('update:selectedMedia')
  public onDeleteFile(fileRecord: File) {
    const files = this.filesRecords.map(({ file }) => file);
    return files;
  }
}
