


























import { Component, Vue } from 'vue-property-decorator';
import ShashaReturnPolicy from './ShashaReturnPolicy.vue';
@Component({
  components: { ShashaReturnPolicy },
})
export default class ReturnPolicy extends Vue {
  public dialog = false;
}
