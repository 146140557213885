

















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { DocumentReference } from '@firebase/firestore-types';

import ConnectDevicePopup from './components/MyDeviceConnectPopup.vue';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import routesNames from '@/app/shared/utilities/routes-names';
import rules from '@/app/shared/validation-rules';
import {
  getDeviceRefByIdAction,
  removeRegisteredPlayerById,
  savePlayerDeviceConnection,
} from '@screenDevices/shared/actions';
import { MyDevice, Nullable } from '@/app/shared/utilities/static-types';

@Component({
  components: {
    FormComponent,
    ConnectDevicePopup,
  },
  data: (vm: any) => ({
    screenIdRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.shouldNotBeWhitespaces(
        vm.$i18n.t('cannot_be_empty_or_whitespaces'),
      ),
    ],
  }),
})
export default class MyDeviceConnectView extends Vue {
  public deviceName = '';
  public deviceId = '';
  public deviceMainRoute = routesNames.MY_DEVICES;

  public screenId = '';

  public connected = false;

  private deviceRef: Nullable<DocumentReference> = null;
  private registeredId = '';

  public async loadDeviceInformation() {
    const loadedId = this.$route.params.id;
    if (!loadedId) {
      throw new Error('Error loading device.');
    }
    this.deviceRef = await getDeviceRefByIdAction(loadedId);
    const snapshot = await this.deviceRef.get();
    const device = snapshot.data() as MyDevice;
    this.deviceName = device.DISPLAY_NAME;
    this.deviceId = device.ID!;
  }

  public onValidCode(registeredId: string) {
    this.connected = true;
    this.registeredId = registeredId;
  }

  public async cancelDeviceConnection() {
    if (this.registeredId) {
      await removeRegisteredPlayerById(this.registeredId);
    }
    this.$router.push(this.deviceMainRoute);
  }

  public async saveDeviceConnection() {
    await savePlayerDeviceConnection(this.registeredId, this.screenId);
    this.registeredId = '';
    this.$router.push(this.deviceMainRoute);
  }
}
