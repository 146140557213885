import { GetterTree } from 'vuex';
import { NavigationState } from './NavigationTypes';
import { RootState } from '@/app/shared/store/RootState';
import { SystemFeature, Role } from '@/app/shared/utilities/static-types';

export const getters: GetterTree<NavigationState, RootState> = {
  getSystemFeatures: (state: NavigationState): SystemFeature[] => {
    return state.systemFeatures!;
  },
  getUserRoles: (state: NavigationState): Role[] => {
    return state.userRoles!;
  },
};
