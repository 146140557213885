





































import { Component, Vue, Prop } from 'vue-property-decorator';
import { TimeSlot } from '@/app/shared/utilities/static-types';
import PreviewSchedule from './components/PreviewSchedule.vue';
import { throttle } from 'lodash';

@Component({
  components: {
    PreviewSchedule,
  },
})
export default class ScheduleHeader extends Vue {
  @Prop({ required: true }) public timeslots!: TimeSlot[];
  @Prop({ default: 'Week #' }) public weekNumber!: string;
  @Prop({ default: 'Start Date' }) public startDate!: string;
  @Prop({ default: 'End Date' }) public endDate!: string;

  public handleScroll: (() => any) | null = null;

  public previewSchedule() {
    // @ts-ignore
    (this.$refs.preview as PreviewSchedule).open();
  }

  public formatScheduleFreeSlots(timeslots: TimeSlot[]) {
    const freeSlots = timeslots.reduce((freeSlotsCount, slot) => !slot.RESERVED ? freeSlotsCount + 1 : freeSlotsCount, 0);
    return freeSlots
      ? `<b class="green--text">
          Remaining free slots: ${freeSlots} Out of ${timeslots.length}</b>`
      : `<b class="grey--text">
          Fully Booked
        </b>`;
  }

  public mounted() {
    const header = document.getElementById('schedule-header');
    const slotsHeader = document.getElementById('slots-header');
    const sticky = slotsHeader ? slotsHeader!.offsetTop : 0;

    this.handleScroll = throttle(() => {
      if (window.pageYOffset > sticky) {
        header!.classList.add('sticky');
      } else {
        header!.classList.remove('sticky');
      }
    }, 100);
    window.addEventListener('scroll', this.handleScroll);
  }

  public beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll!);
  }
}
