import { Route, RawLocation } from 'vue-router';

import { isAdminUserAction } from '@/app/shared/actions/UserRolesActions';
import routesNames from '@/app/shared/utilities/routes-names';

/**
 * Used to guard Admin pages from basic user access.
 * @param to
 * @param from
 * @param next
 */
export const adminPagesGuard = async (to: Route, from: Route, next: (to?: RawLocation) => void) => {
  const admin = await isAdminUserAction();

  if (admin) {
    next();
  } else {
    next({
      ...routesNames.ACCESS_DENIED,
    });
  }
};

