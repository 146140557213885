var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":true,"width":"600","persistent":""}},[_c('form-component',{attrs:{"form-name":"system-features-form","snackbar-msg":"Shasha Ad Created","on-form-submission":_vm.saveShashaAd},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-toolbar',{staticClass:"between",attrs:{"color":"primary"}},[_c('v-toolbar-title',{attrs:{"id":"header-title"}},[_vm._v(_vm._s(_vm.$t('new_shasha_ad'))+" ")]),_c('div',{attrs:{"id":"close"},on:{"click":function($event){!_vm.loading&&_vm.$emit(_vm.isModalOpenEvent, false)}}},[_c('v-icon',{staticClass:"px-1 white-icon icon",attrs:{"small":""}},[_vm._v("close")])],1)],1)]},proxy:true},{key:"fields",fn:function(){return [_c('v-text-field',{attrs:{"label":_vm.$t('ad_name'),"rules":_vm.requiredRules},model:{value:(_vm.adName),callback:function ($$v) {_vm.adName=$$v},expression:"adName"}}),(_vm.frequencies)?_c('v-select',{attrs:{"items":_vm.frequencies,"return-object":"","rules":_vm.requiredRules,"label":_vm.$t('frequency')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var frequency = ref.item;
return [_vm._v(" "+_vm._s(((frequency.LABEL) + " [ " + (frequency.NAME) + " ]"))+" ")]}},{key:"selection",fn:function(ref){
var frequency = ref.item;
return [_vm._v(" "+_vm._s(((frequency.LABEL) + " [ " + (frequency.NAME) + " ]"))+" ")]}}],null,false,3484683420),model:{value:(_vm.adFrequency),callback:function ($$v) {_vm.adFrequency=$$v},expression:"adFrequency"}}):_vm._e(),_c('media-upload',{ref:"mediaUpload",staticClass:"d-none",on:{"media-selected":function($event){return _vm.changeCampaignMedia($event)}}}),_c('div',{staticClass:"media-container"},[_c('span',{staticClass:"media-title"},[_vm._v(_vm._s(_vm.$t('media')))]),(_vm.mediaPreview)?_c('v-img',{attrs:{"src":_vm.mediaPreview,"width":"300","height":"300","contain":""}}):_vm._e(),_c('v-btn',{staticClass:"px-3 ma-0 upload-btn",attrs:{"small":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.selectMedia($event)}}},[_c('v-icon',{staticClass:"px-1",attrs:{"small":""}},[_vm._v("cloud_upload")]),_vm._v(" "+_vm._s(_vm.$t('upload_new_file'))+" ")],1)],1)]},proxy:true},{key:"actions",fn:function(ref){
var valid = ref.valid;
var saving = ref.saving;
return [_c('v-spacer'),_c('v-btn',{attrs:{"loading":saving,"disabled":_vm.loading},on:{"click":function($event){!_vm.loading && _vm.$emit(_vm.isModalOpenEvent, false)}}},[_vm._v("Close ")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","loading":saving,"disabled":!valid || !_vm.mediaPreview || !_vm.adFrequency}},[_vm._v("Save ")])]}}])}),_c('v-snackbar',{attrs:{"top":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMsg)+" "),_c('v-btn',{attrs:{"color":"primary","flat":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }