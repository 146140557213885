import { WriteBatch } from '@firebase/firestore-types';
import {
  Schedule,
  ScreenCluster,
  AdSpace,
  TimeSlot,
} from '@/app/shared/utilities/static-types';
import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';

/**
 * Updates the Schedule document using ID.
 * @param batch
 * @param scheduleId
 * @param schedule
 */
export const updateScheduleInfo = (
  batch: WriteBatch,
  scheduleId: string,
  schedule: Schedule,
) => {
  const scheduleRef = FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(schedule.AD_SPACE_ID)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.VAL)
    .doc(scheduleId);
  batch.update(scheduleRef, schedule);
};

/**
 * Updates the Schedule document by ID, with selected AdSpace.
 * @param batch
 * @param scheduleId
 * @param schedule
 * @param adSpaceId
 */
export const updateScheduleInfoInsideAdSpace = (
  batch: WriteBatch,
  scheduleId: string,
  schedule: Schedule,
  adSpaceId: string,
) => {
  const scheduleRef = FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(adSpaceId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.VAL)
    .doc(scheduleId);
  batch.update(scheduleRef, schedule);
};

/**
 * Updates Cluster document using ID.
 * @param batch
 * @param clusterId
 * @param cluster
 */
export const updateClusterInfo = (
  batch: WriteBatch,
  clusterId: string,
  cluster: ScreenCluster,
) => {
  const clusterRef = FirebaseAppFirestore
    .collection(firebaseNames.SCREEN_CLUSTERS.VAL)
    .doc(clusterId);
  batch.update(clusterRef, cluster);
};

/**
 * Updates Adspace document using ID.
 * @param batch
 * @param adspaceId
 * @param adSpace
 */
export const updateAdSpaceInfo = (
  batch: WriteBatch,
  adSpaceId: string,
  adSpace: AdSpace,
) => {
  const clusterRef = FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(adSpaceId);
  batch.update(clusterRef, adSpace);
};

const getAllSchedleTimeSlotsDocs = async (schedulesRef: any): Promise<any> => {
  const schedulesSnap = await schedulesRef.get();
  const schedulesDocs = schedulesSnap.docs.map((doc: any) => doc.data());

  const promiseArray: Array<Promise<any>> = [];

  schedulesDocs.forEach((item: any) => {
    const { ID: schedleId } = item;
    const sceduleRef = schedulesRef.doc(schedleId);
    promiseArray.push(sceduleRef.collection(firebaseNames.AD_SPACES.SCHEDULES.TIME_SLOTS).get());
  });

  const allSchedleTimeSlotsSnaps = await Promise.all(promiseArray);
  const allSchedleTimeSlotsDocs = allSchedleTimeSlotsSnaps.map((item) => item.docs.map((doc: any) => doc.data()));
  return [schedulesDocs, allSchedleTimeSlotsDocs];
};

const createTimeSlots = (
  batch: any,
  sceduleRef: any,
  timeSlots: any,
  difference: number,
): void => {
  const maxPlayOrder = timeSlots.length;
  [...Array(difference)].forEach((_, newSlotIndex: number) => {
    const newSlotRef = sceduleRef
      .collection(firebaseNames.AD_SPACES.SCHEDULES.TIME_SLOTS)
      .doc();
    const newSlot = {
      PLAY_ORDER: newSlotIndex + 1 + maxPlayOrder,
      DURATION_SECS: 10,
      ID: newSlotRef.id,
    };
    batch.set(newSlotRef, newSlot);
  });
};

const deleteTimeSlots = (
  batch: any,
  sceduleRef: any,
  timeSlots: any,
  difference: number,
  oldCount: number,
): number => {
  const numberToDelete = Math.abs(difference);
  const sortedTimeSlots = [...timeSlots].sort((x: any, y: any) => x.PLAY_ORDER > y.PLAY_ORDER ? 1 : -1);
  const start = oldCount - numberToDelete;
  const toDeleteSolts = [...sortedTimeSlots]
    .splice(start, oldCount)
    .filter((item: TimeSlot) => !item.CAMPAIGN);
  toDeleteSolts.forEach((slotItem: TimeSlot) => {
    const { ID: slotId } = slotItem;
    const timeSlotRef = sceduleRef
      .collection(firebaseNames.AD_SPACES.SCHEDULES.TIME_SLOTS)
      .doc(slotId);
    batch.delete(timeSlotRef);
  });
  return (-1 * toDeleteSolts.length);
};

const updateFreeTimeSlotsCount = (batch: any, sceduleRef: any, timeSlots: any, difference: number): void => {
  const freeSlots = [...timeSlots].filter(
    (freeSlotItem: any) => !freeSlotItem.RESERVED,
  );
  const freeSlotsCount = freeSlots.length + difference;
  const freeSlotsCountToSave = freeSlotsCount < 0 ? 0 : freeSlotsCount;
  batch.update(sceduleRef, 'FREE_SLOTS_COUNT', freeSlotsCountToSave);
};

/**
 * Updates Adspace time slots.
 * @param batch
 * @param adspaceId
 * @param adSpace
 * @param oldCount
 * @param newCount
 */
export const updateAdSpaceTimeSlots = async (
  batch: WriteBatch,
  adSpaceId: string,
  newCount: number,
) => {
  const schedulesRef = FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(adSpaceId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.VAL);

  const [
    schedulesDocs,
    allSchedleTimeSlotsDocs,
  ] = await getAllSchedleTimeSlotsDocs(schedulesRef);

  schedulesDocs.forEach(
    async (item: any, index: number): Promise<void> => {
      const { ID: schedleId } = item;
      const timeSlots = [...allSchedleTimeSlotsDocs[index]];
      const oldCount = timeSlots.length;
      let difference = newCount - oldCount;
      const isCreate = difference > 0;
      const sceduleRef = schedulesRef.doc(schedleId);
      if (difference && isCreate) {
        createTimeSlots(batch, sceduleRef, timeSlots, difference);
      } else if (difference && !isCreate) {
        difference = deleteTimeSlots(batch, sceduleRef, timeSlots, difference, oldCount);
      }
      updateFreeTimeSlotsCount(batch, sceduleRef, timeSlots, difference);
    },
  );
};
