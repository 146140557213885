






import { Component, Vue, Prop } from 'vue-property-decorator';
import { RegisteredPlayer } from '@/app/shared/utilities/static-types';

import ViewableListItem from '@userApp/shared/components/ViewableListItem.vue';
import PlayerItemContent from './components/PlayerItemContent.vue';

@Component({
  components: {
    ViewableListItem,
    PlayerItemContent,
  },
})
export default class ViewablePlayerItem extends Vue {
  @Prop({ required: true }) public player!: RegisteredPlayer & { ID: string; DEVICE_SCREEN_CODE: string};
}
