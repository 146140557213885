









































































































































import ClustersAndSchedules from '@/app/shared/components/ClustersAndSchedules/ClustersAndSchedules.vue';
import PreviewThumbnail from './PreviewThumbnail.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  AdSpace,
  Campaign, CampaignMedia,
  CampaignTableRow,
  ClustersSchedulesRecord,
  ScreenCluster, Tuple,
} from '@/app/shared/utilities/static-types';
import { cloneDeep } from 'lodash';
import {
  calculateCampaignPrice,
  findFirstMatchingMediaResolution,
  getCampaignSelectedAdSpaces,
} from '@campaigns/shared/utils';
import moment from 'moment';
import { uniqueArray } from '@/app/shared/utilities/helper-functions';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';

@Component({
  components: {
    ClustersAndSchedules,
    PreviewThumbnail,
  },
})
export default class CampaignInfoPopup extends Vue {
  @Prop({ required: true, default: () => ({}) }) public campaign!: CampaignTableRow;
  @Prop({ default: false, type: Boolean }) public showPrice!: boolean;

  public campaignName = '';
  public resolutionsMedia: Array<Tuple<AdSpace['SCREENS_RESOLUTION'], CampaignMedia>> = [];
  public campaignAdSpaces: AdSpace[] = [];
  public campaignResolutions: Array<AdSpace['SCREENS_RESOLUTION']> = [];
  public campaignPrice = 0;
  public campaignClustersSchedules: ClustersSchedulesRecord = {};
  public clustersSchedulesRecord: ClustersSchedulesRecord = {};
  public screenClustersItems: ScreenCluster[] = [];
  public preSelectedScreenClusters: ScreenCluster[] = [];

  public show = false;

  get isChangeMediaRequest() {
    return this.campaign && this.campaign!.MEDIA_CHANGE_REQUEST_STATUS && this.campaign!.MEDIA_CHANGE_REQUEST_STATUS!.VAL === SYSTEM_STATUS.NEW.VAL;
  }
  public open() {
    this.show = true;
  }

  public resolutionText({ WIDTH, HEIGHT, UNIT = 'px' }: AdSpace['SCREENS_RESOLUTION']): string {
    return `${WIDTH}${UNIT} ${this.$t('width')} and ${HEIGHT}${UNIT} ${this.$t(
        'height',
    )} `;
  }

  public get formattedStartDate() {
    return moment(this.campaign.START_DATE).format('DD-MM-YYYY');
  }

  @Watch('campaign')
  public async campaignChanged(val: Campaign) {
    if (val) {
      this.campaignClustersSchedules = this.campaign!.SELECTED_CLUSTERS_SCHEDULES;
      this.campaignName = this.campaign!.NAME;
      this.campaignPrice = calculateCampaignPrice(this.campaign);
      this.clustersSchedulesRecord = cloneDeep(this.campaignClustersSchedules);
      this.screenClustersItems = this.campaign!.SCREEN_CLUSTERS;
      this.preSelectedScreenClusters = cloneDeep(this.screenClustersItems);

      await this.loadCampaignAdSpacesAndShowImages();
    }
  }

  public async loadCampaignAdSpacesAndShowImages() {
    this.campaignAdSpaces = await getCampaignSelectedAdSpaces(this.campaign);

    this.campaignResolutions = uniqueArray(this.campaignAdSpaces, (adSpace) => adSpace.SCREENS_RESOLUTION);

    this.resolutionsMedia = this.campaignResolutions.map((resolution) => {
      const media = findFirstMatchingMediaResolution(this.campaign.MEDIA_LIST || [], resolution);
      return [resolution, media] as Tuple<AdSpace['SCREENS_RESOLUTION'], CampaignMedia>;
    });
  }

}
