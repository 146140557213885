import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';

export default {
  async duplicateDeviceName(value: string, msg: string) {
    if (!value) {
      return [];
    }
    const input = value.trim();
    const currentUser = await getCurrentUser();
    const document = await FirebaseAppFirestore
      .collection(firebaseNames.DEVICES.VAL)
      .doc(currentUser!.uid)
      .collection(firebaseNames.DEVICES.MY_DEVICES)
      .where('DISPLAY_NAME', '==', input).get();
    return document.empty ? [] : msg;
  },
};
