













































import { Component, Vue, Prop } from 'vue-property-decorator';
import { CompanyRegCertificate } from '@/app/shared/utilities/static-types';
import { SUPPORTED_TYPES } from '@/app/shared/utilities/object-factory';

@Component
export default class CompanyRegViewer extends Vue {
  @Prop() public activatorLabel!: string;
  @Prop() public imageSource!: string;
  @Prop() public contentType!: string;
  @Prop() public disabled!: boolean;
  @Prop() public loading!: boolean;

  public dialog = false;

  private isPdf = false;

  public created() {
    this.isPdf = this.contentType === SUPPORTED_TYPES.PDF;
  }
}
