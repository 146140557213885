




































import { Component, Vue, Prop } from 'vue-property-decorator';
import { CampaignPriceInfo } from '@/app/shared/utilities/static-types';

@Component
export default class CampaignPrice extends Vue {
  @Prop({ required: true }) public campaignPriceInfo!: CampaignPriceInfo;

  public get appLocale() {
    return this.$root.$i18n.locale;
  }
}
