import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import { findCampaignByName, isUniqCampaignName } from '@campaigns/shared/actions';
import { AdSpaceSchedules, ScreenCluster } from '@/app/shared/utilities/static-types';
import { isEmpty } from 'lodash';

export const invitationCodeRegex = /^(\w+)(\d+)$/;

export default {
  async duplicateCampaignName(value: string, msg: string) {
    if (!value) {
      return [];
    }
    const input = value.trim();
    const isUniq = await isUniqCampaignName(input);
    return isUniq ? [] : msg;
  },
  /**
   * Check if the editing campaign name already exist.
   * Doesn't match against current campaign name.
   * @param value Input value
   */
  async duplicateCampaignNameForEdit(value: string, currentID: string, msg: string) {
    if (!value) {
      return [];
    }
    const input = value.trim();
    const user = await getCurrentUser();
    const documentSnap = await findCampaignByName(user, input);
    const result = documentSnap.docs.filter((doc) => doc.id !== currentID);
    return result.length === 0 ? [] : msg;
  },
  invitationCodeFormat: (msg: string) => (
    (code: string) => invitationCodeRegex.test(code && code.trim()) || msg
  ),
  minNumberOfWeeks: (min: number, msg: string) =>
    (value: Array<{ selectedScreenCluster: ScreenCluster, selectedClusterSchedules: AdSpaceSchedules }> | undefined) => {
    if (!value || isEmpty(value)) {
      return '';
    }

    const [ selectedClusterSchedulesItem ] = value;
    const { selectedClusterSchedules }   = selectedClusterSchedulesItem;

    return selectedClusterSchedules.length >= min || msg;
  },
};
