





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Promotion, VueClipboardsEvent } from '@/app/shared/utilities/static-types';

@Component
export default class PromoCarousel extends Vue {
  @Prop({ default: [], type: Array }) public promos!: Promotion[];

  public copiedPromoCode: string = '';
  public currentPromoBackgroundStyle = '';

  public showCopyPromoCodeSnackbar = false;

  public onCopyPromoCode(event: VueClipboardsEvent) {
    this.showCopyPromoCodeSnackbar = true;
    this.copiedPromoCode = event.text;
  }

  public currentPromoBackground(index: number) {
    const promoBackgrounds = [
      {
        color1: '#0048db',
        color2: '#7dcefd',
      },
      {
        color1: '#db5900',
        color2: '#fd9a7d',
      },
      {
        color1: '#b90069',
        color2: '#ff87b0',
      },
    ];

    const usedIndex = index <= promoBackgrounds.length - 1 ? index : 0;
    const { color1, color2 } = promoBackgrounds[usedIndex];
    this.currentPromoBackgroundStyle = `linear-gradient(270deg, ${color1}, ${color2}, ${color1});`;
    return this.currentPromoBackgroundStyle;
  }

  public get appLocale() {
    return this.$root.$i18n.locale;
  }

  public slideChanged(index: number) {
    this.currentPromoBackground(index);
  }
}
