import { RouteConfig } from 'vue-router';

import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import { adminPagesGuard } from './admin-nav-guards';

import AdminDashboard from '@admin/screens/Dashboard/AdminDashboard.vue';
import AdSpacesPlaylist from '@adminSchedules/screens/AdSpacesPlaylist/AdSpacesPlaylist.vue';
import ScreenSaver from '@adminSchedules/screens/ScreenSaver/ScreenSaver.vue';
import SystemFeatureNew from '@adminFeatures/screens/SystemFeatureNew.vue';
import ManageUsersMain from '@adminRoles/screens/ManageUsersMain.vue';
import ManageUsersEdit from '@adminRoles/screens/ManageUsersEdit.vue';
import ManageUsersRoles from '@adminRoles/screens/ManageUsersRoles.vue';
import RolesSettings from '@adminRoles/screens/RolesSettings.vue';
import SchedulePlayers from '@adminSchedules/screens/SchedulePlayers/SchedulePlayers.vue';
import ManagePricing from '@adminPricing/screens/ManagePricing/ManagePricing.vue';
import PromotionsMain from '@promotions/screens/PromotionsMain/PromotionsMain.vue';
import CreatePromotion from '@promotions/screens/CreatePromotion/CreatePromotion.vue';
import EditPromotion from '@promotions/screens/EditPromotion/EditPromotion.vue';
import PromotionsList from '@promotions/screens/PromotionsList/PromotionsList.vue';
import PricingMain from '@adminPricing/screens/PricingMain.vue';
import FileMgmt from '@files/screens/FileUpload/FileMgmt.vue';
import { userRolesPageGuard } from '@/app/shared/config/roles-guard';
import { USER_ROLES } from '@/app/shared/utilities/object-factory';
import Register from '@admin/screens/Register/RegisterUi.vue';

export default [
  {
    ...AdminRoutes.ADMIN_DASHBOARD,
    path: 'admin-dashboard',
    component: AdminDashboard,
    beforeEnter: adminPagesGuard,
  },
  {
    ...AdminRoutes.ADSPCACES_PLAYLISTS,
    path: 'adspcaces-playlists',
    component: AdSpacesPlaylist,
    beforeEnter: adminPagesGuard,
  },
  {
    ...AdminRoutes.SCREEN_SAVER,
    path: 'screen-saver',
    component: ScreenSaver,
    beforeEnter: adminPagesGuard,
  },
  {
    path: 'pricing',
    component: PricingMain,
    children: [
      {
        ...AdminRoutes.MANAGE_PRICING,
        path: 'manage-pricing',
        component: ManagePricing,
        beforeEnter: adminPagesGuard,
      },
      {
        path: 'promotions',
        component: PromotionsMain,
        beforeEnter: userRolesPageGuard([USER_ROLES.ADMIN, USER_ROLES.SALES]),
        children: [
          {
            ...AdminRoutes.PROMOTIONS_LIST,
            path: '',
            component: PromotionsList,
          },
          {
            ...AdminRoutes.CREATE_PROMOTION,
            path: 'create',
            component: CreatePromotion,
          },
          {
            ...AdminRoutes.EDIT_PROMOTION,
            path: 'edit/:id',
            component: EditPromotion,
          },
        ],
      },
    ],
  },
  {
    ...AdminRoutes.SYSTEM_FEATURES,
    path: 'system-features',
    component: SystemFeatureNew,
    beforeEnter: adminPagesGuard,
  },
  {
    path: 'user-roles',
    component: ManageUsersMain,
    beforeEnter: adminPagesGuard,
    children: [
      {
        ...AdminRoutes.MANAGE_USERS_ROLES,
        path: '',
        component: ManageUsersRoles,
      },
      {
        ...AdminRoutes.MANAGE_USERS_EDIT,
        path: 'edit/:id',
        component: ManageUsersEdit,
      },
      {
        ...AdminRoutes.REGISTER_USER,
        path: 'create',
        component: Register,
      },
    ],
  },
  {
    ...AdminRoutes.ROLES_SETTINGS,
    path: 'roles-settings',
    component: RolesSettings,
    beforeEnter: adminPagesGuard,
  },
  {
    ...AdminRoutes.SCHEDULE_PLAYERS,
    path: 'schedule-players',
    component: SchedulePlayers,
    beforeEnter: adminPagesGuard,
  },
] as RouteConfig[];
