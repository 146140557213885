import { RouteConfig } from 'vue-router';

import Register from '@publicApp/screens/Register/RegisterUi.vue';
import Login from '@publicApp/screens/Login/LoginUi.vue';
import AccessDenied from '@publicApp/screens/AccessDenied/AccessDenied.vue';
// import LandingPage from '@publicApp/screens/LandingPage/LandingPage.vue';
const ForgetPassword = () => import('@publicApp/screens/ForgetPassword/ForgetPassword.vue');
const ResetPassword = () => import('@publicApp/screens/ResetPassword/ResetPassword.vue');
const Redirect = () => import('@/app/screens/App/screens/Redirect/Redirect.vue');

import routesNames from '@/app/shared/utilities/routes-names';

export default [
  { ...routesNames.HOME, path: '/', component: Login },
  { ...routesNames.LOGIN, path: '/login', component: Login },
  { ...routesNames.FORGET_PASSWORD, path: '/forget-password', component: ForgetPassword },
  { ...routesNames.RESET_PASSWORD, path: '/reset-password', component: ResetPassword },
  { ...routesNames.ACCESS_DENIED, path: '/access-denied', component: AccessDenied },
  {
    ...routesNames.REDIRECT,
    path: '/redirect',
    component: Redirect,
    meta: {
      requiresAuth: true,
    },
  },
] as RouteConfig[];
