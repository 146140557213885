import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import { SystemFeature, AuthorizedUser, Role } from '@/app/shared/utilities/static-types';
import firebaseNames from '@/app/shared/utilities/firebase-names';

/**
 * Updates the current system feature.
 * @param feature Input feature
 */
export const updateSystemFeatureAction = async (feature: SystemFeature) => {
  await FirebaseAppFirestore
    .collection(firebaseNames.SYSTEM_FEATURES)
    .doc(feature.ID)
    .update(feature);
};

/**
 * Gets all registered users.
 */
export const getAllRegisteredUsers = async (): Promise<AuthorizedUser[]> => {
  const dataRef = await FirebaseAppFirestore.collection(firebaseNames.USERS_INFO).get();
  return dataRef.docs.map((doc) => doc.data() as AuthorizedUser);
};

export const updateUserInfo = (userId: string, userInfo: Partial<AuthorizedUser>) => {
  return  FirebaseAppFirestore
    .collection(firebaseNames.USERS_INFO)
    .doc(userId)
    .update(userInfo);
};

/**
 * Gets user by UID.
 * @param uid User ID
 */
export const getUserById = async (uid: string): Promise<AuthorizedUser | undefined> => {
  const dataRef = await FirebaseAppFirestore
    .collection(firebaseNames.USERS_INFO)
    .doc(uid)
    .get();
  const dataDoc = dataRef.data();
  return dataDoc as AuthorizedUser;
};

/**
 * Update user's Roles.
 * @param uid User ID
 * @param roles Roles Array
 */
export const updateUserRoles = async (uid: string, roles: Role[]) => {
  await FirebaseAppFirestore.collection(firebaseNames.USERS_INFO).doc(uid).update({
    ROLES: roles,
  });
};
