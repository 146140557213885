




















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import FormShimmer from '@/app/shared/components/FormShimmer/FormShimmer.vue';

@Component({
  components: { FormShimmer },
})
export default class FormComponent extends Vue {
  @Prop({ required: true }) public formName!: string;
  @Prop() public snackbarMsg!: string;
  @Prop() public publicPage!: boolean;
  @Prop() public resetForm!: boolean;
  @Prop() public onFormSubmission!: () => any;
  @Prop() public onComponentCreated!: () => any;
  @Prop({ type: Boolean, default: true }) public withDialog!: boolean;

  public valid = false;
  public saving = false;
  public loading = false;
  public showError = false;
  public errorMsg = '';
  public snackbar = false;
  public errorHandler = -1;

  public async created() {
    try {
      this.loading = true;
      if (this.onComponentCreated) {
        await this.onComponentCreated();
      }
    } catch (error) {
      this.errorMsg = error.message;
      this.showError = true;
      throw error;
    } finally {
      this.loading = false;
      this.errorHandler = setTimeout(() => {
        this.errorMsg = '';
        this.showError = false;
      }, 20000);
    }
  }

  public onHideSnackbar() {
    this.snackbar = false;
  }

  public onCloseAlert() {
    this.showError = false;
    clearTimeout(this.errorHandler);
  }

  public async submitForm() {
    try {
      this.saving = true;
      if (this.onFormSubmission) {
        await this.onFormSubmission();
      }
      this.snackbar = true;
      if (this.resetForm) {
        (this.$refs[this.formName] as any).reset();
      }
    } catch (error) {
      this.errorMsg = error.message;
      this.showError = true;
      throw error;
    } finally {
      this.saving = false;
      this.errorHandler = setTimeout(() => {
        this.errorMsg = '';
        this.showError = false;
      }, 20000);
    }
  }
}
