

































































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

import { getFilesCollectionRefForCampaigns } from '@files/shared/actions';
import { CampaignMediaFile } from '@/app/shared/utilities/static-types';
import routesNames from '@/app/shared/utilities/routes-names';
import UploadFilesPopup from '@userApp/shared/components/UploadFilesPopup.vue';
import { SUPPORTED_TYPES } from '@/app/shared/utilities/object-factory';

@Component({
  components: {
    UploadFilesPopup,
  },
})
export default class CampaignMedia extends Vue {
  @Prop() public  disabled!: boolean;
  @Prop({ default: null }) public initialSelected!: CampaignMediaFile;

  public dialog = false;

  public filesArray: Array<CampaignMediaFile & { selected: boolean }> = [];

  public fileMgmtRoute = routesNames.FILE_MANAGEMENT;
  private filesArraySubscription: any = null;

  private SUPPORTED_TYPES = SUPPORTED_TYPES;

  public async created() {
    const collectionRef = await getFilesCollectionRefForCampaigns();
    this.filesArraySubscription = collectionRef.onSnapshot((querySnapshot) => {
      this.filesArray = [];
      this.filesArray = querySnapshot.docs
        .map((doc) => doc.data() as CampaignMediaFile)
        .map((file) => ({ selected: false, ...file }));
      if (this.initialSelected) {
        const selectedUrl = this.initialSelected.STATIC_LINK.split('?')[0];
        const selectedFile = this.filesArray.find((file) =>
          file.STATIC_LINK.includes(selectedUrl),
        );
        selectedFile!.selected = true;
      }
    });
  }

  @Emit('media-selected')
  public selectImage(selectedFile: CampaignMediaFile): CampaignMediaFile {
    this.filesArray.forEach((file) => {
      file.selected = file.STATIC_LINK === selectedFile.STATIC_LINK;
    });

    this.close();
    return selectedFile;
  }

  public close() {
    this.dialog = false;
  }

  public async destroyed() {
    this.filesArraySubscription && this.filesArraySubscription();
  }
}
