











































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import CampaignInfoPopup from '@campaigns/shared/components/CampaignInfoPopup.vue';
import ChangeCampaignMedia from '@campaigns/shared/components/ChangeCampaignMedia.vue';
import { Campaign, CampaignTableRow, Nullable } from '@/app/shared/utilities/static-types';
import routesNames from '@/app/shared/utilities/routes-names';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import DeleteCampaign from '@campaigns/shared/components/DeleteCampaign.vue';
import DownloadInvoice from '@campaigns/shared/components/DownloadInvoice.vue';
import DownloadEstimate from '@campaigns/shared/components/DownloadEstimate.vue';

@Component({
  components: {
    DownloadEstimate,
    DownloadInvoice,
    CampaignInfoPopup,
    ChangeCampaignMedia,
    DeleteCampaign,
  },
})
export default class TableActions extends Vue {
  @Prop({ required: true, default: () => ({}) }) public campaignRow!: CampaignTableRow;
  @Prop({ required: true, default: false }) public isCampaignOwner!: boolean;

  public selectedCampaign: Nullable<Campaign> = null;
  public advertiserEditCampaignRoute = routesNames.CAMPAIGN_EDIT;

  public showCampaignMediaPopup(): void {
    this.selectedCampaign = this.campaignRow;
    (this.$refs.campaignMediaPopup as CampaignInfoPopup).open();
  }

  public get isCampaignDeletable(): boolean {
    try {
      const { STATUS: { VAL: status } } = this.campaignRow;
      return status === SYSTEM_STATUS.NEW.VAL && this.isCampaignOwner;
    } catch (error) {
      return false;
    }
  }

  public get isCampaignEditable() {
    try {
      const { STATUS: { VAL: status } } = this.campaignRow;
      return (status === SYSTEM_STATUS.REQUIRES_ACTION.VAL || status === SYSTEM_STATUS.NEW.VAL)
      && this.isCampaignOwner;
    } catch (error) {
      return false;
    }
  }

  public get canChangeMedia() {
    try {
      const { STATUS: { VAL: status }, SUBSCRIPTION, APPROVED_BY_ADMIN } = this.campaignRow;
      return (status === SYSTEM_STATUS.UNDER_PREPARATION.VAL && !APPROVED_BY_ADMIN && this.isCampaignOwner)
          || SUBSCRIPTION;
    } catch (error) {
      return false;
    }
  }

  private editCampaign(): void {
    const { ID: id } = this.campaignRow;
    this.$router.push({ ...this.advertiserEditCampaignRoute , params: { id }});
  }
}
