import { MediaResolution, MediaType } from '@/app/shared/utilities/static-types';
import { errors } from '@/app/shared/config/errors';

export const typeFromMime = (mime: MediaType): string => {
  return mime.split('/')
    .splice(-1)
    .pop() as string;
};

export const getVideoMediaDuration = (file: File): Promise<number> => {
  return new Promise((resolve) => {
    const blobURL = URL.createObjectURL(file);
    const video = document.createElement('video');
    video.style.opacity = '0';
    video.style.position = 'absolute';
    video.addEventListener(
      'loadedmetadata',
      function() {
        const duration = this.duration;
        video.remove();
        resolve(duration);
      },
      false,
    );
    video.src = blobURL;
  });
};
const getImageMediaResolution = (file: File): Promise<MediaResolution> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const img = new Image();

      img.onload = () => {
        const { width, height } = img;
        resolve({
          width,
          height,
          unit: 'px',
        });
      };

      img.src = fileReader.result as string;
    };
    fileReader.onerror = (err) => {
      reject(err);
    };
    fileReader.readAsDataURL(file);
  });
};
export const getVideoMediaResolution = (file: File): Promise<MediaResolution> => {
  return new Promise((resolve, reject) => {
    const blobURL = URL.createObjectURL(file);
    const video = document.createElement('video');
    video.style.opacity = '0';
    video.style.position = 'absolute';
    video.addEventListener(
      'loadedmetadata',
      function() {
        const height = this.videoHeight;
        const width = this.videoWidth;
        video.remove();
        resolve({ height, width, unit: 'px' });
      },
      false,
    );
    video.addEventListener('error', () => {
      video.remove();
      reject(errors.MEDIA_RESOLUTION_NOT_MATCH);
    });
    video.src = blobURL;
  });
};
export const getMediaResolution = (file: File): Promise<MediaResolution> => {
  const { type } = file;
  const isVideo = type.includes('video');

  return isVideo
    ? getVideoMediaResolution(file)
    : getImageMediaResolution(file);
};
export const checkMediaResolution = async (
  file: File,
  { width: requiredMediaWidth, height: requiredMediaHeight }: MediaResolution,
) => {
  try {
    if (!requiredMediaWidth && !requiredMediaHeight) {
      return;
    }
    const { width: mediaWidth, height: mediaHeight } = await getMediaResolution(
      file,
    );
    const requiredAspectRatio = requiredMediaWidth / requiredMediaHeight;
    const tolerance = requiredAspectRatio * 0.1;
    const minAspectRatio = requiredAspectRatio - tolerance;
    const maxAspectRatio = requiredAspectRatio + tolerance;
    const fileAspectRatio = mediaWidth / mediaHeight;
    if (fileAspectRatio < minAspectRatio || fileAspectRatio > maxAspectRatio) {
      throw new Error(errors.MEDIA_RESOLUTION_NOT_MATCH);
    }
    return { width: mediaWidth, height: mediaHeight };
  } catch (error) {
    throw new Error(errors.MEDIA_RESOLUTION_NOT_MATCH);
  }
};
