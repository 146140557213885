









































import { Component, Vue } from 'vue-property-decorator';

import { MyDevice } from '@/app/shared/utilities/static-types';
import { getStoreDevicesRefAction } from '@screenDevices/shared/actions';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import { DocumentReference } from '@firebase/firestore-types';

import routesNames from '@/app/shared/utilities/routes-names';
import MyDevicesList from '@userApp/shared/components/ListView.vue';
import ClickableDeviceItem from '@userApp/shared/components/ScreenDeviceItem/ClickableDeviceItem.vue';
import ViewableDeviceItem from '@userApp/shared/components/ScreenDeviceItem/ViewableDeviceItem.vue';
import namespace from '@/app/shared/store/namespace';

@Component({
  components: {
    MyDevicesList,
    ViewableDeviceItem,
    ClickableDeviceItem,
  },
})
export default class StoreDevicesHome extends Vue {
  public loading = true;

  public storeDevices: MyDevice[] = [];
  public pendingDeviceColor = SYSTEM_STATUS.PENDING_APPROVAL.COLOR;

  public storeDeviceRoute = routesNames.STORE_DEVICE_VIEW;

  private refs: DocumentReference[] = [];

  public deviceIsPendingConfirmation(device: MyDevice) {
    return device.STATUS.VAL === SYSTEM_STATUS.PENDING_CONFIRMATION.VAL;
  }

  public async created() {
    try {
      this.loading = true;
      // TODO: Refactor the store's devices fetch, by denormalizing the DB
      this.refs = await getStoreDevicesRefAction();
      const promises = this.refs.map(async (ref) => {
        const snap = await ref.get();
        return snap.data() as MyDevice;
      });
      this.storeDevices = await Promise.all(promises);
    } finally {
      this.loading = false;
    }
  }

  public setDeviceRef(index: number) {
    const targetRef = this.refs[index];
    this.$store.commit(
      `${namespace.MyDeviceModule}/setCurrentStoreDeviceRef`,
      targetRef,
    );
  }
}
