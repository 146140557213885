





















































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

import FormComponent from '@/app/shared/components/FormComponent.vue';
import rules from '@/app/shared/validation-rules';
import {
  devicePassCodeExists,
  connectDeviceById,
  registerNewPlayer,
} from '@screenDevices/shared/actions';
import { DocumentReference } from '@firebase/firestore-types';
import { RegisteredPlayer } from '@/app/shared/utilities/static-types';

@Component({
  components: {
    FormComponent,
  },
  data: (vm: any) => ({
    requiredRule: [rules.required(vm.$i18n.t('field_required'))],
  }),
})
export default class MyDeviceConnectPopup extends Vue {
  @Prop() public deviceRef!: DocumentReference;
  @Prop() public deviceName!: string;
  @Prop() public deviceId!: string;

  public show = false;
  public codeIsValid = false;
  public checkingCode = false;
  public badgeColor = 'red';

  public passCode = '';
  public passCodeHint = '';
  public passCodeErrors: string | string[] = [];

  private targetPasscodeId = '';

  @Watch('passCode')
  public async onPassCodeChanged(val: string, oldVal: string) {
    try {
      this.checkingCode = true;
      this.passCodeErrors = this.$t('validating_code').toString();
      this.passCodeErrors = await this.validatePassCode(val);
      this.codeIsValid = this.isPassCodeValid();
      if (this.codeIsValid) {
        const registeredPlayer: RegisteredPlayer = {
          DEVICE_REF: this.deviceRef,
          CONNECTION: 'PENDING',
        };
        const registeredId = await registerNewPlayer(registeredPlayer);
        await connectDeviceById(registeredId, this.targetPasscodeId);
        this.passCodeHint = this.$t('pass_code_accepted').toString();
        this.$emit('valid-code', registeredId);
        setTimeout(() => {
          this.show = false;
        }, 3000);
      }
    } catch (error) {
      this.passCodeErrors = this.$t('pass_code_expired').toString();
      this.codeIsValid = false;
    } finally {
      this.checkingCode = false;
    }
  }

  @Watch('codeIsValid')
  public onCodeIsValidChanged(val: boolean, oldVal: boolean) {
    this.badgeColor = val ? 'green' : 'red';
  }

  @Emit()
  public validCode(registeredId: string) {
    return registeredId;
  }

  /**
   * Check if the pass code is valid.
   * @param value Pass code value
   */
  private async validatePassCode(value: string) {
    if (!value) {
      return [];
    }
    const passCodeExists = await devicePassCodeExists(value);
    this.targetPasscodeId = passCodeExists ? passCodeExists : '';
    return passCodeExists ? [] : this.$t('invalid_pass_code').toString();
  }

  private isPassCodeValid() {
    if (!this.passCode) {
      return false;
    }
    return (this.passCodeErrors as string[]).length === 0;
  }
}
