import routesNames, { AdminRoutes } from './routes-names';

export const COMMON_NAV_ITEMS = [
  {
    title: 'Company Profile',
    to: routesNames.COMPANY_MANAGEMENT,
    icon: 'business',
    exact: true,
  },
  {
    title: 'File Management',
    to: routesNames.FILE_MANAGEMENT,
    icon: 'cloud',
    exact: true,
  },
];

export const ADVERTISER_NAV_ITEMS = [
  {
    title: 'My Campaigns',
    to: routesNames.MY_CAMPAIGNS,
    icon: 'theaters',
    exact: false,
  },
];

export const SCREEN_OWNER_NAV_ITEMS = [];

export const STORE_OWNER_NAV_ITEMS = [
  {
    title: 'Store\'s Campaigns',
    to: routesNames.MANAGE_STORE_CAMPAIGNS,
    icon: 'movie',
    exact: false,
  },
];

export const ADMIN_NAV_ITEMS = [
  {
    title: 'Main',
    icon: 'home',
    children: [
      {
        title: 'File management',
        icon: 'cloud',
        to: routesNames.FILE_MANAGEMENT,
        exact: true,
      },
    ],
  },
  {
    title: 'Stores',
    icon: 'storefront',
    children: [
      {
        title: 'Approved Branches',
        to: AdminRoutes.APPROVED_BRANCHES,
        icon: 'done_all',
        exact: true,
      },
      {
        title: 'Pending Branches',
        to: AdminRoutes.PENDING_BRANCHES,
        icon: 'av_timer',
        exact: true,
      },
      // {
      //   title: 'Create Store Owner',
      //   to: AdminRoutes.CREATE_STORE_OWNER,
      //   icon: 'people_outline',
      //   exact: true,
      // },
      {
        title: 'Store Owners',
        to: AdminRoutes.STORE_OWNERS,
        icon: 'people_outline',
        exact: true,
      },
      {
        title: 'Regulators',
        to: AdminRoutes.REGULATORS,
        icon: 'admin_panel_settings',
        exact: true,
      },
      {
        title: 'All Ad Spaces',
        to: AdminRoutes.ALL_AD_SPACES,
        icon: 'store',
        exact: true,
      },
      {
        title: 'Create Ad Space',
        to: AdminRoutes.CREATE_STORE_AD_SPACE,
        icon: 'add_business',
        exact: true,
      },
      {
        title: 'Store\'s Campaigns',
        icon: 'event_note',
        children: [
          {
            title: 'Approved by Admin',
            to: AdminRoutes.STORE_APPROVED_CAMPAIGNS,
            icon: 'done_all',
            exact: true,
          },
          {
            title: 'Pending Admin Approval',
            to: AdminRoutes.STORE_PENDING_CAMPAIGNS,
            icon: 'av_timer',
            exact: true,
          },
          {
            title: 'Other campaigns',
            to: AdminRoutes.STORE_OTHER_CAMPAIGNS,
            icon: 'check_circle_outline',
            exact: true,
          },
        ],
      },
    ],
  },
  {
    title: 'Screens',
    icon: 'tv',
    children: [
      {
        title: 'Approved Screens',
        to: AdminRoutes.APPROVED_SCREENS,
        icon: 'done_all',
        exact: true,
      },
      {
        title: 'Pending Screens',
        to: AdminRoutes.PENDING_SCREENS,
        icon: 'av_timer',
        exact: true,
      },
      {
        title: 'Registered Players',
        to: AdminRoutes.REGISTERED_PLAYERS,
        icon: 'settings_system_daydream',
        exact: true,
      },
      {
        title: 'Create Screen Owner',
        to: AdminRoutes.CREATE_SCREEN_OWNER,
        icon: 'people_outline',
        exact: true,
      },
      {
        title: 'Create Screen Cluster',
        to: AdminRoutes.CREATE_SCREEN_CLUSTER,
        icon: 'add_to_queue',
        exact: true,
      },
    ],
  },
  {
    title: 'Advertisers',
    icon: 'local_play',
    children: [
      {
        title: 'Company Profiles',
        icon: 'business',
        children: [
          {
            title: 'Approved Profiles',
            to: AdminRoutes.APPROVED_PROFILES,
            icon: 'done_all',
            exact: true,
          },
          {
            title: 'Pending Profiles',
            to: AdminRoutes.PENDING_PROFILES,
            icon: 'av_timer',
            exact: true,
          },
        ],
      },
      {
        title: 'Advertiser\'s Campaigns',
        icon: 'event_note',
        children: [
          {
            title: 'Approved by Admin',
            to: AdminRoutes.ADVERTISER_APPROVED_CAMPAIGNS,
            icon: 'done_all',
            exact: true,
          },
          {
            title: 'Pending Admin Approval',
            to: AdminRoutes.ADVERTISER_PENDING_ADMIN_CAMPAIGNS,
            icon: 'av_timer',
            exact: true,
          },
          {
            title: 'Pending Store Owner Approval',
            to: AdminRoutes.ADVERTISER_PENDING_STORE_OWNER_CAMPAIGNS,
            icon: 'av_timer',
            exact: true,
          },
          {
            title: 'Other Campaigns',
            to: AdminRoutes.ADVERTISER_OTHER_CAMPAIGNS,
            icon: 'check_circle_outline',
            exact: true,
          },
        ],
      },
      {
        title: 'Advertiser\'s User List',
        icon: 'user',
        to: AdminRoutes.ADVERTISER_USER_LIST,
        exact: true,
      },
    ],
  },
  {
    title: 'Playlists',
    icon: 'schedule',
    children: [
      {
        title: 'Ad Spaces Playlists',
        to: AdminRoutes.ADSPCACES_PLAYLISTS,
        icon: 'settings',
        exact: true,
      },
      {
        title: 'Schedule Players',
        to: AdminRoutes.SCHEDULE_PLAYERS,
        icon: 'sync_alt',
        exact: true,
      },
      {
        title: 'Screen Saver',
        to: AdminRoutes.SCREEN_SAVER,
        icon: 'picture_in_picture',
        exact: true,
      },
    ],
  },
  {
    title: 'Pricing',
    icon: 'money',
    children: [
      {
        title: 'Manage Pricing',
        to: AdminRoutes.MANAGE_PRICING,
        icon: 'monetization_on',
        exact: true,
      },
      {
        title: 'Promotions',
        to: AdminRoutes.PROMOTIONS_LIST,
        icon: 'loyalty',
        exact: true,
      },
    ],
  },
  {
    title: 'Roles & Permissions',
    icon: 'security',
    children: [
      {
        title: 'System Features',
        to: AdminRoutes.SYSTEM_FEATURES,
        icon: 'list',
        exact: true,
      },
      {
        title: 'Manage Users',
        to: AdminRoutes.MANAGE_USERS_ROLES,
        icon: 'person_pin_circle',
        exact: false,
      },
      {
        title: 'Settings',
        to: AdminRoutes.ROLES_SETTINGS,
        icon: 'settings',
        exact: true,
      },
    ],
  },
];
