















import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { LocationData, Promotion } from '@/app/shared/utilities/static-types';

@Component({
  components: {},
})
export default class PromotionLocations extends Vue {
  @Prop({ required: true }) public promotion!: Promotion;
  @Prop({ required: true }) public locations!: LocationData[];
}
