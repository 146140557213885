import { FirebaseAppFirestore, FirebaseAppFunctions } from '@/app/shared/firebase/firebase-app';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import {
  Campaign,
  TimeSlot,
  CampaignRequest,
  CampaignPriceInfo,
  InvitationCode,
  ScreenCluster,
  MyDeviceInfo,
  CampaignCheckoutRequest,
  PaymentMetaData,
  Omit,
  CampaignPaymentRequest,
  AdSpace,
  BillingContact,
  BillingPhoneNumber,
  RecentBillingContact,
  PublishedCampaign,
  Base64, PendingPaymentCampaign, CampaignTuple, ShashaAd,
} from '@/app/shared/utilities/static-types';
import { DocumentReference, DocumentSnapshot, QuerySnapshot } from '@firebase/firestore-types';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import { User } from '@firebase/auth-types';
import firebase from 'firebase';
import firestore = firebase.firestore;
import moment from 'moment';

/**
 * Create a campaign for current user.
 * @param campaign Input campaign
 */
export const createCampaignAction = async (campaign: Omit<Campaign, 'ADVERTISER_UID'>) => {
  const currentUser = await getCurrentUser();
  const campaignRef = FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc();
  const campaignId = campaignRef.id;
  campaign.ID = campaignId;
  campaign.ADVERTISER_UID = currentUser!.uid;
  await campaignRef.set(campaign as Campaign);
  return campaignId;
};
/**
 * Update a campaign for a user.
 * @param campaign Input campaign
 */
export const updateUserCampaignAction = async (campaign: Campaign, updatedData: Partial<Campaign>) => {
  const { ADVERTISER_UID: userId } = campaign;
  await FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaign.ID)
    .update(updatedData);
};

/**
 * Update a campaign for current user.
 * @param campaign Input campaign
 */
export const updateCampaignAction = async (campaign: Partial<Campaign>) => {
  const currentUser = await getCurrentUser();
  await FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaign.ID)
    .update(campaign);
};

/**
 * Update a campaign for an Advertiser.
 * @param campaign Input campaign
 */
export const updateAdvertiserCampaignAction = async (campaign: Campaign) => {
  await FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(campaign.ADVERTISER_UID)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaign.ID)
    .update(campaign);
};

/**
 * Load files from current user repository.
 */
export const getCurrentMediaFilesForCampaignsAction = async () => {
  const currentUser = await getCurrentUser();
  const filesSnap = await FirebaseAppFirestore
    .collection(firebaseNames.MEDIA_FILES.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.MEDIA_FILES.FOR_CAMPAIGNS)
    .get();
  return filesSnap.docs.map((doc) => doc.data());
};

/**
 * @description
 * Subscribe to the updates of the campaigns of current user
 * @param onChangeCampaigns {function(Campaign[])} - A callback to handle listener updates
 *
 * @returns {function} - A callback to destroy the listener
 */

export const subscribeToUserCampaigns = async (onChangeCampaigns: (campaigns: CampaignTuple[]) => Promise<void>) => {
  const currentUser = await getCurrentUser();
  return FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .onSnapshot(async (querySnapshot) => {
      // querySnapshot.docChanges().forEach((change) => {
        // if (change.type === 'added') {
          /* tslint:disable-next-line */
          // console.log('New campaign: ', change.doc.data());
        // }
        // if (change.type === 'modified') {
          /* tslint:disable-next-line */
          // console.log('Modified campaign: ', change.doc.data());
        // }
        // if (change.type === 'removed') {
          /* tslint:disable-next-line */
          // console.log('Removed campaign: ', change.doc.data());
        // }
      // });

      const campaigns = querySnapshot.docs.map((doc) => [doc.data(), doc.ref ] as CampaignTuple);
      return onChangeCampaigns(campaigns);
    });
};

/**
 * Load campaigns DocumentReference for current user.
 */
export const loadCampaignsReferencesAction = async (): Promise<DocumentReference[]> => {
  const currentUser = await getCurrentUser();
  const campaignsSnap = await FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .get();
  return campaignsSnap.docs.map((doc) => doc.ref);
};

/**
 * Load all campaigns assigned to current user's store.
 */
export const loadStoreCampaignsReferencesAction = async (): Promise<DocumentReference[]> => {
  const currentUser = await getCurrentUser();
  const campaignsSnap = await FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.CAMPAIGNS.STORE_CAMPAIGNS)
    .get();

  if (campaignsSnap.empty) {
    return [];
  }

  const dataPromiseArray = campaignsSnap.docs.map(async (doc) => {
    const campaignRef = doc.data().campaignRef;
    return campaignRef;
  });
  return Promise.all(dataPromiseArray);
};

/**
 * Load a campaign by ID for current user.
 * @param campaignId - campaign ID
 */
export const loadCampaignAction = async (campaignId: string): Promise<Campaign> => {
  const currentUser = await getCurrentUser();
  const getCampaignFn = FirebaseAppFunctions
    .httpsCallable(firebaseNames.functions.GET_CAMPAIGN);
  const campaignRequest: CampaignRequest = {
    ID: campaignId,
    ADVERTISER_UID: currentUser!.uid,
  };
  const { data } = await getCampaignFn(campaignRequest);
  return data;
};

/**
 * @description
 * Load the campaign payment info using it's ID for current user.
 * @param campaignId
 * @param promoCode
 */
export const getCampaignPaymentInfoAction = async (campaignId: string, promoCode?: string): Promise<CampaignPriceInfo | null> => {
  const currentUser = await getCurrentUser();
  const userId = currentUser!.uid;

  const campaignPaymentRequest: CampaignPaymentRequest = {
    campaignInfo: {
      ID: campaignId,
      ADVERTISER_UID: userId,
    },
    promoCode,
  };

  const getCampaignFn = FirebaseAppFunctions
    .httpsCallable(firebaseNames.functions.CAMPAIGN_PAYMENT_INFO);
  const { data } = await getCampaignFn(campaignPaymentRequest);
  return data;
};

/**
 * Load schedule's timeslots by schedule ID.
 * @param scheduleId
 */
export const loadScheduleTimeslotsAction = async (scheduleId: string) => {
  const timeslotSnap = await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.SCHEDULES.VAL)
    .doc(scheduleId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.TIME_SLOTS)
    .get();
  return timeslotSnap.docs.map((doc) => doc.data() as TimeSlot).sort((a, b) => {
    return a.PLAY_ORDER - b.PLAY_ORDER;
  });
};

/**
 * Update a timeslot object in a schedule.
 * @param scheduleId
 * @param timeslot
 */
export const updateScheduleTimeslotAction = async (scheduleId: string, timeslot: TimeSlot) => {
  await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.SCHEDULES.VAL)
    .doc(scheduleId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.TIME_SLOTS)
    .doc(timeslot.ID)
    .update(timeslot);
};

export const findCampaignByName = async (user: User | null, campaignName: string): Promise<QuerySnapshot> => {
  const currentUser = await getCurrentUser();
  return FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .where('NAME', '==', campaignName)
    .get();
};

export const isUniqCampaignName = async (campaignName: string) => {
  const user = await getCurrentUser();
  if (!user) {
    return true;
  }

  const documentSnap = await findCampaignByName(user, campaignName);
  return documentSnap.empty;
};

export const getScreenClustersById = async (clusterId: string) => {
  const snap = await FirebaseAppFirestore
    .collection(firebaseNames.SCREEN_CLUSTERS.VAL)
    .doc(clusterId)
    .get();
  return snap.data() as ScreenCluster;
};

export const getDevicesInfoByClusterId = async (clusterId: string) => {
  const snap = await FirebaseAppFirestore
    .collection(firebaseNames.SCREEN_CLUSTERS.VAL)
    .doc(clusterId)
    .collection(firebaseNames.SCREEN_CLUSTERS.CONNECTED_DEVICES)
    .get();
  return snap.docs.map((doc) => doc.data() as MyDeviceInfo);
};


/**
 * @returns {string} Payment URL
 */
export const checkoutCampaign = async (campaignId: string, promoCode?: string): Promise<PaymentMetaData> => {
  const currentUser = await getCurrentUser();
  const uid = currentUser!.uid;

  const checkoutCampaignFn = FirebaseAppFunctions
    .httpsCallable(firebaseNames.functions.CHECKOUT_CAMPAIGN);
  const request: CampaignCheckoutRequest = {
    campaignRequest: {
      ID: campaignId,
      ADVERTISER_UID: uid,
    },
    promoCode,
  };
  const { data } = await checkoutCampaignFn(request);
  return data;
};

/**
 * @description
 * Store owner campaign checkout
 */
export const storeOwnerCampaignCheckout = async (campaign: Campaign): Promise<void> => {
  const currentUser = await getCurrentUser();
  const { ID: campaignId } = campaign;
  const campaignRef = FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);
  campaignRef.update('STATUS', SYSTEM_STATUS.UNDER_PREPARATION);
};

export const redeemInvitationCode = async (invitationCode: string): Promise<InvitationCode | null> => {
  const redeemInvitationCodeFn = FirebaseAppFunctions
    .httpsCallable(firebaseNames.functions.REDEEM_INVITATION_CODE);
  const { data } = await redeemInvitationCodeFn(invitationCode);
  return data;
};

/**
 * @description
 * Subscribe to the updates of the company profile of current user
 * @param campaignId {string}
 * @param handleListener {function(DocumentSnapshot)} - A callback to handle listener updates
 *
 * @returns {function} - A callback to destroy the listener
 */
export const subscribeToCampaignAction =
  async (campaignId: string, handleListener: (doc: DocumentSnapshot) => void) => {
    const currentUser = await getCurrentUser();
    const campaignRef = FirebaseAppFirestore
      .collection(firebaseNames.CAMPAIGNS.VAL)
      .doc(currentUser!.uid)
      .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
      .doc(campaignId);

    return campaignRef.onSnapshot(handleListener);
  };

/**
 * Load store owner created campaigns
 */
export const loadStoreOwnerCreatedCampaignsTuples = async (): Promise<CampaignTuple[]> => {
  const currentUser = await getCurrentUser();
  const campaignsSnap = await FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .get();

  if (campaignsSnap.empty) {
    return [];
  }

  return campaignsSnap.docs.map((doc) => {
    return [ doc.data(), doc.ref ] as CampaignTuple;
  });
};

/**
 * Load store campaigns by reference
 */
export const loadStoreCampaignsTuples = async (): Promise<CampaignTuple[]> => {
  const campaignsRef = await loadStoreCampaignsReferencesAction();
  const promises = campaignsRef.map(async (ref) => {
    const snap = await ref.get();
    return [snap.data(), ref] as CampaignTuple;
  });
  return Promise.all(promises);
};

/**
 * Load store owner campaigns
 */
export const loadStoreOwnerCampaignsAction = async (): Promise<CampaignTuple[]> => {
  try {
    const storeCampaigns = await loadStoreCampaignsTuples();
    const storeCreatedCampaigns = await loadStoreOwnerCreatedCampaignsTuples();
    return [
      ...storeCampaigns,
      ...storeCreatedCampaigns,
    ];
  } catch (err) {
    /* tslint:disable-next-line */
    console.error(err.stack);
    throw err;
  }
};

/**
 * @description
 * Get all Screen clusters in Ad spaces of store owner.
 */
export const getStoreScreenClusters = async (): Promise<ScreenCluster[]> => {
  const currentUser = await getCurrentUser();
  const userAdSpacesSnap = await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .where('SPACE_OWNER_ID', '==', currentUser!.uid)
    .get();

  if (userAdSpacesSnap.empty) {
    return [];
  }

  const storeScreenClustersSnaps = userAdSpacesSnap.docs
    .map((item) => item.data() as AdSpace)
    .filter((item) => item.SCREEN_CLUSTER_REF)
    .map((item) => item.SCREEN_CLUSTER_REF as DocumentReference<ScreenCluster>)
    .map((item) => item.get());

  return (await Promise.all(storeScreenClustersSnaps)).map((item) => item.data()) as ScreenCluster[];
};

/**
 * Load recent billing contact
 */
export const loadRecentBillingContactAction = async (): Promise<RecentBillingContact> => {
  const currentUser = await getCurrentUser();
  const billingContactSnap = await FirebaseAppFirestore
    .collection(firebaseNames.COMPANY_PROFILES)
    .doc(currentUser!.uid)
    .collection(firebaseNames.BILLING_CONTACTS)
    .orderBy('CREATED_AT', 'desc')
    .limit(1)
    .get();

  if (!billingContactSnap.empty) {
    const [ { EMAIL: email, PHONES: [ { VALUE: phone } ] } ] = billingContactSnap.docs.map((doc) => doc.data() as BillingContact);
    return { email, phone };
  }

  return new RecentBillingContact();
};

/**
 * Load billing by email
 */
const loadBillingContactByEmail = async (email: string): Promise<BillingContact> => {
  const currentUser = await getCurrentUser();
  const billingContactSnap = await FirebaseAppFirestore
    .collection(firebaseNames.COMPANY_PROFILES)
    .doc(currentUser!.uid)
    .collection(firebaseNames.BILLING_CONTACTS)
    .where('EMAIL', '==', email)
    .get();
  const [recentBillingContact] = billingContactSnap.docs.map((doc) => doc.data() as BillingContact);
  return recentBillingContact;
};

/**
 * Create billing contact
 * @param data RecentBillingContact
 */
const createBillingContact = async ({ email: EMAIL, phone: VALUE }: RecentBillingContact): Promise<void> => {
  const currentUser = await getCurrentUser();
  const docRef = FirebaseAppFirestore
      .collection(firebaseNames.COMPANY_PROFILES)
      .doc(currentUser!.uid)
      .collection(firebaseNames.BILLING_CONTACTS)
      .doc();
  const { id: ID } = docRef;
  const CREATED_AT = firebase.firestore.Timestamp.now();
  docRef.set({
    ID,
    EMAIL,
    CREATED_AT,
    PHONES: [
      {
        VALUE,
        CREATED_AT,
      },
    ],
  });
};

/**
 * Update billing contact phone
 * @param billingContact BillingContact
 */
const updateBillingContact = async (billingContact: BillingContact): Promise<void> => {
  const currentUser = await getCurrentUser();
  const CREATED_AT = firebase.firestore.Timestamp.now();
  const { ID } = billingContact;
  const docRef = FirebaseAppFirestore
    .collection(firebaseNames.COMPANY_PROFILES)
    .doc(currentUser!.uid)
    .collection(firebaseNames.BILLING_CONTACTS)
    .doc(ID);
  docRef.set(
    {
      ...billingContact,
      CREATED_AT,
    },
    { merge: true },
  );
};

const updateBillingContactPhonesList = (billingContact: BillingContact, phone: string): BillingPhoneNumber[] => {
  const isPhoneExists = billingContact && Boolean(billingContact.PHONES.find((item) => item.VALUE === phone));
  const VALUE = phone;
  const CREATED_AT = firebase.firestore.Timestamp.now();
  return isPhoneExists
    ? billingContact.PHONES
    : [
      {
        VALUE,
        CREATED_AT,
      },
      ...billingContact.PHONES,
    ];
};

/**
 * Update recent billing contact
 * @param data RecentBillingContact
 */
export const updateRecentBillingContactAction = async ({ email, phone }: RecentBillingContact): Promise<void> => {
  const billingContact = await loadBillingContactByEmail(email);
  if (!billingContact) {
    return createBillingContact({ email, phone });
  }
  const PHONES = updateBillingContactPhonesList(billingContact, phone);
  const updatedBillingContact = {
    ...billingContact,
    PHONES,
  };
  return updateBillingContact(updatedBillingContact);
};

/**
 * Approve campaign as store owner.
 * @param campaign Campaign
 */
export const approveCampaignAction = async (campaign: Campaign): Promise<void> => {
  const currentUser = await getCurrentUser();
  const { uid } = currentUser!;
  const { ADVERTISER_UID: userId, ID: campaignId } = campaign;
  const campaignRef = FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);
  return campaignRef.update({
    ACTION_STORE_OWNER_ID: uid,
    APPROVED_BY_STORE_OWNER: true,
  });
};

/**
 * Reject campaign as store owner.
 * @param campaign Campaign
 */
export const rejectCampaignAction = async (campaign: Campaign): Promise<void> => {
  const currentUser = await getCurrentUser();
  const { uid } = currentUser!;
  const { ADVERTISER_UID: userId, ID: campaignId } = campaign;
  const campaignRef = FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);
  return campaignRef.update({
    ACTION_STORE_OWNER_ID: uid,
    APPROVED_BY_STORE_OWNER: false,
    STATUS: SYSTEM_STATUS.REQUIRES_ACTION,
  });
};

/**
 * Delete campaign.
 * @param campaign Campaign
 */
export const deleteCampaignAction = async (campaign: Campaign): Promise<void> => {
  const { ADVERTISER_UID: userId, ID: campaignId } = campaign;
  const campaignRef = FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);
  return campaignRef.delete();
};

export const downloadInvoice = async (invoiceNumber: PublishedCampaign['INVOICE_NUMBER']): Promise<Base64> => {
  const downloadInvoiceFn = FirebaseAppFunctions.httpsCallable(
    firebaseNames.functions.DOWNLOAD_INVOICE,
  );
  const { data } = await downloadInvoiceFn(invoiceNumber);
  return data;
};

export const downloadEstimate = async (estimateNumber: PendingPaymentCampaign['ESTIMATE_NUMBER']): Promise<Base64> => {
  const downloadEstimateFn = FirebaseAppFunctions.httpsCallable(
    firebaseNames.functions.DOWNLOAD_ESTIMATE,
  );
  const { data } = await downloadEstimateFn(estimateNumber);
  return data;
};


/**
 * Change Campaign Start Date.
 * @param campaign Campaign
 */
export const changeCampaignStartDateAction = async (campaign: Campaign): Promise<void> => {
  const { ADVERTISER_UID: userId, ID: campaignId, START_DATE, DURATION_IN_WEEKS } = campaign;

  const startDateMoment = moment(START_DATE);
  const startDateTimeStamp = firestore.Timestamp.fromDate(
    startDateMoment.toDate(),
  );
  const endDateMoment = startDateMoment.add(
    DURATION_IN_WEEKS,
    'weeks',
  );
  const endDateTimeStamp = firestore.Timestamp.fromDate(
    endDateMoment.toDate(),
  );

  const campaignRef = FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);
  return campaignRef.update({
    START_DATE: startDateTimeStamp,
    END_DATE: endDateTimeStamp,
  });
};
export const createShashaAd = async (shashaAd: ShashaAd) => {
  const createShashaAdFn = FirebaseAppFunctions.httpsCallable(
    firebaseNames.functions.CREATE_SHASHA_AD,
  );
  return createShashaAdFn(shashaAd);
};
