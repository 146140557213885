


















import { Component, Vue, Prop } from 'vue-property-decorator';
import { AdSpace } from '@/app/shared/utilities/static-types';

@Component({ name: 'AdSpaceSelectItem' })
export default class AdSpaceSelectItem extends Vue {
  @Prop({ required: true }) public adSpace!: AdSpace;
  @Prop({ type: Boolean }) public showBranch!: boolean;
  @Prop({ type: Boolean }) public showResolution!: boolean;
}
