















import { Component, Vue } from 'vue-property-decorator';

import ToolBar from '@/app/shared/components/ToolBar/ToolBar.vue';
import SideDrawer from './components/SideDrawer.vue';
import UserProfile from './components/UserProfile.vue';
import AdminSideDrawer from './components/AdminSideDrawer.vue';

@Component({
  components: {
    ToolBar,
    SideDrawer,
    AdminSideDrawer,
    UserProfile,
  },
})
export default class AppLayout extends Vue {}
