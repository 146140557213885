




























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  loadScreenSaverAction,
} from '@adminSchedules/shared/actions';
import {
  CampaignMedia,
  MediaType,
  ScreenResolution,
  AdSPaceScreenSaver,
  Nullable,
} from '@/app/shared/utilities/static-types';
import MediaUpload from '@campaigns/shared/components/MediaUpload.vue';
import { errors } from '@/app/shared/config/errors';
import {
  convertFromMediaResolutionTypeToScreenResolutionType,
  USER_TYPES_ABBREVIATION,
} from '@/app/shared/utilities/object-factory';
import { checkMediaResolution } from '@/app/shared/utilities/files';
import { uploadFileForScreenSaver } from '@userApp/shared/actions';
import { flatten } from 'lodash';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';

@Component({
  name: 'CreateAdSpaceScreenSaver',
  components: {
    MediaUpload,
  },
})
export default class CreateAdSpaceScreenSaver extends Vue {
  @Prop() public resolution!: ScreenResolution;
  @Prop() public screenSaversLength!: number;
  @Prop() public adSpaceScreenSavers!: AdSPaceScreenSaver[];
  @Prop() public showCreateAdSpaceScreenSaver!: boolean;

  public closeEvent = 'update:showCreateAdSpaceScreenSaver';
  public screenSaverAvailableMediaList: CampaignMedia[] = [];
  public mediaPreview = '';
  public selectedMedia = '';
  public selectedFile!: Nullable<File>;
  public loading = false;
  public snackbarMsg = '';
  public snackbar = false;
  public mediaId = uuidv4();

  get showCreateAdSpaceScreenSaverValue() {
    return this.showCreateAdSpaceScreenSaver;
  }

  set showCreateAdSpaceScreenSaverValue(showCreateAdSpaceScreenSaver: boolean) {
    this.$emit(this.closeEvent, showCreateAdSpaceScreenSaver);
  }

  public async created() {
    const screenSavers = await loadScreenSaverAction();
    const mediaList = flatten(
      screenSavers.map((screenSaversItems) => screenSaversItems.MEDIA_LIST),
    );
    const { WIDTH: width, HEIGHT: height } = this.resolution;

    this.screenSaverAvailableMediaList = mediaList.filter(
      (media) => media.WIDTH === width && media.HEIGHT === height,
    );
  }

  public selectAvailableMedia(mediaPath: string) {
    if (
      !this.adSpaceScreenSavers.find(
        (adSpaceScreenSaver) =>
          adSpaceScreenSaver.MEDIA_FILE.MEDIA_FILE === mediaPath,
      )
    ) {
      this.selectedMedia = mediaPath;
    }
  }

  public selectMedia() {
    const mediaUpload = this.$refs.mediaUpload as MediaUpload;
    const uploadFileInput = mediaUpload.$refs.uploadFileRef as HTMLInputElement;
    uploadFileInput.click();
  }

  public async checkResolution(
    selectedFile: File,
    adSpaceResolution: ScreenResolution,
  ) {
    const resolution = convertFromMediaResolutionTypeToScreenResolutionType(
      adSpaceResolution,
    );
    return await checkMediaResolution(selectedFile, resolution);
  }

  public async changeAdSpaceScreenSaverMedia(
    selectedFile: File,
  ): Promise<void> {
    try {
      await this.checkResolution(selectedFile, this.resolution);
      this.mediaPreview = URL.createObjectURL(selectedFile);
      this.selectedMedia = this.mediaPreview;
      this.selectedFile = selectedFile;
    } catch (error) {
      if (error && error.message === errors.MEDIA_RESOLUTION_NOT_MATCH) {
        this.snackbar = true;
        this.snackbarMsg = this.$t('media_resolution_error').toString();
      }
    }
  }

  public async uploadMedia(selectedFile: File): Promise<string | undefined> {
    const uploadTaskRef = await uploadFileForScreenSaver(selectedFile, this.mediaId);
    const taskSnapshot = await uploadTaskRef.put(selectedFile);
    return taskSnapshot && (await taskSnapshot.ref.getDownloadURL());
  }

  public async saveScreenSaver() {
    try {
      this.loading = true;
      let mediaFilePath;
      if (this.selectedMedia === this.mediaPreview) {
        mediaFilePath = await this.uploadMedia(this.selectedFile as File);
      } else {
        mediaFilePath = this.selectedMedia;
      }

      const mediaFile: CampaignMedia = {
        MEDIA_FILE: mediaFilePath,
        WIDTH: this.resolution.WIDTH,
        HEIGHT: this.resolution.HEIGHT,
        MEDIA_ID: this.mediaId,
        NAME: `${USER_TYPES_ABBREVIATION.SCREEN_SAVER}--${this.mediaId}.${this.selectedFile!.name.split('.').pop()}`,
        MEDIA_UPLOADED_AT: firebase.firestore.Timestamp.now(),
      };
      if (this.selectedFile) {
        mediaFile.MEDIA_TYPE = this.selectedFile.type as MediaType;
      }
      const adSpaceScreenSaver: AdSPaceScreenSaver = {
        MEDIA_FILE: mediaFile,
        ORDER: this.screenSaversLength + 1,
        ACTIVE: true,
      };
      this.selectedMedia = '';
      this.mediaPreview = '';
      this.selectedFile = null;
      // await createScreenSaver(screenSaver);
      this.$emit('created-ad-space-screen-saver', adSpaceScreenSaver);
      this.$emit(this.closeEvent, false);
    } finally {
      this.loading = false;
    }
  }
}
