








































import { formatDate } from '@/app/shared/utilities/date';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CampaignTableRow } from '@/app/shared/utilities/static-types';
import CountdownTimer from '@campaigns/shared/components/CountdownTimer.vue';
@Component({
  components: { CountdownTimer },
})
export default class CampaignTableRowItem extends Vue {
  @Prop({ required: true, default: () => ({}) })
  public campaignRow!: CampaignTableRow;
  @Prop({ required: true, default: false }) public loading!: boolean;
  @Prop({ default: false }) public isCampaignOwner!: boolean;
  @Prop({ default: false }) public showPrice!: boolean;
  @Prop(Boolean) public showReservedUntil!: boolean;

  public formatDate(date: string) {
    if ((date as unknown as {seconds: number}).seconds) {
      return formatDate(new Date((date as unknown as { seconds: number }).seconds * 1000).toISOString());
    }
    return date ? formatDate(date) : '-';
  }
}
