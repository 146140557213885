import { QuerySnapshot, QueryDocumentSnapshot, DocumentSnapshot } from '@firebase/firestore-types';
import {
  FirebaseAppAuth,
  FirebaseAppFirestore,
  FirebaseAppFunctions,
  FirebaseAppStorage,
  FirebaseSdkApp,
} from '@/app/shared/firebase/firebase-app';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import {
  CampaignMediaFile,
  SystemFeature,
  ScreenCluster,
  MyDeviceInfo,
  ScheduleRequest,
  ClustersSchedulesRecord,
  Campaign,
  AdSpace,
  MediaFile,
} from '@/app/shared/utilities/static-types';

import firebaseNames from '@/app/shared/utilities/firebase-names';

export const logoutUserAction = async () => {
  await FirebaseAppAuth.signOut();
};

/**
 * Uploads Images that will be used for campaigns.
 * @param fileBlob Image file
 * @param mediaId media id for the storage
 */
export const uploadFileForCampaigns = async (fileBlob: any, mediaId?: MediaFile['mediaId']) => {
  const { name: fileName } = fileBlob;
  const uniqueFileName = mediaId ? `${mediaId}.${fileName.split('.').pop()}_${mediaId}` : `${fileName}_${Date.now()}`;
  const currentUser = await getCurrentUser();
  return FirebaseAppStorage
    .ref(firebaseNames.MEDIA_FILES.VAL)
    .child(currentUser!.uid)
    .child(firebaseNames.MEDIA_FILES.FOR_CAMPAIGNS)
    .child(uniqueFileName);
};

export const uploadFileForScreenSaver = async (fileBlob: any, mediaId: MediaFile['mediaId']) => {
  const { name: fileName } = fileBlob;
  const uniqueFileName = `${mediaId}.${fileName.split('.').pop()}_${mediaId}`;
  return FirebaseAppStorage
    .ref(firebaseNames.MEDIA_FILES.SCREEN_SAVERS)
    .child(uniqueFileName);
};

export const uploadMediaForAdSpace = async (fileBlob: File, adSpace: AdSpace) => {
  const { name: fileName } = fileBlob;
  const uniqueFileName = `${fileName}_${Date.now()}`;
  return FirebaseAppStorage
    .ref(firebaseNames.AD_SPACES_MEDIA)
    .child(adSpace.ID)
    .child(uniqueFileName);
};

/**
 * Save Image file static link to Firestore.
 * @param imageFile Image File data
 */
export const saveFileLinkForCampaigns = async (imageFile: CampaignMediaFile) => {
  const currentUser = await getCurrentUser();
  await FirebaseAppFirestore
    .collection(firebaseNames.MEDIA_FILES.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.MEDIA_FILES.FOR_CAMPAIGNS)
    .doc(imageFile.FILE_NAME)
    .set(imageFile);
};

/**
 * Gets list of system's features.
 */
export const getSystemFeaturesAction = async (): Promise<SystemFeature[]> => {
  const dataRef = await FirebaseAppFirestore
    .collection(firebaseNames.SYSTEM_FEATURES)
    .get();
  return dataRef.docs.map((doc: DocumentSnapshot) => doc.data() as SystemFeature);
};

/**
 * Get a list of all screen clusters.
 */
export const getScreenClusters = async (): Promise<ScreenCluster[]> => {
  const snap = await FirebaseAppFirestore
    .collection(firebaseNames.SCREEN_CLUSTERS.VAL)
    .get();

  if (snap.empty) {
    return [];
  }

  return snap.docs.map((doc: DocumentSnapshot) => doc.data() as ScreenCluster);
};

/**
 * Get a list of all connected Devices for a specific screen cluster
 * @param clusterId Screen cluster ID
 */
export const getDevicesInfoByClusterId = async (clusterId: string): Promise<MyDeviceInfo[]> => {
  const snap = await FirebaseAppFirestore
    .collection(firebaseNames.SCREEN_CLUSTERS.VAL)
    .doc(clusterId)
    .collection(firebaseNames.SCREEN_CLUSTERS.CONNECTED_DEVICES)
    .get();
  return snap.docs.map((doc: DocumentSnapshot) => doc.data() as MyDeviceInfo);
};

/**
 * Load upcoming schedules.
 */
export const loadUpcomingSchedulesAction = async (scheduleRequest?: ScheduleRequest): Promise<ClustersSchedulesRecord> => {
  const schedulesFn = FirebaseAppFunctions
    .httpsCallable(firebaseNames.functions.GET_UPCOMING_SCHEDULES);
  const res = await schedulesFn(scheduleRequest);
  return res.data as ClustersSchedulesRecord;
};

/**
 * Get all User IDs in system.
 */
export const loadUserIdsAction = async (): Promise<string[]> => {
  const usersInfoSnap: QuerySnapshot = await FirebaseAppFirestore
    .collection(firebaseNames.USERS_INFO)
    .get();
  return usersInfoSnap.docs.map((doc) => doc.data().UID as string);
};

/**
 * Get all campaigns in system by user IDs list.
 */
export const loadAllUsersCampaignsAction = async (): Promise<Campaign[]> => {
  const userIds = await loadUserIdsAction();
  return (await Promise.all(
    userIds.map((uid: string) => FirebaseAppFirestore
      .collection(firebaseNames.CAMPAIGNS.VAL)
      .doc(uid)
      .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
      .get())))
    .flatMap((campaignSnap: QuerySnapshot) =>
      campaignSnap.docs.map((doc: QueryDocumentSnapshot) => doc.data() as Campaign));
};
