









































import { Component, Vue, Prop } from 'vue-property-decorator';

import PopupModal from '@/app/shared/components/PopupModal.vue';
import { Deferred } from '@/app/shared/utilities/object-factory';
import { DeferredPromise } from '@/app/shared/utilities/static-types';

@Component({
  components: {
    PopupModal,
  },
})
export default class PopupMessage extends Vue {
  @Prop() public title!: string;
  @Prop() public content!: string;

  @Prop({ type: Boolean }) public confirmation!: boolean;
  @Prop({ default: 'confirm' }) public confirmActionName!: string;
  @Prop({ default: 'cancel' }) public cancelActionName!: string;

  @Prop( { type: Boolean }) public alert!: boolean;
  @Prop({ default: 'ok' }) public alertActionName!: string;

  @Prop({ type: Boolean }) public action!: boolean;

  public openModal = false;
  public actionDeferred: DeferredPromise = Deferred();

  public actionPromise() {
    return this.actionDeferred.promise;
  }

  /**
   * {@link https://vuejs.org/v2/guide/components-custom-events.html#sync-Modifier SyncModifier}
   */
  public created() {
    this.$emit('update:show', this.onShowPopup);
    this.$emit('update:hide', this.hidePopup);
  }

  public onShowPopup(): Promise<any> | void {
    this.openModal = true;

    if (this.action) {
      return;
    }
    return this.actionPromise();
  }

  public onConfirm() {
    this.hidePopup();
    this.actionDeferred.resolve!();
  }

  public onCancel() {
    this.hidePopup();
    this.actionDeferred.reject!();
  }

  public hidePopup() {
    this.openModal = false;
  }

  public translate(keyword: string) {
    return this.$t(keyword) as string;
  }
}
