








































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import PopupMessage from '@/app/shared/components/PopupMessage.vue';
import {
  Promotion,
  PromotionTableRow,
} from '@/app/shared/utilities/static-types';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import {
  deletePromotionAction,
  setPromotionAsDefaultAction,
} from '@promotions/shared/actions';

@Component({
  components: {
    PopupMessage,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('name').toString(),
          value: 'NAME',
        },
        {
          text: this.$t('promotion_status').toString(),
          value: 'STATUS',
        },
        {
          text: this.$t('platform_visible').toString(),
          value: 'PLATFORM_VISIBLE',
        },
        {
          text: this.$t('default').toString(),
          value: 'DEFAULT',
        },
        {
          text: this.$t('value').toString(),
          value: 'VALUE',
        },
        {
          text: this.$t('start_date').toString(),
          value: 'START_DATE',
        },
        {
          text: this.$t('end_date').toString(),
          value: 'END_DATE',
        },
        {
          text: this.$t('actions').toString(),
          value: 'ACTIONS',
        },
      ],
    };
  },
})
export default class PromotionsTable extends Vue {
  @Prop({ required: true, default: [] })
  public promotions!: PromotionTableRow[];
  public loading: boolean = false;

  public showDeletePromotionConfirmationMessage:
    | (() => Promise<any>)
    | null = null;

  public pagination = {
    sortBy: 'name',
    descending: false,
  };

  public changeSort(column: string): void {
    if (this.pagination.sortBy === column) {
      this.pagination.descending = !this.pagination.descending;
    } else {
      this.pagination.sortBy = column;
      this.pagination.descending = false;
    }
  }

  public editItem({ ID }: PromotionTableRow): void {
    this.$router.push(`edit/${ID}`);
  }

  @Emit()
  public async setAsDefault({ ID }: PromotionTableRow): Promise<string> {
    this.loading = true;
    await setPromotionAsDefaultAction!(ID!);
    this.loading = false;
    return ID!;
  }

  @Emit()
  public async deleteItem({ ID }: PromotionTableRow): Promise<string> {
    await this.showDeletePromotionConfirmationMessage!();
    await deletePromotionAction(ID!);
    return ID!;
  }
}
