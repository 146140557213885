import { FirebaseAppFirestore } from '../firebase/firebase-app';
import firebaseNames from '../utilities/firebase-names';
import { AdSpace } from '../utilities/static-types';

/**
 * Get all Ad Spaces info
 */
export const getClusteredAdSpaces = async (): Promise<AdSpace[]> => {
  const collectionRef = FirebaseAppFirestore.collection(
    firebaseNames.AD_SPACES.VAL,
  ).orderBy('SCREEN_CLUSTER_REF');

  const adSpacesSnap = await collectionRef.get();

  if (adSpacesSnap.empty) {
    return [];
  }

  return adSpacesSnap.docs.map((doc) => doc.data() as AdSpace);
};
