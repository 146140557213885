import { FirebaseAppFirestore, FirebaseAppFunctions, FirebaseAppStorage } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { DocumentReference } from '@firebase/firestore-types';
import { ScreenCluster, AdSpace, Campaign, CampaignWithSingleAdSpaceMedia, ShashaAd, ScheduleRequest, PlaylistRequest, CampaignMedia } from '@/app/shared/utilities/static-types';
import { flow } from 'lodash';
import moment from 'moment';
import { Timestamp } from '@firebase/firestore-types';
/**
 * Gets a list of all system's devices.
 */
export const getSystemDevicesAction = async () => {
  const devicesSnap = await FirebaseAppFirestore
    .collection(firebaseNames.SYSTEM_DEVICES)
    .get();
  return devicesSnap.docs.map((doc) => doc.data().DEVICE_REF as DocumentReference);
};

/**
 * Uploads the screen photo.
 * @param screenId Screen ID
 */
export const createScreenPhotoUploadTaskAction = async (screenId: string) => {
  return FirebaseAppStorage
    .ref(firebaseNames.MEDIA_FILES.FOR_PHYSICAL_SCREEN_PHOTOS)
    .child(screenId);
};

/**
 * Create new screen cluster.
 * @param cluster ScreenCluster
 * @param adSpace AdSpace
 */
export const createScreenClusterAction = (cluster: ScreenCluster, adSpace: AdSpace) => {
  const clusterDocRef = FirebaseAppFirestore
    .collection(firebaseNames.SCREEN_CLUSTERS.VAL)
    .doc();
  cluster.ID = clusterDocRef.id;

  const { ID: adSpaceId } = adSpace;
  const { PRICE: clusterPrice } = cluster;

  const adSpaceDocRef = FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(adSpaceId);

  const updatedAdSpace: AdSpace = {
    ...adSpace,
    BASE_PRICE: clusterPrice,
    SCREEN_CLUSTER_REF: clusterDocRef,
  };

  const batch = FirebaseAppFirestore.batch();
  batch.set(clusterDocRef, cluster);
  batch.set(adSpaceDocRef, updatedAdSpace);
  return batch.commit();
};

/**
 * Get all stored Ad Spaces
 */
export const getAdSpacesAction = async (): Promise<AdSpace[]> => {
  const adSpaceSnap = await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .get();
  return adSpaceSnap.docs
    .map((doc) => doc.data()) as AdSpace[];
};

/**
 * Get Ad Space for selected cluster.
 */
export const getAdSpaceByCluster = async (screenClusterId: string): Promise<AdSpace> => {
  const clusterRef = FirebaseAppFirestore
    .collection(firebaseNames.SCREEN_CLUSTERS.VAL)
    .doc(screenClusterId);
  const adSpacesSnap = await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .where('SCREEN_CLUSTER_REF', '==', clusterRef)
    .get();
  return adSpacesSnap.docs.map((doc) => doc.data())[0] as AdSpace;
};

export const getAdSpacesCampaign = async (adSpace: AdSpace, selectedDate: string): Promise<CampaignWithSingleAdSpaceMedia[]> => {
  const { SCREEN_CLUSTER_REF: screenClusterRef, ID: id } = adSpace;
  const screenClusterSnap = await screenClusterRef!.get();
  const cluster = screenClusterSnap.data() as ScreenCluster;
  const campaigns = await FirebaseAppFirestore.collectionGroup(
    firebaseNames.CAMPAIGNS.USER_CAMPAIGNS,
  ).where('START_DATE', '<=', new Date(selectedDate))
    .where('SCREEN_CLUSTERS_IDS', 'array-contains', cluster.ID)
    .get();
  const selectedDateMoment = moment(selectedDate);
  return campaigns.docs.map((snapshot) => snapshot.data() as Campaign)
    .filter((campaign: Campaign) => moment(selectedDateMoment).isBefore((campaign.END_DATE as Timestamp).seconds * 1000))
    .map((campaign: Campaign) => {
      if (campaign && campaign.MEDIA_LIST) {
        campaign.mediaFile = campaign.MEDIA_LIST.find((media) => media.AD_SPACE_ID === id)!.MEDIA_FILE || '';
      }
      return campaign as CampaignWithSingleAdSpaceMedia;
    });
};
export const getAdSpacesShashaAds = async (adSpace: AdSpace, selectedDate: string): Promise<ShashaAd[]> => {
  const shashaAds = await FirebaseAppFirestore.collection(firebaseNames.SHASHA_ADS.VAL)
    .where('START_DATE', '<=', new Date(selectedDate))
    .where('AD_SPACE_ID', '==', adSpace.ID).get();

  return shashaAds.docs.map((snapshot) => snapshot.data() as ShashaAd)
    .map((shashaAd) => {
      shashaAd.mediaFile = shashaAd.MEDIA_FILE.MEDIA_FILE;
      return shashaAd;
    });
};

export const deleteShashaAd = async (shashaAdId: string): Promise<void> => {
  return FirebaseAppFirestore.collection(firebaseNames.SHASHA_ADS.VAL)
    .doc(shashaAdId)
    .delete();
};

export const getAdSpacePlaylist = async ({
                                                            adSpaceId,
                                                            selectedDate,
                                                            sortByFrequency,
                                                          }: PlaylistRequest): Promise<Campaign[]> => {
  const getAdSpacePlaylistFn = FirebaseAppFunctions
    .httpsCallable(firebaseNames.functions.GET_ADSPACE_PLAYLIST);
  const playlistRequest: PlaylistRequest = {
    adSpaceId,
    selectedDate,
    sortByFrequency,
    isUserView: true,
  };
  const { data } = await getAdSpacePlaylistFn(playlistRequest);
  return data.map((playlistItem: CampaignWithSingleAdSpaceMedia | ShashaAd) => {

    const playlistItemWithSingleAdSpaceMedia = playlistItem as CampaignWithSingleAdSpaceMedia;

    if (playlistItemWithSingleAdSpaceMedia && playlistItemWithSingleAdSpaceMedia.MEDIA_LIST) {

      flow(() => playlistItemWithSingleAdSpaceMedia.MEDIA_LIST &&
          playlistItemWithSingleAdSpaceMedia.MEDIA_LIST.find((media) => media.AD_SPACE_ID === adSpaceId),
        (mediaFile: CampaignMedia) => playlistItem.mediaFile = mediaFile.MEDIA_FILE,
      )();

    } else if (playlistItem.MEDIA_FILE) {
      (playlistItem as ShashaAd).mediaFile = (playlistItem as ShashaAd).MEDIA_FILE.MEDIA_FILE;
    }
    return playlistItem;
  });

};
export const publishAllPlaylistAction = (): Promise<any> => {
  const publishAllPlaylist = FirebaseAppFunctions
    .httpsCallable(firebaseNames.functions.PUBLISH_ALL_PLAYLISTS, {timeout: 10 * 60 * 1000});

  return publishAllPlaylist();
};
