import { ActionTree, ActionContext } from 'vuex';
import { NavigationState } from './NavigationTypes';
import { RootState } from '@/app/shared/store/RootState';
import { getSystemFeaturesAction } from '@userApp/shared/actions';
import { getUserRolesAction } from '@/app/shared/actions/UserRolesActions';

export const actions: ActionTree<NavigationState, RootState> = {
  initializeSystemFeatures: async (context: ActionContext<NavigationState, RootState>) => {
    const features = await getSystemFeaturesAction();
    context.commit('initSystemFeaturesMutation', features);
  },
  initializeUserRoles: async (context: ActionContext<NavigationState, RootState>) => {
    const roles = await getUserRolesAction();
    context.commit('initUserRolesMutation', roles);
  },
};
