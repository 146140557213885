


































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Schedule } from '@/app/shared/utilities/static-types';
import { chain, cloneDeep, last } from 'lodash';
import ScheduleWeeks from './components/ScheduleWeeks.vue';
import namespace from '@/app/shared/store/namespace';
import { sliceBy } from '@/app/shared/utilities/helper-functions';
import { FirebaseRemoteConfig } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { hasSubscription } from '@campaigns/shared/utils';

@Component({
  components: { ScheduleWeeks },
})
export default class SchedulesCalendar extends Vue {
  @Prop({ default: true, type: Boolean }) public disableRunningSchedules!: boolean;
  @Prop({ required: true, default: () => [] }) public schedulesItems!: Schedule[];
  @Prop({ default: () => [] }) public preSelectedSchedulesItems!: Schedule[];
  @Prop({ default: false, type: Boolean }) public readonly!: boolean;
  @Prop({ default: false, type: Boolean }) public noPayment!: boolean;
  public schedulesSelectableItems: Array<Schedule & { SELECTED: boolean }> = this.schedulesItems.map((schedule) => ({
      ...schedule,
      SELECTED: this.preSelectedSchedulesItems.some(
        (selectedSchedule) => schedule.ID === selectedSchedule.ID,
      ),
    }));
  public selectedSchedules: Schedule[] = [];

  public hasSubscription = hasSubscription;

  public isCalendarWeeksHorizontalSelectionOn = FirebaseRemoteConfig.getBoolean(firebaseNames.remoteConfig.CALENDAR_WEEKS_HORIZONTAL_SELECTION);

  public MONTH_ITEM_HEIGHT = 30;
  public WEEK_ITEM_HEIGHT = 88;

  public monthsOptions = [
    {
      count: 6,
      action: this.selectSixMonths,
      className: 'six-months-option',
    },
    {
      count: 3,
      action: this.selectThreeMonths,
      className: 'three-months-option',
    },
  ];

  public schedulesMonths: string[] = Array.from(
    this.schedulesItems.reduce((months: Set<string>, schedule: Schedule) => {
      return months.add(schedule.START_MONTH).add(schedule.END_MONTH);
    }, new Set()),
  );

  public get scheduleMonthsViewItems() {
    return this.schedulesMonths.map((month) => ({
      name: month,
      offset: this.getMonthOffset(month),
    }));
  }

  public getMonthOffset(month: string) {
    return this.schedulesSelectableItems.reduce((offset: number, schedule: Schedule) => {
      if (schedule.SELECTED) {
        offset += Number(schedule.START_MONTH === month) / 2;
        offset += Number(schedule.END_MONTH === month) / 2;
      } else {
        offset += Number(schedule.START_MONTH === month);
        offset += Number(schedule.END_MONTH === month);
      }
      return offset;
    }, 0);
  }

  public getMonthItemStyles(
    month: { name: string; offset: number },
    idx: number,
  ) {
    const widthUnit = this.WEEK_ITEM_HEIGHT / 2;
    const stretch = month.offset * widthUnit;
    const width = `width: ${stretch}px;`;

    const height = `height: ${this.MONTH_ITEM_HEIGHT}px;`;

    const offset: number = chain(this.scheduleMonthsViewItems)
      .slice(0, idx)
      .reduce((total, { offset: ofst }) => total + ofst , 0)
      .multiply(widthUnit)
      .value();

    const alignment = `top: ${stretch + offset}px;`;
    return `${height} ${width} ${alignment}`;
  }

  public scrollToMonthTag(idx: number) {
    const monthTagElement = this.$el.getElementsByClassName('month-tag-item')[idx];
    if (monthTagElement) {
      monthTagElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  public get selectedWeeks() {
    const weeksCount = this.selectedSchedules.length;
    return this.isCalendarWeeksHorizontalSelectionOn
      ? `<b>${weeksCount}</b>`
      : `<b>${weeksCount}</b> ${this.$t('weeks')}`;
  }

  public selectOneYear() {
    const yearInWeeks = 52;
    const twelveAvailableMonths = sliceBy(
        cloneDeep(this.schedulesSelectableItems),
        yearInWeeks,
        (schedule) => schedule.AVAILABLE,
    );

    this.schedulesSelectableItems.map((selectableSchedule) => {
      selectableSchedule.SELECTED = twelveAvailableMonths.some((availableSchedule) => availableSchedule.ID === selectableSchedule.ID);
    });

    const [ yearOptionElement ] = this.$el.getElementsByClassName('year-option-chip');
    yearOptionElement!.scrollIntoView({ behavior: 'smooth', block: 'center' });

    // wait until selected and collapsed
    setTimeout(() => {
      const lastSelectedWeekElement = last(this.$el.querySelectorAll('.week-list-item.subscription'));

      lastSelectedWeekElement!.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 250);
  }

  public selectSixMonths() {
    const sixMonthsInWeeks = 24;
    const sixAvailableMonths = sliceBy(
        cloneDeep(this.schedulesSelectableItems),
        sixMonthsInWeeks,
          (schedule) => schedule.AVAILABLE,
      );

    this.schedulesSelectableItems.map((selectableSchedule) => {
      selectableSchedule.SELECTED = sixAvailableMonths.some((availableSchedule) => availableSchedule.ID === selectableSchedule.ID);
    });

    const [ sixMonthOptionElement ] = this.$el.getElementsByClassName('six-months-option');
    sixMonthOptionElement!.scrollIntoView({ behavior: 'smooth', block: 'center' });

    // wait until selected and collapsed
    setTimeout(() => {
      const lastSelectedWeekElement = last(this.$el.querySelectorAll('.week-list-item.selected'));

      lastSelectedWeekElement!.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 250);
  }

  public selectThreeMonths() {
    const threeMonthsInWeeks = 12;
    const threeAvailableMonths = sliceBy(
      cloneDeep(this.schedulesSelectableItems),
      threeMonthsInWeeks,
      (schedule) => schedule.AVAILABLE,
    );

    this.schedulesSelectableItems.map((selectableSchedule) => {
      selectableSchedule.SELECTED = threeAvailableMonths.some((availableSchedule) => availableSchedule.ID === selectableSchedule.ID);
    });

    const [ threeMonthOptionElement ] = this.$el.getElementsByClassName('three-months-option');
    threeMonthOptionElement!.scrollIntoView({ behavior: 'smooth', block: 'center' });

    // wait until selected and collapsed
    setTimeout(() => {
      const lastSelectedWeekElement = last(this.$el.querySelectorAll('.week-list-item.selected'));

      lastSelectedWeekElement!.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 250);
  }

  public clearMonthsOptions() {
    this.schedulesSelectableItems.map((selectableSchedule) => {
      selectableSchedule.SELECTED = false;
    });

    // wait until selected and collapsed
    setTimeout(() => {
      const firstWeekElement = this.$el.querySelector('.week-list-item.available');

      firstWeekElement!.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 250);
  }

  public async created() {
    return FirebaseRemoteConfig.fetchAndActivate()
      .then(() => {
        this.isCalendarWeeksHorizontalSelectionOn = FirebaseRemoteConfig.getBoolean(firebaseNames.remoteConfig.CALENDAR_WEEKS_HORIZONTAL_SELECTION);
      })
      .catch((err) => {
        /* tslint:disable-next-line */
        console.error(err);
      });
  }

  public scrollToWeek(idx: number) {
    const el = this.$el.getElementsByClassName('week-list-item')[idx];

    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  public monthsToWeeks(months: number) {
    return months * 4;
  }

  public get appLocale() {
    return this.$root.$i18n.locale;
  }

  public localizeNumber(num: number) {
    switch (this.appLocale) {
      case 'en': {
        return num.toLocaleString('en-US');
      }

      case 'ar': {
        return num.toLocaleString('ar-SA');
      }
    }
  }

  @Watch('schedulesSelectableItems', { deep: true })
  public onChangeSchedulesSelectableItems(val: Array<Schedule & { SELECTED: boolean }>) {
    this.selectedSchedules = this.schedulesItems.filter((scheduleItem) =>
      val.some(
        (selectableSchedule) =>
          scheduleItem.ID === selectableSchedule.ID && selectableSchedule.SELECTED,
      ),
    );
  }

  @Watch('selectedSchedules')
  public onChangeSelectedSchedulesItems(val: Schedule[], oldVal: Schedule[]) {
    if (val.length !== oldVal.length) {
      this.$store.commit(
        `${ namespace.CampaignModule }/setSelectedSchedulesItems`,
        val,
      );
    }
  }

  @Watch('preSelectedSchedulesItems')
  public onChangePreSelectedSchedulesItems(val: Schedule[], oldVal: Schedule[]) {
    this.schedulesSelectableItems = this.schedulesItems.map((schedule) => ({
      ...schedule,
      SELECTED: val.some(
        (selectedSchedule) => schedule.ID === selectedSchedule.ID,
      ),
    }));
  }
}
