





































import { Vue, Prop, Watch, Component } from 'vue-property-decorator';

@Component
export default class LogoIcon extends Vue {
  @Prop({ default: '100%' }) public width!: string;
  @Prop({ default: '100%' }) public height!: string;
  @Prop({ required: true }) public active!: boolean;

  @Watch('active')
  public onActive(val: boolean) {
    return;
  }
}
