



























































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import {
  AdSpaceSchedules,
  ClustersSchedulesRecord,
  Promotion,
  Schedule,
  ScreenCluster,
  Nullable,
} from '@/app/shared/utilities/static-types';
import ScheduleWeeks from './components/SchedulesTabs/components/SchedulesCalendar/components/ScheduleWeeks.vue';
import PromoCarousel from './components/PromoCarousel.vue';
import ClustersAndSchedulesPreview from './components/ClustersAndSchedulesPreview.vue';
import TotalPrice from './components/TotalPrice.vue';
import rules from '@/app/shared/validation-rules';
import campaignRules from '@campaigns/shared/validation-rules';

import ClustersTabs from '@/app/shared/components/ClustersAndSchedules/components/ClustersTabs.vue';
import SchedulesTabs from '@/app/shared/components/ClustersAndSchedules/components/SchedulesTabs/SchedulesTabs.vue';
import { isEqual, isEqualWith } from 'lodash';
import namespace from '@/app/shared/store/namespace';
import { cloneDeep } from 'lodash';
import { concatRecordValues } from '@/app/shared/utilities/helper-functions';
import {
  loadDefaultPromotionsAction,
  loadPlatformPromotionsAction,
} from '@/app/screens/App/screens/Admin/screens/Pricing/screens/Promotions/shared/actions';
import { FirebaseRemoteConfig } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { hasSubscription } from '@campaigns/shared/utils';

@Component({
  data: (vm: any) => ({
    scheduleRules: [
      rules.required(vm.$i18n.t('field_required')),
      campaignRules.minNumberOfWeeks(
        1,
        vm.$i18n.t('min_number_of_weeks', { min: 1 }),
      ),
    ],
  }),
  components: {
    SchedulesTabs,
    ClustersTabs,
    ScheduleWeeks,
    PromoCarousel,
    TotalPrice,
    ClustersAndSchedulesPreview,
  },
})
export default class ClustersAndSchedules extends Vue {
  @Prop() public loading!: boolean;
  @Prop({ required: true }) public clustersSchedulesRecord!: ClustersSchedulesRecord;
  @Prop({ required: true }) public screenClustersItems!: ScreenCluster[];
  @Prop({ default: () => ({}) }) public preSelectedClustersSchedules!: ClustersSchedulesRecord;
  @Prop({ default: () => [] }) public preSelectedScreenClusters!: ScreenCluster[];
  @Prop({ default: false, type: Boolean }) public readonly!: boolean;
  @Prop({ default: false, type: Boolean }) public noPayment!: boolean;
  @Prop({ default: true, type: Boolean }) public disableRunningSchedules!: boolean;

  @Getter('getSelectedClustersSchedules', {
    namespace: namespace.CampaignModule,
  })
  public selectedClustersSchedules!: ClustersSchedulesRecord;
  public selectedSchedules: Schedule[] = [];
  public promos: Promotion[] = [];
  public defaultPromo: Nullable<Promotion> = null;
  public numberOfSelectedSchedules = 0;
  public selectedClusterTab = 0;
  public dialog = false;
  public showConfirm = false;

  public isCampaignSingleClusterSelectionOn = false;
  public hasSubscription = hasSubscription;

  public get appLocale() {
    return this.$root.$i18n.locale;
  }

  public async created() {
    this.promos = await loadPlatformPromotionsAction();

    this.defaultPromo = await loadDefaultPromotionsAction();

    this.$store.commit(
      `${namespace.CampaignModule}/setSelectedClustersSchedules`,
      cloneDeep(this.preSelectedClustersSchedules),
    );

    return FirebaseRemoteConfig.fetchAndActivate()
        .then(() => {
          this.isCampaignSingleClusterSelectionOn = FirebaseRemoteConfig.getBoolean(firebaseNames.remoteConfig.CAMPAIGN_SINGLE_CLUSTER_SELECTION);
        })
        .catch((err: Error) => {
          /* tslint:disable-next-line */
          console.error(err);
        });
  }

  public get selectedScreenClusters(): ScreenCluster[] {
    const selectedClustersIds = Object.keys(this.selectedClustersSchedules);
    const selectedClusters = this.screenClustersItems
      .filter((cluster) => selectedClustersIds.includes(cluster.ID));
    return selectedClusters;
  }

  public get selectedClustersSchedulesItems(): Array<{ selectedScreenCluster: ScreenCluster; selectedClusterSchedules: AdSpaceSchedules; }> {
    return this.preSelectedScreenClusters.map((preSelectedScreenCluster) => ({
      selectedScreenCluster: preSelectedScreenCluster,
      selectedClusterSchedules: this.preSelectedClustersSchedules[preSelectedScreenCluster.ID]
        ? this.preSelectedClustersSchedules[preSelectedScreenCluster.ID].filter(({ AVAILABLE }) => AVAILABLE)
        : [],
    }));
  }

  @Watch('selectedClustersSchedules', { deep: true })
  public onChangeSelectedClustersSchedules(
    val: ClustersSchedulesRecord,
    oldVal: ClustersSchedulesRecord,
  ) {
    this.showConfirm = this.hasSelectedSchedulesChanged(val);
    this.selectedSchedules = concatRecordValues(val);
    this.numberOfSelectedSchedules = this.selectedSchedules.length;
  }

  @Watch('preSelectedClustersSchedules')
  public preSelectedClustersSchedulesChanged(val: string, oldVal: string) {
    this.$store.commit(
      `${namespace.CampaignModule}/setSelectedClustersSchedules`,
      cloneDeep(this.preSelectedClustersSchedules),
    );
  }

  public hasSelectedSchedulesChanged(selectedClustersSchedules: ClustersSchedulesRecord): boolean {
    return !isEqualWith(
      selectedClustersSchedules,
      this.preSelectedClustersSchedules,
      isEqual,
    );
  }

  public clusterSchedulesWeeks(clusterSelectedSchedules: Schedule[]) {
    const clusterAvailableSelectedSchedules = clusterSelectedSchedules.filter(({ AVAILABLE }) => AVAILABLE);
    const weeksCount = clusterAvailableSelectedSchedules.length;

    return `${weeksCount} ${this.$t('weeks')}`;
  }

  public get campaignSubTotalPrice(): number {
    const schedulesTotalPrice = this.selectedSchedules
        .filter(({ AVAILABLE }) => AVAILABLE)
        .reduce((totalPrice, schedule) => totalPrice + schedule.TOTAL_BILL_PRICE, 0);
    return schedulesTotalPrice;
  }

  public updateSelectedSchedulesItems() {
    this.dialog = false;
    this.showConfirm = false;
    this.$emit(
      'update:preSelectedClustersSchedules',
      cloneDeep(this.selectedClustersSchedules),
    );
    this.$emit(
      'update:preSelectedScreenClusters',
      cloneDeep(this.selectedScreenClusters),
    );
  }

  public destroyed() {
    this.$store.commit(`${namespace.CampaignModule}/resetState`);
  }
}
