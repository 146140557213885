












































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { getFilesCollectionRefForCampaigns } from '@files/shared/actions';
import { FileMgmntMenuAction, Campaign, CampaignMediaFile } from '@/app/shared/utilities/static-types';
import { loadAllUsersCampaignsAction } from '@/app/screens/App/shared/actions';
import { deleteMediaFileAction } from '@/app/screens/App/screens/FilesUpload/shared/actions';
import { TranslateResult } from 'vue-i18n';
import UploadFilesPopup from '@userApp/shared/components/UploadFilesPopup.vue';
import PopupMessage from '@/app/shared/components/PopupMessage.vue';
import { SUPPORTED_TYPES } from '@/app/shared/utilities/object-factory';

@Component({
  components: {
    UploadFilesPopup,
    PopupMessage,
  },
})
export default class FileMgmt extends Vue {
  public loading = false;
  public showError = false;
  public errorMessage: TranslateResult|string = '';

  public filesArray: CampaignMediaFile[] = [];
  public campaigns: Campaign[] = [];
  public showConfirmationMessage: (() => Promise<any>) | null = null;
  public SUPPORTED_TYPES = SUPPORTED_TYPES;

  public actions: FileMgmntMenuAction[] = [
    {
      title: 'delete',
      icon: 'delete',
      action: this.deleteFile,
    },
  ];

  private filesArraySubscription: any = null;

  public onCloseAlert(): void {
    this.showError = false;
  }

  public async deleteFile(imageFile: CampaignMediaFile): Promise<void> {
    const isDeletable = !this.isMediaFileUsedInCampaign(imageFile);
    return isDeletable
      ? this.deletableFileDelete(imageFile)
      : this.makeError();
  }

  public async created() {
    await this.loadCampaignsAndMediaFiles();
  }

  public async destroyed() {
    this.filesArraySubscription && this.filesArraySubscription();
  }

  private async deletableFileDelete(imageFile: CampaignMediaFile): Promise<void> {
    await this.showConfirmationMessage!();
    const { FILE_NAME } = imageFile;
    await deleteMediaFileAction(imageFile);
    await this.resetUploadFilesPopupState();
    this.filesArray.filter((mediaFile: CampaignMediaFile) => mediaFile.FILE_NAME !== FILE_NAME);
  }

  private async resetUploadFilesPopupState(): Promise<void> {
    const popupModal = this.$refs.uploadFilesPopup as UploadFilesPopup;
    await popupModal.resetState();
  }

  private makeError(): void {
    this.showError = true;
    this.errorMessage = this.$t('campaign_media_deletion_error');
    setTimeout(() => {
      this.showError = false;
    }, 3000);
  }

  private async loadAllCampaigns(): Promise<void> {
    this.campaigns = await loadAllUsersCampaignsAction();
  }

  private isMediaFileUsedInCampaign({ STATIC_LINK: mediaFile }: CampaignMediaFile): boolean {
    const mediaUsingCampaign = (campaign: Campaign) => campaign.MEDIA_FILE === mediaFile && campaign.STATUS.VAL !== 'finished';
    return Boolean(this.campaigns.find(mediaUsingCampaign));
  }

  private async loadCampaignsAndMediaFiles(): Promise<void> {
    try {
      this.loading = true;
      await this.loadAllCampaigns();
      const collectionRef = await getFilesCollectionRefForCampaigns();
      this.filesArraySubscription = collectionRef.onSnapshot((querySnapshot) => {
        this.filesArray = querySnapshot.docs.map((doc) => doc.data() as CampaignMediaFile);
      });
    } finally {
      this.loading = false;
    }
  }
}
