











































































import PopupMessage from '@/app/shared/components/PopupMessage.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import {
  ScreenCluster,
  Schedule,
  ClusterSchedulesRequest,
  ScheduleRequest,
  Nullable,
} from '@/app/shared/utilities/static-types';
import { getScreenClusters } from '@userApp/shared/actions';
import {
  updateClusterInfo,
  updateAdSpaceInfo,
  updateScheduleInfoInsideAdSpace,
  updateAdSpaceTimeSlots,
} from '../../shared/actions';
import { FirebaseAppFirestore, FirebaseRemoteConfig } from '@/app/shared/firebase/firebase-app';

import FormComponent from '@/app/shared/components/FormComponent.vue';
import SchedulesPricing from './components/SchedulesPricing.vue';

import rules from '@/app/shared/validation-rules';
import { getSchedulesByScreenCluster } from '@globalActions/SchedulesActions';
import { getAdSpaceByCluster } from '@adminDevices/shared/actions';
import firebaseNames from '@/app/shared/utilities/firebase-names';

@Component({
  components: {
    FormComponent,
    SchedulesPricing,
    PopupMessage,
  },
  data: (vm: any) => ({
    screenClusterRules: [rules.required(vm.$i18n.t('field_required'))],
    clusterNumberRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.onlyNumbers(vm.$i18n.t('only_numbers_allowed')),
    ],
  }),
})
export default class ManagePricing extends Vue {
  public showTimeSlotChangeConfirmationMessage:
    | (() => Promise<any>)
    | null = null;
  public screenClusters: ScreenCluster[] = [];
  public loading: boolean = false;
  public selectedScreenCluster: Nullable<ScreenCluster> = null;
  public timeSlotsCache: Nullable<number> = null;
  public timeSlots: Nullable<number> = null;

  public priceLabelText: string = 'Price';

  public upcomingSchedules: Schedule[] = [];
  public upcomingSchedulesClone: Schedule[] = [];

  public upcomingWeeksCount = FirebaseRemoteConfig.getNumber(firebaseNames.remoteConfig.UPCOMING_WEEKS_COUNT);

  private get selectedLocale(): string {
    return this.$i18n.locale;
  }

  public async initPricingData() {
    this.screenClusters = (await getScreenClusters()).map(
      (selectedCluster: ScreenCluster) => ({
        TEXT: this.$t(
          selectedCluster.DISPLAY_NAME[this.selectedLocale],
        ).toString(),
        ...selectedCluster,
      }),
    );

    return FirebaseRemoteConfig.fetchAndActivate()
      .then(() => {
        return this.upcomingWeeksCount = FirebaseRemoteConfig.getNumber(firebaseNames.remoteConfig.UPCOMING_WEEKS_COUNT);
      })
      .catch((err) => {
        /* tslint:disable-next-line */
        console.error(err);
      });
  }

  public async assignClusterPrice() {
    if (this.timeSlots! < this.timeSlotsCache!) {
      await this.showTimeSlotChangeConfirmationMessage!();
    }
    const { ID: screenClusterId } = this.selectedScreenCluster!;
    const batch = FirebaseAppFirestore.batch();
    const attachedAdSpace = await getAdSpaceByCluster(screenClusterId);

    updateClusterInfo(batch, this.selectedScreenCluster!.ID, {
      PRICE: this.selectedScreenCluster!.PRICE,
      TIME_SLOTS: this.timeSlots,
    } as ScreenCluster);

    const { ID: adSpaceId } = attachedAdSpace;
    attachedAdSpace.BASE_PRICE = this.selectedScreenCluster!.PRICE;
    const { TIME_SLOTS: oldCount = 6 } = this.selectedScreenCluster!;
    const newCount = this.timeSlots!;
    updateAdSpaceInfo(batch, adSpaceId, attachedAdSpace);
    await updateAdSpaceTimeSlots(batch, adSpaceId, newCount);

    const updatedSchedules = this.filterUpdatedSchedules();

    updatedSchedules.forEach((schedule) => {
      const schedulePrice = schedule.BASE_PRICE;
      const schedulePriceIncreasePercentage =
        schedule.PRICE_INCREASE_PERCENTAGE;
      const schedulePriceIncrease =
        schedulePrice * (schedulePriceIncreasePercentage / 100);
      const scheduleTotalBillPrice = schedulePrice + schedulePriceIncrease;

      updateScheduleInfoInsideAdSpace(
        batch,
        schedule.ID,
        {
          BASE_PRICE: schedulePrice,
          PRICE_INCREASE_PERCENTAGE: schedulePriceIncreasePercentage,
          PRICE_INCREASE_REASON: schedule.PRICE_INCREASE_REASON || '',
          TOTAL_BILL_PRICE: scheduleTotalBillPrice,
        } as Schedule,
        adSpaceId,
      );
    });

    await batch.commit();
    this.selectedScreenCluster!.TIME_SLOTS = this.timeSlots!;
  }

  public async clusterChanged(val: ScreenCluster) {
    this.loading = true;
    this.priceLabelText = val
      ? `${this.$t('price_of')} ${this.$t(val.DISPLAY_NAME[this.selectedLocale] )}`
      : String(this.$t('price'));

    const { ID: clusterId, TIME_SLOTS } = val;
    const scheduleRequest: ScheduleRequest = {
      startUpcomingWeek: 1,
      endUpcomingWeek: this.upcomingWeeksCount,
    };

    const clusterSchedulesRequest: ClusterSchedulesRequest = {
      clusterId,
      scheduleRequest,
    };

    this.timeSlots = TIME_SLOTS || 6;
    this.timeSlotsCache = TIME_SLOTS || 6;
    this.upcomingSchedules = await getSchedulesByScreenCluster(clusterSchedulesRequest);
    this.upcomingSchedulesClone = this.upcomingSchedules.map((item) => {
      return { ...item };
    });
    this.loading = false;
  }

  public setSchedulePrice(index: number) {
    const hasCurrentClusterPrice = Boolean(
      this.upcomingSchedules[index as number].BASE_PRICE === this.selectedScreenCluster!.PRICE);
    const priceToSet = hasCurrentClusterPrice
      ? this.upcomingSchedulesClone[index as number].BASE_PRICE
      : Number(this.selectedScreenCluster!.PRICE)!;
    this.$set(
      this.upcomingSchedules[index as number],
      'BASE_PRICE',
      priceToSet,
    );
  }

  private filterUpdatedSchedules(): Schedule[] {
    return this.upcomingSchedules.filter((schedule) => Boolean(schedule.BASE_PRICE || schedule.PRICE_INCREASE_PERCENTAGE));
  }
}
