


















































import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import rules from '@/app/shared/validation-rules';

import { Nullable, ScreenCluster } from '@/app/shared/utilities/static-types';
import { getScreenClusters } from '@userApp/shared/actions';
import FormComponent from '@/app/shared/components/FormComponent.vue';
// import ClusterDescription from './ClusterDescription.vue';
import { getScreenClusterConnectedDevices } from '@screenDevices/shared/actions';

@Component({
  components: {
    FormComponent,
    // ClusterDescription,
  },
  data: (vm: any) => ({
    screenClusterRules: [rules.required(vm.$i18n.t('field_required'))],
  }),
})
export default class CampaignScreens extends Vue {
  @Prop() public readonly!: boolean;
  @Prop() public initialSelectionID!: string;

  public dialog = false;
  public isLoading = false;

  public selectedScreenCluster: Nullable<ScreenCluster> = null;
  public screenClusters: ScreenCluster[] = [];
  public devicesCount = 0;

  public async initializeScreenClusters() {
    this.screenClusters = (await getScreenClusters()).map((selectedCluster: ScreenCluster) => ({
      TEXT: this.$t(selectedCluster.DISPLAY_NAME[this.appLocale]).toString(),
      ...selectedCluster,
    }));
    if (this.initialSelectionID) {
      const targetCluster = this.screenClusters.find(
        (cluster) => cluster.ID === this.initialSelectionID,
      );
      this.selectedScreenCluster = targetCluster!;
    }
  }

  public get appLocale() {
    return this.$root.$i18n.locale;
  }

  @Emit('screen-cluster-canceled')
  public cancel() {
    if (!this.initialSelectionID) {
      this.resetSelection();
    }
    this.dialog = false;
    return null;
  }

  @Emit('screen-cluster-selected')
  public assignScreenCluster() {
    this.dialog = false;
    return this.selectedScreenCluster;
  }

  @Watch('selectedScreenCluster')
  public async getConnectedDevices(selectedScreenCluster: ScreenCluster | null) {
    if (selectedScreenCluster) {
      this.isLoading = true;
      this.devicesCount = await getScreenClusterConnectedDevices(selectedScreenCluster.ID);
      this.isLoading = false;
    }
  }

  private resetSelection() {
    this.selectedScreenCluster = null;
  }
}
