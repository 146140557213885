

























































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { Role, SystemFeature } from '@/app/shared/utilities/static-types';
import { USER_ROLES } from '@/app/shared/utilities/object-factory';
import * as actions from '@adminRoles/shared/actions';
import namespace from '@/app/shared/store/namespace';

@Component
export default class RolesSettings extends Vue {
  @Getter('getSystemFeatures', { namespace: namespace.NavigationModule })
  public systemFeatures!: SystemFeature[];

  public pagination = {
    rowsPerPage: -1,
  };

  public headers: any[] = [];
  public items: SystemFeature[] = [];
  public selectionArray: string[][] = [];

  public roleValues: any = USER_ROLES;

  private roles: Role[] = [];

  public async created() {
    await this.loadSystemRoles();
    await this.loadSystemFeatures();
  }

  public async setRole(itemIndex: number, roleValue: string) {
    const targetFeature = this.items[itemIndex];
    targetFeature.ROLES = this.selectionArray[itemIndex].map((val) => {
      const selectedRole = this.roles.find((r) => r.VAL === val);
      return selectedRole!;
    });
    await actions.updateSystemFeatureAction(targetFeature);
  }

  private async loadSystemRoles() {
    this.roles = Object.keys(this.roleValues)
      .filter((key) => key !== 'ADMIN')
      .map((key) => this.roleValues[key]);
    this.headers = this.roles.map((role) => ({
      text: role.DISPLAY_NAME,
      value: role.VAL,
      align: 'center',
    }));
  }

  private async loadSystemFeatures() {
    if (!this.systemFeatures) {
      await this.$store.dispatch(
        `${namespace.NavigationModule}/initializeSystemFeatures`,
      );
    }

    this.items = this.systemFeatures.map((feature) =>
      feature.ROLES ? feature : { ...feature, ROLES: [] },
    );
    this.selectionArray = this.items.map((item) =>
      item.ROLES!.map((role) => role.VAL),
    );
  }
}
