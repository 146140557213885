



































import { Component, Vue } from 'vue-property-decorator';

import routesNames from '@/app/shared/utilities/routes-names';
import { logoutUserAction } from '@userApp/shared/actions';
import { UserProfileItem } from '@/app/shared/utilities/static-types';
import { UserProfileItemFactory } from '@/app/shared/utilities/object-factory';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import namespace from '@/app/shared/store/namespace';
import PopupMessage from '@/app/shared/components/PopupMessage.vue';

@Component({
  components: {
    PopupMessage,
  },
})
export default class UserProfile extends Vue {
  public notificationCount = 0;

  public userProfileItems: UserProfileItem[] = [];

  public currentUserEmail = '';

  public showConfirmationMessage: (() => Promise<any>) | null = null;

  public async created() {
    const user = await getCurrentUser();
    this.currentUserEmail = user!.email || 'unknown';
    this.userProfileItems = [
      UserProfileItemFactory('logout', undefined, async () => {
        try {
          await this.showConfirmationMessage!();
        } catch (err) {
          return;
        }

        try {
          await logoutUserAction();
          this.$store.commit(
            `${namespace.NavigationModule}/resetSystemFeatures`,
          );
          this.$router.replace(routesNames.HOME);
        } catch (err) {
          throw new Error('Failed to logout');
        }
      }),
    ];
  }
}
