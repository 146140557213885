




























import { Component, Vue, Prop } from 'vue-property-decorator';
import { PendingPaymentCampaign } from '@/app/shared/utilities/static-types';
import PopupMessage from '@/app/shared/components/PopupMessage.vue';
import { downloadEstimate } from '../actions';
import { downloadPdfFromBase64 } from '@/app/shared/utilities/helper-functions';

@Component({
  components: {
    PopupMessage,
  },
})
export default class DownloadEstimate extends Vue {
  @Prop({ required: true }) public campaign!: PendingPaymentCampaign;

  public loading = 0;

  public async downloadCampaignEstimate() {
    const intervalId = setInterval( () => this.loading += 1, 300); // completes in 30 sec

    try {
      const { ESTIMATE_NUMBER } = this.campaign;
      const pdf = await downloadEstimate(ESTIMATE_NUMBER);

      const blob = downloadPdfFromBase64(pdf, `${ESTIMATE_NUMBER}.pdf`);

      this.loading = 100;
      setTimeout(() => {
        clearInterval(intervalId);
        this.loading = 0;
      }, 1000);

      /** Open file in current window */
      // const fileURL = URL.createObjectURL(blob);
      // window.location.href = fileURL;
    } catch (err) {
      clearInterval(intervalId);
      this.loading = 0;
      /* tslint:disable-next-line */
      console.error(err);
    }
  }
}
