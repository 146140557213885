



























import { Component, Vue, Prop } from 'vue-property-decorator';
import { PlayerStatus, RegisteredPlayer } from '@/app/shared/utilities/static-types';

@Component
export default class PlayerItemContent extends Vue {
  @Prop({ required: true }) public player!: RegisteredPlayer & { ID: string; DEVICE_SCREEN_CODE: string};

  public getPlayerConnectionStatusColor(status: PlayerStatus): string {
    switch (status) {
      case 'PLAYER_ONLINE':
        return 'success';
        break;
      case 'PLAYER_HIDDEN':
        return 'orange';
        break;
      default:
        return 'error';
        break;
    }
  }
}
