









































import { Component, Vue } from 'vue-property-decorator';

import {
  Campaign,
  CampaignFormData,
  CampaignMediaFile,
  ScreenCluster,
  Nullable,
} from '@/app/shared/utilities/static-types';
import { createCampaignAction } from '@campaigns/shared/actions';
import { SUPPORTED_TYPES, SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';

import campaignRules from '@campaigns/shared/validation-rules';
import routesNames from '@/app/shared/utilities/routes-names';
import CampaignMedia from '@campaigns/shared/components/CampaignMedia.vue';
import CampaignScreens from '@/app/shared/components/CampaignScreens.vue';
import CampaignForm from '@/app/shared/components/CampaignForm.vue';

import { get } from 'lodash/fp';
import { concatRecordValues } from '@/app/shared/utilities/helper-functions';
import { hasSubscription } from '@campaigns/shared/utils';

@Component({
  components: {
    CampaignMedia,
    CampaignScreens,
    CampaignForm,
  },
})
export default class CampaignNew extends Vue {
  public showBadge = false;

  public clusterIsLoaded = false;

  public skipUpload: boolean = false;

  public campaignMainRoute = routesNames.MY_CAMPAIGNS;

  private selectedCluster: Nullable<ScreenCluster> = null;

  private mediaFile: Nullable<CampaignMediaFile> = null;

  public get mediaBadge() {
    return Boolean(this.mediaFile || this.skipUpload);
  }

  public set mediaBadge(val: boolean) {
    return;
  }

  public get mediaBadgeColor() {
    if (this.mediaFile) {
      return 'green';
    } else if (this.skipUpload) {
      return 'orange';
    }
  }

  public async saveCampaign(campaignFormData: CampaignFormData) {
    if (!this.mediaFile && !this.skipUpload) {
      throw new Error('You must select media for new Campaigns.');
    }
    const campaign = {
      ...campaignFormData,
      SCREEN_CLUSTERS_IDS: campaignFormData.SCREEN_CLUSTERS.map(get('ID')),
      MEDIA_FILE: !this.skipUpload ? this.mediaFile!.STATIC_LINK : null,
      MEDIA_TYPE: !this.skipUpload ? this.mediaFile!.FILE_TYPE : null,
      STATUS: SYSTEM_STATUS.NEW,
      MEDIA_SKIPPED: Boolean(this.skipUpload),
      SUBSCRIPTION: hasSubscription(concatRecordValues(campaignFormData.SELECTED_CLUSTERS_SCHEDULES)),
    } as Omit<Campaign, 'ADVERTISER_UID'>;

    if (campaign.MEDIA_TYPE === SUPPORTED_TYPES.MP4 && this.mediaFile!.PLAYBACK_DURATION) {
      campaign.MEDIA_PLAYBACK_DURATION = this.mediaFile!.PLAYBACK_DURATION;
    }

    return await createCampaignAction(campaign);
  }

  public onMediaSelected(selectedFile: CampaignMediaFile) {
    this.showBadge = true;
    this.mediaFile = selectedFile;
  }

  public onScreenClusterSelected(selectedCluster: ScreenCluster) {
    this.clusterIsLoaded = Boolean(selectedCluster);
    this.selectedCluster = selectedCluster;
  }

  public validateNewCampaignName(val: string) {
    return campaignRules.duplicateCampaignName(
      val,
      this.$t('campaign_name_already_exists').toString(),
    );
  }
}
