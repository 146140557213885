import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';

export default {
  /**
   * Checks if the path route already exists.
   * @param value Path route
   */
  async duplicatePathRoute(value: string) {
    if (!value) {
      return [];
    }
    const input = value.trim();
    const dataRef = await FirebaseAppFirestore
      .collection(firebaseNames.SYSTEM_FEATURES)
      .where('ROUTE_PATH', '==', input)
      .get();
    return dataRef.empty ? [] : 'Route Path already exists.';
  },
  /**
   * Checks if the path name already exists.
   * @param value Path name
   */
  async duplicatePathName(value: string) {
    if (!value) {
      return [];
    }
    const input = value.trim();
    const dataRef = await FirebaseAppFirestore
      .collection(firebaseNames.SYSTEM_FEATURES)
      .where('ROUTE_NAME.name', '==', input.toUpperCase())
      .get();
    return dataRef.empty ? [] : 'Route Name already exists.';
  },
  /**
   * Checks if title already exists.
   * @param value Title
   */
  async duplicateTitle(value: string) {
    if (!value) {
      return [];
    }
    const input = value.trim();
    const dataRef = await FirebaseAppFirestore
      .collection(firebaseNames.SYSTEM_FEATURES)
      .where('TITLE', '==', input)
      .get();
    return dataRef.empty ? [] : 'Title already exists.';
  },
};
