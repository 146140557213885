





















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { DocumentReference } from '@firebase/firestore-types';
import { Getter } from 'vuex-class';
import {
  Campaign,
  ClustersSchedulesRecord,
  ScreenCluster,
  AuthorizedUser,
  Nullable,
} from '@/app/shared/utilities/static-types';
import { getCompanyProfileByUserIdAction } from '@globalActions/CompanyProfileActions';
import {
  SUPPORTED_TYPES,
} from '@/app/shared/utilities/object-factory';
import { calculateCampaignPrice, filterStoreCampaignData, showCampaignPrice } from '@campaigns/shared/utils';

import namespace from '@/app/shared/store/namespace';
import routesNames from '@/app/shared/utilities/routes-names';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import PopupMessage from '@/app/shared/components/PopupMessage.vue';
import ClustersAndSchedules from '@/app/shared/components/ClustersAndSchedules/ClustersAndSchedules.vue';

import { cloneDeep } from 'lodash';
import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { approveCampaignAction, getStoreScreenClusters, rejectCampaignAction } from '../../../../shared/actions';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import { getUserById } from '@/app/screens/App/screens/Admin/screens/UserRoles/shared/actions';

@Component({
  components: {
    FormComponent,
    PopupMessage,
    ClustersAndSchedules,
  },
})
export default class CampaignReview extends Vue {
  @Getter('getCurrentStoreCampaignRef', { namespace: namespace.CampaignModule }) public currentRef!: DocumentReference;
  public storeCampaignsRoute = routesNames.MANAGE_STORE_CAMPAIGNS;
  public storeScreenClusters: ScreenCluster[] = [];
  public advertiserId: string = '';

  public campaignName = '';
  public mediaFile: Nullable<string> = null;
  public mediaType: Nullable<string> = null;

  public comments = '';
  public commentsErrors: string | string[] = [];
  public campaignPrice: number = 0;
  public campaign: Nullable<Campaign> = null;

  public clustersSchedulesRecord: ClustersSchedulesRecord = {};
  public campaignClustersSchedules: ClustersSchedulesRecord = {};
  public screenClustersItems: ScreenCluster[] = [];
  public preSelectedScreenClusters: ScreenCluster[] = [];
  public companyName = '';
  public brands: string[] = [];
  public SUPPORTED_TYPES = SUPPORTED_TYPES;
  public currentUser: AuthorizedUser|undefined = undefined;

  public rejected = true;

  public showConfirmationMessage: (() => Promise<any>) | null = null;

  public get showPrice(): boolean {
    return showCampaignPrice(this.campaign!, this.currentUser!);
  }

  public goBack(): void {
    this.$router.go(-1);
  }

  public async loadCurrentUser() {
    const { uid } = (await getCurrentUser())!;
    this.currentUser = await getUserById(uid);
  }

  public async loadUserScreenClusters() {
    this.storeScreenClusters = await getStoreScreenClusters();
  }

  public async initReviewPage() {
    const campaign = await this.loadCampaignFromRef();
    await this.loadCurrentUser();
    await this.loadUserScreenClusters();
    await this.loadCampaignData(campaign);
    await this.calculateCampaignPrice();
    await this.loadComapnyProfile();
  }

  public async loadCampaignFromRef(): Promise<Campaign> {
    if (!this.currentRef) {
      this.$router.push(routesNames.MANAGE_STORE_CAMPAIGNS);
      throw new Error('Error loading campaign.');
    }
    const campaignDoc = await this.currentRef.get();
    return campaignDoc.data() as Campaign;
  }

  public async loadCampaignData(campaign: Campaign) {
    const { ADVERTISER_UID } = campaign;
    this.advertiserId = ADVERTISER_UID;
    this.campaign = filterStoreCampaignData(campaign, this.storeScreenClusters);
    this.campaignName = campaign.NAME;
    this.campaignClustersSchedules = campaign.SELECTED_CLUSTERS_SCHEDULES;
    this.clustersSchedulesRecord = cloneDeep(this.campaignClustersSchedules);
    this.screenClustersItems = campaign.SCREEN_CLUSTERS;
    this.preSelectedScreenClusters = cloneDeep(this.screenClustersItems);
    this.comments = campaign.COMMENTS!;
    this.mediaFile = campaign.MEDIA_FILE;
    this.mediaType = campaign.MEDIA_TYPE;
  }

  public async loadComapnyProfile() {
    if (this.advertiserId) {
      const companyProfile = await getCompanyProfileByUserIdAction(this.advertiserId);
      if (companyProfile) {
        this.companyName = companyProfile.COMPANY_NAME;
        this.brands = companyProfile!.COMPANY_PRODUCTS;
      }
    }
  }

  public async updateCampaign() {
    if (this.rejected) {
      await this.rejectCampaign();
    } else {
      await this.approveCampaign();
    }
    this.$router.push(this.storeCampaignsRoute);
  }

  public async approveCampaign() {
    try {
      await this.showConfirmationMessage!();
    } catch (err) {
      throw new Error(this.$t('campaign_approval_not_confirmed').toString());
    }
    return approveCampaignAction(this.campaign!);
  }

  public async rejectCampaign() {
    if (!this.comments) {
      this.commentsErrors = this.$t('required_for_rejection').toString();
      throw new Error(this.$t('must_add_comments_case_rejection').toString());
    }
    return rejectCampaignAction({ ...this.campaign, COMMENTS: this.comments } as Campaign);
  }

  @Watch('comments')
  public onCommentsChanged(val: string, oldVal: string) {
    if (this.comments) {
      this.commentsErrors = [];
    }
  }

  private calculateCampaignPrice() {
    this.campaignPrice = calculateCampaignPrice(this.campaign!);
  }

  private get campaignRef(): DocumentReference {
    const { ADVERTISER_UID: advertiserId, ID: campaignId } = this.campaign!;
    return FirebaseAppFirestore
      .collection(firebaseNames.CAMPAIGNS.VAL)
      .doc(advertiserId)
      .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
      .doc(campaignId);
  }
}
