






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  AuthorizedUser,
  Promotion,
  Schedule,
  PROMOTION_STATUSES,
  CampaignDiscounts,
  Nullable,
} from '@/app/shared/utilities/static-types';
import { getUserInfoAction } from '@/app/shared/actions/UserRolesActions';
import { concatRecordValuesMemoized } from '@/app/shared/utilities/helper-functions';
import { getPromotionStatus, redeemPromotion } from '@promotions/shared/utils';

@Component
export default class TotalPrice extends Vue {
  @Prop({ default: false, type: Boolean }) public noPayment!: boolean;
  @Prop() public defaultPromo!: Promotion;
  @Prop({ default: [], type: Array }) public selectedSchedules!: Schedule[];
  @Prop({ default: 0, type: Number }) public campaignSubTotalPrice!: number;
  @Prop({ default: false, type: Boolean }) public subscription!: boolean;

  public userInfo: Nullable<AuthorizedUser> = null;

  public isCampaignEligibleForPromotionOffer(promotion: Promotion, userInfo: Nullable<AuthorizedUser>, campaignSchedules: Schedule[], subTotal: number): CampaignDiscounts {
    const promotionStatus = getPromotionStatus(promotion);
    // TODO: Add applicable locations check
    // const promotionApplicableSchedules = concatRecordValuesMemoized(promotion.APPLICABLE_SCHEDULES);
    // const campaignApplicableSchedules = campaignSchedules.filter((schedule) =>
    //   promotionApplicableSchedules.some((applicableSchedule) => applicableSchedule.ID === schedule.ID));
    const campaignApplicableSchedules = [] as any;

    const hasError: boolean = Boolean(
      (userInfo && promotion.FIRST_PUBLISHED_CAMPAIGN_ONLY && userInfo.HAS_PUBLISHED_CAMPAIGNS)
      || (promotion.MINIMUM_NUMBER_OF_WEEKS_IN_CAMPAIGN && campaignSchedules.length < promotion.MINIMUM_NUMBER_OF_WEEKS_IN_CAMPAIGN)
      // || (!campaignApplicableSchedules.length)
      || (promotionStatus === PROMOTION_STATUSES.UPCOMING || promotionStatus === PROMOTION_STATUSES.EXPIRED),
    );

    return hasError
      ? { totalDiscount: 0, discountList: [] }
      : redeemPromotion(promotion, subTotal, campaignApplicableSchedules);
  }

  public get campaignDiscountedPrice(): number {
    if (this.defaultPromo) {
      const { totalDiscount } = this.isCampaignEligibleForPromotionOffer(this.defaultPromo, this.userInfo, this.selectedSchedules, this.campaignSubTotalPrice);
      return totalDiscount ? this.campaignSubTotalPrice - totalDiscount : 0;
    }
    return 0;
  }

  public async created() {
    try {
      this.userInfo = await getUserInfoAction() as AuthorizedUser;
    } catch {
      this.userInfo = null;
    }
  }
}
