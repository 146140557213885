import { FirebaseAppFirestore, FirebaseAppFunctions } from '../firebase/firebase-app';
import firebaseNames from '../utilities/firebase-names';
import { ClusterSchedulesRequest, Schedule } from '../utilities/static-types';

/**
 * Load schedule by ID.
 * @param scheduleId
 */
export const getScheduleByIdAction = async (scheduleId: string) => {
  const scheduleDoc = await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.SCHEDULES.VAL)
    .doc(scheduleId)
    .get();
  return scheduleDoc.data() as Schedule;
};

/**
 * Get all Schedules by Screen Cluster
 */
export const getSchedulesByScreenCluster = async (clusterSchedulesRequest: ClusterSchedulesRequest): Promise<Schedule[]> => {
  const getSchedulesByScreenClusterFn = FirebaseAppFunctions.httpsCallable(
    firebaseNames.functions.GET_CLUSTER_SCHEDULES,
  );
  const { data } = await getSchedulesByScreenClusterFn(clusterSchedulesRequest);
  return data as Schedule[];
};
