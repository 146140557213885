import { RouteConfig } from 'vue-router';

import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import { adminPagesGuard } from '@admin/config/admin-nav-guards';

import ScreensMain from '@adminDevices/screens/ScreenDevicesMain/ScreensMain.vue';
import ApprovedScreens from '@adminDevices/screens/ApprovedScreenDevices/ApprovedScreens.vue';
import PendingScreensHome from '@adminDevices/screens/PendingScreenDevices/screens/PendingScreenDevicesHome/PendingScreensHome.vue';
import PendingScreensView from '@adminDevices/screens/PendingScreenDevices/screens/PendingScreenDevicesForm/PendingScreensView.vue';
import CreateScreenOwner from '@adminDevices/screens/CreateScreenOwnerAccount/CreateScreenOwner.vue';
import CreateScreenCluster from '@adminDevices/screens/CreateScreenCluster/CreateScreenCluster.vue';
import RegisteredPlayers from '@adminDevices/screens/RegisteredPlayers/RegisteredPlayers/RegisteredPlayers.vue';

export default [
  {
    path: 'screens',
    component: ScreensMain,
    beforeEnter: adminPagesGuard,
    children: [
      {
        ...AdminRoutes.APPROVED_SCREENS,
        path: 'approved',
        component: ApprovedScreens,
      },
      {
        ...AdminRoutes.PENDING_SCREENS,
        path: 'pending',
        component: PendingScreensHome,
      },
      {
        ...AdminRoutes.PENDING_SCREENS_VIEW,
        path: 'pending/view/:id',
        component: PendingScreensView,
      },
      {
        ...AdminRoutes.REGISTERED_PLAYERS,
        path: 'registered-players',
        component: RegisteredPlayers,
      },
      {
        ...AdminRoutes.CREATE_SCREEN_OWNER,
        path: 'new-owner',
        component: CreateScreenOwner,
      },
      {
        ...AdminRoutes.CREATE_SCREEN_CLUSTER,
        path: 'new-cluster',
        component: CreateScreenCluster,
      },
    ],
  },
] as RouteConfig[];
