import Vue from 'vue';
import { getValueFromStorage, updateValueInStorage } from '@/app/storage/storage-actions';
import { DB_STORAGE_APP_LOCALE } from '../utilities/object-factory';

export const languageSwitcher = Vue.extend({
  data() {
    return {
      locales: { en: 'English', ar: 'العربية' },
    };
  },
  watch: {
    async locale(val?: string) {
      await this.resetLocale(val);
    },
  },
  computed: {
    locale() {
      return this.$route.query.lang as string;
    },
  },
  methods: {
    async resetLocale(val?: string) {
      const incomingLocale = val || await getValueFromStorage(DB_STORAGE_APP_LOCALE);
      const availableLocales = Object.keys(this.locales);
      const selectedLocale = availableLocales.includes(incomingLocale)
        ? incomingLocale
        : 'en';
      this.setLocale(selectedLocale);
    },
    async setLocale(locale?: string) {
      this.$root.$i18n.locale = locale;

      this.updateLayoutDirection();

      try {
        await updateValueInStorage(
          DB_STORAGE_APP_LOCALE,
          locale,
        );
      } catch (err) {
        throw new Error(`Failed to save app locale to indexedDB ${err}`);
      }
    },
    updateLayoutDirection() {
      switch (this.$root.$i18n.locale) {
        case 'ar': {
          this.$vuetify.rtl = true;
          break;
        }
        default:
          this.$vuetify.rtl = false;
          break;
      }
    },
  },
  async mounted() {
    await this.resetLocale();
  },
});
