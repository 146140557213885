




































































































import { Component, Vue } from 'vue-property-decorator';

import {
  USER_POSITIONS,
  USER_ROLES,
} from '@/app/shared/utilities/object-factory';

import rules from '@/app/shared/validation-rules';
import * as actions from '@publicApp/shared/actions';
import routesNames, { AdminRoutes } from '@/app/shared/utilities/routes-names';

import FormComponent from '@/app/shared/components/FormComponent.vue';
import AppPublicLayout from '@publicApp/shared/components/AppPublicLayout.vue';
import {
  JobPosition,
  Nullable,
  Role,
} from '@/app/shared/utilities/static-types';

@Component({
  components: {
    FormComponent,
    AppPublicLayout,
  },
  data: (vm: any) => ({
    emailRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.emailFormat(vm.$i18n.t('invalid_email')),
    ],
    passwordRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.passwordFormat(vm.$i18n.t('password_hint')),
    ],
    companyNameRules: [rules.required(vm.$i18n.t('field_required'))],
    nameRules: [rules.required(vm.$i18n.t('field_required'))],
    positionRules: [rules.required(vm.$i18n.t('field_required'))],
    phoneNumberRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.saudiPhoneFormat(vm.$i18n.t('phone_not_saudi_valid')),
    ],
    roleRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.maxLength(1, vm.$i18n.t('max_role')),
    ],
  }),
})
export default class Register extends Vue {
  public email = '';
  public phoneNumber = '';

  public password = '';
  public showPassword = false;

  public companyName = '';

  public name = '';
  // TODO: remove this any
  public roleValues: any = USER_ROLES;
  public selectedRole: Role[] = [];
  public allRoles: Role[] = [];

  public selectedPosition: Nullable<JobPosition> = null;
  public manageUsersRoute = AdminRoutes.MANAGE_USERS_ROLES;

  public get positions() {
    return Object.values(USER_POSITIONS).map((position) => ({
      ...position,
      TEXT: this.$t(`positions.${position.VAL}`).toString(),
    }));
  }

  public loginRoute = {
    ...routesNames.LOGIN,
  };

  public async registerUser() {
    const userCredentials = await actions.registerNewUser(
      this.email,
      this.password,
    );
    if (userCredentials.user) {
      await actions.createUserInfo({
        UID: userCredentials.user!.uid,
        EMAIL: userCredentials.user!.email || this.email,
        PHONE_NUMBER: this.phoneNumber,
        COMPANY_NAME: this.companyName,
        NAME: this.name,
        POSITION: this.selectedPosition!,
        ROLES: this.selectedRole,
        HAS_COMPANY_PROFILE: false,
        HAS_PUBLISHED_CAMPAIGNS: false,
      });
      this.$router.push(AdminRoutes.MANAGE_USERS_ROLES);
    } else {
      throw new Error('Registration failed.');
    }
  }

  public back() {
    this.$router.go(-1);
  }

  public created() {
    this.allRoles = Object.keys(this.roleValues)
        .filter((key) => key !== 'ADMIN')
        .map((key) => this.roleValues[key]);
  }
}
