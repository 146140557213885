






















import { Component, Vue, Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { ScreenSaver } from '@/app/shared/utilities/static-types';
import ScreenSaverItem from '../ScreenSaverItem/ScreenSaverItem.vue';
import BlockingProgress from '@/app/shared/components/BlockingProgress.vue';
import { cloneDeep } from 'lodash';
import { updateScreenSaver } from '@adminSchedules/shared/actions';

@Component({
  name: 'ScreenSaverList',
  components: {
    draggable,
    ScreenSaverItem,
    BlockingProgress,
  },
})
export default class ScreenSaverList extends Vue {
  @Prop() public screenSavers!: ScreenSaver[];
  public cachedScreenSavers: ScreenSaver[] = [];
  public screenSaversItems: ScreenSaver[] = [];

  public created() {
    this.updateList();
    this.$emit('update-list-function', this.updateList);
  }

  public activeChanged(screenSaverId: string) {
    this.$emit('active-changed', screenSaverId);
  }

  public screenSaverDeleted() {
    this.$emit('update-list');
  }

  public updateList() {
    this.cachedScreenSavers = [...this.screenSavers];
    this.screenSaversItems = [...this.screenSavers];
  }

  public async updateScreenSaverOrder(event: Event) {
    try {
      const clonedScreenSavers = cloneDeep(this.screenSaversItems);
      (this.$refs.progress as BlockingProgress).show();
      const updatedOrderTimeSlots: ScreenSaver[] = clonedScreenSavers.map(
        (screenSaver: ScreenSaver, index: number) => {
          screenSaver.ORDER = index + 1;
          return screenSaver;
        },
      );

      const changedScreenSavers: ScreenSaver[] = updatedOrderTimeSlots.filter(
        (item: ScreenSaver) => {
          const cachedItem = this.cachedScreenSavers.find(
            (itemToFind) => item.ID === itemToFind.ID,
          )!;
          return Number(cachedItem.ORDER) !== Number(item.ORDER);
        },
      );
      const promiseArray: Array<Promise<void>> = [];
      changedScreenSavers.forEach((screenSaver) => {
        promiseArray.push(updateScreenSaver(screenSaver));
      });
      await Promise.all(promiseArray);
      this.$emit('update-list');
    } finally {
      (this.$refs.progress as BlockingProgress).hide();
    }
  }
}
