import { render, staticRenderFns } from "./DeleteCampaign.vue?vue&type=template&id=2adf148e&scoped=true&"
import script from "./DeleteCampaign.vue?vue&type=script&lang=ts&"
export * from "./DeleteCampaign.vue?vue&type=script&lang=ts&"
import style0 from "./DeleteCampaign.vue?vue&type=style&index=0&id=2adf148e&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2adf148e",
  null
  
)

export default component.exports