








































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  ClustersSchedulesRecord,
  ClustersSchedulesTuple,
  Schedule,
  ScreenCluster,
} from '@/app/shared/utilities/static-types';
import SchedulesCalendar from './components/SchedulesCalendar/SchedulesCalendar.vue';
import { Getter } from 'vuex-class';
import namespace from '@/app/shared/store/namespace';

@Component({
  components: { SchedulesCalendar },
})
export default class SchedulesTabs extends Vue {
  @Prop({ required: true }) public clustersSchedulesRecord!: ClustersSchedulesRecord;
  @Prop({ required: true }) public screenClustersItems!: ScreenCluster[];
  @Prop({ default: () => ({}) }) public preSelectedClustersSchedules!: ClustersSchedulesRecord;
  @Prop({ default: 0 }) public selectedClusterTab!: number;
  @Prop({ default: false, type: Boolean }) public readonly!: boolean;
  @Prop({ default: false, type: Boolean }) public noPayment!: boolean;
  @Prop({ default: true, type: Boolean }) public disableRunningSchedules!: boolean;

  public getSelectedSchedulesFromCluster(clusterId: string) {
    return this.preSelectedClustersSchedules[clusterId];
  }

  public get clustersSchedulesTuples(): ClustersSchedulesTuple[] {
    return Object.entries(this.clustersSchedulesRecord);
  }

  public getClusterDescription(clusterId: string): string {
    const screenCluster =  this.screenClustersItems.find((cluster) => cluster.ID === clusterId);
    return screenCluster ? screenCluster.DESCRIPTION[this.appLocale] : clusterId;
  }

  public get appLocale() {
    return this.$root.$i18n.locale;
  }

  @Watch('selectedClusterTab', { immediate: true })
  public onChangeSelectedClusterTab(val: number) {
    const [ clusterId ] = this.clustersSchedulesTuples[val];
    this.$store.commit(
      `${namespace.CampaignModule}/setSelectedClusterId`,
      clusterId,
    );
  }
}
