


























import { Component, Vue } from 'vue-property-decorator';
import ShashaTerms from '@/app/shared/components/ShashaTerms.vue';
@Component({
  components: { ShashaTerms },
})
export default class PublicTermsNConditions extends Vue {
  public dialog = false;
}
