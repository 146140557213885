





























import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { ClustersSchedulesRecord, ClustersSchedulesTuple, ScreenCluster } from '@/app/shared/utilities/static-types';
import { isEmpty } from 'lodash';

@Component
export default class ClustersTabs extends Vue {
  @Prop({ required: true }) public clustersSchedulesRecord!: ClustersSchedulesRecord;
  @Prop({ required: true }) public screenClustersItems!: ScreenCluster[];
  @Prop({ default: 0 }) public selectedClusterTab!: number;
  @Prop({ required: true }) public selectedClustersSchedules!: ClustersSchedulesRecord;
  @Prop({ required: true }) public numberOfSelectedSchedules!: number;
  @Prop({ default: false, type: Boolean }) public singleClusterSelection!: boolean;

  public selectedClusterTabValue = this.selectedClusterTab;

  public get clustersSchedulesTuples(): ClustersSchedulesTuple[] {
    return Object.entries(this.clustersSchedulesRecord);
  }

  public created() {
    if (!isEmpty(this.selectedClustersSchedules)) {
      const [ firstSelectedClusterId ] = Object.keys(this.selectedClustersSchedules);
      // Initialize the selected cluster tab
      this.selectedClusterTabValue = this.clustersSchedulesTuples.findIndex(([ clusterId ]) => clusterId === firstSelectedClusterId);
    }
  }

  public getClusterName(clusterId: string): string {
    const screenCluster =  this.screenClustersItems.find((cluster) => cluster.ID === clusterId);
    return screenCluster ? screenCluster.DISPLAY_NAME[this.appLocale] : clusterId;
  }

  public get appLocale() {
    return this.$root.$i18n.locale;
  }

  /**
   * Disable cluster if any other cluster has any selected schedules,
   * so the campaign can select only one cluster
   * @param clusterId
   */
  public isDisabledCluster(clusterId: string): boolean {
    if (!this.singleClusterSelection || !this.numberOfSelectedSchedules) {
      return false;
    }

    return !Boolean(this.selectedClustersSchedules[clusterId]);
  }

  public hasSelectedSchedules(clusterId: string): boolean {
    return Boolean(this.selectedClustersSchedules[clusterId]);
  }

  // To sync `selectedClusterTab` without illegally mutating the prop
  @Emit('update:selectedClusterTab')
  @Watch('selectedClusterTabValue')
  public onChangeSelectedClusterTabValue(val: number) {
    return val;
  }
}
