


























































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import CampaignInfoPopup from '@campaigns/shared/components/CampaignInfoPopup.vue';
import ChangeCampaignMedia from '@campaigns/shared/components/ChangeCampaignMedia.vue';
import DeleteCampaign from '@campaigns/shared/components/DeleteCampaign.vue';
import { Campaign, CampaignTableRow, Nullable } from '@/app/shared/utilities/static-types';
import routesNames from '@/app/shared/utilities/routes-names';
import namespace from '@/app/shared/store/namespace';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';

@Component({
  components: {
    CampaignInfoPopup,
    ChangeCampaignMedia,
    DeleteCampaign,
  },
})
export default class TableActions extends Vue {
  @Prop({ required: true, default: () => ({}) }) public campaignRow!: CampaignTableRow;
  @Prop({ required: true, default: false }) public isCampaignOwner!: boolean;

  public selectedCampaign: Nullable<Campaign> = null;
  public reviewCampaignRoute = routesNames.CAMPAIGN_REVIEW;
  public editCampaignRoute = routesNames.STORE_CAMPAIGN_EDIT;

  public showCampaignMediaPopup(): void {
    this.selectedCampaign = this.campaignRow;
    (this.$refs.campaignMediaPopup as CampaignInfoPopup).open();
  }

  public reviewCampaign(): void {
    const { ID: id } = this.campaignRow;
    this.setCampaignRef();
    this.$router.push({ ...this.reviewCampaignRoute , params: { id } });
  }

  public get isCampaignDeletable(): boolean {
    try {
      const { STATUS: { VAL: status } } = this.campaignRow;
      return status === SYSTEM_STATUS.NEW.VAL && this.isCampaignOwner;
    } catch (error) {
      return false;
    }
  }

  public get isCampaignEditable() {
    try {
      const { STATUS: { VAL: status } } = this.campaignRow;
      return (status === SYSTEM_STATUS.REQUIRES_ACTION.VAL || status === SYSTEM_STATUS.NEW.VAL)
      && this.isCampaignOwner;
    } catch (error) {
      return false;
    }
  }

  public get canChangeMedia() {
    try {
      const { STATUS: { VAL: status }, APPROVED_BY_ADMIN } = this.campaignRow;
      return status === SYSTEM_STATUS.UNDER_PREPARATION.VAL && !APPROVED_BY_ADMIN && this.isCampaignOwner;
    } catch (error) {
      return false;
    }
  }

  public get isCampaignPendingApproval() {
    try {
      return this.campaignRow.APPROVED_BY_ADMIN && !this.campaignRow.APPROVED_BY_STORE_OWNER;
    } catch (error) {
      return false;
    }
  }

  private get campaignRef() {
    const { ADVERTISER_UID, ID } = this.campaignRow;
    return FirebaseAppFirestore
      .collection(firebaseNames.CAMPAIGNS.VAL)
      .doc(ADVERTISER_UID)
      .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
      .doc(ID);
  }

  private setCampaignRef() {
    this.$store.commit(
      `${namespace.CampaignModule}/setCurrentStoreCampaignRef`,
      this.campaignRef,
    );
  }

  private editCampaign() {
    const { ID: id } = this.campaignRow;
    this.$router.push({ ...this.editCampaignRoute , params: { id }});
  }
}
