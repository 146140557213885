













































































































































import draggable from 'vuedraggable';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  Schedule,
  TimeSlot,
  AdSpace,
  Campaign,
  Nullable,
  PlaylistCampaign,
} from '@/app/shared/utilities/static-types';

import FormComponent from '@/app/shared/components/FormComponent.vue';
import BlockingProgress from '@/app/shared/components/BlockingProgress.vue';
import ScheduleHeader from './components/ScheduleHeader/ScheduleHeader.vue';
import CampaignList from './components/CampaignList/CampaignList.vue';
import AdSpaceSelectItem from '@/app/shared/components/AdSpaceSelectItem/AdSpaceSelectItem.vue';
import CreateShashaAd from '@/app/screens/App/screens/Admin/screens/Schedules/screens/AdSpacesPlaylist/components/CreateShashaAd/CreateShashaAd.vue';
import {
  getAdSpacePlaylist,
  getAdSpacesAction,
  publishAllPlaylistAction,
} from '@adminDevices/shared/actions';
import { simulateProgress } from '@/app/shared/utilities/helper-functions';

@Component({
  components: {
    draggable,
    FormComponent,
    ScheduleHeader,
    CampaignList,
    BlockingProgress,
    AdSpaceSelectItem,
    CreateShashaAd,
  },
})
export default class AdSpacesPlaylist extends Vue {
  public selectedAdSpace: Nullable<AdSpace> = null;
  public schedulesRules: string | string[] = [];
  public schedules: Schedule[] = [];
  public adSpaces: AdSpace[] = [];

  public timeslots: TimeSlot[] = [];
  public campaignList: PlaylistCampaign[] = [];
  public startDate = '';
  public endDate = '';
  public selectedDate = '';
  public loadCampaigns = false;
  public sortByFrequency = false;
  public showCreateAd = false;
  public isModalOpen = false;
  public showShashaAdCreated = false;
  public snackbar = false;
  public snackbarMsg = '';
  public snackbarColor = 'primary';
  public campaignListLoading = false;
  public progress = 0;
  public isPublishAllPlaylistLoading = false;
  public progressBarInterval = 0;

  public async loadAdSpaces() {
    this.adSpaces = await getAdSpacesAction();
  }

  public createShashaAd(): void {
    this.showCreateAd = true;
    this.isModalOpen = true;
    this.showShashaAdCreated = false;
  }
  public async publishAllPlaylist() {
    try {
      this.isPublishAllPlaylistLoading = true;
      this.progressBarInterval = simulateProgress({ maxProgressBarInMinutes: .5 , progressUpdateCallback: (updatedProgress) => {
        this.progress = updatedProgress;
      } });
      await publishAllPlaylistAction();
      this.snackbar = true;
      this.snackbarMsg = this.$t('all_playlist_published_successfully').toString();
      this.snackbarColor = 'success';
    } catch (e) {
      /* tslint:disable-next-line */
      console.log(e);
      this.snackbar = true;
      this.snackbarMsg = this.$t('all_playlist_published_failed').toString();
      this.snackbarColor = 'error';
    } finally {
      this.isPublishAllPlaylistLoading = false;
      clearInterval(this.progressBarInterval);
    }
  }

  public async loadCampaignsData() {
    try {
      this.loadCampaigns = true;
      this.campaignListLoading = true;
      this.campaignList = await getAdSpacePlaylist({
        adSpaceId: this.selectedAdSpace!.ID,
        selectedDate: this.selectedDate,
        sortByFrequency: this.sortByFrequency,
      });
    } finally {
      this.loadCampaigns = false;
      this.campaignListLoading = false;
    }
  }


  @Watch('isModalOpen')
  public isModalOpenChanged(val: boolean) {
    if (!val) {
      this.showCreateAd = false;
      this.loadCampaignsData();
    }
  }

  @Watch('showShashaAdCreated')
  public isShowShashaAdCreatedChanged(val: boolean) {
    if (val) {
      this.snackbar = true;
      this.snackbarMsg = this.$t('shasha_ad_created').toString();
      this.snackbarColor = 'success';

    }
  }

  @Watch('selectedDate')
  public onDateChanged() {
    if (this.selectedAdSpace) {
      this.loadCampaignsData();
    }
  }

  @Watch('selectedAdSpace')
  public onAdSpaceChange() {
    if (this.selectedDate) {
      this.loadCampaignsData();
    }
  }

  @Watch('sortByFrequency')
  public onSortByFrequencyChange() {
    if (this.selectedDate && this.selectedAdSpace) {
      this.loadCampaignsData();
    }
  }


  public updateCampaignInList(campaign: Campaign) {
    if (campaign) {
      this.campaignList = this.campaignList.map((campaignItem) => {
        if (campaignItem.ID === campaign.ID) {
          return campaign;
        }
        return campaignItem;
      });
    }
  }

  public deleteShashaAd(shashaAdId: string) {
    if (shashaAdId) {
      this.campaignList = this.campaignList.filter((campaignItem) => campaignItem.ID !== shashaAdId);
    }
  }
}
