import { RouteConfig } from 'vue-router';

import StoreAdSpacesMain from '../screens/StoreOwner/screens/AdSpacesMain/StoreAdSpacesMain.vue';
import StoreAdSpaces from '../screens/StoreOwner/screens/AdSpaces/AdSpaces.vue';
import AdSpaceNew from '../screens/StoreOwner/screens/AdSpaceNew/AdSpaceNew.vue';
import AdSpaceEdit from '../screens/StoreOwner/screens/AdSpaceEdit/AdSpaceEdit.vue';
import routesNames from '@/app/shared/utilities/routes-names';
import { USER_ROLES } from '@/app/shared/utilities/object-factory';

export default [
  {
    path: 'ad-spaces',
    component: StoreAdSpacesMain,
    meta: {
      roles: [
        USER_ROLES.STORE_OWNER,
      ],
    },
    children: [
      {
        ...routesNames.AD_SPACES,
        path: '',
        component: StoreAdSpaces,
      },
      {
        ...routesNames.AD_SPACE_NEW,
        path: 'new',
        component: AdSpaceNew,
      },
      {
        ...routesNames.AD_SPACE_EDIT,
        path: 'edit/:id',
        component: AdSpaceEdit,
      },
    ],
  },
] as RouteConfig[];
