

































































import { Component, Vue } from 'vue-property-decorator';

import { MyDevice } from '@/app/shared/utilities/static-types';
import { getDevicesAction } from '@screenDevices/shared/actions';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import routesNames from '@/app/shared/utilities/routes-names';
import MyDevicesList from '@userApp/shared/components/ListView.vue';
import ViewableDeviceItem from '@userApp/shared/components/ScreenDeviceItem/ViewableDeviceItem.vue';

@Component({
  components: {
    MyDevicesList,
    ViewableDeviceItem,
  },
})
export default class MyDevicesHome extends Vue {
  public loading = false;

  public newDeviceRoute = routesNames.MY_DEVICE_NEW;
  public connectedDeviceRoute = routesNames.MY_DEVICE_CONNECT_LIST;

  public devices: MyDevice[] = [];
  public newDevices: MyDevice[] = [];
  public connectedDevices: MyDevice[] = [];

  public newDeviceColor = SYSTEM_STATUS.NEW.COLOR;
  public connectedDeviceColor = SYSTEM_STATUS.CONNECTED.COLOR;

  public async created() {
    try {
      this.loading = true;
      this.devices = await getDevicesAction();
      this.newDevices = this.devices
        .slice()
        .filter((device) => device.STATUS.VAL === SYSTEM_STATUS.NEW.VAL);
      this.connectedDevices = this.devices
        .slice()
        .filter((device) => device.STATUS.VAL === SYSTEM_STATUS.CONNECTED.VAL);
    } finally {
      this.loading = false;
    }
  }
}
