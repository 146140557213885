














































































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

import {
  CampaignFormData,
  CampaignMediaFile,
  ClustersSchedulesRecord,
  Schedule,
  ScheduleRequest,
  ScreenCluster,
} from '@/app/shared/utilities/static-types';
import { loadAdSpacesIdsByStoreOwnerAction } from '@globalActions/ClustersActions';

import rules from '../validation-rules';
import campaignRules from '@/app/screens/App/screens/Campaigns/shared/validation-rules';
import routesNames from '../utilities/routes-names';
import FormComponent from './FormComponent.vue';
import PopupMessage from './PopupMessage.vue';
import { getCurrentUser } from '../firebase/firebase-user';
import { campaignDefaultNameFactory } from '@/app/shared/utilities/object-factory';
import ClustersAndSchedules from '@/app/shared/components/ClustersAndSchedules/ClustersAndSchedules.vue';
import { loadUpcomingSchedulesAction } from '@userApp/shared/actions';
import { getScreenClustersDataFromClustersRecord } from '@screenDevices/shared/actions';
import { compose, cloneDeep, concat, uniqWith } from 'lodash/fp';
import moment from 'moment';
import { FirebaseRemoteConfig } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { getUserById } from '@adminRoles/shared/actions';
import { getCampaignDuration, getCampaignStartDate, isScheduleRunning } from '@/app/screens/App/screens/Campaigns/shared/utils';
import { concatRecordValues } from '../utilities/helper-functions';
import { Timestamp } from '@firebase/firestore-types';

@Component({
  components: {
    ClustersAndSchedules,
    FormComponent,
    PopupMessage,
  },
  data: (vm: any) => ({
    requiredRule: [rules.required(vm.$i18n.t('field_required'))],
    invitationCodeRule: [
      rules.required(vm.$i18n.t('field_required')),
      campaignRules.invitationCodeFormat(vm.$i18n.t('invalid_invitation_code')),
    ],
  }),
})
export default class CampaignForm extends Vue {
  @Prop({ required: true }) public formName!: string;
  @Prop({ required: true }) public snackbarMsg!: string;
  @Prop({ required: true }) public mediaFile!: CampaignMediaFile;
  @Prop({ default: false, type: Boolean }) public skipUpload!: boolean;
  @Prop({ default: () => () => [] }) public validateCampaignName!: (val: string) => any[] | string;
  @Prop({ required: true }) public submitCampaign!: (campaign: CampaignFormData) => Promise<string | undefined>;
  @Prop({ default: () => () => ({}) }) public loadCampaign!: () => Promise<CampaignFormData>;
  @Prop({ default: false }) public resetForm!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly!: boolean;
  @Prop({ default: false, type: Boolean }) public disableSaveButton!: boolean;
  @Prop({ default: false, type: Boolean }) public requireCompleteCompanyProfile!: boolean;
  @Prop({ default: false, type: Boolean }) public storeOwner!: boolean;
  @Prop({ default: true, type: Boolean }) public hasCheckoutButton!: boolean;
  @Prop({ type: Boolean, default: true }) public withDialog!: boolean;

  public isLanding = false;

  public isRequestingCheckout = false;

  public isAllowSelectingRunningScheduleOn = FirebaseRemoteConfig.getBoolean(
      firebaseNames.remoteConfig.ALLOW_SELECTING_RUNNING_SCHEDULE,
  );

  public get skipChecked() {
    return this.skipUpload;
  }
  /**
   * {@link https://vuejs.org/v2/guide/components-custom-events.html#sync-Modifier SyncModifier}
   */
  public set skipChecked(val: boolean) {
    this.$emit('update:skipUpload', val);
  }

  public campaignId: string | undefined;
  public campaignName = '';
  public campaignMainRoute: string | { name: string; } = routesNames.MY_CAMPAIGNS;
  public campaignCheckoutRoute: { name: string; } = routesNames.CHECKOUT_PAGE;

  public clustersSchedulesRecord: ClustersSchedulesRecord = {};
  public selectedClustersSchedules: ClustersSchedulesRecord = {};
  public screenClustersItems: ScreenCluster[] = [];
  public selectedScreenClusters: ScreenCluster[] = [];

  public showMissingCompanyProfileMsg: (() => Promise<any>) | null = null;
  public closeMissingCompanyProfileMsg: (() => void) | null = null;
  public missingCompanyProfileMsg = '';

  public campaignNameErrors: string | string[] = [];
  public storeOwnerAdSpacesIds: string[] = [];
  public duration: number = 0;
  public startDate!: Timestamp | string | Date;

  public upcomingWeeksCount = FirebaseRemoteConfig.getNumber(firebaseNames.remoteConfig.UPCOMING_WEEKS_COUNT);

  // public askForInvitationCode: (() => Promise<any>) | null = null;
  // public cancelInvitationCode: (() => void) | null = null;
  // public invitationCode = '';
  // public invitationCodeErrorMsg: string | string[] = [];
  // public isRedeeming = false;

  public get scheduleRequest(): ScheduleRequest {
    // All users can see current week if it's available
    const startUpcomingWeek = this.isAllowSelectingRunningScheduleOn ? 0 : 1;
    return {
      startUpcomingWeek,
      endUpcomingWeek: this.isAllowSelectingRunningScheduleOn
          ? this.upcomingWeeksCount - 1
          : this.upcomingWeeksCount,
    };
  }

  public get canSkipMedia(): boolean {
    return !this.storeOwner && !this.isRunningScheduleSelected;
  }

  public get isRunningScheduleSelected(): boolean {
    return concatRecordValues(this.selectedClustersSchedules)
      .some(isScheduleRunning);
  }

  public async onComponentCreated() {
    const user = await getCurrentUser();
    this.isLanding = Boolean(user);

    const campaignFormData = await this.loadCampaign();

    await FirebaseRemoteConfig.fetchAndActivate()
      .then(() => {
        this.upcomingWeeksCount = FirebaseRemoteConfig.getNumber(firebaseNames.remoteConfig.UPCOMING_WEEKS_COUNT);
        this.isAllowSelectingRunningScheduleOn = FirebaseRemoteConfig.getBoolean(firebaseNames.remoteConfig.ALLOW_SELECTING_RUNNING_SCHEDULE);
      })
      .catch((err: Error) => {
        /* tslint:disable-next-line */
        console.error(err);
      });

    const  upcomingClustersSchedulesRecord = await loadUpcomingSchedulesAction(this.scheduleRequest);

    if (campaignFormData.SELECTED_CLUSTERS_SCHEDULES) {
      this.selectedClustersSchedules = campaignFormData.SELECTED_CLUSTERS_SCHEDULES;

      const sortClustersSchedules = (schedules: Schedule[]) =>
        schedules.sort((a: Schedule, b: Schedule) =>
          moment(a.START_DATE).diff(b.START_DATE),
        );
      const uniqueClustersSchedules = uniqWith(
        (prevSchedule: Schedule, nextSchedule: Schedule) =>
          prevSchedule.WEEK === nextSchedule.WEEK, // TODO: Unique with Start Date
      );

      this.clustersSchedulesRecord = Object.entries(upcomingClustersSchedulesRecord)
        .map(([ clusterId, upcomingClusterSchedules ]) => {
          const selectedClusterSchedules = campaignFormData.SELECTED_CLUSTERS_SCHEDULES[clusterId] || [];

          const combineWithSelectedSchedules = compose(
            sortClustersSchedules,
            uniqueClustersSchedules,
            concat(selectedClusterSchedules),
            cloneDeep,
          );
          const clusterSchedules = combineWithSelectedSchedules(upcomingClusterSchedules);

          return [ clusterId, clusterSchedules ];
        })
        .reduce((allClustersSchedulesRecord, [ clusterId, clusterSchedules ]) => (
          { ...allClustersSchedulesRecord, [clusterId]: clusterSchedules }
        ), {} as ClustersSchedulesRecord);

      const uniqueScreenClusters = uniqWith(
        (prevClusters: ScreenCluster, nextCluster: ScreenCluster) =>
          prevClusters.ID === nextCluster.ID,
      );

      this.screenClustersItems =  await getScreenClustersDataFromClustersRecord(upcomingClustersSchedulesRecord);
      this.selectedScreenClusters = campaignFormData.SCREEN_CLUSTERS;
    } else {
      this.clustersSchedulesRecord = upcomingClustersSchedulesRecord;
      this.screenClustersItems = await getScreenClustersDataFromClustersRecord(this.clustersSchedulesRecord);
    }

    this.startDate = getCampaignStartDate(campaignFormData);
    this.duration = getCampaignDuration(campaignFormData);

    if (this.storeOwner) {
      this.campaignMainRoute = routesNames.STORE_CAMPAIGNS;
      this.campaignCheckoutRoute = routesNames.STORE_CAMPAIGN_CHECKOUT_PAGE;
      this.storeOwnerAdSpacesIds = await loadAdSpacesIdsByStoreOwnerAction();
      await this.filterScreenClustersByStoreOwnerAdSpacesIds();
    }

    if (user) {
      const appLanguage = this.$root.$i18n.locale;
      this.campaignName = campaignFormData.NAME || await campaignDefaultNameFactory(user, appLanguage as 'ar' | 'en');
    }
  }

  public async onFormSubmission() {
    const campaignFormData = this.getCampaignFormData();
    try {
      this.campaignId = await this.submitCampaign(campaignFormData);
      if (!this.campaignId) {
        throw new Error(this.$t('error_occurred_while_creating_campaign').toString());
      }

      this.$router.push(this.campaignMainRoute);
    } catch (err) {
      this.isRequestingCheckout = false;
      throw new Error(this.$t('something_went_wrong').toString() + '::' + err.message);
    }
  }

  public getCampaignFormData(): CampaignFormData {
    return {
      NAME: this.campaignName,
      SELECTED_CLUSTERS_SCHEDULES: this.selectedClustersSchedules,
      SCREEN_CLUSTERS: this.selectedScreenClusters,
    } as CampaignFormData;
  }

  public async isCompanyProfileComplete() {
    const currentUser = await getCurrentUser();
    const userInfo = await getUserById(currentUser!.uid);
    if (userInfo!.HAS_COMPANY_PROFILE) {
      return true;
    }

    this.isRequestingCheckout = false;

    try {
      this.missingCompanyProfileMsg = this.$t('your_company_profile_not_complete').toString();
      await this.showMissingCompanyProfileMsg!();
      return false;
    } catch (err) {
      return false;
    }
  }

  public async isAllowedToCheckout() {
    if (this.requireCompleteCompanyProfile) {
      return this.isCompanyProfileComplete();
    }
    return true;
  }

  public async proceedToCheckout() {
    const isAllowed = this.storeOwner || await this.isAllowedToCheckout();
    if (!isAllowed) {
      return;
    }

    const campaignFormData = this.getCampaignFormData();
    try {
      this.campaignId = await this.submitCampaign(campaignFormData);
      if (!this.campaignId) {
        throw new Error(this.$t('error_occurred_while_creating_campaign').toString());
      }

      // const currentUser = await getCurrentUser();
      // const userInfo = await getUserById(currentUser!.uid);
      // if (!userInfo!.ACTIVATED) {
      //   this.askForInvitationCode!();
      //   return;
      // }

      this.redirectToCheckoutPage(this.campaignId!);
    } catch (err) {
      this.isRequestingCheckout = false;
      throw new Error(this.$t('something_went_wrong').toString() + '::' + err.message);
    }
  }

  public redirectToCheckoutPage(campaignId: string) {
    this.$router.push({
      ...this.campaignCheckoutRoute,
      params: { id: campaignId! },
    });
  }

  @Watch('isRunningScheduleSelected')
  public resetSkipMedia(val: boolean) {
    if (val) { this.skipChecked = false; }
  }

  // @Watch('invitationCode')
  // public async checkInvitationCode() {
  //   const isValidCode = invitationCodeRegex.test(this.invitationCode);
  //   if (isValidCode) {
  //     try {
  //       this.isRedeeming = true;
  //       const isInvited = await redeemInvitationCode(this.invitationCode);
  //       this.isRedeeming = false;
  //       if (!isInvited) {
  //         this.invitationCodeErrorMsg = this.$t(`invalid_invitation_code`).toString();
  //       }
  //
  //       this.cancelInvitationCode!();
  //       this.redirectToCheckoutPage(this.campaignId!);
  //     } catch (err) {
  //       this.isRedeeming = false;
  //       this.invitationCodeErrorMsg = this.$t(`invalid_invitation_code`).toString();
  //     }
  //   }
  // }

  public gotoCompanyPage() {
    this.$router.push(routesNames.COMPANY_MANAGEMENT);
  }

  @Watch('campaignName')
  public async onCampaignNameChanged(val: string, oldVal: string) {
    this.campaignNameErrors = await this.validateCampaignName(val);
  }

  private async filterScreenClustersByStoreOwnerAdSpacesIds(): Promise<void> {
    Object.keys(this.clustersSchedulesRecord)
      .forEach((key: string) => {
        const adSpaceId = this.clustersSchedulesRecord[key][0].AD_SPACE_ID;
        if (!this.storeOwnerAdSpacesIds.includes(adSpaceId)) {
          delete this.clustersSchedulesRecord[key];
        }
      });
  }
}
