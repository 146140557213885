var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-component',{attrs:{"form-name":"screen-cluster-form","snackbar-msg":_vm.$t('screen_cluster_created'),"reset-form":true,"on-form-submission":_vm.createScreenCluster,"on-component-created":_vm.loadAdSpaces},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',{attrs:{"id":"header-title"}},[_vm._v(_vm._s(_vm.$t('create_screen_cluster')))])],1)]},proxy:true},{key:"fields",fn:function(ref){
var loading = ref.loading;
return [_c('v-text-field',{staticStyle:{"direction":"ltr"},attrs:{"rules":_vm.requiredRules,"label":_vm.$t('screen_cluster_name')},model:{value:(_vm.clusterName.en),callback:function ($$v) {_vm.$set(_vm.clusterName, "en", $$v)},expression:"clusterName.en"}}),_c('v-text-field',{staticStyle:{"direction":"rtl"},attrs:{"rules":_vm.requiredRules,"label":_vm.$t('screen_cluster_name_ar')},model:{value:(_vm.clusterName.ar),callback:function ($$v) {_vm.$set(_vm.clusterName, "ar", $$v)},expression:"clusterName.ar"}}),_c('v-select',{attrs:{"rules":_vm.requiredRules,"items":_vm.freeAdSpaces,"label":_vm.$t('free_ad_spaces_branches'),"loading":loading,"return-object":""},on:{"change":_vm.selectAdSpace},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var adSpace = ref.item;
return [_c('ad-space-select-item',{attrs:{"ad-space":adSpace,"show-branch":""}})]}},{key:"selection",fn:function(ref){
var adSpace = ref.item;
return [_c('ad-space-select-item',{attrs:{"ad-space":adSpace,"show-branch":""}})]}}],null,true),model:{value:(_vm.selectedAdSpace),callback:function ($$v) {_vm.selectedAdSpace=$$v},expression:"selectedAdSpace"}}),_c('v-textarea',{staticClass:"mt-3",staticStyle:{"direction":"ltr"},attrs:{"outline":"","label":_vm.$t('screen_cluster_description'),"rules":_vm.requiredRules,"auto-grow":""},model:{value:(_vm.clusterDescription.en),callback:function ($$v) {_vm.$set(_vm.clusterDescription, "en", $$v)},expression:"clusterDescription.en"}}),_c('v-textarea',{staticClass:"mt-3",staticStyle:{"direction":"rtl"},attrs:{"outline":"","label":_vm.$t('screen_cluster_description_ar'),"rules":_vm.requiredRules,"auto-grow":""},model:{value:(_vm.clusterDescription.ar),callback:function ($$v) {_vm.$set(_vm.clusterDescription, "ar", $$v)},expression:"clusterDescription.ar"}}),_c('v-text-field',{attrs:{"label":_vm.$t('screen_cluster_price'),"placeholder":_vm.$t('screen_cluster_price'),"rules":_vm.screenClusterNumberRules,"value":_vm.clusterPrice,"type":"number","prefix":"SAR","disabled":""}}),_c('v-text-field',{attrs:{"label":_vm.$t('time_slots'),"placeholder":_vm.$t('time_slots'),"rules":_vm.screenClusterNumberRules,"type":"number"},model:{value:(_vm.timeSlots),callback:function ($$v) {_vm.timeSlots=_vm._n($$v)},expression:"timeSlots"}}),_c('v-text-field',{staticStyle:{"direction":"ltr"},attrs:{"rules":_vm.requiredRules,"label":_vm.$t('zoho_item_name')},model:{value:(_vm.zohoItemName),callback:function ($$v) {_vm.zohoItemName=$$v},expression:"zohoItemName"}})]}},{key:"actions",fn:function(ref){
var saving = ref.saving;
var valid = ref.valid;
return [_c('v-btn',{attrs:{"type":"submit","color":"primary","loading":saving,"disabled":!valid}},[_vm._v("Save")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }