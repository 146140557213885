import { render, staticRenderFns } from "./AdSpaceScreenSaverList.vue?vue&type=template&id=ced0bcd0&scoped=true&"
import script from "./AdSpaceScreenSaverList.vue?vue&type=script&lang=ts&"
export * from "./AdSpaceScreenSaverList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ced0bcd0",
  null
  
)

export default component.exports