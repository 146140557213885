











import { Component, Vue } from 'vue-property-decorator';

@Component
export default class BlockingProgress extends Vue {
  public dialog = false;

  public show() {
    this.dialog = true;
  }

  public hide() {
    this.dialog = false;
  }
}
