



































import { Component, Vue, Watch } from 'vue-property-decorator';

import {
  CampaignFormData,
  CampaignMediaFile,
  CampaignUpdate,
  ScreenCluster,
  Nullable,
} from '@/app/shared/utilities/static-types';
import {
  loadCampaignAction,
  updateCampaignAction,
} from '@campaigns/shared/actions';
import { SUPPORTED_TYPES, SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';

import campaignRules from '@campaigns/shared/validation-rules';
import routesNames from '@/app/shared/utilities/routes-names';
import CampaignMedia from '@campaigns/shared/components/CampaignMedia.vue';
import CampaignForm from '@/app/shared/components/CampaignForm.vue';

import { get } from 'lodash/fp';

@Component({
  components: {
    CampaignMedia,
    CampaignForm,
  },
})
export default class CampaignEdit extends Vue {
  public clusterIsLoaded = false;

  public isEdited = false;
  public isRejected = false;
  public skipUpload: boolean = false;

  public campaignMainRoute = routesNames.STORE_CAMPAIGNS;

  private selectedCluster: Nullable<ScreenCluster> = null;

  private mediaFile: Nullable<CampaignMediaFile> = null;

  private campaignId = '';
  private campaignName = '';

  public goBack(): void {
    this.$router.go(-1);
  }

  public get mediaBadge() {
    return Boolean(this.mediaFile || this.skipUpload);
  }

  public set mediaBadge(val: boolean) {
    return;
  }

  public get mediaBadgeColor() {
    if (this.mediaFile) {
      return 'green';
    } else if (this.skipUpload) {
      return 'orange';
    }
  }

  public async loadCampaign(): Promise<CampaignFormData> {
    const loadedId = this.$route.params.id;
    if (!loadedId) {
      this.$router.push(routesNames.MY_CAMPAIGNS);
      throw new Error('Error loading campaign.');
    }

    const campaign = await loadCampaignAction(loadedId);

    this.campaignName = campaign!.NAME;
    this.campaignId = loadedId;

    this.isRejected = campaign!.STATUS.VAL === SYSTEM_STATUS.REQUIRES_ACTION.VAL;

    if (campaign.MEDIA_FILE) {
      const campaignMedia: CampaignMediaFile = {
        FILE_NAME: '',
        STATIC_LINK: campaign.MEDIA_FILE,
        FILE_TYPE: campaign.MEDIA_TYPE,
      };

      if (campaign.MEDIA_TYPE === SUPPORTED_TYPES.MP4 && campaign.MEDIA_PLAYBACK_DURATION) {
        campaignMedia.PLAYBACK_DURATION = campaign.MEDIA_PLAYBACK_DURATION;
      }

      this.mediaFile = campaignMedia;

    }

    this.skipUpload = campaign.MEDIA_SKIPPED;

    this.clusterIsLoaded = Boolean(this.selectedCluster);

    return {
      NAME: this.campaignName,
      SELECTED_CLUSTERS_SCHEDULES: campaign.SELECTED_CLUSTERS_SCHEDULES,
      SCREEN_CLUSTERS: campaign.SCREEN_CLUSTERS,
    } as CampaignFormData;
  }

  public async saveCampaign(campaignFormData: CampaignFormData) {
    const campaign = {
      ...campaignFormData,
      ID: this.campaignId,
      SCREEN_CLUSTERS_IDS: campaignFormData.SCREEN_CLUSTERS.map(get('ID')),
      MEDIA_FILE: !this.skipUpload ? this.mediaFile!.STATIC_LINK : null,
      MEDIA_TYPE: !this.skipUpload ? this.mediaFile!.FILE_TYPE : null,
      STATUS: this.isRejected
        ? SYSTEM_STATUS.UNDER_PREPARATION
        : SYSTEM_STATUS.NEW,
      MEDIA_SKIPPED: Boolean(this.skipUpload),
    } as CampaignUpdate;

    if (campaign.MEDIA_TYPE === SUPPORTED_TYPES.MP4 && this.mediaFile!.PLAYBACK_DURATION) {
      campaign.MEDIA_PLAYBACK_DURATION = this.mediaFile!.PLAYBACK_DURATION;
    }

    await updateCampaignAction(campaign);
    return this.campaignId;
  }

  public onMediaSelected(selectedFile: CampaignMediaFile) {
    this.mediaFile = selectedFile;
  }

  public validateEditCampaignName(val: string) {
    return campaignRules.duplicateCampaignNameForEdit(
      val,
      this.campaignId,
      this.$t('campaign_name_already_exists').toString(),
    );
  }

  @Watch('mediaFile')
  public async onMediaFileChanged(val: string, oldVal: string) {
    if (oldVal !== null && val !== oldVal) {
      this.isEdited = true;
    }
  }
}
