import { DocumentReference, DocumentSnapshot } from '@firebase/firestore-types';

import { FirebaseAppFirestore, FirebaseAppStorage } from '@/app/shared/firebase/firebase-app';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import { CompanyProfile } from '@/app/shared/utilities/static-types';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { updateUserInfo } from '@adminRoles/shared/actions';

/**
 * Uploads the certificate file.
 * @param fileBlob Company registration certificate file
 */
export const uploadRegistrationCertificate = async (fileBlob: any) => {
  const currentUser = await getCurrentUser();
  return FirebaseAppStorage
    .ref(firebaseNames.MEDIA_FILES.VAL)
    .child(currentUser!.uid)
    .child(firebaseNames.MEDIA_FILES.FOR_COMPANY_PROFILE)
    .child(fileBlob.name);
};

/**
 * Create a new company profile.
 * @param companyProfile Input company profile
 */
export const createCompanyProfileAction = async (companyProfile: CompanyProfile) => {
  const currentUser = await getCurrentUser();
  await FirebaseAppFirestore
    .collection(firebaseNames.COMPANY_PROFILES)
    .doc(currentUser!.uid)
    .set(companyProfile);
  await updateUserInfo(currentUser!.uid, { HAS_COMPANY_PROFILE: true });
};

export const getCompanyProfileRef = async (): Promise<DocumentReference> => {
  const currentUser = await getCurrentUser();
  return await FirebaseAppFirestore
    .collection(firebaseNames.COMPANY_PROFILES)
    .doc(currentUser!.uid);
};

/**
 * @description
 * Subscribe to the updates of the company profile of current user
 * @param handleListener {function(DocumentSnapshot)} - A callback to handle listener updates
 *
 * @returns {callback} - A callback to destroy the listener
 */
export const subscribeToCompanyProfileAction =
  async (handleListener: (doc: DocumentSnapshot) => void) => {
    const documentRef = await getCompanyProfileRef();

    return documentRef.onSnapshot(handleListener);
  };
