
































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import {
  getUserById,
  updateUserRoles,
} from '@adminRoles/shared/actions';
import { getCompanyProfileByUserIdAction } from '@globalActions/CompanyProfileActions';
import {
  Role,
  CompanyRegCertificate, JobPosition,
} from '@/app/shared/utilities/static-types';
import { USER_ROLES } from '@/app/shared/utilities/object-factory';
import rules from '@/app/shared/validation-rules';
import CompanyRegViewer from '@/app/shared/components/CompanyRegViewer.vue';
import FormComponent from '@/app/shared/components/FormComponent.vue';

@Component({
  components: {
    FormComponent,
    CompanyRegViewer,
  },
  data: (vm: any) => ({
    rolesRules: [rules.required(vm.$i18n.t('field_required'))],
  }),
})
export default class ManageUsersEdit extends Vue {
  public email = '';
  public phoneNumber = '';
  public companyName = '';
  public position = '';
  public companyRegNumber = '';
  public companyRegCertLink = '';
  public companyRegCertType = '';
  public companyAddress = '';
  public companyBrands: string[] = [];
  public companyVat = '';

  public isStoreOwner = false;
  public storesCount = 0;

  public isAdvertiser = false;
  public campaignsCount = 0;

  public isScreenOwner = false;
  public screensCount = 0;

  public selectedRoles: Role[] = [];
  public allRoles: Role[] = [];
  public roleValues: any = USER_ROLES;

  public usersMainRoute = AdminRoutes.MANAGE_USERS_ROLES;

  private userId = '';

  public async loadUserInformation() {
    await this.loadUser();
    await this.loadAllRoles();
  }

  public async loadUser() {
    const loadedId = this.$route.params.id;
    if (!loadedId) {
      throw new Error('Error loading User.');
    }
    const user = await getUserById(loadedId);
    const company = await getCompanyProfileByUserIdAction(loadedId);
    this.userId = user!.UID;
    this.email = user!.EMAIL;
    this.phoneNumber = user!.PHONE_NUMBER;
    this.companyName = user!.COMPANY_NAME;
    this.position = user!.POSITION && user!.POSITION.VAL;
    this.selectedRoles = user!.ROLES || [];
    if (this.selectedRoles) {
      this.isStoreOwner = this.selectedRoles.some((role) => role.VAL === USER_ROLES.STORE_OWNER.VAL);
      this.storesCount = 0;
      this.isAdvertiser =
        this.selectedRoles.some((role) => role.VAL === USER_ROLES.ADVERTISER.VAL);
      this.campaignsCount = 0;
      this.isScreenOwner = this.selectedRoles.some((role) => role.VAL === USER_ROLES.SCREEN_OWNER.VAL);
      this.screensCount = 0;
    }
    if (company) {
      this.companyRegNumber = company!.COMPANY_REGISTRATION_NUMBER;
      this.companyRegCertLink = company!.COMPANY_REGISTRATION_CERTIFICATE.STATIC_LINK;
      this.companyRegCertType = company!.COMPANY_REGISTRATION_CERTIFICATE.CONTENT_TYPE;
      this.companyAddress = company!.COMPANY_ADDRESS;
      this.companyVat = company!.COMPANY_VAT!;
      this.companyBrands = company!.COMPANY_PRODUCTS;
    }
  }

  public async updateUser() {
    await updateUserRoles(this.userId, this.selectedRoles);
  }

  public async loadAllRoles() {
    this.allRoles = Object.keys(this.roleValues)
      .filter((key) => key !== 'ADMIN')
      .map((key) => this.roleValues[key]);
  }
}
