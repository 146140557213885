














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ListView extends Vue {
  @Prop({ required: true }) public items!: any[];
  @Prop({ required: false }) public icon!: string;
  @Prop({ default: 'black' }) public color!: string;
  @Prop({ required: true }) public emptyMsg!: string;
  @Prop({ required: true }) public subHeading!: string;
}
