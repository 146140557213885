import { GetterTree } from 'vuex';
import { CampaignState } from './CampaignTypes';
import { RootState } from '@/app/shared/store/RootState';
import { DocumentReference } from '@firebase/firestore-types';
import { ClustersSchedulesRecord } from '@/app/shared/utilities/static-types';
import { cloneDeep } from 'lodash';

export const getters: GetterTree<CampaignState, RootState> = {
  getCurrentStoreCampaignRef(state: CampaignState): DocumentReference {
    return state.currentStoreCampaignRef!;
  },
  getSelectedClustersSchedules(state: CampaignState): ClustersSchedulesRecord {
    const { selectedClusterId, selectedSchedulesInCluster } = state;

    if (selectedSchedulesInCluster.length) {
      state.selectedClustersSchedules[selectedClusterId] = cloneDeep(selectedSchedulesInCluster);
    } else {
      delete state.selectedClustersSchedules[selectedClusterId];
    }

    return state.selectedClustersSchedules;
  },
};
