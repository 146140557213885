















































































































































































































import { Component, Vue } from 'vue-property-decorator';
import {
  loadCampaignAction,
  checkoutCampaign,
  updateCampaignAction,
  loadRecentBillingContactAction,
  updateRecentBillingContactAction,
} from '@campaigns/shared/actions';
import {
  SystemStatus,
  ScreenCluster,
  CampaignPriceInfo,
  ClustersSchedulesRecord,
  AuthorizedUser,
  Campaign,
  RecentBillingContact,
} from '@/app/shared/utilities/static-types';
import {
  SUPPORTED_TYPES,
  SYSTEM_STATUS,
} from '@/app/shared/utilities/object-factory';

import routesNames from '@/app/shared/utilities/routes-names';
import rules from '@/app/shared/validation-rules';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import TermsNConditions from '@campaigns/shared/components/TermsNConditions.vue';
import PopupMessage from '@/app/shared/components/PopupMessage.vue';
import { cloneDeep } from 'lodash';
import ClustersAndSchedules from '@/app/shared/components/ClustersAndSchedules/ClustersAndSchedules.vue';
import CampaignPrice from '@campaigns/shared/components/CampaignPrice.vue';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import { getUserById } from '@adminRoles/shared/actions';
import { FirebaseRemoteConfig } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';

@Component({
  components: {
    CampaignPrice,
    ClustersAndSchedules,
    FormComponent,
    TermsNConditions,
    PopupMessage,
  },
  data: (vm: any) => ({
    requiredRules: [rules.required(vm.$i18n.t('field_required'))],
    promoCodeRules: [
      rules.onlyAlphanumeric(vm.$i18n.t('only_alphanumerics_allowed')),
    ],
    emailRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.emailFormat(vm.$i18n.t('invalid_email')),
    ],
    phoneNumberRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.saudiPhoneFormat(vm.$i18n.t('phone_not_saudi_valid')),
    ],
  }),
  beforeRouteEnter: async (to, from, next) => {
    const currentUser = await getCurrentUser();
    const userInfo = await getUserById(currentUser!.uid);
    const { id } = to.params;

    if (!userInfo!.HAS_COMPANY_PROFILE) {
      next({
        ...routesNames.CAMPAIGN_EDIT,
        params: { id },
      });
      return;
    }

    const campaignData = await loadCampaignAction(id);
    const isCampaignNew = campaignData.STATUS.VAL === SYSTEM_STATUS.NEW.VAL;
    const isCampaignRequiresAction = campaignData.STATUS.VAL === SYSTEM_STATUS.REQUIRES_ACTION.VAL;

    if (!isCampaignNew || isCampaignRequiresAction) {
      next(routesNames.MY_CAMPAIGNS);
      return;
    }

    next();
  },
})
export default class CheckoutPage extends Vue {
  public acceptTerms = false;
  public paymentPanel = [true];

  public campaignMainRoute = routesNames.MY_CAMPAIGNS;
  public campaignEditRoute = routesNames.CAMPAIGN_EDIT;

  public campaign: Campaign | null = null;
  public campaignId = '';
  public campaignName = '';

  public campaignStatus: SystemStatus | null = null;
  public mediaFile: string | null = null;
  public mediaType: string | null = null;
  public recentBillingContact: RecentBillingContact = new RecentBillingContact();

  public isHundredSarForFirstWeekOfferOn = FirebaseRemoteConfig.getBoolean(
    firebaseNames.remoteConfig.HUNDRED_SAR_FOR_FIRST_WEEK_OFFER,
  );

  // public billingInfo: BillingInfo | null = null;
  // public cardInfo: CardInfo | null = null;

  public isRejected = false;

  public clustersSchedulesRecord: ClustersSchedulesRecord = {};
  public campaignClustersSchedules: ClustersSchedulesRecord = {};
  public screenClustersItems: ScreenCluster[] = [];
  public preSelectedScreenClusters: ScreenCluster[] = [];

  public promoCode = '';
  public promoCodeLoading = false;
  public promoCodeSuccessMsg = '';
  public promoCodeErrorMsg = '';

  public returnUrl = '';

  public isPayLaterForCampaignOn = FirebaseRemoteConfig.getBoolean(
    firebaseNames.remoteConfig.PAY_LATER_FOR_CAMPAIGN,
  );
  public pendingPaymentTimeoutInHours = FirebaseRemoteConfig.getBoolean(
    firebaseNames.remoteConfig.PENDING_PAYMENT_TIMEOUT_IN_HOURS,
  );

  public userInfo: AuthorizedUser | null | undefined = null;
  public campaignPriceInfo: CampaignPriceInfo | null = null;

  private SUPPORTED_TYPES = SUPPORTED_TYPES;

  public async loadCampaignInfo() {
    await this.loadRecentBillingContact();
    const currentUser = await getCurrentUser();
    this.userInfo = await getUserById(currentUser!.uid);
    this.campaignId = this.$route.params.id;
    if (!this.campaignId) {
      throw new Error('Error loading campaign.');
    }

    // TODO: get rid of `await` to avoid blocking for `Promise.all`
    [ this.campaign, this.campaignPriceInfo ] = await Promise.all([
      (async () => loadCampaignAction(this.campaignId))(),
      (async () => this.loadCheckoutData(this.campaignId))(),
    ]);

    this.campaignName = this.campaign.NAME;
    this.campaignClustersSchedules = this.campaign.SELECTED_CLUSTERS_SCHEDULES;
    this.clustersSchedulesRecord = cloneDeep(this.campaignClustersSchedules);
    this.screenClustersItems = this.campaign.SCREEN_CLUSTERS;
    this.preSelectedScreenClusters = cloneDeep(this.screenClustersItems);
    this.mediaFile = this.campaign.MEDIA_FILE;
    this.mediaType = this.campaign.MEDIA_TYPE;
    this.campaignStatus = this.campaign!.STATUS;
    this.isRejected = this.campaign!.STATUS.VAL === SYSTEM_STATUS.REQUIRES_ACTION.VAL;

    if (!this.campaignPriceInfo) {
      throw new Error(`Couldn't get campaign price!`);
    }

    await FirebaseRemoteConfig.fetchAndActivate()
      .then(() => {
        this.isHundredSarForFirstWeekOfferOn = FirebaseRemoteConfig.getBoolean(
          firebaseNames.remoteConfig.HUNDRED_SAR_FOR_FIRST_WEEK_OFFER,
        );
        this.isPayLaterForCampaignOn = FirebaseRemoteConfig.getBoolean(
          firebaseNames.remoteConfig.PAY_LATER_FOR_CAMPAIGN,
        );
        this.pendingPaymentTimeoutInHours = FirebaseRemoteConfig.getBoolean(
          firebaseNames.remoteConfig.PENDING_PAYMENT_TIMEOUT_IN_HOURS,
        );
      })
      .catch((err: Error) => {
        /* tslint:disable-next-line */
        console.error(err);
      });
  }

  public async loadRecentBillingContact(): Promise<void> {
    this.recentBillingContact = await loadRecentBillingContactAction();
  }

  public async loadCheckoutData( campaignId: string, promoCode?: string ): Promise<CampaignPriceInfo | null> {
    if (campaignId) {
      try {
        const { checkoutId, returnUrl, paymentBill } = await checkoutCampaign(campaignId, promoCode);

        const paymentGatewayDomain = process.env.VUE_APP_HYPER_PAY_DOMAIN;
        const paymentGatewayUrl = `${paymentGatewayDomain}/v1/paymentWidgets.js?checkoutId=${checkoutId}`;

        this.loadScript(paymentGatewayUrl, () => {
          this.returnUrl = returnUrl;
        });

        return paymentBill;
      } catch (err) {
        throw new Error(this.$t('something_went_wrong').toString() + '::' + err.message);
      }
    }

    return null;
  }

  public async publishCampaign() {
    try {
      await updateCampaignAction({
        ID: this.campaign!.ID,
        STATUS: SYSTEM_STATUS.PENDING_PAYMENT,
      } as Campaign);
      await updateRecentBillingContactAction(this.recentBillingContact);
      this.$router.push(this.campaignMainRoute);
    } catch (err) {
      throw new Error(this.$t('something_went_wrong').toString() + '::' + err.message);
    }
  }

  public backToEditCampaign(campaignId: string) {
    this.$router.push({
      ...this.campaignEditRoute,
      params: {
        id: campaignId,
      },
    });
  }

  public loadScript(url: string, onload: () => any) {
    (window as any).wpwlOptions = {
      locale: this.$root.$i18n.locale,
    };

    const paymentScriptId = 'paymentScript';
    const paymentScript = document.getElementById(paymentScriptId);
    if (paymentScript) {
      paymentScript.parentNode!.removeChild(paymentScript);
    }

    const script = document.createElement('script');
    script.id = paymentScriptId;
    script.type = 'text/javascript';

    script.onload = onload;

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  public async redeemPromotion(campaignId: string, promoCode: string) {
    this.promoCodeLoading = true;
    this.promoCodeSuccessMsg = '';
    this.promoCodeErrorMsg = '';

    try {
      this.campaignPriceInfo = await this.loadCheckoutData(campaignId, promoCode);
    } catch (err) {
      this.promoCodeLoading = false;
      this.promoCodeErrorMsg = this.$t('something_went_wrong').toString();
    }

    if (!this.campaignPriceInfo) {
      this.promoCodeLoading = false;
      return;
    }

    if (this.campaignPriceInfo.ERROR_MESSAGE) {
      this.promoCodeLoading = false;
      this.promoCodeErrorMsg = this.$t(this.campaignPriceInfo.ERROR_MESSAGE).toString();
      return;
    }

    this.promoCodeLoading = false;

    this.promoCodeSuccessMsg = this.$t('offer_redeemed').toString();
  }

  public gotoCompanyProfilePage() {
    this.$router.push(routesNames.COMPANY_MANAGEMENT);
  }
}
