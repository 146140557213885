








































































import { Component, Vue } from 'vue-property-decorator';
import {
  createScreenSaver,
  getScreenSaverCount,
  loadAdSpacesAction,
} from '@adminSchedules/shared/actions';
import { uniqueArray } from '@/app/shared/utilities/helper-functions';
import {
  CampaignMedia,
  MediaType,
  ScreenResolution,
  ScreenSaver,
  ScreenSaverItem,
} from '@/app/shared/utilities/static-types';
import MediaUpload from '@campaigns/shared/components/MediaUpload.vue';
import { errors } from '@/app/shared/config/errors';
import {
  convertFromMediaResolutionTypeToScreenResolutionType, getMediaName,
  USER_TYPES_ABBREVIATION,
} from '@/app/shared/utilities/object-factory';
import { checkMediaResolution } from '@/app/shared/utilities/files';
import { uploadFileForScreenSaver } from '@userApp/shared/actions';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
@Component({
  name: 'CreateScreenSaver',
  components: {
    MediaUpload,
  },
})
export default class CreateScreenSaver extends Vue {
  public closeEvent = 'update:showCreateScreenSaver';
  public screenSaverMediaList: ScreenSaverItem[] = [];
  public loading = false;
  public snackbarMsg = '';
  public snackbar = false;

  get invalidImages() {
    return (
      this.screenSaverMediaList.length === 0 ||
      this.screenSaverMediaList.filter(
        (screenSaverItem) => !screenSaverItem.selectedFile,
      ).length > 0
    );
  }

  public async created() {
    const adSpaces = await loadAdSpacesAction();
    const publishedAdSpaces = adSpaces.filter(
      (adSpace) => adSpace.SCREEN_CLUSTER_REF,
    );
    const resolutions = uniqueArray(
      publishedAdSpaces,
      (adSpace) => adSpace.SCREENS_RESOLUTION,
    );
    this.screenSaverMediaList = resolutions
        .filter((resolution) =>
            Boolean(resolution)
            && !Number.isNaN(resolution.WIDTH)
            && !Number.isNaN(resolution.HEIGHT))
        .map((resolution) => ({
          mediaPreview: '',
          selectedFile: null,
          resolution,
      }));
  }

  public selectMedia(refName: string) {
    const [mediaUpload] = this.$refs[refName] as MediaUpload[];
    const uploadFileInput = mediaUpload.$refs.uploadFileRef as HTMLInputElement;
    uploadFileInput.click();
  }

  public async checkResolution(
    selectedFile: File,
    adSpaceResolution: ScreenResolution,
  ) {
    const resolution = convertFromMediaResolutionTypeToScreenResolutionType(
      adSpaceResolution,
    );
    return await checkMediaResolution(selectedFile, resolution);
  }

  public async changeCampaignMedia(
    selectedFile: File,
    screenSaverItem: ScreenSaverItem,
  ): Promise<void> {
    try {
      await this.checkResolution(selectedFile, screenSaverItem.resolution);
      screenSaverItem.mediaPreview = URL.createObjectURL(selectedFile);
      screenSaverItem.selectedFile = selectedFile;
    } catch (error) {
      if (error && error.message === errors.MEDIA_RESOLUTION_NOT_MATCH) {
        this.snackbar = true;
        this.snackbarMsg = this.$t('media_resolution_error').toString();
      }
    }
  }

  public async uploadMedia(selectedFile: File, mediaId: string): Promise<string | undefined> {
    const uploadTaskRef = await uploadFileForScreenSaver(selectedFile, mediaId);
    const taskSnapshot = await uploadTaskRef.put(selectedFile);
    return taskSnapshot && (await taskSnapshot.ref.getDownloadURL());
  }

  public async uploadImages(mediaIds: string[]) {
    const promises: Array<Promise<string | undefined>> = [];
    this.screenSaverMediaList.forEach((screenSaverItem, index) => {
      promises.push(this.uploadMedia(screenSaverItem.selectedFile as File, mediaIds[index]));
    });
    return Promise.all(promises);
  }

  public async saveScreenSaver() {
    try {
      this.loading = true;
      const mediaIds = this.screenSaverMediaList.map(() => uuidv4());
      const downloadedImages = await this.uploadImages(mediaIds);
      const isFailedToUpload =
          downloadedImages.filter((downloadedImage) => !downloadedImage).length > 0;
      if (!isFailedToUpload) {
        const mediaList = this.screenSaverMediaList.map(
            (screenSaverItem, index) => {
              const mediaFile: CampaignMedia = {
                MEDIA_FILE: downloadedImages[index],
                WIDTH: screenSaverItem.resolution.WIDTH,
                HEIGHT: screenSaverItem.resolution.HEIGHT,
                MEDIA_ID: mediaIds[index],
                NAME:
                    getMediaName({
                      userTypeAbbreviation: USER_TYPES_ABBREVIATION.SCREEN_SAVER,
                      companyName: 'admin',
                      mediaId: mediaIds[index],
                      extension: screenSaverItem.selectedFile!.name.split('.').pop() || '',
                    }),
                MEDIA_UPLOADED_AT: firebase.firestore.Timestamp.now(),
              };
              if (screenSaverItem.selectedFile) {
                mediaFile.MEDIA_TYPE = screenSaverItem.selectedFile
                    .type as MediaType;
              }
              return mediaFile;
            },
        );
        const screenSaverCount = await getScreenSaverCount();
        const screenSaver: ScreenSaver = {
          MEDIA_LIST: mediaList,
          ORDER: screenSaverCount + 1,
          ACTIVE: true,
        };
        await createScreenSaver(screenSaver);
        this.$emit('update-list');
        this.$emit(this.closeEvent, false);
      }
    } finally {
      this.loading = false;
    }

  }
}
