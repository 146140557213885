







































import { Component, Vue } from 'vue-property-decorator';
import { DocumentReference } from '@firebase/firestore-types';

import { MyDevice } from '@/app/shared/utilities/static-types';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import { getSystemDevicesAction } from '@adminDevices/shared/actions';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';

import namespace from '@/app/shared/store/namespace';
import MyDevicesList from '@userApp/shared/components/ListView.vue';
import ClickableDeviceItem from '@userApp/shared/components/ScreenDeviceItem/ClickableDeviceItem.vue';

@Component({
  components: {
    MyDevicesList,
    ClickableDeviceItem,
  },
})
export default class PendingScreensHome extends Vue {
  public loading = true;

  public pendingDevices: MyDevice[] = [];
  public pendingDeviceColor = SYSTEM_STATUS.PENDING_APPROVAL.COLOR;

  public storeDeviceRoute = AdminRoutes.PENDING_SCREENS_VIEW;

  private refs: DocumentReference[] = [];

  public async created() {
    try {
      this.loading = true;
      this.refs = await getSystemDevicesAction();
      const promises = this.refs.map(async (ref) => {
        const snap = await ref.get();
        return snap.data() as MyDevice;
      });
      this.pendingDevices = await Promise.all(promises);
    } finally {
      this.loading = false;
    }
  }

  public setDeviceRef(index: number) {
    const targetRef = this.refs[index];
    this.$store.commit(
      `${namespace.MyDeviceModule}/setCurrentStoreDeviceRef`,
      targetRef,
    );
  }
}
