






























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import {
  MyDevice,
  Country,
  City,
  StoreAccount,
  AdSpace,
  Nullable,
} from '@/app/shared/utilities/static-types';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import {
  getStoreByIdAction,
  getCitiesByCountryCodeAction,
  getCountriesAction,
} from '@globalActions/DevicesActions';
import { Getter } from 'vuex-class';
import { DocumentReference } from '@firebase/firestore-types';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';

import rules from '@/app/shared/validation-rules';
import namespace from '@/app/shared/store/namespace';
import ScreenDevicePhoto from '@userApp/shared/components/ScreenDevicePhoto/ScreenDevicePhoto.vue';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import { getAdSpacesAction } from '@/app/screens/App/screens/ScreenDevices/shared/actions';

@Component({
  components: {
    FormComponent,
    ScreenDevicePhoto,
  },
  data: (vm: any) => ({
    requiredRule: [rules.required(vm.$i18n.t('field_required'))],
  }),
})
export default class PendingScreensView extends Vue {
  @Getter('getCurrentStoreDeviceRef', { namespace: namespace.MyDeviceModule })
  public currentRef!: DocumentReference;

  public editMode = false;

  public deviceName = '';

  public selectedCountry: Nullable<Country> = null;
  public countryArray: Country[] = [];
  public get countries(): Country[] {
    return this.countryArray!
      .map((country) => ({
        ...country,
        TEXT: this.$t(`countries.${country.DISPLAY_NAME}`).toString(),
      } as Country));
  }

  public selectedCity: Nullable<City> = null;
  public cityArray: City[] = [];
  public get cities(): City[] {
    return this.cityArray!
      .map((city) => ({
        ...city,
        TEXT: this.$t(`cities.${city.DISPLAY_NAME}`).toString(),
      } as City));
  }

  public selectedStore: Nullable<StoreAccount> = null;
  public selectedAdSpace: Nullable<AdSpace> = null;
  public adSpaces: AdSpace[] = [];
  public storeArray: StoreAccount[] = [];

  public deviceMainRoute = AdminRoutes.PENDING_SCREENS;

  public screenId = '';

  public screenPhoto = '';

  public selectedArea: Nullable<string> = null;
  public screenAreas = ['Promo'];

  public comments = '';

  private deviceId = '';

  public async loadAdSpaces() {
    this.adSpaces = await getAdSpacesAction();
  }

  public async loadDeviceInfo() {
    await this.loadAdSpaces();
    if (!this.currentRef) {
      this.$router.push(this.deviceMainRoute);
      return;
    }
    const deviceSnap = await this.currentRef.get();
    const device = deviceSnap.data() as MyDevice;
    this.selectedStore = await getStoreByIdAction(device.STORE_ID);
    this.selectedCountry = device.COUNTRY!;
    this.deviceName = device.DISPLAY_NAME;
    this.selectedAdSpace = this.adSpaces.find((item) => item.ID === device.AD_SPACE_ID)!;
    this.selectedCity = device.CITY!;
    this.screenId = device.SCREEN_CODE!;
    this.screenPhoto = device.PHOTO_URL!;
    this.selectedArea = device.STORE_AREA!;
    this.deviceId = device.ID!;
    this.loadCities(this.selectedCountry);
    this.loadCountries();
  }

  public loadCountries() {
    this.countryArray = getCountriesAction();
  }

  public async updateDevice() {
    if (!this.screenPhoto) {
      throw new Error('You must add a Screen photo');
    }
    this.editMode = false;
    const device: MyDevice = {
      DISPLAY_NAME: this.deviceName,
      COUNTRY: this.selectedCountry!,
      CITY: this.selectedCity!,
      STORE_ID: this.selectedStore!.UID!,
      STORE_NAME: this.selectedStore!.STORE_NAME,
      AD_SPACE_NAME: this.selectedAdSpace!.NAME!,
      AD_SPACE_ID: this.selectedAdSpace!.ID,
      STATUS: SYSTEM_STATUS.CONNECTED,
      ID: this.deviceId,
      STORE_AREA: this.selectedArea!,
      COMMENTS: this.comments,
      SCREEN_CODE: this.screenId,
      PHOTO_URL: this.screenPhoto,
    };
    await this.currentRef.update(device);
  }

  public loadCities(country: Country) {
    this.cityArray = getCitiesByCountryCodeAction(country);
  }

  public async photoUpdated(newPhoto: string) {
    this.screenPhoto = newPhoto;
  }

  @Watch('$root.$i18n.locale')
  public onChangeLocale(val: string, oldVal: string) {
    const [ prevSelectedCountry, prevSelectedCity ] = [ this.selectedCountry, this.selectedCity ];

    this.loadCountries();

    if (prevSelectedCountry) {
      this.selectedCountry = this.countries.find((country) => country.GEONAME_ID === prevSelectedCountry.GEONAME_ID)!;
      this.loadCities(prevSelectedCountry);
    }

    if (prevSelectedCity) {
      this.selectedCity = this.cities.find((city) => city.GEONAME_ID === prevSelectedCity.GEONAME_ID)!;
    }
  }
}
