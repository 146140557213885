































import { Component, Vue, Prop } from 'vue-property-decorator';
import { TimeSlot, Campaign } from '@/app/shared/utilities/static-types';
import PreviewThumbnail from '@campaigns/shared/components/PreviewThumbnail.vue';

@Component({
  components: {
    PreviewThumbnail,
  },
})
export default class PreviewSchedule extends Vue {
  @Prop({ required: true }) public timeslots!: TimeSlot[];

  public show = false;
  public loading = false;
  public interval = 0;

  public previewItems: any[] = [];

  public async created() {
    this.interval = this.timeslots[0].DURATION_SECS * 1000;
    const promises = this.timeslots
      .filter((ts) => ts.CAMPAIGN)
      .map(async (ts) => {
        const campaignDoc = await ts.CAMPAIGN!.get();
        const campaign = campaignDoc.data() as Campaign;
        return {
          ID: ts.ID,
          ORDER: ts.PLAY_ORDER,
          MEDIA: campaign.MEDIA_FILE,
        };
      });
    this.previewItems = await Promise.all(promises);
  }

  public open() {
    this.show = true;
  }

  public close() {
    this.show = false;
  }
}
