



















import { Component, Vue } from 'vue-property-decorator';
import PromotionForm from '@promotions/shared/components/PromotionForm.vue';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import { Promotion } from '@/app/shared/utilities/static-types';
import { updatePromotionAction, loadPromotionAction } from '@promotions/shared/actions';
@Component({
  components: {
    PromotionForm,
  },
})
export default class EditPromotion extends Vue {
  public promotionMainRoute = AdminRoutes.PROMOTIONS_LIST;

  public async updatePromotion(promotion: Promotion): Promise<void> {
    await updatePromotionAction(promotion);
    this.$router.push(this.promotionMainRoute);
  }

  public async loadPromotion(): Promise<Promotion> {
    const { id } = this.$route.params;
    return loadPromotionAction(id);
  }
}
