













import { Component, Vue } from 'vue-property-decorator';

import AppPublicLayout from '@publicApp/shared/components/AppPublicLayout.vue';

@Component({
  components: {
    AppPublicLayout,
  },
})
export default class AccessDenied extends Vue {}
