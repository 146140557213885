import { Module } from 'vuex';
import { CampaignState } from './CampaignTypes';
import { RootState } from '@/app/shared/store/RootState';
import { mutations } from './CampaignMutations';
import { getters } from './CampaignGetters';
import { actions } from './CampaignActions';

export const getDefaultState = (): CampaignState => {
  return {
    currentStoreCampaignRef: null,
    selectedClustersSchedules: {},
    selectedClusterId: '',
    selectedSchedulesInCluster: [],
  };
};

const state: CampaignState = getDefaultState();

export const CampaignModule: Module<CampaignState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
