import { QuerySnapshot, DocumentSnapshot, DocumentReference, Transaction } from '@firebase/firestore-types';
import { Nullable, Promotion, PROMOTION_STATUSES } from '@/app/shared/utilities/static-types';
import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { getPromotionStatus } from '../utils';
import firebase from 'firebase';
/**
 * Create new promotion.
 * @param promotion Promotion
 */
export const createPromotionAction = async (
  promotion: Promotion,
): Promise<void> => {
  const promotionRef = FirebaseAppFirestore
    .collection(firebaseNames.PROMOTIONS)
    .doc();
  const { id: ID } = promotionRef;
  promotion.ID = ID;

  promotion.START_DATE = firebase.firestore.Timestamp.fromDate(new Date(promotion.START_DATE as string));
  promotion.END_DATE = firebase.firestore.Timestamp.fromDate(new Date(promotion.END_DATE as string));

  await promotionRef.set(promotion);
};

/**
 * Load all promotions.
 */
export const loadPromotionsAction = async (): Promise<Promotion[]> => {
  const promotionsSnap: QuerySnapshot = await FirebaseAppFirestore
    .collection(firebaseNames.PROMOTIONS)
    .get();
  return promotionsSnap.docs.map((doc) => doc.data()) as Promotion[];
};

/**
 * Load all promotions for platform (visible/active).
 */
export const loadPlatformPromotionsAction = async (): Promise<Promotion[]> => {
  const promotionsSnap: QuerySnapshot = await FirebaseAppFirestore
    .collection(firebaseNames.PROMOTIONS)
    .where('PLATFORM_VISIBLE', '==', true)
    .get();
  return promotionsSnap.docs
    .map((doc) => doc.data() as Promotion)
    .filter((promo: Promotion) => getPromotionStatus(promo) === PROMOTION_STATUSES.ACTIVE);
};

/**
 * Load default active promotion
 */
export const loadDefaultPromotionsAction = async (): Promise<Nullable<Promotion>> => {
  const promotionsSnap = (await FirebaseAppFirestore
    .collection(firebaseNames.PROMOTIONS)
    .where('DEFAULT', '==', true)
    .get() as QuerySnapshot<Promotion>);

  if (promotionsSnap.empty) {
    return null;
  }

  const [defaultPromotion] = promotionsSnap
    .docs
    .map((doc) => doc.data());

  return getPromotionStatus(defaultPromotion) === PROMOTION_STATUSES.ACTIVE
    ? defaultPromotion
    : null;
};

/**
 * Update promotion.
 * @param promotion Promotion
 */
export const updatePromotionAction = async (
  promotion: Promotion,
): Promise<void> => {
  const { ID } = promotion;
  const promotionRef = FirebaseAppFirestore
    .collection(firebaseNames.PROMOTIONS)
    .doc(ID);
  await promotionRef.update(promotion);
};

/**
 * Load promotion by id.
 * @param id String
 */
export const loadPromotionAction = async (
  id: string,
): Promise<Promotion> => {
  const promotionSnap: DocumentSnapshot = await FirebaseAppFirestore
    .collection(firebaseNames.PROMOTIONS)
    .doc(id)
    .get();
  return promotionSnap.data() as Promotion;
};

/**
 * Delete promotion by id.
 * @param id String
 */
export const deletePromotionAction = async (
  id: string,
): Promise<void> => {
  const promotionRef: DocumentReference = FirebaseAppFirestore
    .collection(firebaseNames.PROMOTIONS)
    .doc(id);
  await promotionRef.delete();
};

/**
 * Mark promotion as default.
 * @param id String
 */
export const setPromotionAsDefaultAction = async (
  id: string,
): Promise<void> => {
  const currentDefaultPromoSnap = await FirebaseAppFirestore
    .collection(firebaseNames.PROMOTIONS)
    .where('DEFAULT', '==', true)
    .get();
  const newDefaultPromoRef = FirebaseAppFirestore
    .collection(firebaseNames.PROMOTIONS)
    .doc(id);
  return FirebaseAppFirestore
    .runTransaction(async (transaction: Transaction) => {
      const newDefaultPromo = await transaction.get(newDefaultPromoRef);
      if (currentDefaultPromoSnap.docs.length) {
        const [currentDefaultPromoRef] = currentDefaultPromoSnap.docs.map((item) => item.ref);
        transaction.update(currentDefaultPromoRef, { DEFAULT: false });
      }
      if (newDefaultPromo.exists) {
        transaction.update(newDefaultPromoRef, { DEFAULT: true });
      }
    });
};
