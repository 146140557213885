





























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  AuthorizedUser,
  CampaignTableRow,
  Nullable,
} from '@/app/shared/utilities/static-types';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import CampaignTableRowItem from './CampaignTableRowItem.vue';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import { isCampaignOwner, showCampaignPrice } from '../utils';
import { getUserById } from '@adminRoles/shared/actions';

@Component({
  components: { CampaignTableRowItem },
  data() {
    return {
      headers: [
        {
          text: this.$t('name').toString(),
          value: 'NAME',
          sortable: true,
        },
        {
          text: this.$t('start_date').toString(),
          value: 'START_DATE',
          sortable: true,
        }, {
          text: this.$t('end_date').toString(),
          value: 'END_DATE',
          sortable: true,
        }, {
          text: this.$t('duration_in_weeks').toString(),
          value: 'DURATION_IN_WEEKS',
          sortable: true,
        },
        {
          text: this.$t('screen_cluster').toString(),
          value: 'SCREEN_CLUSTERS_NAMES',
          sortable: true,
        },
        {
          text: this.$t('company_name').toString(),
          value: 'COMPANY_NAME',
          sortable: true,
        },
        {
          text: this.$t('price').toString(),
          value: 'PRICE',
          sortable: true,
        },
        {
          text: this.$t('actions').toString(),
          value: 'ACTIONS',
          sortable: false,
        },
      ],
    };
  },
})
export default class CampaignsTableView extends Vue {
  @Prop({ required: true, default: () => ([]) }) public campaignsList!: CampaignTableRow[];
  @Prop({ required: true, default: true }) public loading!: boolean;
  @Prop(Boolean) public showReservedUntil!: boolean;
  public search: string = '';
  public filterStatus: string = '';
  public currentUser: Nullable<AuthorizedUser> = null;

  public pagination = {
    sortBy: 'NAME',
    descending: false,
  };

  public get systemStatuses() {
    return Object.keys(SYSTEM_STATUS).map((key) => {
      return {
        value: SYSTEM_STATUS[key].VAL,
        color: SYSTEM_STATUS[key].COLOR,
      };
    });
  }

  public async loadCurrentUser() {
    const { uid } = (await getCurrentUser())!;
    this.currentUser = await getUserById(uid) || null;
  }

  public changeSort(column: string): void {
    if (this.pagination.sortBy === column) {
      this.pagination.descending = !this.pagination.descending;
    } else {
      this.pagination.sortBy = column;
      this.pagination.descending = false;
    }
  }

  public filterItems(items: any, search: string, filter: any) {
    return this.filterStatus
      ? items
        .filter(this.filteredItemsByStatus(this.filterStatus))
        .filter(this.searchMatch(search))
      : items.filter(this.searchMatch(search));
  }

  public customSort(items: CampaignTableRow[], key: string, isDesc: boolean) {
    return items.sort((a: CampaignTableRow, b: CampaignTableRow) => {
      let compareA: any = a[key];
      let compareB: any = b[key];

      if (key === 'START_DATE') {
        compareA = a[key] ? new Date(a[key] as string) : 0;
        compareB = b[key] ? new Date(b[key] as string) : 0;
      }

      if (!isDesc) {
        return compareA < compareB ? -1 : 1;
      }
      return compareB < compareA ? -1 : 1;
    });
  }

  public async created() {
    await this.loadCurrentUser();
    if (this.showReservedUntil) {

      this.$data.headers.push({
        text: this.$t('reserved_until').toString(),
        value: 'RESERVED_UNTIL',
        sortable: false,
      });
    }
  }

  public showPrice(campaignRow: CampaignTableRow) {
    return showCampaignPrice(campaignRow, this.currentUser!);
  }

  public isCampaignOwner(campaignRow: CampaignTableRow) {
    return isCampaignOwner(campaignRow)(this.currentUser!);
  }

  private searchMatch = (search: string) => (item: any) => search ? item.NAME.toLowerCase().includes(search.toLowerCase()) : true;

  private filteredItemsByStatus = (filterStatus: string) => (item: any) => item.STATUS.VAL === filterStatus;
}
