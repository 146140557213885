



































import { Component, Vue } from 'vue-property-decorator';
import { AdSpace, StoreAccount } from '@/app/shared/utilities/static-types';
import ClickableAdspaceItem from './components/ClickableAdspaceItem.vue';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import AdspacesList from '@userApp/shared/components/ListView.vue';
import { loadAllAdSpacesAction, loadUserAdSpacesAction } from '@adSpaces/shared/actions';
import { isAdminUserAction, isStoreOwnerAction } from '@globalActions/UserRolesActions';
import { getStoresAction } from '@globalActions/DevicesActions';

@Component({
  components: {
    AdspacesList,
    ClickableAdspaceItem,
  },
})
export default class StoreAdSpaces extends Vue {
  public loading = false;
  public newAdSpaceRoute = AdminRoutes.CREATE_STORE_AD_SPACE;
  public editAdSpaceRoute = AdminRoutes.EDIT_STORE_AD_SPACE;
  public adSpaces: AdSpace[] = [];
  public storeAccountsList: StoreAccount[] = [];
  public storeAccountsMap: Record<NonNullable<StoreAccount['UID']>,  StoreAccount> = {};
  public isAdminUser = false;
  public isStoreOwner = false;

  public async created() {
    try {
      this.loading = true;
      this.isAdminUser = await isAdminUserAction();
      this.isStoreOwner = await isStoreOwnerAction();
      if (this.isAdminUser) {
        this.adSpaces = await loadAllAdSpacesAction();
        this.storeAccountsList = await getStoresAction();
        this.storeAccountsMap = this.storeAccountsList.reduce((map, store) => {
          return {
            ...map,
            [store.UID!]: store,
          };
        }, {});
      } else if (this.isStoreOwner) {
        this.adSpaces = await loadUserAdSpacesAction();
      }
    } finally {
      this.loading = false;
    }
  }

  public adSpaceStoreName(adSpace: AdSpace) {
    const storeName = this.storeAccountsMap[adSpace.SPACE_OWNER_ID]
      ? this.storeAccountsMap[adSpace.SPACE_OWNER_ID].STORE_NAME
      : '';
    return `<b>${storeName}</b>`;
  }

}
