






















import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

import ScreenPhotoPopup from './components/ScreenPhotoPopup.vue';

@Component({
  components: {
    ScreenPhotoPopup,
  },
})
export default class PendingScreensView extends Vue {
  @Prop({ default: undefined }) public screenPhoto!: string;
  @Prop({ default: undefined }) public screenId!: string;
  @Prop({ default: false }) public editing!: boolean;

  @Emit()
  public async photoUpdated() {
    try {
      const popupRef = this.$refs['photo-popup'] as ScreenPhotoPopup;
      return await popupRef.open(this.screenId);
    } catch (error) {
      return null;
    }
  }
}
