




























































































import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class VisaCardIcon extends Vue {
  @Prop({ default: '100%' }) public width!: string;
  @Prop({ default: '100%' }) public height!: string;

}
