var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"pa-3",attrs:{"column":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('div',{staticClass:"search-area"},[(_vm.campaignsList.length)?_c('v-text-field',{attrs:{"prepend-icon":"search","data-search-input":"","placeholder":_vm.$t('search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('div',{staticClass:"filters-area d-flex ml-auto"},[(_vm.campaignsList.length)?_c('v-select',{attrs:{"prepend-icon":"filter_alt","item-value":"value","items":[{ value: '' } ].concat( _vm.systemStatuses),"label":"Filter by status"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.value ? _vm.$t(("status." + (data.item.value))) : 'No filter')+" ")]}},{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.value ? _vm.$t(("status." + (data.item.value))) : 'No filter')+" ")]}}],null,false,4192259292),model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}}):_vm._e()],1)]),(_vm.currentUser)?_c('v-data-table',{staticClass:"elevation-1 table",attrs:{"custom-filter":_vm.filterItems,"rows-per-page-items":[
      20,
      50,
      { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 } ],"search":_vm.search,"pagination":_vm.pagination,"custom-sort":_vm.customSort,"headers":_vm.headers,"loading":_vm.loading,"items":_vm.campaignsList},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{staticStyle:{"width":"100%"},attrs:{"color":"primary","indeterminate":""}})]},proxy:true},{key:"headers",fn:function(props){return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:[
            'column',
            _vm.pagination.descending ? 'desc' : 'asc',
            header.value === _vm.pagination.sortBy ? 'active' : '',
            header.sortable ? 'sortable' : '' ],on:{"click":function($event){return _vm.changeSort(header.value)}}},[(header.sortable)?_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_upward")]):_vm._e(),_vm._v(" "+_vm._s(header.text)+" ")],1)}),0)]}},{key:"items",fn:function(ref){
          var item = ref.item;
return [_c('campaign-table-row-item',{attrs:{"campaign-row":item,"loading":_vm.loading,"showPrice":_vm.showPrice(item),"isCampaignOwner":_vm.isCampaignOwner(item),"showReservedUntil":""},scopedSlots:_vm._u([{key:"tableActions",fn:function(ref){
          var data = ref.data;
return [_vm._t("tableActions",null,{"data":data})]}}],null,true)})]}}],null,false,1711548939)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }