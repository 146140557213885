































import { Component, Vue } from 'vue-property-decorator';
import Campaigns from '@campaigns/shared/components/Campaigns.vue';
import routesNames from '@/app/shared/utilities/routes-names';
import { campaignDefaultNameFactory, SUPPORTED_TYPES, SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import {
  AuthorizedUser,
  Campaign,
  CampaignMediaFile,
  CampaignTuple,
  CompanyProfile,
} from '@/app/shared/utilities/static-types';
import namespace from '@/app/shared/store/namespace';
import { saveFileLinkForCampaigns, uploadFileForCampaigns } from '@/app/screens/App/shared/actions';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import { subscribeToUserCampaigns, createCampaignAction } from '@campaigns/shared/actions';
import TableActions from './components/TableActions.vue';
import ClickableCampaignItem from './components/ClickableCampaignItem.vue';
import { getUserById } from '@/app/screens/App/screens/Admin/screens/UserRoles/shared/actions';
import { isCampaignOwner } from '@campaigns/shared/utils';

@Component({
  components: {
    Campaigns,
    TableActions,
    ClickableCampaignItem,
  },
})
export default class AdvertiserCampaigns extends Vue {
  public loading = false;

  public newCampaignRoute = routesNames.CAMPAIGN_NEW;
  public editCampaignRoute = routesNames.CAMPAIGN_EDIT;

  public newStatus = SYSTEM_STATUS.NEW.VAL;

  public campaignsTuples: CampaignTuple[] = [];
  public companyProfiles: CompanyProfile[] = [];
  public currentUser: AuthorizedUser|undefined = undefined;

  public async loadCurrentUser() {
    const { uid } = (await getCurrentUser())!;
    this.currentUser = await getUserById(uid);
  }

  public isCampaignOwner(campaign: Campaign) {
    return isCampaignOwner(campaign)(this.currentUser!);
  }

  public async created() {
    try {
      this.loadCurrentUser();
      this.loading = true;
      // If a campaign was cached from Landing page ...
      const preCachedCampaign = this.$store.getters[
        `${namespace.LandingModule}/getSavedCampaign`
      ];
      if (preCachedCampaign) {
        const campaignId = await this.createLandingCampaign(preCachedCampaign);
        this.$router.push({
          ...this.editCampaignRoute,
          params: { id: campaignId },
        });
      }

      await subscribeToUserCampaigns(async (campaigns) => {
        this.campaignsTuples = campaigns;
        this.loading = false;
        this.scrollToFirstPendingPaymentCampaign();
      });
    } catch (err) {
      throw new Error(this.$t('something_went_wrong').toString() + '::' + err.message);
    }
  }

  public async createLandingCampaign(savedCampaign: Omit<Campaign, 'ADVERTISER_UID'>) {
    if (!savedCampaign.MEDIA_SKIPPED) {
      const file = savedCampaign.MEDIA_FILE as File;
      try {
        const uploadTaskRef = await uploadFileForCampaigns(file);
        const taskSnapshot = await uploadTaskRef.put(file);
        savedCampaign.MEDIA_FILE =
          taskSnapshot && (await taskSnapshot.ref.getDownloadURL());
        savedCampaign.MEDIA_TYPE = file.type;
      } catch (err) {
        throw new Error(`Couldn't upload the selected file`);
      }

      const mediaFileData: CampaignMediaFile = {
        FILE_NAME: file.name,
        FILE_TYPE: file.type,
        STATIC_LINK: savedCampaign.MEDIA_FILE,
      };
      if (mediaFileData.FILE_TYPE === SUPPORTED_TYPES.MP4) {
        savedCampaign.MEDIA_PLAYBACK_DURATION = mediaFileData.PLAYBACK_DURATION = await this.calculateVideoFileDuration(file);
      }
      await saveFileLinkForCampaigns(mediaFileData);
    }

    if (!savedCampaign.NAME) {
      const user = await getCurrentUser();
      const appLanguage = this.$root.$i18n.locale;
      savedCampaign.NAME = await campaignDefaultNameFactory(user, appLanguage as 'ar' | 'en');
    }

    const campaignId = await createCampaignAction(savedCampaign as Campaign);
    this.$store.commit(`${namespace.LandingModule}/setSavedCampaign`);

    return campaignId;
  }

  public calculateVideoFileDuration(file: any): Promise<number> {
    const video = document.createElement('video');
    video.preload = 'metadata';

    const duration = new Promise<number>(((resolve) => {
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(Math.ceil(video.duration));
      };
    }));

    video.src = window.URL.createObjectURL(file);

    return duration;
  }

  public scrollToFirstPendingPaymentCampaign() {
    setTimeout(() => {
      const pendingPaymentCampaignElement = this.$el.querySelector('.campaign-item.pending-payment');

      if (pendingPaymentCampaignElement) {
        pendingPaymentCampaignElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 1000);
  }
}
