






import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { MyDevice } from '@/app/shared/utilities/static-types';

import MyDeviceItemContent from './MyDeviceItemContent.vue';
import ClickableListItem from '../ClickableListItem.vue';

@Component({
  components: {
    MyDeviceItemContent,
    ClickableListItem,
  },
})
export default class ClickableDeviceItem extends Vue {
  @Prop({ required: true }) public device!: MyDevice;
  @Prop({ required: true }) public index!: string;
  @Prop({ default: null }) public navigateTo!: { name: string };

  @Emit('view-device')
  public goToDeviceView(id: string, index: number) {
    if (this.navigateTo) {
      this.$router.push({
        ...this.navigateTo,
        params: {
          id,
        },
      });
    }
    return index;
  }
}
