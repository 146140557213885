import { AuthorizedUser, Role } from './../utilities/static-types';
import { Route, RawLocation } from 'vue-router';

import { getUserRolesAction, userHasSomeRolesAction } from '@/app/shared/actions/UserRolesActions';
import routesNames from '@/app/shared/utilities/routes-names';
import { isAdvertiser, isStoreOwner } from '@/app/screens/App/screens/Campaigns/shared/utils';
import { USER_ROLES } from '../utilities/object-factory';

/**
 * Used for authorization.
 */
export default async (to: Route, _: Route, next: (to?: RawLocation) => void) => {
  try {
    const userRoles = await getUserRolesAction();
    const targetRoute = to.matched.find((route) => Boolean(route.meta.roles));
    const routeRoles: any[] = targetRoute && targetRoute.meta.roles;

    if (!routeRoles || !userRoles) {
      return next();
    }

    const isAuthorized = Boolean(
      userRoles!.find(
        (role) => routeRoles.map((routeRole) => routeRole.VAL).includes(role.VAL),
      ),
    );

    const isRouteRolesIncludeAdvertiserOrStore = Boolean(
      routeRoles.find(({ VAL }) => VAL === USER_ROLES.ADVERTISER.VAL || VAL === USER_ROLES.STORE_OWNER.VAL),
    );

    const isNotAdmin = isAdvertiser({ ROLES: userRoles } as AuthorizedUser) || isStoreOwner({ ROLES: userRoles } as AuthorizedUser);
    const redirectUserToNewPlatform = isNotAdmin && isRouteRolesIncludeAdvertiserOrStore;

    if (redirectUserToNewPlatform) {
      return next({
        ...routesNames.REDIRECT,
      });
    }

    if (isAuthorized) {
      return next();
    }

    next(routesNames.ACCESS_DENIED);

  } catch ({ message }) {
    next(routesNames.ACCESS_DENIED);
    // tslint:disable-next-line:no-console
    console.error(`On Authorization Guard, ${message}`);
  }
};

export const userRolesPageGuard = (roles: Role[]) => {
  return async (to: Route, from: Route, next: (to?: RawLocation) => void) => {
    const isUserHasRole = await userHasSomeRolesAction(roles);
    if (isUserHasRole) {
      next();
    } else {
      next({
        ...routesNames.ACCESS_DENIED,
      });
    }
  };
};
