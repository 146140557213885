




















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  Campaign,
  CampaignMetaData,
} from '@/app/shared/utilities/static-types';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import ChangeCampaignMedia from '@campaigns/shared/components/ChangeCampaignMedia.vue';
import DeleteCampaign from '@campaigns/shared/components/DeleteCampaign.vue';
import { campaignMetaData } from '@campaigns/shared/utils';
import DownloadInvoice from '@campaigns/shared/components/DownloadInvoice.vue';
import DownloadEstimate from '@campaigns/shared/components/DownloadEstimate.vue';
import CountdownTimer from '@campaigns/shared/components/CountdownTimer.vue';

@Component({
  components: {
    CountdownTimer,
    DownloadEstimate,
    DownloadInvoice,
    ChangeCampaignMedia,
    DeleteCampaign,
  },
})
export default class CampaignItemContent extends Vue {
  @Prop({ required: true }) public campaign!: Campaign;
  @Prop({ default: false, type: Boolean }) public isCampaignOwner!: boolean;
  @Prop({ default: false, type: Boolean }) public showPrice!: boolean;
  @Prop({ default: true, type: Boolean }) public canChangeMedia!: boolean;
  @Prop({ default: false, type: Boolean }) public showInvoice!: boolean;

  public get campaignScreenClusters() {
    const campaignScreenClusters = this.campaign.SCREEN_CLUSTERS
      .map((screenCluster) => screenCluster.DISPLAY_NAME[this.appLocale])
      .join(', ');
    return `${campaignScreenClusters}`;
  }

  public get appLocale() {
    return this.$root.$i18n.locale;
  }

  public get campaignName() {
    return `${this.campaign.NAME || 'Untitled campaign'}`;
  }

  public get campaignPrice() {
    const price = this.campaign.PAYMENT_BILL && this.campaign.PAYMENT_BILL.TOTAL_PRICE;
    return price && `${this.$t('price')}: ${price} ${this.$t('saudi_riyal')}`;
  }

  public get isCampaignDeletable(): boolean {
    const { STATUS: { VAL: status } } = this.campaign;
    return status === SYSTEM_STATUS.NEW.VAL && this.isCampaignOwner;
  }

  private get campaignMetaData(): CampaignMetaData {
    const { campaign, isCampaignOwner } = this;
    return campaignMetaData(campaign, isCampaignOwner);
  }
}
