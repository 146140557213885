<template>
  <content-loader
    :height="160"
    :width="400"
    :speed=".6"
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="30" y="20" rx="4" ry="4" width="85%" height="3" />
    <rect x="30" y="40" rx="4" ry="4" width="85%" height="3" />
    <rect x="30" y="55" rx="4" ry="4" width="85%" height="20" />
    <rect x="30" y="90" rx="4" ry="4" width="85%" height="3" />
    <rect x="30" y="110" rx="4" ry="4" width="85%" height="3" />
    <rect x="320" y="140" rx="4" ry="4" width="50" height="20" />
  </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  components: {
    ContentLoader,
  },
};
</script>
