


















import { Component, Vue } from 'vue-property-decorator';
import PromotionForm from '@promotions/shared/components/PromotionForm.vue';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import { Promotion } from '@/app/shared/utilities/static-types';
import { createPromotionAction } from '@promotions/shared/actions';
@Component({
  components: {
    PromotionForm,
  },
})
export default class CreatePromotion extends Vue {
  public promotionMainRoute = AdminRoutes.PROMOTIONS_LIST;

  public async createPromotion(promotion: Promotion): Promise<void> {
    await createPromotionAction(promotion);
    this.$router.push(this.promotionMainRoute);
  }
}
