import { MutationTree } from 'vuex';
import { NavigationState } from './NavigationTypes';
import { SystemFeature, Role } from '@/app/shared/utilities/static-types';

export const mutations: MutationTree<NavigationState> = {
  initSystemFeaturesMutation: (state: NavigationState, payload: SystemFeature[]) => {
    state.systemFeatures = payload;
  },
  initUserRolesMutation: (state: NavigationState, payload: Role[]) => {
    state.userRoles = payload;
  },
  resetSystemFeatures: (state: NavigationState) => {
    state.userRoles = null;
    state.systemFeatures = null;
  },
};
