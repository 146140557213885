



























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import {
  MyDevice,
  Country,
  City,
  Branch,
  StoreAccount,
  AdSpace,
} from '@/app/shared/utilities/static-types';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import {
  createDeviceAction,
  getAdSpacesAction,
} from '@screenDevices/shared/actions';
import {
  getCountriesAction,
  getCitiesByCountryCodeAction,
  getStoresAction,
} from '@globalActions/DevicesActions';
import rules from '@/app/shared/validation-rules';
import deviceRules from '@screenDevices/shared/validation-rules';
import routesNames from '@/app/shared/utilities/routes-names';
import FormComponent from '@/app/shared/components/FormComponent.vue';

@Component({
  components: {
    FormComponent,
  },
  data: (vm: any) => ({
    requiredRules: [rules.required(vm.$i18n.t('field_required'))],
  }),
})
export default class MyDeviceNew extends Vue {
  public deviceName = '';
  public deviceNameErrors: string | string[] = [];
  public selectedCountry: Country | null = null;
  public selectedAdSpace: AdSpace | null = null;
  public countryArray: Country[] = [];
  public selectedCity: City | null = null;
  public cityArray: City[] = [];
  public selectedStore: StoreAccount | null = null;
  public storesArray: StoreAccount[] = [];
  public adSpacesArray: AdSpace[] = [];
  public deviceMainRoute = routesNames.MY_DEVICES;

  public get countries(): Country[] {
    return this.countryArray!.map(
      (country) =>
        ({
          ...country,
          TEXT: this.$t(`countries.${country.DISPLAY_NAME}`).toString(),
        } as Country),
    );
  }

  public get cities(): City[] {
    return this.cityArray!.map(
      (city) =>
        ({
          ...city,
          TEXT: this.$t(`cities.${city.DISPLAY_NAME}`).toString(),
        } as City),
    );
  }

  public async loadData() {
    this.loadCountries();
    await this.loadSpaceOwners();
    await this.loadAdSpaces();
  }

  public async createDevice() {
    const device: MyDevice = {
      DISPLAY_NAME: this.deviceName,
      COUNTRY: this.selectedCountry!,
      AD_SPACE_ID: this.selectedAdSpace!.ID,
      AD_SPACE_NAME: this.selectedAdSpace!.NAME,
      STORE_ID: this.selectedStore!.UID!,
      STORE_NAME: this.selectedStore!.STORE_NAME,
      CITY: this.selectedCity!,
      STATUS: SYSTEM_STATUS.NEW,
    };
    await createDeviceAction(device);
    this.$router.push(this.deviceMainRoute);
  }

  @Watch('deviceName')
  public async onDeviceNameChanged(val: string, oldVal: string) {
    this.deviceNameErrors = await deviceRules.duplicateDeviceName(
      val,
      this.$t('device_name_already_exists').toString(),
    );
  }

  @Watch('$root.$i18n.locale')
  public onChangeLocale(val: string, oldVal: string) {
    const [prevSelectedCountry, prevSelectedCity] = [
      this.selectedCountry,
      this.selectedCity,
    ];

    this.loadCountries();

    if (prevSelectedCountry) {
      this.selectedCountry = this.countries.find(
        (country) => country.GEONAME_ID === prevSelectedCountry.GEONAME_ID,
      )!;
      this.loadCities(prevSelectedCountry);
    }

    if (prevSelectedCity) {
      this.selectedCity = this.cities.find(
        (city) => city.GEONAME_ID === prevSelectedCity.GEONAME_ID,
      )!;
    }
  }

  private loadCountries() {
    this.countryArray = getCountriesAction();
  }

  private loadCities(country: Country) {
    this.cityArray = getCitiesByCountryCodeAction(country);
  }

  private async loadAdSpaces() {
    this.adSpacesArray = await getAdSpacesAction();
  }

  private async loadSpaceOwners() {
    this.storesArray = await getStoresAction();
  }
}
