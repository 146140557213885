


























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import {
  AdSpace,
  CampaignFrequency,
  MediaType,
  Nullable,
  ShashaAd,
} from '@/app/shared/utilities/static-types';
import { errors } from '@/app/shared/config/errors';
import {
  campaignFrequencies,
  convertFromMediaResolutionTypeToScreenResolutionType, getMediaName, USER_TYPES_ABBREVIATION,
} from '@/app/shared/utilities/object-factory';
import {
  checkMediaResolution,
  getVideoMediaDuration,
} from '@/app/shared/utilities/files';
import { uploadFileForCampaigns } from '@userApp/shared/actions';
import MediaUpload from '@campaigns/shared/components/MediaUpload.vue';
import rules from '@/app/shared/validation-rules';
import firebase from 'firebase';
import firestore = firebase.firestore;
import { createShashaAd } from '@campaigns/shared/actions';
import { v4 as uuidv4 } from 'uuid';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
const { Timestamp } = firestore;

@Component({
  name: 'CreateShashaAd',
  components: { FormComponent, MediaUpload },
  data: (vm: any) => ({
    requiredRules: [rules.required(vm.$i18n.t('field_required'))],
  }),
})
export default class CreateShashaAd extends Vue {
  @Prop({ required: true }) public adSpace!: AdSpace;
  @Prop({ required: true, type: String }) public selectedDate!: string;

  public frequencies = Object.values(campaignFrequencies);
  public adName = '';
  public adFrequency: Nullable<CampaignFrequency> = null;
  public formLoading = false;
  public snackbarShow = false;
  public selectedFile!: File;
  public mediaPreviewUrl = '';
  public snackbarMessage = '';
  public isModalOpenEvent = 'update:is-modal-open';
  public mediaId = uuidv4();
  get loading() {
    return this.formLoading;
  }

  set loading(formLoading: boolean) {
    this.formLoading = formLoading;
  }

  get snackbar() {
    return this.snackbarShow;
  }

  set snackbar(snackbarShow: boolean) {
    this.snackbarShow = snackbarShow;
  }

  get mediaPreview() {
    return this.mediaPreviewUrl;
  }

  set mediaPreview(mediaPreviewUrl: string) {
    this.mediaPreviewUrl = mediaPreviewUrl;
  }

  get snackbarMsg() {
    return this.snackbarMessage;
  }

  set snackbarMsg(snackbarMessage: string) {
    this.snackbarMessage = snackbarMessage;
  }

  public selectMedia() {
    const mediaUpload = this.$refs.mediaUpload as MediaUpload;
    const uploadFileInput = mediaUpload.$refs.uploadFileRef as HTMLInputElement;
    uploadFileInput.click();
  }

  public async checkResolution(selectedFile: File) {
    if (this.adSpace) {
      const resolution = convertFromMediaResolutionTypeToScreenResolutionType(
        this.adSpace.SCREENS_RESOLUTION,
      );
      return await checkMediaResolution(selectedFile, resolution);
    }
  }

  public async uploadMedia(selectedFile: File): Promise<string | undefined> {
    const uploadTaskRef = await uploadFileForCampaigns(selectedFile, this.mediaId);
    const taskSnapshot = await uploadTaskRef.put(selectedFile);
    return taskSnapshot && (await taskSnapshot.ref.getDownloadURL());
  }

  public async changeCampaignMedia(selectedFile: File): Promise<void> {
    try {
      await this.checkResolution(selectedFile);
      this.mediaPreview = URL.createObjectURL(selectedFile);
      this.selectedFile = selectedFile;
    } catch (error) {
      if (error && error.message === errors.MEDIA_RESOLUTION_NOT_MATCH) {
        this.snackbar = true;
        this.snackbarMsg = this.$t('media_resolution_error').toString();
      }
    }
  }

  public async saveShashaAd() {
    try {
      this.loading = true;
      if (this.selectedFile) {
        const mediaFile = await this.uploadMedia(this.selectedFile);
        let duration = 0;
        if (this.selectedFile.type.includes('video')) {
          duration = await getVideoMediaDuration(this.selectedFile);
        }
        const currentUser = await getCurrentUser();
        const shashaAd: ShashaAd = {
          MEDIA_FILE: {
            MEDIA_FILE: mediaFile,
            MEDIA_TYPE: this.selectedFile.type as MediaType,
            WIDTH: this.adSpace.SCREENS_RESOLUTION.WIDTH,
            HEIGHT: this.adSpace.SCREENS_RESOLUTION.HEIGHT,
            AD_SPACE_ID: this.adSpace.ID,
            MEDIA_ID: this.mediaId,
            NAME: getMediaName({
              userTypeAbbreviation: USER_TYPES_ABBREVIATION.shasha,
              companyName: 'admin',
              campaignName: this.adName,
              mediaId: this.mediaId,
              extension: this.selectedFile.name.split('.').pop() || '',
            }),
            MEDIA_UPLOADED_AT: firebase.firestore.Timestamp.now(),
          },
          AD_SPACE_ID: this.adSpace.ID,
          FREQUENCY: this.adFrequency as CampaignFrequency,
          START_DATE: Timestamp.fromDate(new Date(this.selectedDate)),
          END_DATE: Timestamp.fromDate(new Date(this.selectedDate)),
          NAME: this.adName,
          ADMIN_UID: currentUser!.uid,
          companyName: 'Shasha',

        };
        if (duration) {
          shashaAd.MEDIA_FILE.MEDIA_PLAYBACK_DURATION = duration;
        }
        await createShashaAd(shashaAd);
      }
      this.$emit('update:show-shasha-ad-created', true);
      this.$emit(this.isModalOpenEvent, false);
    } catch (error) {
      this.snackbar = true;
      this.snackbarMsg = error.message;
    } finally {
      this.loading = false;
    }
  }
}
