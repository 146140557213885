export default {
  USERS_INFO: 'USERS_INFO',
  REGULATORS: 'REGULATORS',
  COMPANY_PROFILES: 'COMPANY_PROFILES',
  BILLING_CONTACTS: 'BILLING_CONTACTS',
  CAMPAIGNS: {
    VAL: 'CAMPAIGNS',
    USER_CAMPAIGNS: 'USER_CAMPAIGNS',
    ADMIN_CAMPAIGNS: 'ADMIN_CAMPAIGNS',
    STORE_CAMPAIGNS: 'STORE_CAMPAIGNS',
  },
  PROMOTIONS: 'PROMOTIONS',
  AD_SPACES_MEDIA: 'AD_SPACES_MEDIA',
  MEDIA_FILES: {
    VAL: 'MEDIA_FILES',
    FOR_CAMPAIGNS: 'FOR_CAMPAIGNS',
    FOR_COMPANY_PROFILE: 'FOR_COMPANY_PROFILE',
    FOR_PHYSICAL_SCREEN_PHOTOS: 'FOR_PHYSICAL_SCREEN_PHOTOS',
    FOR_SCREEN_SAVER: 'FOR_SCREEN_SAVER',
    SCREEN_SAVERS: 'SCREEN_SAVERS',
  },
  DEVICES: {
    VAL: 'DEVICES',
    MY_DEVICES: 'MY_DEVICES',
    STORE_DEVICES: 'STORE_DEVICES',
  },
  AD_SPACES: {
    VAL: 'AD_SPACES',
    SCHEDULES: {
      VAL: 'SCHEDULES',
      TIME_SLOTS: 'TIME_SLOTS',
    },
  },
  STORES: 'STORES',
  SYSTEM_FEATURES: 'SYSTEM_FEATURES',
  SYSTEM_CAMPAIGNS: 'SYSTEM_CAMPAIGNS',
  SYSTEM_DEVICES: 'SYSTEM_DEVICES',
  SYSTEM_PROFILES: 'SYSTEM_PROFILES',
  RANDOM_CODE_POOL: 'RANDOM_CODE_POOL',
  REGISTERED_PLAYERS: 'REGISTERED_PLAYERS',
  SCREEN_CLUSTERS: {
    VAL: 'SCREEN_CLUSTERS',
    CONNECTED_DEVICES: 'CONNECTED_DEVICES',
  },
  SHASHA_ADS: {
    VAL: 'SHASHA_ADS',
  },
  SCREEN_SAVER: {
    VAL: 'SCREEN_SAVER',
    SCREEN_SAVER_DOC: 'SCREEN_SAVER_DOC',
  },
  functions: {
    CREATE_HOSTING_REQUEST: 'CREATE_HOSTING_REQUEST',
    CREATE_STORE_ACCOUNT: 'CREATE_STORE_ACCOUNT',
    CREATE_REGULATOR_ACCOUNT: 'CREATE_REGULATOR_ACCOUNT',
    GET_UPCOMING_SCHEDULES: 'GET_UPCOMING_SCHEDULES',
    GET_UPLOAD_TOKEN: 'GET_UPLOAD_TOKEN',
    GET_CAMPAIGN: 'GET_CAMPAIGN',
    CAMPAIGN_PAYMENT_INFO: 'CAMPAIGN_PAYMENT_INFO',
    GET_SCREEN_CLUSTER_CONNECTED_DEVICES: 'GET_SCREEN_CLUSTER_CONNECTED_DEVICES',
    CHECKOUT_CAMPAIGN: 'CHECKOUT_CAMPAIGN',
    SHOULD_RECEIVE_INVITATION: 'SHOULD_RECEIVE_INVITATION',
    REDEEM_INVITATION_CODE: 'REDEEM_INVITATION_CODE',
    GET_CLUSTER_SCHEDULES: 'GET_CLUSTER_SCHEDULES',
    DOWNLOAD_INVOICE: 'DOWNLOAD_INVOICE',
    DOWNLOAD_ESTIMATE: 'DOWNLOAD_ESTIMATE',
    REQUEST_ACCESS_TOKEN: 'REQUEST_ACCESS_TOKEN',
    GET_ADSPACE_PLAYLIST: 'GET_ADSPACE_PLAYLIST',
    CHANGE_USER_EMAIL: 'CHANGE_USER_EMAIL',
    PUBLISH_ALL_PLAYLISTS: 'PUBLISH_ALL_PLAYLISTS',
    CREATE_SHASHA_AD: 'CREATE_SHASHA_AD',
  },
  remoteConfig: {
    HUNDRED_SAR_FOR_FIRST_WEEK_OFFER: 'HUNDRED_SAR_FOR_FIRST_WEEK_OFFER',
    TWENTY_PERCENT_OFF_FOUR_WEEKS_PROMO: 'TWENTY_PERCENT_OFF_FOUR_WEEKS_PROMO',
    CALENDAR_SCHEDULES_TIME_SLOT_INFO: 'CALENDAR_SCHEDULES_TIME_SLOT_INFO',
    CALENDAR_WEEKS_HORIZONTAL_SELECTION: 'CALENDAR_WEEKS_HORIZONTAL_SELECTION',
    UPCOMING_WEEKS_COUNT: 'UPCOMING_WEEKS_COUNT',
    PAY_LATER_FOR_CAMPAIGN: 'PAY_LATER_FOR_CAMPAIGN',
    PENDING_PAYMENT_TIMEOUT_IN_HOURS: 'PENDING_PAYMENT_TIMEOUT_IN_HOURS',
    CAMPAIGN_SINGLE_CLUSTER_SELECTION: 'CAMPAIGN_SINGLE_CLUSTER_SELECTION',
    ALLOW_SELECTING_RUNNING_SCHEDULE: 'ALLOW_SELECTING_RUNNING_SCHEDULE',
  },
};
