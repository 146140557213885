var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"actions-center"},[_c('campaign-info-popup',{ref:"campaignMediaPopup",attrs:{"campaign":_vm.selectedCampaign,"show-price":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1",attrs:{"color":"grey darken-1"},on:{"click":_vm.showCampaignMediaPopup}},on),[_vm._v(" visibility ")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('open'))+" ")]),(_vm.isCampaignEditable)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1",attrs:{"color":"grey darken-1"},on:{"click":_vm.editCampaign}},on),[_vm._v(" edit ")])]}}],null,false,999772581)},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")]):_vm._e(),(_vm.isCampaignPendingApproval)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1",attrs:{"small":"","color":"grey darken-1"},on:{"click":_vm.reviewCampaign}},on),[_vm._v(" fact_check ")])]}}],null,false,1530996895)},[_vm._v(" "+_vm._s(_vm.$t('review'))+" ")]):_vm._e(),(_vm.canChangeMedia)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"upload-media-section"},on),[_c('change-campaign-media',{staticClass:"mx-1",attrs:{"campaign":_vm.campaignRow},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-icon',{attrs:{"color":"grey darken-1"}},[_vm._v(" cloud_upload ")])]},proxy:true}],null,true)})],1)]}}],null,false,1326833444)},[_vm._v(" "+_vm._s(_vm.$t('change_media'))+" ")]):_vm._e(),(_vm.isCampaignDeletable)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('delete-campaign',{attrs:{"campaign":_vm.campaignRow},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-icon',_vm._g({attrs:{"color":"grey darken-1"}},on),[_vm._v(" delete ")])]},proxy:true}],null,true)})]}}],null,false,666785526)},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }