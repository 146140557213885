import { DocumentReference } from '@firebase/firestore-types';
import {
  Campaign,
  HostInfo,
  Nullable, RegulatorAccount,
  StoreAccount,
  StoreCampaign,
  Tuple,
} from '@/app/shared/utilities/static-types';
import {
  FirebaseAppFirestore,
  FirebaseAppFunctions,
} from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import firebase from 'firebase';

/**
 * Create a store account.
 * @param storeAccount
 */
export const createStoreAccountAction = async (storeAccount: StoreAccount) => {
  const createStoreAccountFn = FirebaseAppFunctions.httpsCallable(
    firebaseNames.functions.CREATE_STORE_ACCOUNT,
  );
  await createStoreAccountFn(storeAccount);
};

export const createRegulatorAction = async (regulatorAccount: RegulatorAccount) => {
  const createRegulatorAccountFn = FirebaseAppFunctions.httpsCallable(
    firebaseNames.functions.CREATE_REGULATOR_ACCOUNT,
  );
  await createRegulatorAccountFn(regulatorAccount);
};

export const createHostingRequestAction = async (hostInfo: HostInfo) => {
  const createHostingRequestFn = FirebaseAppFunctions.httpsCallable(
    firebaseNames.functions.CREATE_HOSTING_REQUEST,
  );
  await createHostingRequestFn(hostInfo);
};

/**
 * Load store owner pending approval campaigns data for Admin approval.
 */
export const loadStoreOwnersPendingCampaignsDataAction = async (): Promise<Array<Tuple<Campaign, DocumentReference>>> => {
  return (
    await FirebaseAppFirestore.collectionGroup(
      firebaseNames.CAMPAIGNS.USER_CAMPAIGNS,
    )
      .where('STATUS.VAL', '==', SYSTEM_STATUS.UNDER_PREPARATION.VAL)
      .where('CREATED_BY_STORE_OWNER', '==', true)
      .get()
  ).docs
    .map((doc) => [doc.data(), doc.ref] as Tuple<Campaign, DocumentReference>)
    .filter(([{ APPROVED_BY_ADMIN }]) => !APPROVED_BY_ADMIN);
};

/**
 * Load stores approved campaigns data.
 */
export const loadApprovedStoreOwnersCampaignsAction = async (): Promise<Array<Tuple<Campaign, DocumentReference>>> => {
  return (
    await FirebaseAppFirestore.collectionGroup(
      firebaseNames.CAMPAIGNS.USER_CAMPAIGNS,
    )
      .where('STATUS.VAL', '==', SYSTEM_STATUS.UNDER_PREPARATION.VAL)
      .where('CREATED_BY_STORE_OWNER', '==', true)
      .where('APPROVED_BY_ADMIN', '==', true)
      .get()
  ).docs.map(
    (doc) => [doc.data(), doc.ref] as Tuple<Campaign, DocumentReference>,
  );
};

/**
 * Load store's other campaigns.
 * The campaigns other than ones need approval or approved.
 */
export const loadStoreOtherCampaignsAction = async (): Promise<Array<Tuple<Campaign, DocumentReference>>> => {
  const notIncludedCampaignsTypes = [SYSTEM_STATUS.UNDER_PREPARATION.VAL];
  return (
    await FirebaseAppFirestore.collectionGroup(
      firebaseNames.CAMPAIGNS.USER_CAMPAIGNS,
    ).get()
  ).docs
    .map((doc) => [doc.data(), doc.ref] as Tuple<Campaign, DocumentReference>)
    .filter(
      ([campaign]) =>
        campaign.CREATED_BY_STORE_OWNER &&
        !notIncludedCampaignsTypes.includes(campaign.STATUS.VAL),
    );
};

/**
 * Approve campaign as admin.
 * @param campaign Campaign
 */
export const approveCampaignAction = async (
  campaign: Campaign,
): Promise<void> => {
  const { ADVERTISER_UID: userId, ID: campaignId } = campaign;
  const campaignRef = FirebaseAppFirestore.collection(
    firebaseNames.CAMPAIGNS.VAL,
  )
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);
  return campaignRef.update({
    APPROVED_BY_ADMIN: true,
  });
};

/**
 * Reject campaign as admin.
 * @param campaign Campaign
 */
export const rejectCampaignAction = async (
  campaign: Campaign,
): Promise<void> => {
  const { ADVERTISER_UID: userId, ID: campaignId, COMMENTS } = campaign;
  const campaignRef = FirebaseAppFirestore.collection(
    firebaseNames.CAMPAIGNS.VAL,
  )
    .doc(userId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc(campaignId);
  return campaignRef.update({
    APPROVED_BY_ADMIN: false,
    STATUS: SYSTEM_STATUS.REQUIRES_ACTION,
    COMMENTS,
    REJECTED_AT: firebase.firestore.Timestamp.now(),
  });
};

/**
 * Load store owners
 */
export const loadStoreOwners = async (): Promise<StoreAccount[]> => {
  const storesSnap = await FirebaseAppFirestore.collection(
    firebaseNames.STORES,
  ).get();
  return storesSnap.docs.map((doc) => doc.data() as StoreAccount);
};
/**
 * Load regulators
 */
export const loadRegulators = async (): Promise<RegulatorAccount[]> => {
  const storesSnap = await FirebaseAppFirestore.collection(
    firebaseNames.REGULATORS,
  ).get();
  return storesSnap.docs.map((doc) => doc.data() as RegulatorAccount);
};

/**
 * Load store account data
 */
export const loadStoreAccountAction = async (
  id: string,
): Promise<Nullable<StoreAccount>> => {
  const storeAccountRef = FirebaseAppFirestore.collection(
    firebaseNames.STORES,
  ).doc(id);

  const storeAccountSnap = await storeAccountRef.get();

  if (storeAccountSnap.exists) {
    return storeAccountSnap.data() as StoreAccount;
  }
  return null;
};

/**
 * Load regulator account data
 */
export const loadRegulatorAccountAction = async (
  id: string,
): Promise<Nullable<RegulatorAccount>> => {
  const storeAccountRef = FirebaseAppFirestore.collection(
    firebaseNames.REGULATORS,
  ).doc(id);

  const regulatorAccountSnap = await storeAccountRef.get();

  if (!regulatorAccountSnap.exists) {
    return null;
  }
  return regulatorAccountSnap.data() as RegulatorAccount;
};

/**
 * Update store account data
 */
export const updateStoreAccountAction = async (
  store: StoreAccount,
): Promise<void> => {
  const { UID } = store;

  const storeAccountRef = FirebaseAppFirestore.collection(
    firebaseNames.STORES,
  ).doc(UID);

  await storeAccountRef.update(store);
};

export const updateRegulatorAccountAction = async (
  regulator: RegulatorAccount,
): Promise<void> => {
  try {
    const { UID } = regulator;

    const regulatorAccountRef = FirebaseAppFirestore.collection(
      firebaseNames.REGULATORS,
    ).doc(UID);
    const oldData = await regulatorAccountRef.get();

    const data = oldData.data();
    if (data && data.EMAIL !== regulator.EMAIL) {
      const changeUserEmailFn = FirebaseAppFunctions.httpsCallable(
        firebaseNames.functions.CHANGE_USER_EMAIL,
      );
      const result = await changeUserEmailFn({ oldEmail: data.EMAIL, newEmail: regulator.EMAIL });
      if (result.data && result.data.error) {
        throw new Error(result.data.error);
      }

    }
    await regulatorAccountRef.update(regulator);
  } catch (e) {
    throw new Error(e.message);
  }

};
