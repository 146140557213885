














import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class InstagramIcon extends Vue {
  @Prop({ default: '100%' }) public width!: string;
  @Prop({ default: '100%' }) public height!: string;
  @Prop({ default: false, type: Boolean }) public dark!: string;
}
