











































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { bytesToSize } from '@/app/shared/utilities/helper-functions';
import { getUserStorageStatus } from '@files/shared/actions';
import { SizeFormat } from '@/app/shared/utilities/static-types';

@Component
export default class UploadFiles extends Vue {
  @Prop() public label!: string;
  @Prop() public hintMessage!: string;
  @Prop() public accept!: string;
  @Prop() public errorMessages!: string | string[];
  @Prop({default: false, type: Boolean}) public multiple!: boolean;
  @Prop() public isUploadCompleted!: string;
  @Prop() public loading!: boolean;
  @Prop({
    default: () => () => {
      return true;
    },
  }) public filterFiles!: (file?: any) => boolean;
  @Prop() public validationRules!: Array<(files?: File[]) => string | boolean>;

  public filesArray: File[] = [];
  public filesDescription = '';

  public showStorageStatus = false;
  public storageLimit: SizeFormat = {
    bytes: 0.0,
    format: '0.00',
  };
  public storageUsed: SizeFormat = {
    bytes: 0.0,
    format: '0.00',
  };
  public storageLeft: SizeFormat = {
    bytes: 0.0,
    format: '0.00',
  };

  public isStorageLow = false;

  public validationError: string | string[] = [];
  public set errors(message: string | string[]) {
    this.validationError = message;
  }
  public get errors() {
    if (this.errorMessages) {
      return this.errorMessages;
    }
    return this.validationError;
  }

  public async created() {
    const {
      STORAGE_LIMIT_IN_BYTES,
      STORAGE_USED_IN_BYTES,
      STORAGE_LEFT_IN_BYTES,
    } = await getUserStorageStatus();

    [this.storageLimit, this.storageUsed, this.storageLeft] = [
      STORAGE_LIMIT_IN_BYTES,
      STORAGE_USED_IN_BYTES,
      STORAGE_LEFT_IN_BYTES,
    ].map(bytesToSize);

    this.showStorageStatus = true;
  }

  public fileNameChange(file: File): File {
    const { name, type } = file;
    const extension = `${name}`.split('.').pop();
    const [uniqueFileName] = [name]
      .map((fileName) => fileName.replace(/\.[^/.]+$/, ''))
      .map((fileName) => `${fileName}_${Date.now()}`)
      .map((fileName) => `${fileName}.${extension}`);
    return new File([file], uniqueFileName, { type });
  }

  public onFilesPicked(event: any) {
    const selectedFiles: any = event!.currentTarget.files;
    this.filesArray =
      selectedFiles &&
      Object.keys(selectedFiles)
        .map((index) => selectedFiles[+index])
        .map(this.fileNameChange)
        .filter(this.filterFiles);

    if (this.filesArray.length < selectedFiles.length) {
      const rejectedFilesCount = selectedFiles.length - this.filesArray.length;
      // @ts-ignore
      this.errors = this.$t('file_upload_extension_error', {rejectedFilesCount}).toString();
    }

    this.filesDescription = this.formatFilesDescription(this.filesArray, this.multiple);

    this.$emit('files-picked', this.filesArray);

    this.isStorageLow = this.isStorageLimitExceeded(this.filesArray, this.storageLeft.bytes);

    this.validationRules.every((rule) => rule(this.filesArray) === true) &&
      !this.isStorageLow &&
      this.toggleUploadStart();
  }

  public chooseFile() {
    (this.$refs.uploadFileRef as any).click();
  }

  @Emit('upload-start')
  public toggleUploadStart() {
    return;
  }

  public formatFilesDescription(files: File[], isMultipleFilesAllowed: boolean) {
    const totalFilesSizeInBytes = files.reduce((total, file) => total + file.size, 0);

    if (isMultipleFilesAllowed) {
      const totalFilesSize = bytesToSize(totalFilesSizeInBytes);
      return `${files.length} files (${totalFilesSize.format}) selected`;
    } else {
      const [ description ] = files
        .map((file) => [file.name, bytesToSize(file.size).format])
        .map(([fileName, fileSize]) => `${fileName} (${fileSize})`);
      return description;
    }
  }

  public isStorageLimitExceeded(files: File[], storageLeftInBytes: number) {
    const totalFilesSize = files.reduce((total, file) => total + file.size, 0);

    return totalFilesSize > storageLeftInBytes;
  }

  @Watch('isUploadCompleted')
  public async onIsUploadCompleted(val: boolean, oldVal: boolean) {
    if (val === true) {
      // Reset the storage status bar
      this.storageLimit = {
        bytes: this.storageLimit.bytes,
        format: '0.00',
      };
      this.storageUsed = {
        bytes: 0.0,
        format: '0.00',
      };

      // Update the storage status
      const {
        STORAGE_LIMIT_IN_BYTES,
        STORAGE_USED_IN_BYTES,
        STORAGE_LEFT_IN_BYTES,
      } = await getUserStorageStatus();

      [this.storageLimit, this.storageUsed, this.storageLeft] = [
        STORAGE_LIMIT_IN_BYTES,
        STORAGE_USED_IN_BYTES,
        STORAGE_LEFT_IN_BYTES,
      ].map(bytesToSize);
    }
  }
}
