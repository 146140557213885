import { DocumentReference } from '@firebase/firestore-types';

import { FirebaseAppFirestore, FirebaseAppFunctions } from '@/app/shared/firebase/firebase-app';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import {
  ClustersSchedulesRecord,
  MyDevice,
  RegisteredPlayer,
  AdSpace,
  ScreenCluster,
} from '@/app/shared/utilities/static-types';
import { SYSTEM_STATUS, COUNTRY_CODES } from '@/app/shared/utilities/object-factory';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { getScreenClustersById } from '@campaigns/shared/actions';

/**
 * Create a device for current user.
 * @param device Input Device
 */
export const createDeviceAction = async (device: MyDevice) => {
  const currentUser = await getCurrentUser();
  const deviceRef = FirebaseAppFirestore
    .collection(firebaseNames.DEVICES.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.DEVICES.MY_DEVICES)
    .doc();
  const deviceId = deviceRef.id;
  device.ID = deviceId;
  await deviceRef.set(device);
};

/**
 * Get a list of devices for current user.
 */
export const getDevicesAction = async () => {
  const currentUser = await getCurrentUser();
  const devicesSnap = await FirebaseAppFirestore
    .collection(firebaseNames.DEVICES.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.DEVICES.MY_DEVICES)
    .get();
  return devicesSnap.docs.map((doc) => doc.data() as MyDevice);
};

/**
 * Get a list of devices by a selected status.
 * @param status
 */
export const getDevicesByStatusAction = async (status: string) => {
  const currentUser = await getCurrentUser();
  const devicesSnap = await FirebaseAppFirestore
    .collection(firebaseNames.DEVICES.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.DEVICES.MY_DEVICES)
    .where('STATUS.VAL', '==', status)
    .get();
  return devicesSnap.docs.map((doc) => doc.data() as MyDevice);
};

/**
 * Gets the document reference of device by ID.
 * @param deviceId Device ID
 */
export const getDeviceRefByIdAction = async (deviceId: string) => {
  const currentUser = await getCurrentUser();
  const deviceRef = FirebaseAppFirestore
    .collection(firebaseNames.DEVICES.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.DEVICES.MY_DEVICES)
    .doc(deviceId);
  return deviceRef;
};

/**
 * Checks if a pass code exists in RANDOM_CODE_POOL collection.
 * @param passCode
 */
export const devicePassCodeExists = async (passCode: string): Promise<false | string> => {
  const passCodeDoc = await FirebaseAppFirestore
    .collection(firebaseNames.RANDOM_CODE_POOL)
    .where('RANDOM_CODE', '==', passCode).get();
  if (passCodeDoc.empty) {
    return false;
  }
  const targetDoc = passCodeDoc.docs[0];
  return targetDoc.id;
};

/**
 * Get Assigned devices for current user's store.
 */
export const getStoreDevicesRefAction = async (): Promise<DocumentReference[]> => {
  const currentUser = await getCurrentUser();
  const deviceSnap = await FirebaseAppFirestore
    .collection(firebaseNames.DEVICES.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.DEVICES.STORE_DEVICES)
    .get();
  return deviceSnap.docs
    .map((doc) => doc.data().DEVICE_REF as DocumentReference);
};

/**
 * @param onPlayersChange {function(RegisteredPlayer[])
 */
export const subscribeToRegisteredPlayers = async (onPlayersChange: (players: RegisteredPlayer[]) => Promise<void>) => {
  return FirebaseAppFirestore
    .collection(firebaseNames.REGISTERED_PLAYERS)
    .onSnapshot(async (querySnapshot) => {
      const registeredPlayers = querySnapshot.docs.map((doc) => doc.data() as RegisteredPlayer);
      await onPlayersChange(registeredPlayers);
    });
};

/**
 * Store a registered ID under a candidate Pass Code.
 * @param registeredId Regitered Player ID
 * @param targetPasscodeId Pass Code ID
 */
export const connectDeviceById = async (registeredId: string, targetPasscodeId: string) => {
  await FirebaseAppFirestore
    .collection(firebaseNames.RANDOM_CODE_POOL)
    .doc(targetPasscodeId).update({ AUTH: registeredId });
};

/**
 * Register a new player and store the device ref under it.
 * @param deviceRef Device document reference
 */
export const registerNewPlayer = async (player: RegisteredPlayer) => {
  const playerDoc = FirebaseAppFirestore
    .collection(firebaseNames.REGISTERED_PLAYERS)
    .doc();
  await playerDoc.set(player);
  return playerDoc.id;
};

/**
 * Remove a registered player by ID.
 * @param registeredId Registered player ID
 */
// TODO: Normalize DEVICES on DB
export const removeRegisteredPlayerById = async (registeredId: string) => {
  await FirebaseAppFirestore
    .collection(firebaseNames.REGISTERED_PLAYERS)
    .doc(registeredId)
    .delete();
};

/**
 * Save the connection between Registered player & Device.
 * @param registeredId Registered Player ID
 */
export const savePlayerDeviceConnection = async (registeredId: string, screenId: string) => {
  const playerRef = FirebaseAppFirestore
    .collection(firebaseNames.REGISTERED_PLAYERS)
    .doc(registeredId);
  const playerDoc = await playerRef.get();
  const playerData = playerDoc.data();
  const deviceRef = playerData && playerData.DEVICE_REF as DocumentReference;
  await deviceRef!.update({
    STATUS: SYSTEM_STATUS.PENDING_CONFIRMATION,
    REGISTERED_PLAYER_ID: registeredId,
    SCREEN_CODE: screenId,
  } as MyDevice);
};

/**
 * Get the number of Connected Devices to a Screen Cluster
 * @param screenClusterId
 */
export const getScreenClusterConnectedDevices = async (screenClusterId: string): Promise<number> => {
  const getConnectedDevicesFn = FirebaseAppFunctions
    .httpsCallable(firebaseNames.functions.GET_SCREEN_CLUSTER_CONNECTED_DEVICES);
  const { data } = await getConnectedDevicesFn(screenClusterId);
  return data;
};

export function getScreenClustersDataFromClustersRecord(clustersRecord: ClustersSchedulesRecord): Promise<ScreenCluster[]> {
  return Promise.all(
    Object.keys(clustersRecord)
      .map(getScreenClustersById));
}

/**
 * Load all Ad Spaces.
 */
export const getAdSpacesAction = async (): Promise<AdSpace[]> => {
  const adSpaceSnap = await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .get();
  return adSpaceSnap.docs.map((doc) => doc.data()) as AdSpace[];
};
