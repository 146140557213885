import { GetterTree } from 'vuex';
import { LandingState } from './LandingTypes';
import { RootState } from '@/app/shared/store/RootState';
import { Campaign } from '@/app/shared/utilities/static-types';

export const getters: GetterTree<LandingState, RootState> = {
  getSavedCampaign: (state: LandingState): Campaign | null => {
    return state.savedCampaign;
  },
};
