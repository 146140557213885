













import { Component, Vue, Prop } from 'vue-property-decorator';
import { AdSpace } from '@/app/shared/utilities/static-types';
import ClickableListItem from '@/app/screens/App/shared/components/ClickableListItem.vue';

@Component({
  components: {
    ClickableListItem,
  },
})
export default class ClickableAdspaceItem extends Vue {
  @Prop({ required: true }) public adSpace!: AdSpace;
  @Prop({ default: '' }) public storeName!: string;
  @Prop({ default: null }) public navigateTo!: { name: string };

  public goToEditAdSpace(id: string) {
    this.$router.push({
      ...this.navigateTo,
      params: {
        id,
      },
    });
  }

  public get adSpaceInfo() {
    const city = this.$t(`cities.${this.adSpace.CITY.DISPLAY_NAME!}`);
    const country = this.$t(`countries.${this.adSpace.COUNTRY.DISPLAY_NAME!}`);
    const address = this.adSpace.ADDRESS;
    return `${city}, ${country}, ${address}`;
  }
}
