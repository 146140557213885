























































import { Component, Vue } from 'vue-property-decorator';
import VisaCardIcon from '@/app/shared/components/VisaCardIcon.vue';
import MasterCardIcon from '@/app/shared/components/MasterCardIcon.vue';
import MadaCardIcon from '@/app/shared/components/MadaCardIcon.vue';
import LogoIcon from '@/app/shared/components/icons/LogoIcon.vue';
import TwitterIcon from '@/app/shared/components/icons/TwitterIcon.vue';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy.vue';
import ReturnPolicy from '@publicApp/screens/LandingPage/components/PublicFooter/ReturnPolicy/ReturnPolicy.vue';
import PublicTermsNConditions from '@publicApp/screens/LandingPage/components/PublicFooter/PublicTermsNConditions.vue';
import FacebookIcon from '@/app/shared/components/icons/FacebookIcon.vue';
import InstagramIcon from '@/app/shared/components/icons/InstagramIcon.vue';

@Component({
  components: {
    PublicTermsNConditions,
    ReturnPolicy,
    MadaCardIcon,
    VisaCardIcon,
    MasterCardIcon,
    PrivacyPolicy,
    LogoIcon,
    FacebookIcon,
    TwitterIcon,
    InstagramIcon,
  },
})
export default class PublicFooter extends Vue {
  public thisYear = new Date().getFullYear();
}
