















































































import { Component, Vue } from 'vue-property-decorator';
import {
  loadCampaignAction,
  storeOwnerCampaignCheckout,
} from '@campaigns/shared/actions';
import {
  SystemStatus,
  Schedule,
  ScreenCluster,
  CampaignPriceInfo,
  ClustersSchedulesRecord,
  AuthorizedUser,
  Campaign,
  Nullable,
} from '@/app/shared/utilities/static-types';
import {
  SUPPORTED_TYPES,
  SYSTEM_STATUS,
} from '@/app/shared/utilities/object-factory';

import routesNames from '@/app/shared/utilities/routes-names';
import rules from '@/app/shared/validation-rules';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import TermsNConditions from '@campaigns/shared/components/TermsNConditions.vue';
import PopupMessage from '@/app/shared/components/PopupMessage.vue';
import { cloneDeep } from 'lodash';
import ClustersAndSchedules from '@/app/shared/components/ClustersAndSchedules/ClustersAndSchedules.vue';
import CampaignPrice from '@campaigns/shared/components/CampaignPrice.vue';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import { getUserById } from '@adminRoles/shared/actions';

@Component({
  components: {
    CampaignPrice,
    ClustersAndSchedules,
    FormComponent,
    TermsNConditions,
    PopupMessage,
  },
  data: (vm: any) => ({
    requiredRules: [rules.required(vm.$i18n.t('field_required'))],
  }),
  beforeRouteEnter: async (to, from, next) => {
    const { id } = to.params;
    const campaignData = await loadCampaignAction(id);
    const isCampaignNew = campaignData.STATUS.VAL === SYSTEM_STATUS.NEW.VAL;

    if (!isCampaignNew) {
      next(routesNames.STORE_CAMPAIGNS);
      return;
    }

    next();
  },
})
export default class CheckoutPage extends Vue {
  public acceptTerms = false;
  public saving = false;

  public campaignMainRoute = routesNames.STORE_CAMPAIGNS;
  public campaignEditRoute = routesNames.STORE_CAMPAIGN_EDIT;

  public campaignName = '';
  public selectedSchedule: Nullable<Schedule> = null;
  public campaign: Nullable<Campaign> = null;

  public campaignStatus: Nullable<SystemStatus> = null;
  public mediaFile: Nullable<string> = null;
  public mediaType: Nullable<string> = null;

  public isRejected = false;

  public showFullScheduleMsg: (() => Promise<any>) | null = null;
  public fullScheduleMsg = '';

  public clustersSchedulesRecord: ClustersSchedulesRecord = {};
  public campaignClustersSchedules: ClustersSchedulesRecord = {};
  public screenClustersItems: ScreenCluster[] = [];
  public preSelectedScreenClusters: ScreenCluster[] = [];

  public userInfo: AuthorizedUser | null | undefined = null;
  public campaignPriceInfo: Nullable<CampaignPriceInfo> = null;
  private campaignId = '';

  private SUPPORTED_TYPES = SUPPORTED_TYPES;

  public async created() {
    const currentUser = await getCurrentUser();
    this.userInfo = await getUserById(currentUser!.uid);
  }

  public async finishCampaignSubmittion() {
    await storeOwnerCampaignCheckout(this.campaign!);
  }

  public async loadCampaignInfo() {
    const campaignId = this.$route.params.id;
    if (!campaignId) {
      throw new Error('Error loading campaign.');
    }
    this.campaign = await loadCampaignAction(campaignId);

    this.campaignId = this.campaign.ID;
    this.campaignName = this.campaign.NAME;
    this.campaignClustersSchedules = this.campaign.SELECTED_CLUSTERS_SCHEDULES;
    this.clustersSchedulesRecord = cloneDeep(this.campaignClustersSchedules);
    this.screenClustersItems = this.campaign.SCREEN_CLUSTERS;
    this.preSelectedScreenClusters = cloneDeep(this.screenClustersItems);
    this.mediaFile = this.campaign.MEDIA_FILE;
    this.mediaType = this.campaign.MEDIA_TYPE;
    this.campaignStatus = this.campaign!.STATUS;
    this.isRejected = this.campaign!.STATUS.VAL === SYSTEM_STATUS.REQUIRES_ACTION.VAL;

    this.campaignPriceInfo = {
      SUBTOTAL: 0,
      TOTAL_PRICE: 0,
    } as CampaignPriceInfo;

    if (!this.campaignPriceInfo) {
      throw new Error(`Couldn't get campaign price!`);
    }
  }

  public async publishCampaign() {
    if (
      this.selectedSchedule &&
      this.selectedSchedule.FREE_SLOTS_COUNT! === 0
    ) {
      const weekNo = this.selectedSchedule!.WEEK.toString();
      try {
        this.fullScheduleMsg = this.$t('oops_selected_week_is_full', {
          weekNo,
        }).toString();
        await this.showFullScheduleMsg!();
        return;
      } catch (err) {
        return;
      }
    }

    if (!this.campaignPriceInfo) {
      throw new Error(`Couldn't get campaign price!`);
    }

    this.$router.push(this.campaignMainRoute);
  }

  public backToEditCampaign() {
    this.$router.push({
      ...this.campaignEditRoute,
      params: {
        id: this.campaignId,
      },
    });
  }

}
