import { Module } from 'vuex';
import { NavigationState } from './NavigationTypes';
import { RootState } from '@/app/shared/store/RootState';
import { mutations } from './NavigationMutations';
import { getters } from './NavigationGetters';
import { actions } from './NavigationActions';

const state: NavigationState = {
  systemFeatures: null,
  userRoles: null,
};

export const NavigationModule: Module<NavigationState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
