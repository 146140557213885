import { RouteConfig } from 'vue-router';

import { USER_ROLES } from '@/app/shared/utilities/object-factory';
import MyDevicesHome from '../screens/DevicesHome/MyDevicesHome.vue';
import MyDevicesMain from '../screens/DevicesMain/MyDevicesMain.vue';
import MyDeviceNew from '../screens/CreateDevice/MyDeviceNew.vue';
import MyDeviceView from '../screens/ViewDevice/MyDeviceView.vue';
import MyDeviceConnectView from '@connectDevices/screens/ConnectDeviceForm/MyDeviceConnectView.vue';
import MyDeviceConnectHome from '@connectDevices/screens/ConnectDevicesHome/MyDeviceConnectHome.vue';
import StoreDevicesHome from '@storeDevices/screens/StoreDevicesHome/StoreDevicesHome.vue';
import StoreDeviceView from '@storeDevices/screens/ViewStoreDevice/StoreDeviceView.vue';
import StoreDevicesMain from '@storeDevices/screens/StoreDevicesMain/StoreDevicesMain.vue';
import routesNames from '@/app/shared/utilities/routes-names';

export default [
  {
    path: 'devices',
    component: MyDevicesMain,
    meta: {
      roles: [
        USER_ROLES.SCREEN_OWNER,
      ],
    },
    children: [
      {
        ...routesNames.MY_DEVICES,
        path: '',
        component: MyDevicesHome,
      },
      {
        ...routesNames.MY_DEVICE_NEW,
        path: 'new',
        component: MyDeviceNew,
      },
      {
        ...routesNames.MY_DEVICE_VIEW,
        path: 'view/:id',
        component: MyDeviceView,
      },
      {
        ...routesNames.MY_DEVICE_CONNECT_LIST,
        path: 'connect',
        component: MyDeviceConnectHome,
      },
      {
        ...routesNames.MY_DEVICE_CONNECT,
        path: 'connect/:id',
        component: MyDeviceConnectView,
      },
    ],
  },
  {
    path: 'store-devices',
    component: StoreDevicesMain,
    meta: {
      roles: [
        USER_ROLES.STORE_OWNER,
      ],
    },
    children: [
      {
        ...routesNames.STORE_DEVICES,
        path: '',
        component: StoreDevicesHome,
      },
      {
        ...routesNames.STORE_DEVICE_VIEW,
        path: 'review/:id',
        component: StoreDeviceView,
      },
    ],
  },
] as RouteConfig[];
