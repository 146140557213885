import {
  AdSpace,
  AuthorizedUser,
  Base64,
  Callback,
  CampaignMedia,
  CampaignWithSingleAdSpaceMedia,
  MimeString,
  Nullable,
  SizeFormat,
} from '@/app/shared/utilities/static-types';

import {
  take,
  memoize, findIndex as lodashFindIndex,
  last,
  cloneDeep,
  identity,
  chain,
  isEqual,
  curryRight,
  flow,
  invoke,
  map,
  chunk,
  flatten,
  uniqWith,
} from 'lodash';
import { FirebaseAppFirestore, FirebaseAppFunctions } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { getUserById } from '@adminRoles/shared/actions';
import { QuerySnapshot } from '@firebase/firestore-types';
import { FILTER_QUERY_LIMIT } from '@/app/shared/utilities/object-factory';
// tslint:disable-next-line:max-line-length
const mobileRegex1 = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i;
// tslint:disable-next-line:max-line-length
const mobileRegex2 = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i;


export const isMobileUser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
  return mobileRegex1.test(userAgent) || mobileRegex2.test(userAgent.substr(0, 4));
};

export const bytesToSize = (bytes: number): SizeFormat => {
  if (bytes === 0) {
    return {
      bytes,
      format: '0 Bytes',
    };
  }

  const kilo = 1024;
  const units = ['Bytes', 'KBs', 'MBs', 'GBs'];

  const [base] =
    // calculate the log/exponent(e) of 1024 that gives a value <= the value of "bytes".
    // (e.g., 1024^(2) <= 1,500,000 bytes(1.5MBs) |=> log(1024) 1,500,000 ~= 2)
    [Math.log(bytes) / Math.log(kilo)]
      .map(Math.floor);

  const unit = units[base];

  const [size] =
    // calculate how many bytes in unit size. (e.g., 1,000,000 bytes in 1MB)
    [Math.pow(kilo, base)]
      // calculate how many units of size. (e.g., 500,000,000 bytes / 1,000,000 bytes in MB = 5 MBs)
      .map((bytesInUnitSize) => bytes / bytesInUnitSize)
      // round the size to 2 decimal digits
      .map((num) => num * 100)
      .map(Math.round)
      .map((num) => num / 100);

  return {
    bytes,
    format: `${size} ${unit}`,
  };
};

/**
 * Convert Base64 (dataURI) to blob with mimeType
 * @param base64String
 * @param mimeType
 */
export const base64ToBlob = (base64String: Base64, mimeType: MimeString): { blob: Blob, dataURI: string } => {
  const dataURI = `data:${mimeType};base64,${base64String}`;
  const binary = atob(base64String.replace(/\s/g, ''));
  // write the bytes of the string to an ArrayBuffer
  const buffer = new ArrayBuffer(binary.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < binary.length; i++) {
    view[i] = binary.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([buffer], { type: mimeType });
  return { blob, dataURI };
};

/**
 * requestPdf function to return a PDF from API endpoint * Converts base64 string to blob and inits download
 * @param {Blob} blob
 * @param {string} saveAsName
 * @see {@link https://code-tribe.com/automatic-pdf-download-in-all-browsers/}
 */
export function downloadBlob(blob: Blob, saveAsName: string): Blob {
  // Internet Explorer support
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, saveAsName);
    return blob;
  }

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', saveAsName);
  document.body.appendChild(link);
  link.click();

  link.remove();
  return blob;
}

export function getBlobFromImageSrc(imageSrc: string): Promise<File> {
  const fileName = getFileNameFromStorageURL(imageSrc);
  return fetch(imageSrc)
    .then((res) => res.blob()) // Gets the response and returns it as a blob
    .then((blob) => new File([blob], fileName!));
}

export function getFileNameFromStorageURL(fileUrl: string) {
  const [path] = fileUrl.split('?');
  return last(splitPath(path));
}

export function splitPath(url: string) {
  return decodeURIComponent(url).split('/');
}

export function downloadPdfFromBase64(base64String: Base64, saveAsName: string) {
  const { blob } = base64ToBlob(base64String, 'application/pdf');
  return downloadBlob(blob, saveAsName);
}

export const concatRecordValues = <T extends string, K>(record: Record<T, K | K[]>): K[] =>
  Object.values<K | K[]>(record)
    .reduce((recordValues: K[], value: K | K[]) => recordValues.concat(value), []);

export const splitAfter = <T>(array: T[], count: number) => {
  return [array.slice(0, count), array.slice(count)];
};

export const sliceBy = <T>(array: T[], count: number, predicate: (elem: T) => boolean) => {
  const filteredPart = array.filter(predicate);
  return take(filteredPart, count);
};

export function cacheKeyResolver(...args: any[]) {
  return JSON.stringify(args);
}

export const concatRecordValuesMemoized: <T extends string, K>(record: Record<T, K | K[]>) => K[] = memoize(concatRecordValues, cacheKeyResolver);

export const not = (val: any) => !val;

/**
 * Returns the index of the first element predicate returns truthy.
 * @param array The array to search.
 * @param predicate The function invoked per iteration.
 * @return Returns the index of the found element, else undefined.
 */
export function findIndex<T>(array: T[], predicate: Callback<T, boolean>): number | undefined {
  const idx = lodashFindIndex(array, predicate);
  return idx >= 0 ? idx : undefined;
}

export function filterRecordByKeys<T extends Record<string, U>, U>(record: T, keys: string[]) {
  const clonedRecord = cloneDeep(record);
  const includedKeys = Object.keys(clonedRecord)
    .filter((key) => keys.includes(key));
  return includedKeys.reduce((filteredRecord, key) => {
    return { ...filteredRecord, [key]: clonedRecord[key] };
  }, {} as T);
}

export function isNonNullable<T>(val: Nullable<T>): val is NonNullable<T> {
  return Boolean(val);
}

export const uniqueArray = <T, K>(arr: T[], mapper: Callback<T, K> = identity): K[] =>
  chain(arr)
    .map(mapper)
    .uniqWith(isEqual)
    .value();
export const signInToPlatformWithUserAsAdmin = async (user: AuthorizedUser) => {
  const requestAccessToken = FirebaseAppFunctions
    .httpsCallable(firebaseNames.functions.REQUEST_ACCESS_TOKEN);
  const { data: token } = await requestAccessToken({ UID: user.UID });
  const fullUser = await getUserById(user.UID);
  const [role] = fullUser && fullUser.ROLES || [];
  const base64Token = window.btoa(token);
  const userType = window.btoa(role.VAL || '');
  const platformUrl = process.env.VUE_APP_PLATFORM_URL;
  window.open(`${platformUrl}/admin-login/${base64Token}/${userType}`, '_blank');
};
export const toDataURL = (url: string): Promise<string> => {
  return fetch(url)
    .then((response: Response) => {
      return response.blob();
    })
    .then((blob: Blob) => {
      return URL.createObjectURL(blob);
    });
};

export const downloadMedia = async (campaign: CampaignWithSingleAdSpaceMedia, adSpace: AdSpace): Promise<void> => {
  const a = document.createElement('a');
  a.href = await toDataURL(campaign.mediaFile as string);
  const media = campaign.MEDIA_LIST!.find((mediaItem) => mediaItem.AD_SPACE_ID === adSpace.ID);
  const { NAME } = campaign!;
  const { MEDIA_TYPE: mediaType } = media!;
  const fileExtension = mediaType!.split('/')[1];
  const campaignName = NAME.replace(' ', '-');
  a.download = `${campaignName}.${fileExtension}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export function curriedMap() {
  return curryRight(map);
}

export function sequencePromiseT<T>(array: Array<Promise<T>>): Promise<T[]> {
  return Promise.all(array);
}

export function getAllDocsDataFromSnap<T>(snap: QuerySnapshot<T>): T[] {
  return flow(curriedMap()((doc: T) => invoke(doc, 'data')))(snap.docs);
}

// There is limit with in operator with 10 items
export function chunkFirebaseQueryWithItsLimit<T>(list: T[]) {
  return chunk(list, 10);
}

export const segmentFiltersQueries =
  async <T>(size = FILTER_QUERY_LIMIT, filtersList: any[], fieldPath: keyof T & string, collectionPath: string) => {

    return flow((data) => uniqWith(data, isEqual),
      chunkFirebaseQueryWithItsLimit,
      curriedMap()(async (filterChunk: T) => {
        return getAllDocsDataFromSnap(await FirebaseAppFirestore.collection(collectionPath)
          .where(fieldPath, 'in', filterChunk)
          .get());
      }),
      sequencePromiseT,
      async (result) => flatten(await result),
    )(filtersList);

  };

export const convertScreenSaverMediaListToMap = (mediaList: CampaignMedia[]) => {
  return mediaList.reduce(
    (mediaMap, media) => {
      return {
        ...mediaMap,
        ...{ [`${media.HEIGHT}*${media.WIDTH}`]: media },
      };
    },
    {} as Record<string, CampaignMedia>,
  );
};

export const convertAdSpaceListToMap = (adSpaces: AdSpace[]) => {
  return adSpaces.reduce((mediaMap, adSpace) => {
    const screenResolution = `${adSpace.SCREENS_RESOLUTION.HEIGHT}*${adSpace.SCREENS_RESOLUTION.WIDTH}`;
    if (mediaMap[screenResolution]) {
      mediaMap[screenResolution] = mediaMap[screenResolution].concat(adSpace);
    } else {
      mediaMap[screenResolution] = [adSpace];
    }
    return mediaMap;
  }, {} as Record<string, AdSpace[]>);
};
export const simulateProgress = ({ maxProgressBarInMinutes, progressUpdateCallback }: { maxProgressBarInMinutes: number, progressUpdateCallback: (progress: number) => void }) => {
  let progress = 0;

  const duration = maxProgressBarInMinutes * 60 * 1000;
  let cycleCount = 0; // Tracks the number of cycles completed
  return setInterval(() => {
    if (cycleCount % 2 === 0) { // Even cycle, progress to 100
      progress = Math.min(progress + 1, 100);
    } else { // Odd cycle, progress to 99
      progress = Math.min(progress + (Math.random() * 2 - 1), 99); // Randomly increment between -1 and 1 (effectively 0 or 1)
    }
    if (progress === 100) {
      cycleCount++; // Increment cycle count on reaching 100
    }
    progressUpdateCallback(progress);
  }, duration / 100); // Update every 1/100th of the duration (30 milliseconds)
};
