






















import {
  CampaignTableRow,
  CampaignTuple,
  ScreenCluster,
} from '@/app/shared/utilities/static-types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import CampaignsTableView from '@campaigns/shared/components/CampaignsTableView.vue';
import CampaignsListView from './CampaignsListView.vue';
import {
  getCampaignDuration,
  getCampaignStartDate,
  stringifyScreenClustersNames,
} from '../utils';
import {
  loadScreenClusters,
} from '@/app/shared/actions/ClustersActions';

@Component({
  components: {
    CampaignsTableView,
    CampaignsListView,
  },
})
export default class Campaigns extends Vue {
  @Prop({ required: true, default: () => ({}) })
  public campaignsTuples!: CampaignTuple[];
  @Prop(Boolean) public showReservedUntil!: boolean;
  public screenClusters: ScreenCluster[] = [];
  public loading: boolean = false;

  public async created() {
    try {
      this.loading = true;
      this.screenClusters = await loadScreenClusters();
    } finally {
      this.loading = false;
    }
  }

  public get appLocale(): string {
    return this.$root.$i18n.locale;
  }

  public get campaignsList(): CampaignTableRow[] {
    return this.campaignsTuples.map(([campaignData, campaignRef]) => {
      const mappedScreenClusters =
        campaignData.SCREEN_CLUSTERS ||
        campaignData.SCREEN_CLUSTERS_IDS.map(
          (id: string) => this.screenClusters.find(({ ID }) => id === ID)!,
        );

      return {
        ...campaignData,
        REF: campaignRef,
        SCREEN_CLUSTERS: mappedScreenClusters,
        SCREEN_CLUSTERS_NAMES: stringifyScreenClustersNames(
          mappedScreenClusters,
          this.appLocale,
        ),
        COMPANY_NAME: campaignData.COMPANY_NAME || '-',
        START_DATE: getCampaignStartDate(campaignData).toISOString(),
        DURATION_IN_WEEKS: getCampaignDuration(campaignData),
      };
    });
  }
}
