import { CardSchema, MonthYear } from '@/app/shared/utilities/static-types';
import { SUPPORTED_TYPES } from '@/app/shared/utilities/object-factory';

// tslint:disable-next-line:max-line-length
const emailRegex = /^(([^<>()\[\]\\.,;:\s@^"]+(\.[^<>()\[\]\\.,;:\s@^"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const testEmailRegex = /^(([^<>()\[\]\\.,;:\s@^"]+(\.[^<>()\[\]\\.,;:\s@^"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// tslint:disable-next-line:max-line-length
const passwordRegex = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[!@#\$%\^&\*])))(?=.{8,})/;
// tslint:disable-next-line:max-line-length
const saudiPhoneRegex = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
const onlyLettersRegex = /^[a-zA-Z]+$/;
const onlyNumbersRegex = /^[0-9]+$/;
const alphanumericRegex = /^[a-zA-Z0-9_]*$/;

const QJtrim = (text: string) => {
  const rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
  if (text === null) {
    return '';
  } else {
    return (text + '').replace(rtrim, '');
  }
};

const luhnCheck = (cardNumber: string | number) => {
  let odd = true;
  let sum = 0;

  const digits = cardNumber.toString().split('').reverse();

  digits.forEach((digit) => {
    let intDigit = parseInt(digit, 10);
    odd = ! odd;
    if (odd) {
      intDigit *= 2;
    }
    if (intDigit > 9) {
      intDigit -= 9;
    }
    sum += intDigit;
  });

  return sum % 10 === 0;
};

const defaultCardFormat = /(\d{1,4})/g;
const cardSchemas: CardSchema[] = [
  {
    type: 'maestro',
    pattern: /^(5018|5020|5038|6304|6703|6708|6759|676[1-3])/,
    format: defaultCardFormat,
    length: [12, 13, 14, 15, 16, 17, 18, 19],
    cvcLength: [3],
    luhn: true,
  }, {
    type: 'mastercard',
    pattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    format: defaultCardFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
  }, {
    type: 'visaelectron',
    pattern: /^4(026|17500|405|508|844|91[37])/,
    format: defaultCardFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
  }, {
    type: 'visa',
    pattern: /^4/,
    format: defaultCardFormat,
    length: [13, 16, 19],
    cvcLength: [3],
    luhn: true,
  },
];

const cardFromNumber = (cardNumber: string | number): CardSchema | undefined => {
  const stringifiedCardNumber: string = cardNumber.toString().replace(/\D/g, '');
  return cardSchemas.find((card) => card.pattern.test(stringifiedCardNumber));
};

const cardFromType = (type: string): CardSchema | undefined => {
  return cardSchemas.find((card) => card.type === type);
};

export const validateCardNumber = (cardNumber: string | number): boolean => {
  const stringifiedCardNumber = cardNumber.toString()
    .replace(/\s+|-/g, '');
  if (!/^\d+$/.test(stringifiedCardNumber)) {
    return false;
  }
  const matchedCard = cardFromNumber(stringifiedCardNumber);
  if (!matchedCard) {
    return false;
  }

  const isValidLength = matchedCard.length
    .includes(stringifiedCardNumber.length);
  const isValidLuhn = !matchedCard.luhn || luhnCheck(stringifiedCardNumber);

  return isValidLength && isValidLuhn;
};

const cardExpiryVal = (monthYear: string ): MonthYear => {
  monthYear = monthYear.replace(/\s/g, '');
  const [ month ] = monthYear.split('/', 2);
  let [ , year ] = monthYear.split('/', 2);

  if ((year != null ? year.length : void 0) === 2 && /^\d+$/.test(year)) {
    const prefix = new Date().getFullYear()
      .toString()
      .slice(0, 2);
    year = prefix + year;
  }

  return {
    month: parseInt(month, 10),
    year: parseInt(year, 10),
  };
};

export const validateCardExpiry = (month: string | number, year?: string | number): boolean => {
  if (typeof month === 'object' && 'month' in month) {
    const ref: {
      month: number;
      year: number;
    } = month;
    month = ref.month;
    year = ref.year;
  } else if (typeof month === 'string' && month.includes( '/')) {
    const ref: {
      month: number;
      year: number;
    } = cardExpiryVal(month);
    month = ref.month;
    year = ref.year;
  }
  if (!(month && year)) {
    return false;
  }
  month = QJtrim(month as string);
  year = QJtrim(year as string);
  if (!/^\d+$/.test(month as string)) {
    return false;
  }
  if (!/^\d+$/.test(year as string)) {
    return false;
  }
  month = parseInt(month as string, 10);
  if (!(month && month <= 12)) {
    return false;
  }

  if ((year as string).length !== 4) {
    return false;
  }
  // if ((year as string).length === 2) {
  //   const prefix = new Date().getFullYear()
  //     .toString()
  //     .slice(0, 2);
  //   year = prefix + year;
  // }
  year = parseInt(year, 10);

  const expiry: Date = new Date(year, month);
  const currentTime = new Date();
  expiry!.setMonth(expiry.getMonth() - 1);
  expiry!.setMonth(expiry.getMonth() + 1, 1);
  return expiry > currentTime;
};

export const validateCardCVC = (cvc: string, type?: string): boolean => {
  cvc = QJtrim(cvc);
  if (!/^\d+$/.test(cvc)) {
    return false;
  }
  if (type && cardFromType(type)) {
    const CVCLength = cvc.length;
    const matchedCard = cardFromType(type);
    return matchedCard!.length.includes(CVCLength);
  } else {
    return cvc.length >= 3 && cvc.length <= 4;
  }
};

export default {
  emailFormat: (msg: string) => (email: string) =>
    !email || emailRegex.test(email && email.trim()) || msg
  ,
  testEmailFormat: (msg: string) => (email: string) =>
    testEmailRegex.test(email && email.trim()) || msg
  ,
  passwordFormat: (msg: string) => (password: string) =>
    passwordRegex.test(password) || msg
  ,
  saudiPhoneFormat: (msg: string) => (phone: string): boolean | string =>
    !phone || saudiPhoneRegex.test(phone) || msg
  ,
  required: (msg: string) => (value: string | any[]) => {
    if (Array.isArray(value)) {
      return (value && value.length > 0) || msg;
    }
    return value !== undefined && value !== null || msg;
  },
  shouldNotHaveSpaces: (msg: string) => (value: string) =>
    value && !value.includes(' ') || msg
  ,
  shouldNotBeWhitespaces: (msg: string) => (value: string) =>
    value && (value.trim().length > 0 || msg)
  ,
  onlyLetters: (msg: string) => (value: string) =>
    onlyLettersRegex.test(value) || msg // Localization key: 'only_letters_allowed'
  ,
  onlyNumbers: (msg: string) => (value: string) =>
    onlyNumbersRegex.test(value) || msg // Localization key: 'only_numbers_allowed'
  ,
  onlyAlphanumeric: (msg: string) => (value: string) =>
    alphanumericRegex.test(value) || msg // Localization key: 'only_alphanumerics_allowed'
  ,
  minLength: (min: number, msg: string) => (value: string) =>
    value.length >= min || msg
  ,
  maxLength: (max: number, msg: string) => (value: string) =>
    value.length <= max || msg
  ,
  maximumDocumentSizeInMegabytes: (maximumSize: number, msg: string) => (files: File[]) => {
    if (!Array.isArray(files) || !files.length) {
      return true;
    }

    const maximumSizeInBytes = maximumSize * 1048576;

    const nonVideoFiles = files.filter((file) => file && file.type !== SUPPORTED_TYPES.MP4);
    return nonVideoFiles.every((file) => file && file.size < maximumSizeInBytes) || msg;
  },
  cardNumberFormat: (msg: string) => (cardNumber: string) =>
    validateCardNumber(cardNumber) || msg
  ,
  cardExpiryFormat: (msg: string) => (expiryDate: string) => {
    // const [ expiryMonth, expiryYear ] = expiryDate.split('/');
    const [ expiryMonth, expiryYear ] = [ expiryDate.substring(0, 2), expiryDate.substring(2)];
    return validateCardExpiry(expiryMonth, expiryYear) || msg;
  },
  cardCCVFormat: (msg: string) => (value: string) =>
    validateCardCVC(value) || msg
  ,
};

