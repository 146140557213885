import { getCurrentUser } from '../firebase/firebase-user';
import { FirebaseAppFirestore } from '../firebase/firebase-app';
import {
  MyDevice,
  StoreAccount,
  City,
  Country,
} from '../utilities/static-types';

import firebaseNames from '../utilities/firebase-names';

import geonames from '@/app/shared/resources/geonames.json';

/**
 * Gets a Device by its ID
 * @param deviceId Device ID
 */
export const getDeviceByIdAction = async (deviceId: string) => {
  const currentUser = await getCurrentUser();
  const deviceSnap = await FirebaseAppFirestore
    .collection(firebaseNames.DEVICES.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.DEVICES.MY_DEVICES)
    .doc(deviceId)
    .get();
  return deviceSnap.data() as MyDevice;
};

/**
 * Get a list of system's stores.
 */
export const getStoresAction = async () => {
  const storesSnap = await FirebaseAppFirestore
    .collection(firebaseNames.STORES)
    .get();
  return storesSnap.docs.map((doc) => doc.data() as StoreAccount);
};

/**
 * Get store by ID.
 * @param storeId
 */
export const getStoreByIdAction = async (storeId: string) => {
  const storesSnap = await FirebaseAppFirestore
    .collection(firebaseNames.STORES)
    .doc(storeId)
    .get();
  return storesSnap.data() as StoreAccount;
};

/**
 * @description
 * Get List of cities using GeoNames API.
 * @param country {Country}
 */
export const getCitiesByCountryCodeAction = (country: Country): City[] => {
  const { countries } = geonames;
  const cities = countries
    .filter((countryObj: any) => countryObj.countryCode === country.COUNTRY_CODE)
    .flatMap((countryObj: any) => countryObj.cities);

  const result: City[] = cities
    .map((city: any) => ({
      DISPLAY_NAME: city.name,
      GEONAME_ID: city.geonameId,
    } as City));

  return result;
};

/**
 * @description
 * Get List of supported countries using GeoNames API.
 */
export const getCountriesAction = (): Country[] => {
  const { countries } = geonames;

  const result: Country[] = countries
    .map((country: any) => ({
      DISPLAY_NAME: country.countryName,
      GEONAME_ID: country.geonameId,
      COUNTRY_CODE: country.countryCode,
    }));

  return result;
};
