import { CompanyProfile, Nullable } from '../utilities/static-types';
import { getCurrentUser } from '../firebase/firebase-user';
import { FirebaseAppFirestore } from '../firebase/firebase-app';
import firebaseNames from '../utilities/firebase-names';
import { DocumentSnapshot } from '@firebase/firestore-types';

/**
 * Gets the company profile of current user.
 */
export const getCompanyProfileAction = async (): Promise<CompanyProfile | undefined> => {
  const currentUser = await getCurrentUser();
  const docRef = await FirebaseAppFirestore
    .collection(firebaseNames.COMPANY_PROFILES)
    .doc(currentUser!.uid)
    .get();
  const docData = docRef.data();
  return docData ? {
    USER_ID: docData!.USER_ID,
    COMPANY_NAME: docData!.COMPANY_NAME,
    COMPANY_REGISTRY_NAME: docData!.COMPANY_REGISTRY_NAME,
    COMPANY_REGISTRATION_NUMBER: docData!.COMPANY_REGISTRATION_NUMBER,
    COMPANY_REGISTRATION_CERTIFICATE: docData!.COMPANY_REGISTRATION_CERTIFICATE,
    HEADQUARTER_COUNTRY: docData!.HEADQUARTER_COUNTRY,
    COMPANY_ADDRESS: docData!.COMPANY_ADDRESS,
    COMPANY_PRODUCTS: docData!.COMPANY_PRODUCTS,
    STATUS: docData!.STATUS,
    COMPANY_SIZE: docData!.COMPANY_SIZE,
    COMPANY_VAT: docData!.COMPANY_VAT,
  } : undefined;
};

/**
 * Get company for specific User by his ID.
 * @param uid User ID
 */
export const getCompanyProfileByUserIdAction = async (uid: string): Promise<Nullable<CompanyProfile>> => {
  const dataRef = await FirebaseAppFirestore.collection(firebaseNames.COMPANY_PROFILES).doc(uid).get();
  const dataDoc = dataRef.data();
  return dataDoc ? dataDoc as CompanyProfile : null;
};

/**
 * Get company profiles action.
 */
export const loadCompanyProfilesAction = async () => {
  const dataRef = await FirebaseAppFirestore.collection(firebaseNames.COMPANY_PROFILES).get();
  const companyProfiles = dataRef.docs.map((item: DocumentSnapshot) => {
    return {...item.data(), USER_ID: item.id };
  });
  return companyProfiles as CompanyProfile[];
};
