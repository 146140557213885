





































import { Component, Vue } from 'vue-property-decorator';
import Campaigns from '@campaigns/shared/components/Campaigns.vue';
import routesNames from '@/app/shared/utilities/routes-names';
import { getStoreScreenClusters, loadStoreOwnerCampaignsAction } from '@campaigns/shared/actions';
import TableActions from './components/TableActions.vue';
import ClickableCampaignItem from './components/ClickableCampaignItem.vue';
import { AuthorizedUser, Campaign, CampaignTuple, ScreenCluster } from '@/app/shared/utilities/static-types';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import { getUserById } from '@/app/screens/App/screens/Admin/screens/UserRoles/shared/actions';
import { isCampaignOwner, filterStoreCampaignsData } from '@campaigns/shared/utils';

@Component({
  components: {
    Campaigns,
    TableActions,
    ClickableCampaignItem,
  },
})
export default class StoreCampaigns extends Vue {
  public loading = false;
  public newCampaignRoute = routesNames.STORE_CAMPAIGN_NEW;
  public currentUser: AuthorizedUser | undefined = undefined;
  public campaignsTuples: CampaignTuple[] = [];

  public async created() {
    this.currentUser = await this.loadCurrentUser();
    const storeScreenClusters = await getStoreScreenClusters();

    this.campaignsTuples = await this.loadCampaignsAction(storeScreenClusters);
  }

  public async loadCurrentUser() {
    const { uid } = (await getCurrentUser())!;
    return getUserById(uid);
  }

  public isCampaignOwner(campaign: Campaign) {
    return isCampaignOwner(campaign)(this.currentUser!);
  }

  public async loadCampaignsAction(storeScreenClusters: ScreenCluster[]) {
    const campaignsTuples = await loadStoreOwnerCampaignsAction();
    // To show only campaigns with store's clusters and prices in his clusters
    return filterStoreCampaignsData(
      campaignsTuples,
      storeScreenClusters,
    );
  }
}
