import { RouteConfig } from 'vue-router';

import AdvertiserCampaigns from '../screens/Advertiser/screens/AdvertiserCampaigns/AdvertiserCampaigns.vue';
import AdvCampaignsMain from '../screens/Advertiser/screens/CampaignsMain/AdvertiserCampaignsMain.vue';
import CampaignNew from '../screens/Advertiser/screens/CreateCampaign/CampaignNew.vue';
import CampaignEdit from '../screens/Advertiser/screens/EditCampaign/CampaignEdit.vue';
import CheckoutPage from '../screens/Advertiser/screens/CheckoutCampaign/CheckoutPage.vue';
import StoreCheckoutPage from '../screens/StoreOwner/screens/CheckoutCampaign/CheckoutPage.vue';
import StoreCampaigns from '../screens/StoreOwner/screens/StoreCampaigns/StoreCampaigns.vue';
import StoreCampaignReview from '../screens/StoreOwner/screens/ReviewCampaign/CampaignReview.vue';
import StoreCampaignsMain from '../screens/StoreOwner/screens/CampaignsMain/StoreCampaignsMain.vue';
import StoreCampaignNew from '../screens/StoreOwner/screens/CreateCampaign/CampaignNew.vue';
import StoreCampaignEdit from '../screens/StoreOwner/screens/EditCampaign/CampaignEdit.vue';
import routesNames from '@/app/shared/utilities/routes-names';
import { USER_ROLES } from '@/app/shared/utilities/object-factory';
import CampaignInvoice from '@campaigns/screens/Advertiser/screens/CampaignInvoice/CampaignInvoice.vue';

export default [
  {
    path: 'campaigns',
    component: AdvCampaignsMain,
    meta: {
      roles: [
        USER_ROLES.ADVERTISER,
      ],
    },
    children: [
      {
        ...routesNames.MY_CAMPAIGNS,
        path: '',
        component: AdvertiserCampaigns,
      },
      {
        ...routesNames.CAMPAIGN_NEW,
        path: 'new',
        component: CampaignNew,
      },
      {
        ...routesNames.CAMPAIGN_EDIT,
        path: 'edit/:id',
        component: CampaignEdit,
      },
      {
        ...routesNames.CHECKOUT_PAGE,
        path: 'checkout/:id',
        component: CheckoutPage,
      },
      {
        ...routesNames.PAYMENT_STATUS,
        path: 'payment/:id',
        component: CampaignInvoice,
      },
    ],
  },
  {
    path: 'store-campaigns',
    component: StoreCampaignsMain,
    meta: {
      roles: [
        USER_ROLES.STORE_OWNER,
      ],
    },
    children: [
      {
        ...routesNames.STORE_CAMPAIGN_NEW,
        path: 'new',
        component: StoreCampaignNew,
      },
      {
        ...routesNames.STORE_CAMPAIGN_EDIT,
        path: 'edit/:id',
        component: StoreCampaignEdit,
      },
      {
        ...routesNames.MANAGE_STORE_CAMPAIGNS,
        path: '',
        component: StoreCampaigns,
      },
      {
        ...routesNames.STORE_CAMPAIGN_CHECKOUT_PAGE,
        path: 'checkout/:id',
        component: StoreCheckoutPage,
      },
      {
        ...routesNames.CAMPAIGN_REVIEW,
        path: 'review/:id',
        component: StoreCampaignReview,
      },
    ],
  },
] as RouteConfig[];
