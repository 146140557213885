











































































import { Component, Vue, Watch, Prop, Emit } from 'vue-property-decorator';
import { Schedule } from '@/app/shared/utilities/static-types';
import { FirebaseRemoteConfig } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { isScheduleFinished, isScheduleStarted } from '@/app/screens/App/screens/Campaigns/shared/utils';

@Component
export default class ScheduleWeeks extends Vue {
  @Prop({ default: true, type: Boolean }) public disableRunningSchedules!: boolean;
  @Prop({ required: true }) public schedulesViewItems!: Array<Schedule & { SELECTED: boolean}>;
  // @Prop({ default: 0 }) public weekItemHeight!: number;
  @Prop({ default: false, type: Boolean }) public subscription!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly!: boolean;
  @Prop({ default: false, type: Boolean }) public noPayment!: boolean;

  public isSchedulesCalendarTimeSlotInfoOn = FirebaseRemoteConfig.getBoolean(firebaseNames.remoteConfig.CALENDAR_SCHEDULES_TIME_SLOT_INFO);

  public scheduleItemClass({ AVAILABLE, SELECTED }: Schedule): string {
    const availablityClass = AVAILABLE
      ? 'available'
      : 'not-available';
    const selectionClass = SELECTED && 'selected';
    return `
      week-list-item
      ${selectionClass}
      ${availablityClass}
    `;
  }

  public formatScheduleName(schedule: Schedule) {
    const { ID, DAYS, FREE_SLOTS_COUNT, AVAILABLE } = schedule;
    const week = this.formatScheduleWeek(schedule);
    const days = this.formatScheduleDays(DAYS);
    const hasFreeSlots = Boolean(FREE_SLOTS_COUNT);
    const isStarted = isScheduleStarted(schedule) && AVAILABLE;
    const isFinished = isScheduleFinished(schedule) || !AVAILABLE;
    const additionalScheduleStatus = isFinished
      ? `(${this.$t('status.finished')})`
      : '';
    const tabspace = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
    return `<div class="schedule-name">${week} ${tabspace} ${days} ${additionalScheduleStatus}</div>`;
  }

  public async created() {
    return FirebaseRemoteConfig.fetchAndActivate()
      .then(() => {
        this.isSchedulesCalendarTimeSlotInfoOn = FirebaseRemoteConfig.getBoolean(firebaseNames.remoteConfig.CALENDAR_SCHEDULES_TIME_SLOT_INFO);
      })
      .catch((err) => {
        /* tslint:disable-next-line */
        console.error(err);
      });
  }

  // public mounted() {
  //   const [element] = this.$refs.weekListItem as any[];
  //   this.$emit('update:weekItemHeight', element.$el.clientHeight);
  // }

  public get availableSchedules() {
    return this.schedulesViewItems.filter(({ AVAILABLE }) => AVAILABLE);
  }

  public formatScheduleWeek(schedule: Schedule) {
    const tabspace = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
    const scheduleIdx = this.availableSchedules.findIndex((scheduleItem) => scheduleItem.ID === schedule.ID);

    if (scheduleIdx < 0 || !schedule.AVAILABLE) {
      return `<span class="purple--text week-text">${this.$t('week')} ${tabspace}</span>`;
    }

    const isRunning = isScheduleStarted(schedule);
    const isFinished = isScheduleFinished(schedule);

    // if first schedule element
    if (scheduleIdx === 0) {
      return isRunning && !isFinished
      ? `<span class="purple--text week-text">${ this.$t('current_week') }</span>`
      : `<span class="purple--text week-text">${this.$t('next_week')}</span>`;

    } else {
      return `<span class="purple--text week-text">${this.$t('week')} ${scheduleIdx + 1} ${tabspace}</span>`;
    }
  }
  public formatScheduleDays(days: string) {
    return `<span class="blue--text days-text">${days}</span>`;
  }

  public formatScheduleFreeSlots(schedule: Schedule) {
    if (!schedule.AVAILABLE && schedule.FREE_SLOTS_COUNT) {
      return '';
    }

    const slots = schedule.FREE_SLOTS_COUNT;

    return slots
      ? `<span class="green--text slots-text">
          ${this.$t('only_count_remaining', { count: slots })}</span>`
      : `<span class="grey--text slots-text">
          ${this.$t('fully_booked')}
        </span>`;
  }

  // To sync `schedulesViewItems` prop, and detect changes on the prop
  @Emit('update:schedulesViewItems')
  @Watch('schedulesViewItems', { deep: true })
  public onChangeSchedulesViewItems(val: Schedule[]) {
    return val;
  }
}
