
















import { Component, Vue, Prop } from 'vue-property-decorator';

import ToolBar from '@/app/shared/components/ToolBar/ToolBar.vue';
import PublicFooter from '@publicApp/screens/LandingPage/components/PublicFooter/PublicFooter.vue';

@Component({
  components: {
    ToolBar,
    PublicFooter,
  },
})
export default class AppPublicLayout extends Vue {
  @Prop({ default: false, type: Boolean }) public landing!: boolean;
  @Prop({ default: true, type: Boolean, required: false }) public withButtons!: boolean;
}
