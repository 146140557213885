

















































import { Component, Vue, Watch } from 'vue-property-decorator';

import { AuthorizedUser } from '@/app/shared/utilities/static-types';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import { getAllRegisteredUsers } from '@adminRoles/shared/actions';

@Component
export default class ManageUsersRoles extends Vue {
  public loading = false;

  public filterQuery = '';

  public editUserRoute = AdminRoutes.MANAGE_USERS_EDIT;
  public addUserRoute = AdminRoutes.REGISTER_USER;

  public usersDisplayArray: AuthorizedUser[] = [];
  private registeredUsers: AuthorizedUser[] = [];

  public async created() {
    try {
      this.loading = true;
      this.registeredUsers = await getAllRegisteredUsers();
      this.usersDisplayArray = this.registeredUsers.slice();
    } finally {
      this.loading = false;
    }
  }

  @Watch('filterQuery')
  public onFilterChange(val: string, oldVal: string) {
    this.usersDisplayArray = this.registeredUsers.slice().filter((user) => {
      if (user.EMAIL.toLowerCase().includes(val.toLowerCase())) {
        return true;
      }
      if (user.PHONE_NUMBER.toLowerCase().includes(val.toLowerCase())) {
        return true;
      }
      if (user.COMPANY_NAME.toLowerCase().includes(val.toLowerCase())) {
        return true;
      }
      if (user.POSITION && user.POSITION.DISPLAY_NAME.toLowerCase().includes(val.toLowerCase())) {
        return true;
      }
      return false;
    });
  }
  public createUser() {
    this.$router.push({ ...this.addUserRoute });
  }
}
