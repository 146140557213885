import { FirebaseAppFirestore } from '../firebase/firebase-app';
import firebaseNames from '../utilities/firebase-names';
import { getCurrentUser } from '../firebase/firebase-user';
import { AdSpace, ScreenCluster } from '@/app/shared/utilities/static-types';

/**
 * Get Screen Clusters Data by IDs
 */
export const loadScreenClustersByIds = async (
  clustersIds: string[],
): Promise<ScreenCluster[]> => {
  return (
    await FirebaseAppFirestore.collection(firebaseNames.SCREEN_CLUSTERS.VAL)
      .where('ID', 'in', clustersIds)
      .get()
  ).docs.map((doc) => doc.data() as ScreenCluster);
};

/**
 * Get Screen Clusters Data
 */
export const loadScreenClusters = async (): Promise<ScreenCluster[]> => {
  return (
    await FirebaseAppFirestore.collection(
      firebaseNames.SCREEN_CLUSTERS.VAL,
    ).get()
  ).docs.map((doc) => doc.data() as ScreenCluster);
};

/**
 * Get Ad Space Ids created by store owner.
 */
export const loadAdSpacesIdsByStoreOwnerAction = async (): Promise<string[]> => {
  const currentUser = await getCurrentUser();
  const currentUserId = currentUser!.uid;
  const adSpacesSnap = await FirebaseAppFirestore.collection(
    firebaseNames.AD_SPACES.VAL,
  )
    .where('SPACE_OWNER_ID', '==', currentUserId)
    .get();
  const adSpacesIds = adSpacesSnap.docs
    .map((doc) => doc.data() as AdSpace)
    .map((adSpace) => adSpace.ID);
  return adSpacesIds;
};
