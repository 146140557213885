

















import { Component, Prop, Vue } from 'vue-property-decorator';
import PreviewThumbnail from '@campaigns/shared/components/PreviewThumbnail.vue';

@Component({
  name: 'ViewAdSpaceScreenSaver',
  components: {
    PreviewThumbnail,
  },
})
export default class ViewAdSpaceScreenSaver extends Vue {
  @Prop() public mediaPath!: string;
  @Prop() public showMedia!: boolean;
  public closeEvent = 'update:show-media';

  get showMediaValue() {
    return this.showMedia;
  }

  set showMediaValue(showMedia) {
    this.$emit(this.closeEvent, showMedia);
  }
}
