

















import { Component, Vue, Prop } from 'vue-property-decorator';
import rules from '@/app/shared/validation-rules';
import { SUPPORTED_TYPES } from '@/app/shared/utilities/object-factory';
import { AdSpace, Nullable } from '@/app/shared/utilities/static-types';

@Component({
  data: (vm: any) => ({
    uploadFilesRules: [
      rules.maximumDocumentSizeInMegabytes(
        10,
        vm.$i18n.t('maximum_allowed_file_size', { maximumSize: 10 }),
      ),
    ],
  }),
})
export default class MediaUpload extends Vue {
  @Prop() public resolution!: AdSpace['SCREENS_RESOLUTION'];
  public selectedFile: Nullable<File> = null;
  public validationError: Nullable<string> = null;

  public onFilesPicked(event: any) {
    // const isSelectedFile = event!.currentTarget.files.length;
    const isSelectedFile = true;
    if (isSelectedFile) {
      const selectedFiles: any = event!.currentTarget.files;
      [this.selectedFile] =
        selectedFiles &&
        Object.keys(selectedFiles)
          .map((index) => selectedFiles[+index])
          .filter(this.filterImageFiles);

      if (!this.selectedFile || selectedFiles > 1) {
        const rejectedFilesCount = selectedFiles.length;
        this.validationError = this.$t('file_upload_extension_error', {
          rejectedFilesCount,
        }).toString();
        this.$emit('validation-error', this.validationError);
      }

      if (this.resolution) {
        // TODO: validate the resolution
      }
      this.$data.uploadFilesRules.every(
        (rule: (input: any) => true | string) =>
          rule([this.selectedFile]) === true,
      ) && this.$emit('media-selected', this.selectedFile);
      // this.$refs['uploadFileRef'].value = null;
    }
  }

  public filterImageFiles(file: any) {
    return file.type === SUPPORTED_TYPES.JPEG || SUPPORTED_TYPES.GIF;
  }

  public assignNewMedia() {
    (this.$refs.uploadFileRef as any).click();
  }
}
