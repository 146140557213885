import {
  USER_ROLES,
  SYSTEM_STATUS,
} from '@/app/shared/utilities/object-factory';
import {
  Campaign,
  AuthorizedUser,
  CampaignMetaData,
  CampaignTableRow,
  CampaignClustersDiscountsRecord,
  CampaignClustersPricesRecord,
  ScreenCluster,
  CompanyProfile,
  Schedule,
  CampaignTuple,
  PaymentBill,
  Role,
  CampaignFormData,
  AdSpace,
  WithRequired,
  MediaFile,
  CampaignMedia,
  Nullable,
} from '@/app/shared/utilities/static-types';
import moment from 'moment';
import Vue from 'vue';
import {
  filterRecordByKeys,
  isNonNullable,
} from '@/app/shared/utilities/helper-functions';
import { flatten } from 'lodash';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebase from 'firebase';
import firestore = firebase.firestore;
import { generatePlaylist, PlaylistCampaign } from '@campaigns/shared/utils/generatePlaylist';

export const events = new Vue();

export const calculateCampaignPrice = (campaign: Campaign) => {
  return campaign.PAYMENT_BILL ? campaign.PAYMENT_BILL.TOTAL_PRICE : 0;
};

export const calculateClusterTotalPriceOnCampaign = (
  campaign: WithRequired<Campaign, 'PAYMENT_BILL'>,
  clusterId: string,
): number => {
  const paymentBill = campaign.PAYMENT_BILL;
  const clusterPrice = paymentBill.CLUSTERS_PRICES_RECORD[clusterId];
  const clusterDiscount = paymentBill.CLUSTERS_DISCOUNTS_RECORD[clusterId]
    ? paymentBill.CLUSTERS_DISCOUNTS_RECORD[clusterId]!.discountValue
    : 0;
  return clusterPrice - clusterDiscount;
};

const isUserOfType = (type: string) => (user: AuthorizedUser) =>
  user && (user.ROLES || []).find((role: Role) => role.VAL === type);
export const isAdvertiser = isUserOfType(USER_ROLES.ADVERTISER.VAL);
export const isAdmin = isUserOfType(USER_ROLES.ADMIN.VAL);
export const isStoreOwner = isUserOfType(USER_ROLES.STORE_OWNER.VAL);

export const isCampaignOwner = (campaign: Campaign | CampaignTableRow) => (
  user: AuthorizedUser,
) => user && campaign && campaign.ADVERTISER_UID === user.UID;
const showPriceForAdvertiser = (campaign: Campaign | CampaignTableRow) => (
  user: AuthorizedUser,
) =>
  user &&
  campaign &&
  campaign.STATUS.VAL === SYSTEM_STATUS.PENDING_PAYMENT.VAL &&
  isAdvertiser(user);
const showPriceForStoreOwner = (campaign: Campaign | CampaignTableRow) => (
  user: AuthorizedUser,
) =>
  user &&
  campaign &&
  campaign.STATUS.VAL !== SYSTEM_STATUS.NEW.VAL &&
  campaign.APPROVED_BY_ADMIN &&
  isStoreOwner(user);

const campaignIcon = (campaign: Campaign) => (
  campaignOwner: boolean,
): string => {
  return campaign.STATUS.VAL === 'canceled'
    ? 'cancel'
    : campaignOwner
    ? 'account_circle'
    : campaign.CREATED_BY_ADMIN
    ? 'admin_panel_settings'
    : campaign.CREATED_BY_STORE_OWNER
    ? 'store'
    : '';
};

const campaignType = (campaign: Campaign) => (
  campaignOwner: boolean,
): string => {
  return campaign.STATUS.VAL === 'canceled'
    ? 'canceled_by_admin'
    : campaignOwner
    ? 'your_campaign'
    : campaign.CREATED_BY_ADMIN
    ? 'created_by_admin'
    : campaign.CREATED_BY_STORE_OWNER
    ? 'created_by_store_owner'
    : '';
};

export const hasSubscription = (schedules: Schedule[]) => {
  return schedules.filter((schedule) => schedule.AVAILABLE).length >= 52;
};

export const showCampaignPrice = (
  campaign: Campaign | CampaignTableRow,
  user: AuthorizedUser,
) => {
  return Boolean(
    isAdmin(user) ||
      isCampaignOwner(campaign)(user) ||
      showPriceForAdvertiser(campaign)(user) ||
      showPriceForStoreOwner(campaign)(user),
  );
};

export const campaignMetaData = (
  campaign: Campaign,
  campaignOwner: boolean,
): CampaignMetaData => {
  return {
    icon: campaignIcon(campaign)(campaignOwner),
    type: campaignType(campaign)(campaignOwner),
  };
};

const calculateCampaignPriceByPriceRecords = (
  pricesRecord: CampaignClustersPricesRecord,
  discountsRecord: CampaignClustersDiscountsRecord,
): Pick<PaymentBill, 'TOTAL_PRICE' | 'TOTAL_DISCOUNT' | 'DISCOUNTS_LIST'> => {
  const subTotal = Object.values(pricesRecord).reduce(
    (total, campaignClusterPrice) => total + campaignClusterPrice,
    0,
  );

  const DISCOUNTS_LIST = Object.values(discountsRecord).filter(isNonNullable);

  const TOTAL_DISCOUNT = DISCOUNTS_LIST.map((discount) =>
    discount ? discount.discountValue : 0,
  ).reduce((total, campaignDiscount) => total + campaignDiscount, 0);

  const TOTAL_PRICE = subTotal - TOTAL_DISCOUNT;

  return { TOTAL_PRICE, TOTAL_DISCOUNT, DISCOUNTS_LIST };
};

/**
 * Filter the data of a store campaign to only show:
 * - The total price of the campaign's schedules in this store's locations
 * - The total discount applied to the campaign's schedules in this store's locations
 * - The screen clusters selected in the campaign that belong to the store's locations
 * @param campaignData
 * @param storeScreenClusters
 */
export const filterStoreCampaignData = (
  campaignData: Campaign,
  storeScreenClusters: ScreenCluster[],
): Campaign => {
  const [mappedCampaign] = [campaignData]
    .map((campaign) => filterCampaignPrice(campaign, storeScreenClusters))
    .map((campaign) =>
      filterCampaignClusterSchedules(campaign, storeScreenClusters),
    )
    .map((campaign) => filterCampaignClusters(campaign, storeScreenClusters));
  return mappedCampaign;
};

/**
 * Filter the data of store campaigns to show only relative data to his locations
 * @param campaignTuples
 * @param storeScreenClusters
 */
export const filterStoreCampaignsData = async (
  campaignTuples: CampaignTuple[],
  storeScreenClusters: ScreenCluster[],
): Promise<CampaignTuple[]> => {
  return campaignTuples.map(([campaign, campaignRef]) => {
    const mappedCampaign = filterStoreCampaignData(
      campaign,
      storeScreenClusters,
    );
    return [mappedCampaign, campaignRef] as CampaignTuple;
  });
};

export const filterCampaignPrice = (
  campaign: Campaign,
  storeScreenClusters: ScreenCluster[],
): Campaign => {
  if (!campaign.PAYMENT_BILL) {
    return campaign;
  }

  const {
    CLUSTERS_PRICES_RECORD: clustersPricesRecord = {},
    CLUSTERS_DISCOUNTS_RECORD: clustersDiscountsRecord = {},
  } = campaign.PAYMENT_BILL;

  const storeScreenClustersIds = storeScreenClusters.map(({ ID }) => ID);

  const filteredCampaignPricesRecord = filterRecordByKeys(
    clustersPricesRecord,
    storeScreenClustersIds,
  );
  const filteredCampaignDiscountsRecord = filterRecordByKeys(
    clustersDiscountsRecord,
    storeScreenClustersIds,
  );
  const paymentBill = calculateCampaignPriceByPriceRecords(
    filteredCampaignPricesRecord,
    filteredCampaignDiscountsRecord,
  );

  return {
    ...campaign,
    CLUSTERS_PRICES_RECORD: filteredCampaignPricesRecord,
    CLUSTERS_DISCOUNTS_RECORD: filteredCampaignDiscountsRecord,
    PAYMENT_BILL: { ...campaign.PAYMENT_BILL, ...paymentBill },
  };
};

export const filterCampaignClusterSchedules = (
  campaign: Campaign,
  storeScreenClusters: ScreenCluster[],
): Campaign => {
  const { SELECTED_CLUSTERS_SCHEDULES: selectedClustersSchedules } = campaign;
  const storeScreenClustersIds = storeScreenClusters.map(({ ID }) => ID);

  const filteredClustersSchedules = filterRecordByKeys(
    selectedClustersSchedules,
    storeScreenClustersIds,
  );

  return {
    ...campaign,
    SELECTED_CLUSTERS_SCHEDULES: filteredClustersSchedules,
  };
};

export const filterCampaignClusters = (
  campaign: Campaign,
  storeScreenClusters: ScreenCluster[],
): Campaign => {
  const selectedScreenClustersIds = Object.keys(
    campaign.SELECTED_CLUSTERS_SCHEDULES,
  );
  const filteredScreenClusters = storeScreenClusters.filter(({ ID }) =>
    selectedScreenClustersIds.includes(ID),
  );
  return { ...campaign, SCREEN_CLUSTERS: filteredScreenClusters };
};

export const getCompanyNameOfCampaign = (
  campaign: Campaign,
  companyProfiles: CompanyProfile[],
): string => {
  const { ADVERTISER_UID: userId } = campaign;
  const [userCompanyProfile] = companyProfiles.filter(
    (item: CompanyProfile) => item.USER_ID === userId,
  );
  return userCompanyProfile ? userCompanyProfile.COMPANY_NAME : '-';
};

const isDatePassed = (date: string): boolean => {
  try {
    const now = moment();
    const momentDate = moment(date);
    return now.diff(momentDate, 'seconds') >= 0;
  } catch (error) {
    return false;
  }
};

export const isScheduleStarted = ({
  START_DATE: startDate,
}: Schedule): boolean => {
  return isDatePassed(startDate);
};

export const isScheduleFinished = ({
  END_DATE: endDate,
}: Schedule): boolean => {
  return isDatePassed(endDate);
};

export const isScheduleRunning = (schedule: Schedule) => {
  return isScheduleStarted(schedule) && !isScheduleFinished(schedule);
};

export const getCampaignStartDate = ({
  SELECTED_CLUSTERS_SCHEDULES: selectedClustersSchedules = {},
  START_DATE: startDate,
}: Campaign | CampaignFormData) => {
  if (startDate && startDate instanceof firestore.Timestamp) {
    return new Date(startDate.seconds * 1000);
  }

  const schedules = flatten(Object.values(selectedClustersSchedules));

  if (schedules && schedules.length) {
    const [{ START_DATE: campaignStartDate } = {} as Campaign] = schedules.sort(
      ({ START_DATE: leftStartDate }, { START_DATE: rightStartDate }) =>
        new Date(leftStartDate).valueOf() - new Date(rightStartDate).valueOf(),
    );

    return new Date(campaignStartDate as string);
  }

  return new Date(startDate as string);
};

export const getCampaignDuration = ({
  SELECTED_CLUSTERS_SCHEDULES: selectedClustersSchedules,
  DURATION_IN_WEEKS: duration,
}: Campaign | CampaignFormData) => {
  if (duration) {
    return duration;
  }

  // TODO: Adjust the logic to support different number of selected weeks per location
  const [selectedSchedules] = Object.values(selectedClustersSchedules);
  return selectedSchedules.length;
};

export const stringifyScreenClustersNames = (
  screenClusters: ScreenCluster[],
  appLocale: string,
) =>
  screenClusters
    .map(
      (screenCluster: ScreenCluster) => screenCluster.DISPLAY_NAME[appLocale],
    )
    .join(', ');

export async function getCampaignSelectedAdSpaces(
  campaign: Campaign,
): Promise<AdSpace[]> {
  const campaignClustersIds = getCampaignScreenClusterIds(campaign);
  return getAdSpacesOfScreenClusters(campaignClustersIds);
}

export const getCampaignScreenClusterIds = (campaign: Campaign): string[] => {
  const { SCREEN_CLUSTERS_IDS } = campaign;
  return SCREEN_CLUSTERS_IDS && SCREEN_CLUSTERS_IDS.length
    ? SCREEN_CLUSTERS_IDS
    : [];
};

export async function getAdSpacesOfScreenClusters(
  screenClusterIds: string[],
): Promise<AdSpace[]> {
  const screenClusterRefs = screenClusterIds.map((clusterId: string) =>
    FirebaseAppFirestore
      .collection(firebaseNames.SCREEN_CLUSTERS.VAL)
      .doc(clusterId),
  );
  return (await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .where('SCREEN_CLUSTER_REF', 'in', screenClusterRefs)
    .get())
    .docs.map((doc) => doc.data() as AdSpace);
}

export function findFirstMatchingMediaResolution<T extends MediaFile | CampaignMedia>(mediaList: T[], resolution: Partial<T>): Nullable<T> {
  return mediaList.find((media) =>  isMediaMatchingResolution(media, resolution));
}

export function findAllMatchingMediaResolution<T extends MediaFile | CampaignMedia>(mediaList: T[], resolution: Partial<T>): T[] {
  return mediaList.filter((media) => isMediaMatchingResolution(media, resolution));
}

function isMediaMatchingResolution<T extends MediaFile | CampaignMedia>(media1: T, media2: T): boolean {
  if (isCampaignMedia(media1)) {
    return (media1 as CampaignMedia).WIDTH === (media2 as CampaignMedia).WIDTH
      && (media1 as CampaignMedia).HEIGHT === (media2 as CampaignMedia).HEIGHT;
  } else {
    return (media1 as MediaFile).width === (media2 as MediaFile).width
      && (media1 as MediaFile).height === (media2 as MediaFile).height;
  }
}

function isCampaignMedia(media: MediaFile | CampaignMedia): media is CampaignMedia {
  return Boolean( (media as CampaignMedia).WIDTH && (media as CampaignMedia).HEIGHT);
}

