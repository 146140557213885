import { GetterTree } from 'vuex';
import { MyDeviceState } from './MyDeviceTypes';
import { RootState } from '@/app/shared/store/RootState';
import { DocumentReference } from '@firebase/firestore-types';

export const getters: GetterTree<MyDeviceState, RootState> = {
  getCurrentStoreDeviceRef: (state: MyDeviceState): DocumentReference => {
    return state.currentStoreDeviceRef!;
  },
};
