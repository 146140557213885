

























import { Component, Vue } from 'vue-property-decorator';

import { createScreenPhotoUploadTaskAction } from '@adminDevices/shared/actions';
import UploadFiles from '@/app/shared/components/UploadFiles.vue';
import { Nullable } from '@/app/shared/utilities/static-types';
import firebase from 'firebase';
import storage = firebase.storage;

@Component({
  components: {
    UploadFiles,
  },
})
export default class ScreenPhotoPopup extends Vue {
  public screenId = '';

  public show = false;

  public uploadTaskRef: Nullable<storage.Reference> = null;
  public uploadTask: Nullable<storage.UploadTask> = null;
  public uploadFilesRules = [];
  public uploadFileErrors: string | string[] = [];
  public uploading = false;
  public uploadFilesHint = `Click <v-icon>backup</v-icon> to upload files.`;
  public uploadProgress = 0;
  public photoUrl: Nullable<string> = null;

  private resolve!: (photoUrl?: string) => any;
  private reject!: (error: Error) => any;

  private photoFile: any = null;

  public open(screenId: string): Promise<any> {
    this.screenId = screenId;
    this.show = true;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  public async onUploadStart() {
    try {
      this.uploading = true;
      if (!this.photoFile) {
        throw new Error('You must select a file to upload.');
      }
      this.uploadTaskRef = await createScreenPhotoUploadTaskAction(
        this.screenId,
      );
      this.uploadTask = this.uploadTaskRef!.put(this.photoFile);
      this.uploadTask.on(
        storage.TaskEvent.STATE_CHANGED,
        this.fileUploadObserver,
        (error: Error) => {
          throw error;
        },
        this.fileUploadComplete,
      );
    } catch (error) {
      this.uploadFileErrors = error.message;
      this.uploading = false;
    }
  }

  public fileUploadObserver(snapshot: storage.UploadTaskSnapshot) {
    this.uploadProgress =
      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  }

  public async fileUploadComplete() {
    this.photoUrl =
      this.uploadTask && (await this.uploadTask.snapshot.ref.getDownloadURL());
    const meta = await this.uploadTaskRef!.getMetadata();
    // @ts-ignore
    this.uploadFilesHint = this.$t('file_uploaded_successfully').toString();
    this.uploading = false;
  }

  public onFilesPicked(files: any[]) {
    this.uploadFilesHint = '';
    this.uploadProgress = 0;
    this.uploadFileErrors = [];
    this.photoFile = files[0];
  }

  private onYes() {
    this.show = false;
    this.resolve(this.photoUrl!);
  }

  private onNo() {
    this.show = false;
    this.reject(new Error('Aborted'));
  }
}
