import { RouteConfig } from 'vue-router';

import FileMgmt from '@files/screens/FileUpload/FileMgmt.vue';
import routesNames from '@/app/shared/utilities/routes-names';
import { USER_ROLES } from '@/app/shared/utilities/object-factory';

export default [
  {
    ...routesNames.FILE_MANAGEMENT,
    path: 'file-management',
    component: FileMgmt,
    meta: {
      roles: [
        USER_ROLES.ADVERTISER,
        USER_ROLES.STORE_OWNER,
        USER_ROLES.ADMIN,
      ],
    },
  },
] as RouteConfig[];
