




















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CountdownTimer extends Vue {
  @Prop({ required: true }) public endDate!: Date;
  @Prop(Boolean) public center!: boolean;

  public timeout = 0;
  public timerId = 0;

  public created() {
    this.startCountDown();
  }

  public updated() {
    this.startCountDown();
  }

  public startCountDown() {
    if (!this.timerId) {
      const reservedUntil = this.endDate.toString();
      const now = new Date().toString();

      this.timeout = Date.parse(reservedUntil) - Date.parse(now);
      this.startTimer();
    }
  }

  public startTimer() {
    this.timerId = setInterval(() => {
      if (this.timeout > 0) {
        this.timeout -= 1000;
      }
    }, 1000);
  }

  public formatTimeRemaining(timeLeft: number) {
    const seconds = Math.floor((timeLeft / 1000) % 60);
    const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
    const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
    const days = Math.ceil(timeLeft / (1000 * 60 * 60 * 24));

    const localSeconds = this.$t('sec');
    const localMinutes = this.$t('min');
    const localHours = this.$t('hr');
    return days > 1 ? `${days} ${this.$t('days')}` : `${this.localizeTime(hours)}${localHours} : ${this.localizeTime(minutes)}${localMinutes} : ${this.localizeTime(seconds)}${localSeconds}`;
  }

  public get appLocale() {
    return this.$root.$i18n.locale;
  }

  public localizeTime(time: number) {
    switch (this.appLocale) {
      case 'en': {
        return time.toLocaleString('en-US').padStart(2, '0');
      }

      case 'ar': {
        return time.toLocaleString('ar-SA').padStart(2, '٠');
      }
    }
  }

  public destroyed() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }
}
