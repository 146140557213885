































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import {
  AdSpace,
  Campaign,
  CampaignMedia,
  MediaFile,
  MediaResolution,
  MediaType,
  Nullable,
} from '@/app/shared/utilities/static-types';
import PopupMessage from '@/app/shared/components/PopupMessage.vue';
import PreviewThumbnail from './PreviewThumbnail.vue';
import MediaUpload from './MediaUpload.vue';
import { updateUserCampaignAction } from '../actions';
import { uploadFileForCampaigns } from '@/app/screens/App/shared/actions';
import { checkMediaResolution } from '@/app/shared/utilities/files';
import {
  convertFromMediaResolutionTypeToScreenResolutionType, getMediaName,
  USER_TYPES_ABBREVIATION,
} from '@/app/shared/utilities/object-factory';
import { errors } from '@/app/shared/config/errors';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
import { getUserById } from '@adminRoles/shared/actions';
@Component({
  components: {
    MediaUpload,
    PopupMessage,
    PreviewThumbnail,
  },
})
export default class ChangeCampaignMedia extends Vue {

  private static async uploadMedia(selectedFile: File, mediaId: MediaFile['mediaId']): Promise<string | undefined> {
    const uploadTaskRef = await uploadFileForCampaigns(selectedFile, mediaId);
    const taskSnapshot = await uploadTaskRef.put(selectedFile);
    return taskSnapshot && (await taskSnapshot.ref.getDownloadURL());
  }
  @Prop({ required: true }) public campaign!: Campaign;
  @Prop() public campaignAdSpace!: AdSpace;
  @Prop() public media!: string;

  public changingMedia: boolean = false;
  public snackbar: boolean = false;
  public snackbarMsg: string = '';
  public campaignNewMediaPreview: Nullable<string> = null;
  public showMediaChangeConfirmationMessage: (() => Promise<any>) | null = null;
  public mediaId = uuidv4();

  public selectMedia() {
    const mediaUpload = this.$refs.mediaUpload as MediaUpload;
    const uploadFileInput = mediaUpload.$refs.uploadFileRef as HTMLInputElement;
    uploadFileInput.click();
  }

  public async checkResolution(selectedFile: File) {
    if (this.campaignAdSpace) {
      const resolution = convertFromMediaResolutionTypeToScreenResolutionType(this.campaignAdSpace.SCREENS_RESOLUTION);
      return await checkMediaResolution(selectedFile, resolution);
    }
  }

  public async updateMediaList(selectedFile: File, mediaResolution: MediaResolution, mediaFile: string) {
    let mediaList: CampaignMedia[] = [];
    if (!this.campaign.MEDIA_SKIPPED) {

      mediaList = this.campaign.MEDIA_LIST!.filter((media) => media.AD_SPACE_ID !== this.campaignAdSpace.ID);
    }
    const advertiser = await getUserById(this.campaign.ADVERTISER_UID);
    const { COMPANY_NAME } = advertiser || {};

    mediaList.push({
      AD_SPACE_ID: this.campaignAdSpace.ID,
      MEDIA_FILE: mediaFile! || '',
      MEDIA_TYPE: selectedFile.type as MediaType,
      WIDTH: mediaResolution!.width,
      HEIGHT: mediaResolution!.height,
      MEDIA_ID: this.mediaId,
      NAME: getMediaName({
        userTypeAbbreviation: USER_TYPES_ABBREVIATION.admin,
        companyName: COMPANY_NAME || '',
        campaignName: this.campaign.NAME,
        mediaId: this.mediaId,
        extension: selectedFile.name.split('.').pop() || '',
      }),
      MEDIA_UPLOADED_AT: firebase.firestore.Timestamp.now(),
    });
    const updatedCampaign = cloneDeep(this.campaign);
    updatedCampaign.MEDIA_LIST = [...mediaList];
    updatedCampaign.mediaFile = mediaFile! || '';
    await updateUserCampaignAction(this.campaign, { MEDIA_LIST: [...mediaList] });
    return updatedCampaign;
  }
  @Emit('update:campaign')
  public async changeCampaignMedia(selectedFile: File): Promise<Campaign | null> {
    try {
      const mediaResolution = await this.checkResolution(selectedFile);
      this.campaignNewMediaPreview = URL.createObjectURL(selectedFile);
      await this.showMediaChangeConfirmationMessage!();
      this.changingMedia = true;
      const mediaFile = await ChangeCampaignMedia.uploadMedia(selectedFile, this.mediaId);
      if (this.campaignAdSpace) {
        return await this.updateMediaList(selectedFile, mediaResolution as MediaResolution, mediaFile as string);
      }
      const updatedCampaignData = {
        MEDIA_FILE: mediaFile!,
        MEDIA_TYPE: selectedFile.type as MediaType,
      };
      const updatedCampaign = {
        ...this.campaign,
        ...updatedCampaignData,
      };
      await updateUserCampaignAction(this.campaign, updatedCampaignData);
      return updatedCampaign;
    } catch (error) {
      if (error && error.message === errors.MEDIA_RESOLUTION_NOT_MATCH) {
        this.snackbar = true;
        this.snackbarMsg = this.$t('media_resolution_error').toString();
      }
      return null;
    } finally {
      this.changingMedia = false;
    }
  }
}
