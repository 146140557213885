






import { Component, Vue, Prop } from 'vue-property-decorator';
import { MyDevice } from '@/app/shared/utilities/static-types';

import MyDeviceItemContent from './MyDeviceItemContent.vue';
import ViewableListItem from '../ViewableListItem.vue';

@Component({
  components: {
    MyDeviceItemContent,
    ViewableListItem,
  },
})
export default class ViewableDeviceItem extends Vue {
  @Prop({ required: true }) public device!: MyDevice;
}
