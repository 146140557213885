

















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { Campaign, Nullable } from '@/app/shared/utilities/static-types';
import CampaignItemContent from '@userApp/shared/components/CampaignItemContent.vue';
import ClickableListItem from '@userApp/shared/components/ClickableListItem.vue';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import CampaignInfoPopup from '@/app/screens/App/screens/Campaigns/shared/components/CampaignInfoPopup.vue';
import routesNames from '@/app/shared/utilities/routes-names';
import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import namespace from '@/app/shared/store/namespace';

@Component({
  components: {
    CampaignItemContent,
    ClickableListItem,
    CampaignInfoPopup,
  },
})
export default class ClickableCampaignItem extends Vue {
  @Prop({ required: true }) public campaign!: Campaign;
  @Prop({ required: false, default: false }) public isCampaignOwner!: boolean;

  public selectedCampaign: Nullable<Campaign> = null;
  public reviewCampaignRoute = routesNames.CAMPAIGN_REVIEW;
  public editCampaignRoute = routesNames.STORE_CAMPAIGN_EDIT;

  public showCampaignMediaPopup(event: MouseEvent): void {
    const input = event.target as HTMLInputElement;
    if (input.nodeName.toLowerCase() !== 'input') {
      this.selectedCampaign = this.campaign;
      (this.$refs.campaignMediaPopup as CampaignInfoPopup).open();
    }
  }

  public showPrice(campaign: Campaign) {
   return campaign.APPROVED_BY_ADMIN && campaign.APPROVED_BY_STORE_OWNER;
  }

  public handleClick(event: MouseEvent) {
    return this.isCampaignEditable
      ? this.editCampaign()
      : this.isCampaignPendingApproval
      ? this.reviewCampaign()
      : this.showCampaignMediaPopup(event);
  }

  public get isCampaignEditable() {
    try {
      const { STATUS: { VAL: status } } = this.campaign;
      return (status === SYSTEM_STATUS.REQUIRES_ACTION.VAL || status === SYSTEM_STATUS.NEW.VAL)
      && this.isCampaignOwner;
    } catch (error) {
      return false;
    }
  }

  public get canChangeMedia() {
    try {
      const { STATUS: { VAL: status }, APPROVED_BY_ADMIN } = this.campaign;
      return status === SYSTEM_STATUS.UNDER_PREPARATION.VAL && !APPROVED_BY_ADMIN && this.isCampaignOwner;
    } catch (error) {
      return false;
    }
  }

  public get isCampaignPendingApproval() {
    try {
      return this.campaign.APPROVED_BY_ADMIN && !this.campaign.APPROVED_BY_STORE_OWNER;
    } catch (error) {
      return false;
    }
  }

  public reviewCampaign(): void {
    const { ID: id } = this.campaign;
    this.setCampaignRef();
    this.$router.push({ ...this.reviewCampaignRoute , params: { id } });
  }

  private get campaignRef() {
    const { ADVERTISER_UID, ID } = this.campaign;
    return FirebaseAppFirestore
      .collection(firebaseNames.CAMPAIGNS.VAL)
      .doc(ADVERTISER_UID)
      .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
      .doc(ID);
  }

  private setCampaignRef() {
    this.$store.commit(
      `${namespace.CampaignModule}/setCurrentStoreCampaignRef`,
      this.campaignRef,
    );
  }

  private editCampaign() {
    const { ID: id } = this.campaign;
    this.$router.push({ ...this.editCampaignRoute , params: { id }});
  }
}
