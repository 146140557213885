import { Route, RawLocation } from 'vue-router';

import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import { isAdminUserAction } from '@/app/shared/actions/UserRolesActions';

import routesNames, { AdminRoutes } from '@/app/shared/utilities/routes-names';

/**
 * Used for Route level authentication.
 */
export default async (to: Route, from: Route, next: (to?: RawLocation) => void) => {
  try {
    const currentUser = await getCurrentUser();
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    if (requiresAuth && !currentUser) {
      const currentPath = to.path;
      next({
        ...routesNames.LOGIN,
        query: { redirect: currentPath },
      });
    } else {
      next();
    }
  } catch (error) {
    next({
      ...routesNames.ACCESS_DENIED,
    });
    throw new Error(`On Auth Guard, ${error.message}`);
  }
};

/**
 * Used to navigate to '/dashboard' if the User already Logged in.
 * @param to
 * @param from
 * @param next
 */
export const publicPageGuard = async (to: Route, from: Route, next: (to?: RawLocation) => void) => {
  try {
    const currentUser = await getCurrentUser();
    const admin = await isAdminUserAction();
    if (currentUser) {
      const continueTo = admin ? AdminRoutes.ADMIN_DASHBOARD : routesNames.DASHBOARD;
      next({
        ...continueTo,
      });
    } else {
      next();
    }
  } catch (error) {
    next({
      ...routesNames.ACCESS_DENIED,
    });
    throw new Error(`On Public Page Guard, ${error.message}`);
  }
};
