import { ScreenSaver, ScreenServerOrderBy } from '@/app/shared/utilities/static-types';
import { FirebaseAppFirestore, FirebaseAppStorage } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import {
  Schedule,
  MyDevice,
  RegisteredPlayer,
  AdSpace,
  TimeSlot,
  ScreenCluster,
  Campaign,
  CampaignMediaFile,
} from '@/app/shared/utilities/static-types';
import { DocumentReference, DocumentSnapshot, WriteBatch } from '@firebase/firestore-types';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import { SYSTEM_STATUS, campaignDefaultNameFactory, SUPPORTED_TYPES } from '@/app/shared/utilities/object-factory';
import moment from 'moment';
import i18n from '@/i18n';
import { getDevicesInfoByClusterId } from '@userApp/shared/actions';
import { getAdSpaceByCluster } from '@adminDevices/shared/actions';

/**
 * Uploads the screen saver photo.
 */
export const createScreenSaverUploadTaskAction = async () => {
  return FirebaseAppStorage.ref(
    firebaseNames.MEDIA_FILES.FOR_SCREEN_SAVER,
  );
};

/**
 * Save screen saver document.
 * @param imageUrl
 */
export const saveScreenSaverAction = async (imageUrl: string) => {
  await FirebaseAppFirestore
    .collection(firebaseNames.SCREEN_SAVER.VAL)
    .doc(firebaseNames.SCREEN_SAVER.SCREEN_SAVER_DOC)
    .set({ IMAGE_URL: imageUrl });
};

/**
 * Load the screen saver image.
 */
export const loadScreenSaverAction = async () => {
  const snap = await FirebaseAppFirestore
    .collection(firebaseNames.SCREEN_SAVER.VAL)
    .orderBy(ScreenServerOrderBy.ORDER)
    .get();
  return snap.docs.map((screenSaverQueryDocSnapshot) => screenSaverQueryDocSnapshot.data() as ScreenSaver);
};

/**
 * Copies the schedule reference into all registered players.
 * @param screenClusterId
 * @param scheduleId
 */
export const scheduleScreenClusterPlayers = async (screenClusterId: string, scheduleId: string ) => {
  const devicesInfo = await getDevicesInfoByClusterId(screenClusterId);
  const devicesRefs = devicesInfo.map((info) => info.DEVICE_REF);

  const clusterAdSpace = await getAdSpaceByCluster(screenClusterId);

  const scheduleRef = FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(clusterAdSpace.ID)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.VAL)
    .doc(scheduleId);

  const batch = FirebaseAppFirestore.batch();
  await Promise.all(
    devicesRefs.map(async (deviceRef) => {
      const device = (await deviceRef.get()).data() as MyDevice;
      const playerId = device.REGISTERED_PLAYER_ID;
      batch.update(
        FirebaseAppFirestore
          .collection(firebaseNames.REGISTERED_PLAYERS)
          .doc(playerId),
        { SCHEDULE_REF: scheduleRef } as RegisteredPlayer,
      );
    }),
  );
  await batch.commit();
};

/**
 * Load all Ad Spaces.
 */
export const loadAdSpacesAction = async (): Promise<AdSpace[]> => {
  const snap = await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .get();
  return snap.docs.map((doc: DocumentSnapshot) => doc.data() as AdSpace);
};

/**
 * Load schedules for Ad Space.
 */
export const loadSchedulesByAdSpaceAction = async (adSpaceId: string) => {
  const snap = await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(adSpaceId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.VAL)
    .get();
  return snap.docs.map((doc: DocumentSnapshot) => doc.data() as Schedule);
};

/**
 * Load schedule's timeslots by schedule ID, and Ad Space Id.
 * @param scheduleId
 * @param adSpaceId
 */
export const loadScheduleTimeslotsAction = async (
  scheduleId: string,
  adSpaceId: string,
) => {
  const timeslotSnap = await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(adSpaceId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.VAL)
    .doc(scheduleId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.TIME_SLOTS)
    .get();
  return timeslotSnap.docs
    .map((doc) => doc.data() as TimeSlot)
    .sort((a: TimeSlot, b: TimeSlot) => {
      return a.PLAY_ORDER - b.PLAY_ORDER;
    });
};

/**
 * Update a timeslot object in a schedule for an Ad Space.
 * @param adSpaceId
 * @param scheduleId
 * @param timeslot
 */
export const updateScheduleTimeslotAction = async (
  adSpaceId: string,
  scheduleId: string,
  timeslot: TimeSlot,
) => {
  await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(adSpaceId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.VAL)
    .doc(scheduleId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.TIME_SLOTS)
    .doc(timeslot.ID)
    .update(timeslot);
};

export const getFreeTimeSlotsCountAction = (timeSlots: TimeSlot[], type: string): number => {
  const factor = type === 'delete' ? -1 : 1;
  const freeTimeSlots = [...timeSlots].filter(
    (freeSlotItem: any) => !freeSlotItem.RESERVED,
  );
  return freeTimeSlots.length + factor;
};

/**
 * Update free time slots count in schedule.
 * @param batch
 * @param adSpaceId
 * @param scheduleId
 * @param count
 */
export const updateFreeTimeSlotsCountAction = (
  batch: WriteBatch,
  adSpaceId: string,
  scheduleId: string,
  count: number,
): void => {
  const scheduleRef = FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(adSpaceId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.VAL)
    .doc(scheduleId);
  batch.update(scheduleRef, 'FREE_SLOTS_COUNT', count);
};

const generateNewtimeSlot = (
  timeSlotRef: DocumentReference,
  playOrder: number,
): TimeSlot => {

  const { id: ID } = timeSlotRef;
  const PLAY_ORDER = playOrder;
  const RESERVED = false;
  const DURATION_SECS = 10;
  return { ID, PLAY_ORDER, RESERVED, DURATION_SECS };
};

/**
 * Create new time slot in specific ad space and schedule.
 * @param batch
 * @param adSpaceId
 * @param scheduleId
 * @param playOrder
 */
export const createTimeSlotAction = async (
  batch: WriteBatch,
  adSpaceId: string,
  scheduleId: string,
  playOrder: number,
) => {
  const newTimeSlotRef = FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(adSpaceId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.VAL)
    .doc(scheduleId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.TIME_SLOTS)
    .doc();
  const newTimeSlot = generateNewtimeSlot(newTimeSlotRef, playOrder);
  batch.set(newTimeSlotRef, newTimeSlot);
  return newTimeSlot;
};

/**
 * delete time slot in specific ad space and schedule.
 * @param batch
 * @param adSpaceId
 * @param scheduleId
 * @param timeSlotId
 */
export const deleteTimeSlotAction = async (
  batch: WriteBatch,
  adSpaceId: string,
  scheduleId: string,
  timeSlotId: string,
): Promise<void> => {
  const timeSlotRef = FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(adSpaceId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.VAL)
    .doc(scheduleId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.TIME_SLOTS)
    .doc(timeSlotId);
  batch.delete(timeSlotRef);
};

/**
 * Filter time slot to get slot below the deleted one in order.
 * @param timeSlots
 * @param index
 */
const getChangedTimeSlots = (
  timeSlots: TimeSlot[],
  index: number,
): TimeSlot[] => {
  return timeSlots.filter((_, i) => i > index);
};

/**
 * Shift up time slots below the deleted time slot in order.
 * @param timeSlots
 * @param index
 */
export const shiftTimeSlotsUpAction = async (
  timeSlots: TimeSlot[],
  index: number,
): Promise<TimeSlot[]> => {
  const changedTimeSlots = getChangedTimeSlots(timeSlots, index);
  return changedTimeSlots.map((item: TimeSlot) => {
    item.PLAY_ORDER -= 1;
    return item;
  });
};

/**
 * Update changed timeSlots.
 * @param batch
 * @param adSpaceId
 * @param scheduleId
 * @param timeSlots
 */
export const updateTimeSlotsAction = async (
  batch: WriteBatch,
  adSpaceId: string,
  scheduleId: string,
  timeSlots: TimeSlot[],
): Promise<void> => {
  const timeSlotCollectionRef = FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(adSpaceId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.VAL)
    .doc(scheduleId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.TIME_SLOTS);
  timeSlots.forEach((updatedTimeSlot: TimeSlot) => {
    const { ID } = updatedTimeSlot;
    const timeSlotRef = timeSlotCollectionRef.doc(ID);
    batch.set(timeSlotRef, updatedTimeSlot);
  });
};

/**
 * Create a new campaign instead of canceled advertiser campaign.
 * @param campaign Input campaign
 */
const createCampaignRefForAdvertiser = async (campaign: Campaign): Promise<DocumentReference> => {
  const currentUserId = campaign.ADVERTISER_UID;
  const campaignRef = FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(currentUserId)
    .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
    .doc();
  return campaignRef;
};

/**
 * Create a new campaign from scratch by admin.
 * @param campaign Input campaign
 */
const createCampaignRefForAdminFromScratch = async (): Promise<DocumentReference> => {
  const currentUser = await getCurrentUser();
  const currentUserId = currentUser!.uid;
  const campaignRef = FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(currentUserId)
    .collection(firebaseNames.CAMPAIGNS.ADMIN_CAMPAIGNS)
    .doc();
  return campaignRef;
};

/**
 * Create a new campaign for time slot.
 * @param campaign Input campaign
 */
export const createCampaignForTimeSlotAction = async (campaign: Campaign): Promise<DocumentReference> => {
  const isCampaignCreatedForAdvertiser = Boolean(campaign.ADVERTISER_UID);
  return isCampaignCreatedForAdvertiser
    ? await createCampaignByAdvertiserCampaignCloneAction(campaign)
    : await createAdminCampaignAction(campaign);
};

/**
 * Create a clone campaign of Advertiser's campaign data.
 * @param campaign Input campaign
 */
export const createCampaignByAdvertiserCampaignCloneAction = async (campaign: Campaign): Promise<DocumentReference> => {
  const createdCampaignRef = await createCampaignRefForAdvertiser(campaign);
  campaign.ID = createdCampaignRef.id;
  await createdCampaignRef.set(campaign as Campaign);
  return createdCampaignRef;
};

/**
 * Create a new campaign by the Admin.
 * @param campaign Input campaign
 */
export const createAdminCampaignAction = async (campaign: Campaign): Promise<DocumentReference> => {
  const createdCampaignRef = await createCampaignRefForAdminFromScratch();
  const { uid } = (await getCurrentUser())!;
  campaign.ID = createdCampaignRef.id;
  await createdCampaignRef.set({
    ...campaign,
    ADVERTISER_UID: uid,
    APPROVED_BY_STORE_OWNER: true,
    APPROVED_BY_ADMIN: true,
    STATUS: SYSTEM_STATUS.UNDER_PREPARATION,
  } as Campaign);
  return createdCampaignRef;
};

/**
 * Assign campaign to time slot.
 * @param adSpaceId
 * @param scheduleId
 * @param timeSlotId
 * @param campaignRef
 */
export const assignCampaignToTimeSlotAction = async (adSpaceId: string, scheduleId: string, timeSlotId: string, timeSlotData: TimeSlot) => {
  const timeSlotRef = FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(adSpaceId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.VAL)
    .doc(scheduleId)
    .collection(firebaseNames.AD_SPACES.SCHEDULES.TIME_SLOTS)
    .doc(timeSlotId);
  await timeSlotRef.set(timeSlotData);
};

/**
 * Assign campaign ref to store owner.
 * @param adSpaceId
 * @param campaignRef
 */
export const assignCampaignRefToStoreAction = async (adSpaceId: string, campaignRef: DocumentReference) => {
  const adSpaceSnap = await FirebaseAppFirestore
    .collection(firebaseNames.AD_SPACES.VAL)
    .doc(adSpaceId)
    .get();
  const adSpaceDoc = adSpaceSnap.data()!;
  const { SPACE_OWNER_ID: spaceOwnerId } = adSpaceDoc;
  const { id: CampaignId } = campaignRef;
  const storeCampaignRef = FirebaseAppFirestore
    .collection(firebaseNames.CAMPAIGNS.VAL)
    .doc(spaceOwnerId)
    .collection(firebaseNames.CAMPAIGNS.STORE_CAMPAIGNS)
    .doc(CampaignId);
  await storeCampaignRef.set({campaignRef});
};

/**
 * Cancel campaign.
 * @param campaignRef
 */
export const cancelCampaignAction = async (campaignRef: DocumentReference) => {
  await campaignRef.update('STATUS', SYSTEM_STATUS.CANCELED);
};

/**
 * Get campaign status.
 * @param campaign Input campaign
 */
const getCampaignStatus = (campaign: Campaign|null, scheduleStartDate: string) => {
  if (campaign) {
    return campaign.STATUS;
  }
  return hasScheduleStarted(scheduleStartDate)
    ? SYSTEM_STATUS.RUNNING
    : SYSTEM_STATUS.UNDER_PREPARATION;
};

/**
 * Check if schedule has started.
 * @param startDate
 */
const hasScheduleStarted = (startDate: string): boolean => {
  const momentNowDate = moment(new Date());
  const momentStartDate = moment(new Date(startDate));
  return Boolean(momentStartDate.diff(momentNowDate, 'days') < 0);
};

/**
 * Generate new campaign data.
 * @param adSpace
 * @param schedule
 * @param timeSlot
 * @param selectedMedia
 */
export const generateNewCampaignDataAction = async (
  screenCluster: ScreenCluster,
  schedule: Schedule,
  timeSlot: TimeSlot,
  selectedMedia: CampaignMediaFile,
): Promise<Campaign> => {
  const { ID: screenClusterId } = screenCluster;
  const { START_DATE: scheduleStartDate } = schedule!;
  const { CAMPAIGN: slotCampaignRef } = timeSlot;
  const currentSlotCampaign = slotCampaignRef
    ? (await slotCampaignRef!.get()).data() as Campaign
    : null;
  const appLanguage = i18n.locale;
  const user = await getCurrentUser();
  const NAME = await campaignDefaultNameFactory(user, appLanguage as 'ar' | 'en', true);
  const STATUS = getCampaignStatus(currentSlotCampaign, scheduleStartDate);
  const { STATIC_LINK: MEDIA_FILE, PLAYBACK_DURATION, FILE_TYPE: MEDIA_TYPE } = selectedMedia;
  const campaignData = {
    SELECTED_CLUSTERS_SCHEDULES: {
      [screenClusterId]: [schedule],
    },
    SCREEN_CLUSTERS: [screenCluster],
    SCREEN_CLUSTERS_IDS: [screenCluster.ID],
    NAME,
    ...(currentSlotCampaign || {}),
    TIME_SLOT_ID: timeSlot.ID,
    MEDIA_FILE,
    MEDIA_TYPE,
    CREATED_BY_ADMIN: true,
    RESERVED: true,
    STATUS,
  } as Campaign;

  if (MEDIA_TYPE === SUPPORTED_TYPES.MP4) {
    campaignData.MEDIA_PLAYBACK_DURATION = PLAYBACK_DURATION;
  } else {
    delete campaignData.MEDIA_PLAYBACK_DURATION;
  }

  return campaignData as Campaign;
};
export const getScreenSaverCount = async () => {
  const screenSaverSnapshot = await FirebaseAppFirestore
    .collection(firebaseNames.SCREEN_SAVER.VAL).get();
  return screenSaverSnapshot.size;
};

export const createScreenSaver = async (screenSaver: ScreenSaver) => {
  const screenSaverRef = FirebaseAppFirestore
    .collection(firebaseNames.SCREEN_SAVER.VAL).doc();
  screenSaver.ID = screenSaverRef.id;
  return screenSaverRef.set(screenSaver);
};
export const updateScreenSaver = async (screenSaver: ScreenSaver) => {
  return FirebaseAppFirestore
    .collection(firebaseNames.SCREEN_SAVER.VAL).doc(screenSaver.ID).update(screenSaver);
};

export const deleteScreenSaverAndReorder = async (screenSaver: ScreenSaver) => {
  await FirebaseAppFirestore
    .collection(firebaseNames.SCREEN_SAVER.VAL).doc(screenSaver.ID).delete();
  const screenSaversNeedsReorder = (await FirebaseAppFirestore
    .collection(firebaseNames.SCREEN_SAVER.VAL).where('ORDER', '>', screenSaver.ORDER).get()).docs.map((snap) => snap.data() as ScreenSaver);
  if (screenSaversNeedsReorder.length) {
    const batch = FirebaseAppFirestore.batch();
    screenSaversNeedsReorder.forEach((screenSaverItem) => {
      const ref = FirebaseAppFirestore.collection(firebaseNames.SCREEN_SAVER.VAL).doc(screenSaverItem.ID);
      batch.update(ref, { ORDER: screenSaverItem.ORDER - 1 });
    });
    return batch.commit();
  }
};
