

































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class PopupModal extends Vue {
  @Prop({ default: false, required: true }) public open!: boolean;
  @Prop() public title!: string;
  @Prop() public content!: string;
}
