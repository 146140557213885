




























































import { Component, Vue } from 'vue-property-decorator';

import { isAdminUserAction } from '@globalActions/UserRolesActions';
import { ADMIN_NAV_ITEMS } from '@/app/shared/utilities/navigation-factory';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';

@Component
export default class AdminSideDrawer extends Vue {
  // Roles
  public navigationItems = ADMIN_NAV_ITEMS;

  public userLoggedIn = false;
  public adminUser = false;

  public async created() {
    const user = await getCurrentUser();
    this.userLoggedIn = user ? true : false;
    this.adminUser = user ? await isAdminUserAction() : false;
  }
}
