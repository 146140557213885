import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import { FirebaseAppFirestore, FirebaseAppFunctions } from '@/app/shared/firebase/firebase-app';
import { USER_ROLES } from '@/app/shared/utilities/object-factory';
import { InvitationCode, Role, UserInfo } from '@/app/shared/utilities/static-types';
import firebaseNames from '@/app/shared/utilities/firebase-names';

/**
 * Gets roles for current user.
 */
export const getUserRolesAction = async (): Promise<Role[] | undefined> => {
  const currentUser = await getCurrentUser();
  if (!currentUser) {
    return undefined;
  }
  const docRef = await FirebaseAppFirestore
    .collection(firebaseNames.USERS_INFO)
    .doc(currentUser!.uid)
    .get();
  const docData = docRef.data();
  return docData && docData.ROLES;
};

export const getUserInfoAction = async (): Promise<UserInfo> => {
  const currentUser = await getCurrentUser();
  if (!currentUser) {
    throw new Error('User is not authenticated');
  }

  const docRef = await FirebaseAppFirestore
    .collection(firebaseNames.USERS_INFO)
    .doc(currentUser!.uid)
    .get();

  return docRef.data() as UserInfo;
};

/**
 * Check if current user is an ADMIN.
 */
export const isAdminUserAction = async (): Promise<boolean> => {
  const roles = await getUserRolesAction() || [];
  return roles.some((role) => USER_ROLES.ADMIN.VAL === role.VAL);
};


export const userHasSomeRolesAction = async (roles: Role[]): Promise<boolean> => {
  const userRoles = await getUserRolesAction() || [];
  const userRolesMap = userRoles.reduce((rolesMap: Record<string, Role>, role: Role) => {
    rolesMap[role.VAL] = role;
    return rolesMap;
  }, {});

  return roles.some((role) => userRolesMap[role.VAL]);
};

/**
 * Check if current user is a STORE_OWNER.
 */
export const isStoreOwnerAction = async (): Promise<boolean> => {
  const roles = await getUserRolesAction() || [];
  return roles.some((role) => USER_ROLES.STORE_OWNER.VAL === role.VAL);
};

/**
 * Check if current user is a SCREEN_OWNER.
 */
export const isScreenOwnerAction = async (): Promise<boolean> => {
  const roles = await getUserRolesAction() || [];
  return roles.some((role) => USER_ROLES.SCREEN_OWNER.VAL === role.VAL);
};

/**
 * Check if current user is an ADVERTISER.
 */
export const isAdvertiserAction = async (): Promise<boolean> => {
  const roles = await getUserRolesAction() || [];
  return roles.some((role) => USER_ROLES.ADVERTISER.VAL === role.VAL);
};

export const shouldUserReceiveInvitationCode = async (userId: string): Promise<InvitationCode | false> => {
  const checkoutCampaignFn = FirebaseAppFunctions
    .httpsCallable(firebaseNames.functions.SHOULD_RECEIVE_INVITATION);
  const { data } = await checkoutCampaignFn(userId);
  return data;
};
