import { getStorageDB } from './storage-app';
import { DB_STORAGE_APP_DATA } from '../shared/utilities/object-factory';

/**
 * Add new value to Storage.
 * @param key
 * @param value
 */
export const addValueToStorage = async (key: string, value: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const transaction = await getStorageTransaction('readwrite');
      const objectStore = transaction.objectStore(DB_STORAGE_APP_DATA);
      objectStore.add(value, key);
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = () => {
        reject(transaction.error);
      };
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Update existing value in Storage.
 * @param key
 * @param value
 */
export const updateValueInStorage = async (key: string, value: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const transaction = await getStorageTransaction('readwrite');
      const objectStore = transaction.objectStore(DB_STORAGE_APP_DATA);
      objectStore.put(value, key);
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = () => {
        reject(transaction.error);
      };
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Get stored value from storage.
 * @param key
 */
export const getValueFromStorage = async (key: string): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const transaction = await getStorageTransaction('readonly');
      const objectStore = transaction.objectStore(DB_STORAGE_APP_DATA);
      const request = objectStore.get(key);
      request.onerror = () => {
        reject(request.error);
      };
      request.onsuccess = () => {
        resolve(request.result);
      };
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Remove stored value from Storage.
 * @param key
 */
export const removeValueFromStorage = async (key: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const transaction = await getStorageTransaction('readwrite');
      const objectStore = transaction.objectStore(DB_STORAGE_APP_DATA);
      objectStore.delete(key);
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = () => {
        reject(transaction.error);
      };
    } catch (error) {
      reject(error);
    }
  });
};

async function getStorageTransaction(mode?: IDBTransactionMode) {
  const db = await getStorageDB();
  return db.transaction([DB_STORAGE_APP_DATA], mode);
}
