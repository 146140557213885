





























































































































import { Component, Vue } from 'vue-property-decorator';

import { publicPageGuard } from '@/app/shared/config/auth-guards';
import { isAdminUserAction } from '@/app/shared/actions/UserRolesActions';

import rules from '@/app/shared/validation-rules';
import * as loginActions from '@publicApp/shared/actions';
import routesNames, { AdminRoutes } from '@/app/shared/utilities/routes-names';

import FormComponent from '@/app/shared/components/FormComponent.vue';
import AppPublicLayout from '@publicApp/shared/components/AppPublicLayout.vue';
import { UserCredential } from '@firebase/auth-types';
import namespace from '@/app/shared/store/namespace';

@Component({
  beforeRouteEnter: publicPageGuard,
  components: {
    FormComponent,
    AppPublicLayout,
  },
  data: (vm: any) => ({
    emailRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.testEmailFormat(vm.$i18n.t('invalid_email')),
    ],
    passwordRules: [rules.required(vm.$i18n.t('field_required'))],
  }),
})
export default class Login extends Vue {
  public email = '';

  public password = '';
  public showPassword = false;

  public registerRoute = {
    ...routesNames.REGISTER,
  };

  public created() {
    const { new_campaign } = this.$router.currentRoute.query;
    if (new_campaign) {
      const savedCampaign = this.$store.getters[
        `${namespace.LandingModule}/getSavedCampaign`
      ];

      if (!savedCampaign) {
        this.$router.push(routesNames.HOME);
      }
    }
  }

  public async loginUser() {
    try {
      let userCredentials: UserCredential | undefined;
      try {
        userCredentials = await loginActions.loginUserAction(
          this.email,
          this.password,
        );
      } catch (error) {
        throw new Error(loginActions.getErrorMsgFromCode(error.code));
      }
      if (userCredentials!.user) {
        const redirectPath = this.$router.currentRoute.query.redirect as string;
        const admin = await isAdminUserAction();

        // If locally-saved campaign found...
        const savedCampaign = this.$store.getters[
          `${namespace.LandingModule}/getSavedCampaign`
        ];
        if (savedCampaign) {
          this.$router.push(routesNames.MY_CAMPAIGNS);
        } else if (redirectPath) {
          this.$router.push(redirectPath);
        } else {
          this.$router.push(admin ? AdminRoutes.ADMIN_DASHBOARD : routesNames.DASHBOARD);
        }
        await loginActions.addLoginTimestamp();
      } else {
        throw new Error('User does not exist.');
      }
    } catch (error) {
      throw Error(`Error while processing user login => ${error.message}`);
    }
  }

  public navigateToForgetPasswordPage(): void {
    this.$router.push(routesNames.FORGET_PASSWORD);
  }

  public close() {
    this.$router.push(routesNames.HOME);
  }
}
