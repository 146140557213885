




















































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

import { Schedule } from '@/app/shared/utilities/static-types';

import rules from '@/app/shared/validation-rules';

@Component({
  data: (vm: any) => ({
    percentageRules: [rules.onlyNumbers(vm.$i18n.t('only_numbers_allowed'))],
  }),
})
export default class SchedulesPricing extends Vue {
  @Prop({ required: true }) public schedules!: Schedule[];
  @Prop({ default: null }) public clusterPrice!: number;

  public selectedSchedules: number[] = [];
  public editingPercentage: { value: number; index: number } | null = null;

  public mounted() {
    this.schedulesMapper();
  }

  public setPrice(index: number) {
    this.$emit('set-price', index);
    this.schedulesMapper();
  }

  public selectedSchedule(index: number) {
    return this.selectedSchedules.includes(index);
  }

  public totalPrice(index: number): number {
    const { BASE_PRICE, PRICE_INCREASE_PERCENTAGE } = this.schedules[index];
    return BASE_PRICE + ((PRICE_INCREASE_PERCENTAGE / 100) * BASE_PRICE);
  }

  public schedulesMapper() {
    this.selectedSchedules = this.schedules
      .map((item, index) => {
        return {
          index,
          selected: item.BASE_PRICE === Number(this.clusterPrice),
        };
      })
      .filter((item) => item.selected)
      .map((item) => item.index);
  }

  @Watch('clusterPrice')
  public clusterPriceChange() {
    this.schedulesMapper();
  }
}
