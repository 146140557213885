






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  AdSpace,
  CampaignMedia,
  ScreenSaver,
} from '@/app/shared/utilities/static-types';
import PreviewThumbnail from '@campaigns/shared/components/PreviewThumbnail.vue';
import ViewScreenSaver from './ViewScreenSaver/ViewScreenSaver.vue';
import DeleteScreenSaver from './DeleteScreenSaver/DeleteScreenSaver.vue';
import { loadAdSpacesAction } from '@adminSchedules/shared/actions';
import {
  applyToAllAdSpacesAction,
  updateAdSpaceAction,
} from '@adSpaces/shared/actions';
import BlockingProgress from '@/app/shared/components/BlockingProgress.vue';
import {
  convertAdSpaceListToMap,
  convertScreenSaverMediaListToMap,
} from '@/app/shared/utilities/helper-functions';
import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';

@Component({
  name: 'ScreenSaverItem',
  components: {
    PreviewThumbnail,
    ViewScreenSaver,
    DeleteScreenSaver,
    BlockingProgress,
  },
})
export default class ScreenSaverItem extends Vue {
  @Prop() public screenSaver!: ScreenSaver;
  public showMedia = false;
  public showDeleteScreenSaver = false;
  public firstMedia!: CampaignMedia;

  public created() {
    [this.firstMedia] = this.screenSaver.MEDIA_LIST;
  }

  public updateScreenSaverActive() {
    this.$emit('active-changed', this.screenSaver.ID);
  }

  public screenSaverDeleted() {
    this.$emit('screen-saver-deleted');
  }

  public async applyScreenSaverToAllAdSpaces() {
    (this.$refs.progress as BlockingProgress).show();
    try {
      const adSpaces = await loadAdSpacesAction();

      const batch = FirebaseAppFirestore.batch();
      const screenSaverMap = convertScreenSaverMediaListToMap(
        this.screenSaver.MEDIA_LIST,
      );

      const adSpaceMap = convertAdSpaceListToMap(adSpaces);
      Object.entries(screenSaverMap).forEach(
        ([screenDimension, screenSaverMedia]) => {
          const adSpacesMatchesResolution = adSpaceMap[screenDimension];
          adSpacesMatchesResolution.forEach((adSpace) => {
            applyToAllAdSpacesAction(
              batch,
              adSpace,
              this.screenSaver,
              screenSaverMedia,
            );
          });
        },
      );

      await batch.commit();
    } finally {
      (this.$refs.progress as BlockingProgress).hide();
    }
  }
}
