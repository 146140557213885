


















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { Campaign, Nullable } from '@/app/shared/utilities/static-types';
import CampaignItemContent from '@userApp/shared/components/CampaignItemContent.vue';
import ClickableListItem from '@userApp/shared/components/ClickableListItem.vue';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import CampaignInfoPopup from '@/app/screens/App/screens/Campaigns/shared/components/CampaignInfoPopup.vue';
import routesNames from '@/app/shared/utilities/routes-names';

@Component({
  components: {
    CampaignItemContent,
    ClickableListItem,
    CampaignInfoPopup,
  },
})
export default class ClickableCampaignItem extends Vue {
  @Prop({ required: true }) public campaign!: Campaign;
  @Prop({ required: false, default: false }) public isCampaignOwner!: boolean;

  public selectedCampaign: Nullable<Campaign> = null;
  public advertiserEditCampaignRoute = routesNames.CAMPAIGN_EDIT;

  public showCampaignMediaPopup(event: MouseEvent): void {
    const input = event.target as HTMLInputElement;
    if (input.nodeName.toLowerCase() !== 'input') {
      this.selectedCampaign = this.campaign;
      (this.$refs.campaignMediaPopup as CampaignInfoPopup).open();
    }
  }

  public get isCampaignEditable() {
    try {
      const { STATUS: { VAL: status } } = this.campaign;
      return (status === SYSTEM_STATUS.REQUIRES_ACTION.VAL || status === SYSTEM_STATUS.NEW.VAL)
      && this.isCampaignOwner;
    } catch (error) {
      return false;
    }
  }

  public get canChangeMedia() {
    try {
      const { STATUS: { VAL: status }, SUBSCRIPTION, APPROVED_BY_ADMIN } = this.campaign;
      return (status === SYSTEM_STATUS.UNDER_PREPARATION.VAL && !APPROVED_BY_ADMIN)
          || SUBSCRIPTION;
    } catch (error) {
      return false;
    }
  }

  public handleClick(event: MouseEvent) {
    if (this.isCampaignEditable) {
      return this.editCampaign();
    }
    return this.showCampaignMediaPopup(event);
  }

  private editCampaign() {
    const { ID: id } = this.campaign;
    this.$router.push({ ...this.advertiserEditCampaignRoute , params: { id }});
  }
}
