















































































import { Component, Vue } from 'vue-property-decorator';
import SuccessIcon from './components/SuccessIcon.vue';
import FailureIcon from './components/FailureIcon.vue';
import { loadCampaignAction } from '@campaigns/shared/actions';
import { PAYMENT_STATUSES } from '@/app/shared/utilities/static-types';
import GearLoadingIcon from '@campaigns/screens/Advertiser/screens/CampaignInvoice/components/GearLoadingIcon.vue';
import routesNames from '@/app/shared/utilities/routes-names';
import PopupMessage from '@/app/shared/components/PopupMessage.vue';

@Component({
  components: {
    PopupMessage,
    GearLoadingIcon,
    SuccessIcon,
    FailureIcon,
  },
})
export default class CampaignInvoice extends Vue {
  public campaignId = '';

  public isPaymentProcessing = false;
  public isPaymentSuccessful = false;

  public showInvoiceMsg: (() => Promise<any>) | null = null;

  // public showInvitationCode: (() => Promise<any>) | null = null;
  // public invitationCode = '';
  // public showCodeCopiedMsg = false;

  public campaignMainRoute = routesNames.MY_CAMPAIGNS;
  // public campaignCheckoutRoute = routesNames.CHECKOUT_PAGE;

  public async created() {
    this.campaignId = this.$route.params.id;
    if (!this.campaignId) {
      throw new Error('Error loading campaign.');
    }
    this.isPaymentProcessing = true;

    try {
      const campaignData = await loadCampaignAction(this.campaignId);
      this.isPaymentSuccessful = campaignData.PAYMENT_STATUS === PAYMENT_STATUSES.APPROVED;

      this.isPaymentProcessing = false;

      // if (this.isPaymentSuccessful) {
      // const currentUser = await getCurrentUser();

      //   const invitationCode = await shouldUserReceiveInvitationCode(currentUser!.uid);
      //   if (invitationCode) {
      //     this.invitationCode = invitationCode.CODE;
      //     await this.showInvitationCode!();
      //   }
      // }
    } catch (err) {
      throw new Error(this.$t('something_went_wrong').toString() + '::' + err.message);
    } finally {
      this.isPaymentProcessing = false;
    }
  }

  // public redirectToCheckoutCampaign() {
  //   this.$router.push({
  //     ...this.campaignCheckoutRoute,
  //     params: {
  //       id: this.campaignId,
  //     },
  //   });
  // }

  public redirectToMyCampaigns() {
    this.$router.push(this.campaignMainRoute);
  }
}
