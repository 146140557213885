






































































import { Component, Prop, Vue } from 'vue-property-decorator';
import UploadFiles from '@/app/shared/components/UploadFiles.vue';

import {
  UPLOAD_STATUS,
  SUPPORTED_TYPES,
} from '@/app/shared/utilities/object-factory';
import * as actions from '../actions';
import rules from '@/app/shared/validation-rules';
import { bytesToSize } from '@/app/shared/utilities/helper-functions';
import { CampaignMediaFile } from '@/app/shared/utilities/static-types';
import firebase from 'firebase';
import storage = firebase.storage;

@Component({
  components: {
    'upload-files': UploadFiles,
  },
  data: (vm: any) => ({
    uploadFilesRules: [
      rules.maximumDocumentSizeInMegabytes(
        10,
        vm.$i18n.t('maximum_allowed_file_size', { maximumSize: 10 }),
      ),
    ],
  }),
})
export default class UploadFilesPopup extends Vue {
  @Prop() public selectImage!: (fileData: CampaignMediaFile) => any;

  public dialog = false;
  public refresher = true;
  public uploading = false;
  public isUploadCompleted = false;
  public uploadingStatus = UPLOAD_STATUS;

  public bytesToSize = bytesToSize;

  private filesUploading: any[] = [];

  public resetState() {
    this.filesUploading = [];
    this.refresher = false;
    this.$nextTick(() => {
      this.refresher = true;
    });
  }

  public onFilesPicked(files: any[]) {
    this.isUploadCompleted = false;
    this.filesUploading = files.map((file) => ({
      object: file,
      meta: {
        upload_progress: UPLOAD_STATUS.SELECTED,
        uploaded_bytes: 0,
        static_link: '',
      },
    }));
  }

  public filterImageFiles(file: any) {
    return file.type === SUPPORTED_TYPES.JPEG || SUPPORTED_TYPES.GIF || SUPPORTED_TYPES.MP4;
  }

  public async onUploadStart() {
    try {
      this.uploading = true;
      if (!this.filesUploading || this.filesUploading.length === 0) {
        throw new Error('You must select a file to upload.');
      }
      for (const file of this.filesUploading) {
        file.meta.upload_progress = UPLOAD_STATUS.UPLOADING;
        const uploadTaskRef = await actions.uploadFileForCampaigns(file.object);
        const uploadTask = uploadTaskRef.put(file.object);
        uploadTask.on(
          storage.TaskEvent.STATE_CHANGED,
          (snapshot) => {
            file.meta.uploaded_bytes =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error: Error) => {
            file.meta.upload_progress = UPLOAD_STATUS.ERROR;
            this.uploading = false;
          },
          async () => {
            file.meta.static_link =
              uploadTask && (await uploadTask.snapshot.ref.getDownloadURL());

            const mediaFileData: CampaignMediaFile = {
              FILE_NAME: file.object.name,
              FILE_TYPE: file.object.type,
              STATIC_LINK: file.meta.static_link,
            };
            if (mediaFileData.FILE_TYPE === SUPPORTED_TYPES.MP4) {
              mediaFileData.PLAYBACK_DURATION = await this.calculateVideoFileDuration(file.object);
            }
            await actions.saveFileLinkForCampaigns(mediaFileData);

            this.uploading = false;
            this.isUploadCompleted = true;
            file.meta.upload_progress = UPLOAD_STATUS.UPLOADED;
            this.selectImage && this.selectImage({
              FILE_NAME: file.object.name,
              FILE_TYPE: file.object.type,
              STATIC_LINK: file.meta.static_link,
            });
          },
        );
      }
    } catch (error) {
      this.uploading = false;
    }
  }

  public calculateVideoFileDuration(file: any): Promise<number> {
    const video = document.createElement('video');
    video.preload = 'metadata';

    const duration = new Promise<number>(((resolve) => {
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(Math.ceil(video.duration));
      };
    }));

    video.src = window.URL.createObjectURL(file);

    return duration;
  }
}
