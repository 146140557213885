import {
  CampaignDiscounts,
  Promotion, PROMOTION_RATES,
  PROMOTION_STATUSES,
  PromotionStatus,
  Schedule,
} from '@/app/shared/utilities/static-types';
import { filter, flow, identity, map, sum, take } from 'lodash/fp';
import moment from 'moment';

export const getPromotionStatus = (item: Promotion): PromotionStatus => {
  const { START_DATE, END_DATE } = item;
  const startDateMoment = moment(START_DATE);
  const endDateMoment = moment(END_DATE);
  const nowMoment = moment();
  const promoExpired = nowMoment.isAfter(endDateMoment);
  const promoStarted = nowMoment.isAfter(startDateMoment);
  return promoExpired
    ? PROMOTION_STATUSES.EXPIRED
    : promoStarted
    ? PROMOTION_STATUSES.ACTIVE
    : PROMOTION_STATUSES.UPCOMING;
};

export function validateDiscountPromotion(promotion: Promotion, subTotal: number): CampaignDiscounts {
  const totalDiscount = promotion.VALUE_CAP && subTotal > promotion.VALUE_CAP
    ? Math.round(promotion.VALUE_CAP * (promotion.TYPE.VALUE / 100))
    : Math.round(subTotal * (promotion.TYPE.VALUE / 100));

  return {
    totalDiscount,
    discountList: [
      { discountReason: promotion.REDEEM_MESSAGE, discountValue: totalDiscount },
    ],
  };
}

export function validateFixedPricePromotion(promotion: Promotion, campaignApplicableSchedules: Schedule[]): CampaignDiscounts {
  const campaignSchedulesToApplyThePromotionOn = flow(
    filter((schedule: Schedule) => schedule.TOTAL_BILL_PRICE > promotion.TYPE.VALUE),
    promotion.WEEKS_PERIOD_CAP
      ? take(promotion.WEEKS_PERIOD_CAP)
      : identity,
  )(campaignApplicableSchedules);

  const totalDiscount = flow(
    map((schedule: Schedule) => schedule.TOTAL_BILL_PRICE - promotion.TYPE.VALUE),
    sum,
  )(campaignSchedulesToApplyThePromotionOn);

  return {
    totalDiscount,
    discountList: [
      { discountReason: promotion.REDEEM_MESSAGE, discountValue: totalDiscount },
    ],
  };
}

export function redeemPromotion(promotion: Promotion, subTotal: number, campaignApplicableSchedules: Schedule[]) {
  switch (promotion.TYPE.RATE) {
    case (PROMOTION_RATES.DISCOUNT): {
      return validateDiscountPromotion(promotion, subTotal);
    }

    case (PROMOTION_RATES.FIXED_PRICE): {
      return validateFixedPricePromotion(promotion, campaignApplicableSchedules);
    }
  }
}
