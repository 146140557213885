import { MutationTree } from 'vuex';
import { CampaignState } from './CampaignTypes';
import { DocumentReference } from '@firebase/firestore-types';
import { ClustersSchedulesRecord, Schedule } from '@/app/shared/utilities/static-types';
import { cloneDeep } from 'lodash';
import { getDefaultState } from '@campaigns/shared/store/CampaignModule';

export const mutations: MutationTree<CampaignState> = {
  setCurrentStoreCampaignRef(state: CampaignState, payload: DocumentReference) {
    state.currentStoreCampaignRef = payload;
  },
  setSelectedClustersSchedules(state: CampaignState, payload: ClustersSchedulesRecord) {
    state.selectedClustersSchedules = cloneDeep(payload);
  },
  setSelectedClusterId(state: CampaignState, payload: string) {
    state.selectedClusterId = payload;
    state.selectedSchedulesInCluster = cloneDeep(state.selectedClustersSchedules[payload]) || [];
  },
  setSelectedSchedulesItems(state: CampaignState, payload: Schedule[]) {
    state.selectedSchedulesInCluster = cloneDeep(payload);
  },
  resetState(state: CampaignState) {
    Object.assign(state, getDefaultState());
  },
};
