




















































import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  ScreenCluster,
  MyDeviceInfo,
  Schedule,
  Nullable,
} from '@/app/shared/utilities/static-types';
import { getScreenClusters } from '@userApp/shared/actions';
import { scheduleScreenClusterPlayers } from '../../shared/actions';

import validationRules from '@/app/shared/validation-rules';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import { getSchedulesByScreenCluster } from '@globalActions/SchedulesActions';
// import ClusterDescription from '@/app/shared/components/ClusterDescription.vue';

@Component({
  components: {
    FormComponent,
    // ClusterDescription,
  },
  data: (vm: any) => ({
    requiredRules: [validationRules.required(vm.$i18n.t('field_required'))],
  }),
})
export default class SchedulePlayers extends Vue {
  public selectedScheduleId: Nullable<string> = null;
  public schedules: Schedule[] = [];
  public isLoadingSchedules = false;

  public screenClusters: ScreenCluster[] = [];
  public selectedCluster: Nullable<ScreenCluster> = null;

  private devicesInfo: MyDeviceInfo[] = [];

  private get selectedLocale(): string {
    return this.$i18n.locale;
  }

  public async initializeSchedulePlayers() {
    this.screenClusters = (await getScreenClusters()).map((cluster: ScreenCluster) => ({
      TEXT: this.$t(cluster.DISPLAY_NAME[this.selectedLocale]).toString(),
      ...cluster,
    }));
  }

  @Watch('selectedCluster')
  public async onChangeSelectedCluster(selectedCluster: ScreenCluster) {
    if (selectedCluster) {
      this.isLoadingSchedules = true;

      const clusterId = selectedCluster.ID;
      this.schedules = await getSchedulesByScreenCluster({ clusterId });

      this.isLoadingSchedules = false;
    }
  }

  public async schedulePlayers() {
    await scheduleScreenClusterPlayers(
        this.selectedCluster!.ID,
        this.selectedScheduleId!,
    );
  }
}
