





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { AdSPaceScreenSaver } from '@/app/shared/utilities/static-types';
import AdSpaceScreenSaverItem from '@adSpaces/screens/StoreOwner/screens/AdSpaceNew/Components/AdSpaceScreenSaverItem/AdSpaceScreenSaverItem.vue';

@Component({
  name: 'AdSpaceScreenSaverList',
  components: {
    draggable,
    AdSpaceScreenSaverItem,
  },
})
export default class AdSpaceScreenSaverList extends Vue {
  @Prop() public adSpaceScreenSavers!: AdSPaceScreenSaver[];
  public adSpaceScreenSaversItems: AdSPaceScreenSaver[] = [];

  public created() {
    this.updateList();
    this.$emit('update-list-function', this.updateList);
  }

  public activeChanged(screenSaverMediaPath: string) {
    this.$emit('active-changed', screenSaverMediaPath);
  }

  public adSpaceScreenSaverDeleted(screenSaverMediaPath: string) {
    this.$emit('ad-space-screen-saver-deleted', screenSaverMediaPath);
  }

  @Watch('adSpaceScreenSavers')
  public adSpaceScreenSaversChanged() {
    this.updateList();
  }

  public updateList() {
    this.adSpaceScreenSaversItems = [...this.adSpaceScreenSavers];
  }

  public async updateScreenSaverOrder(event: Event) {
    this.$emit(
      'ad-space-screen-saver-reordered',
      this.adSpaceScreenSaversItems,
    );
  }
}
