



































import { Component, Vue, Prop } from 'vue-property-decorator';
import { MyDevice } from '@/app/shared/utilities/static-types';

@Component
export default class MyDeviceItemContent extends Vue {
  @Prop({ required: true }) public device!: MyDevice;
}
