import Vue from 'vue';
import Router from 'vue-router';

import AuthenticationRoutes from '@publicApp/config/routes';
import AuthenticationGuards from '@/app/shared/config/auth-guards';
import AuthorizationGuards from '@/app/shared/config/roles-guard';
import NavigationRoutes from '@/app/screens/App/config/routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...AuthenticationRoutes,
    ...NavigationRoutes,
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
    // },
  ],
});
router.beforeEach(AuthenticationGuards);
router.beforeEach(AuthorizationGuards);
export default router;
