import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';

export default {
  async duplicateRegistrationNumber(value: string, msg: string) {
    if (!value) {
      return [];
    }
    const input = value.trim();
    const currentUser = await getCurrentUser();
    const document = await FirebaseAppFirestore
      .collection(firebaseNames.COMPANY_PROFILES)
      .where('COMPANY_REGISTRATION_NUMBER', '==', input).get();
    const result = document.docs.filter((doc) => doc.id !== currentUser!.uid);
    return result.length === 0 ? [] : msg;
  },
};
