import { GetterTree } from 'vuex';
import { CompanyProfileState } from './CompanyProfileTypes';
import { RootState } from '@/app/shared/store/RootState';
import { DocumentReference } from '@firebase/firestore-types';

export const getters: GetterTree<CompanyProfileState, RootState> = {
  getCurrentCompanyProfileRef: (state: CompanyProfileState): DocumentReference => {
    return state.currentCompanyProfileRef!;
  },
};
