import { RouteConfig } from 'vue-router';

import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import { adminPagesGuard } from '@admin/config/admin-nav-guards';
const CampaignsMain = () => import('@adminStores/screens/CampaignsMain/CampaignsMain.vue');
const ApprovedCampaigns = () => import('@adminStores/screens/ApprovedCampaigns/ApprovedCampaigns.vue');
const OtherCampaigns = () => import('@adminStores/screens/OtherCampaigns/OtherCampaigns.vue');
const PendingCampaigns = () => import('@adminStores/screens/PendingCampaigns/PendingCampaigns.vue');
const CampaignReview = () => import('@adminStores/screens/CampaignReview/CampaignReview.vue');
const StoresMain = () => import('@adminStores/screens/StoresMain.vue');
const ApprovedBranches = () => import('@adminStores/screens/ApprovedBranches.vue');
const PendingBranches = () => import('@adminStores/screens/PendingBranches.vue');
const CreateStoreOwner = () => import('@adminStores/screens/CreateStoreOwner.vue');
const EditStoreOwner = () => import('@adminStores/screens/EditStoreOwner.vue');
const CreateRegulator = () => import('@adminStores/screens/CreateRegulator.vue');
const Regulators = () => import('@adminStores/screens/Regulators.vue');
const EditRegulator = () => import('@adminStores/screens/EditRegulator.vue');
const StoreOwners = () => import('@adminStores/screens/StoreOwners.vue');
const AdSpaces = () => import('@adSpaces/screens/StoreOwner/screens/AdSpaces/AdSpaces.vue');
const CreateStoreAdSpace = () => import('@adSpaces/screens/StoreOwner/screens/AdSpaceNew/AdSpaceNew.vue');
const EditStoreAdSpace = () => import('@adSpaces/screens/StoreOwner/screens/AdSpaceEdit/AdSpaceEdit.vue');

export default [
  {
    path: 'stores',
    component: StoresMain,
    beforeEnter: adminPagesGuard,
    children: [
      {
        ...AdminRoutes.APPROVED_BRANCHES,
        path: 'approved',
        component: ApprovedBranches,
      },
      {
        ...AdminRoutes.PENDING_BRANCHES,
        path: 'pending',
        component: PendingBranches,
      },
      {
        ...AdminRoutes.CREATE_STORE_OWNER,
        path: 'create-store-owner',
        component: CreateStoreOwner,
      },
      {
        ...AdminRoutes.EDIT_STORE_OWNER,
        path: 'edit-store-owner/:id',
        component: EditStoreOwner,
      },
      {
        ...AdminRoutes.REGULATORS,
        path: 'regulators',
        component: Regulators,
      },
      {
        ...AdminRoutes.CREATE_REGULATOR,
        path: 'create-regulator',
        component: CreateRegulator,
      },
      {
        ...AdminRoutes.EDIT_REGULATOR,
        path: 'edit-regulator/:id',
        component: EditRegulator,
      },
      {
        ...AdminRoutes.STORE_OWNERS,
        path: 'store-owners',
        component: StoreOwners,
      },
      {
        ...AdminRoutes.ALL_AD_SPACES,
        path: 'all-ad-spaces',
        component: AdSpaces,
      },
      {
        ...AdminRoutes.CREATE_STORE_AD_SPACE,
        path: 'new-ad-space',
        component: CreateStoreAdSpace,
      },
      {
        ...AdminRoutes.EDIT_STORE_AD_SPACE,
        path: 'edit-ad-space',
        component: EditStoreAdSpace,
      },
      {
        path: 'campaigns',
        component: CampaignsMain,
        beforeEnter: adminPagesGuard,
        children: [
          {
            ...AdminRoutes.STORE_APPROVED_CAMPAIGNS,
            path: 'approved',
            component: ApprovedCampaigns,
          },
          {
            ...AdminRoutes.STORE_OTHER_CAMPAIGNS,
            path: 'other',
            component: OtherCampaigns,
          },
          {
            ...AdminRoutes.STORE_PENDING_CAMPAIGNS,
            path: 'pending',
            component: PendingCampaigns,
          },
          {
            ...AdminRoutes.STORE_CAMPAIGN_REVIEW,
            path: 'review',
            component: CampaignReview,
          },
        ],
      },
    ],
  },
] as RouteConfig[];
