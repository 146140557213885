
































































import { Component, Vue } from 'vue-property-decorator';

import {
  Country,
  City,
  StoreAccount,
  Nullable,
} from '@/app/shared/utilities/static-types';
import routesNames from '@/app/shared/utilities/routes-names';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import {
  getDeviceByIdAction,
  getStoreByIdAction,
} from '@globalActions/DevicesActions';

@Component({
  components: {
    FormComponent,
  },
})
export default class MyDeviceView extends Vue {
  public deviceName = '';

  public selectedCountry: Nullable<Country> = null;

  public selectedCity: Nullable<City> = null;

  public selectedStore: Nullable<StoreAccount> = null;

  public selectedAdSpaceName: string = '';

  public deviceMainRoute = routesNames.STORE_DEVICES;

  public screenId = '';

  public screenPhoto = '';

  public selectedArea: Nullable<string> = null;

  public comments = '';

  private deviceId = '';

  public async loadDeviceInfo() {
    const loadedId = this.$route.params.id;
    if (!loadedId) {
      throw new Error('Error loading device.');
    }
    const device = await getDeviceByIdAction(loadedId);
    this.selectedStore = await getStoreByIdAction(device.STORE_ID!);
    this.selectedCountry = device.COUNTRY!;
    this.deviceName = device.DISPLAY_NAME;
    this.selectedCity = device.CITY!;
    this.selectedAdSpaceName = device.AD_SPACE_NAME!;
    this.screenId = device.SCREEN_CODE!;
    this.screenPhoto = device.PHOTO_URL!;
    this.selectedArea = device.STORE_AREA!;
    this.deviceId = device.ID!;
  }
}
