






















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { Campaign, MediaType } from '@/app/shared/utilities/static-types';
import PopupMessage from '@/app/shared/components/PopupMessage.vue';
import { TranslateResult } from 'vue-i18n';
import { deleteCampaignAction } from '../actions';
import { events } from '../utils';

@Component({
  components: {
    PopupMessage,
  },
})
export default class ChangeCampaignMedia extends Vue {
  @Prop({ required: true }) public campaign!: Campaign;

  public showCampaignDeleteConfirmationMessage: (() => Promise<any>) | null = null;

  public async deleteCampaign() {
    await this.showCampaignDeleteConfirmationMessage!();
    await deleteCampaignAction(this.campaign);
  }

  public get campaignDeleteConfirmationMessage(): TranslateResult {
    return this.$t('delete_campaign_confirmation_message');
  }

  public get confirmationTitle(): TranslateResult {
    return this.$t('confirmation_title');
  }
}
