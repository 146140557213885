











































import { Component, Vue } from 'vue-property-decorator';
import PromotionsTable from './components/PromotionsTable.vue';
import {
  LocationData,
  Promotion,
  PromotionTableRow,
} from '@/app/shared/utilities/static-types';
import { loadPromotionsAction } from '../../shared/actions';
import moment from 'moment';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import { getPromotionStatus } from '@promotions/shared/utils';
import namespace from '@/app/shared/store/namespace';
import { LoadLocations } from '@/app/shared/store/common-module/types';
import { Getter, Action } from 'vuex-class';
import firebase from 'firebase';

@Component({
  components: {
    PromotionsTable,
  },
})
export default class PromotionList extends Vue {
  @Getter('locations', { namespace: namespace.CommonModule })
  public locations!: LocationData[];

  @Action('loadLocations', { namespace: namespace.CommonModule })
  public loadLocations!: LoadLocations;

  public loading = false;
  public promotionsData: PromotionTableRow[] = [];
  public createPromotionRoute = AdminRoutes.CREATE_PROMOTION;

  public selectedLocationsNames({ APPLICABLE_SCREEN_CLUSTER_IDS = [] }: Promotion) {
    return this.locations
      .filter(({ clusterId }) =>
        APPLICABLE_SCREEN_CLUSTER_IDS.includes(clusterId),
      )
      .map(({ NAME }) => NAME)
      .join(', ');
  }

  public async created(): Promise<void> {
    await this.loadLocations();
    await this.loadPromotions();
  }

  public promoDeleted(id: string) {
    this.promotionsData = this.promotionsData.filter((item) => item.ID !== id);
  }

  public promoSetAsDefault(id: string) {
    this.promotionsData = this.promotionsData.map((promotion) => {
      promotion.DEFAULT = Boolean(promotion.ID === id);
      return promotion;
    });
  }

  public async loadPromotions(): Promise<void> {
    this.loading = true;
    this.promotionsData = (await loadPromotionsAction()).map(
      this.promotionDataMap,
    );
    this.loading = false;
  }

  public createPromotion(): void {
    this.$router.push(this.createPromotionRoute);
  }

  public promotionDataMap(promotion: Promotion): PromotionTableRow {
    const {
      ID,
      NAME,
      DEFAULT,
      TYPE,
      START_DATE,
      END_DATE,
      PLATFORM_VISIBLE,
    } = promotion;
    const { VALUE, RATE } = TYPE || {};

    return {
      ID,
      NAME,
      STATUS: getPromotionStatus(promotion),
      DEFAULT,
      PLATFORM_VISIBLE,
      VALUE: `${VALUE} ${RATE === 'DISCOUNT' ? '%' : 'SAR'}`,
      TYPE: `${
        RATE === 'DISCOUNT' ? this.$t('discount') : this.$t('fixed_price')
      }`,
      START_DATE: moment(new Date(START_DATE instanceof firebase.firestore.Timestamp ? START_DATE.toDate() : START_DATE as string)).format('YYYY-MM-DD'),
      END_DATE: moment(new Date(END_DATE instanceof firebase.firestore.Timestamp ? END_DATE.toDate() : END_DATE as string)).format('YYYY-MM-DD'),
      APPLICABLE_LOCATIONS_NAMES: this.selectedLocationsNames(promotion),
    };
  }
}
