/**
 * Module for firebase operations. Firebase Services included here:
 * 1. Firebase application.
 * 2. Firebase Firestore database. Used for the Data model.
 * @author aelsayed@mycorr.co
 */
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/remote-config';

import firebaseNames from '@/app/shared/utilities/firebase-names';
import firebase from 'firebase';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FB_DB_URL,
  projectId: process.env.VUE_APP_FB_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FB_APP_ID,
};

const getFirebaseApp = (): firebase.app.App => {
  if (firebase.apps.length > 0) {
    return firebase.app();
  } else {
    return firebase.initializeApp(firebaseConfig, process.env.VUE_APP_FB_APP_NAME);
  }
};

/**
 * Firebase SDK application.
 */
export const FirebaseSdkApp = getFirebaseApp();

/* tslint:disable-next-line */
console.log('Firebase App Name:', FirebaseSdkApp.name);

/** Firebase App Auth. */
export const FirebaseAppAuth = FirebaseSdkApp.auth();

/** Firebase App Firestore. */
export const FirebaseAppFirestore = FirebaseSdkApp.firestore();

FirebaseAppFirestore.settings({ ignoreUndefinedProperties: true });
/** Firebase App Functions. */
export const FirebaseAppFunctions = FirebaseSdkApp.functions();
if (process.env.NODE_ENV === 'development') {
  FirebaseAppFunctions.useFunctionsEmulator('http://localhost:5001');
}

/** Firebase App Storage. */
export const FirebaseAppStorage = FirebaseSdkApp.storage();

/** Firebase App Remote Config */
export const FirebaseRemoteConfig = FirebaseSdkApp.remoteConfig();

// Firebase App Remote Config settings
FirebaseRemoteConfig.settings = {
  fetchTimeoutMillis: 5000,
  minimumFetchIntervalMillis: 300000,
};

// Firebase App Remote Config default parameter values
FirebaseRemoteConfig.defaultConfig = ({
  [firebaseNames.remoteConfig.HUNDRED_SAR_FOR_FIRST_WEEK_OFFER]: false,
  [firebaseNames.remoteConfig.TWENTY_PERCENT_OFF_FOUR_WEEKS_PROMO]: true,
  [firebaseNames.remoteConfig.CALENDAR_SCHEDULES_TIME_SLOT_INFO]: false,
  [firebaseNames.remoteConfig.CALENDAR_WEEKS_HORIZONTAL_SELECTION]: false,
  [firebaseNames.remoteConfig.UPCOMING_WEEKS_COUNT]: 8,
  [firebaseNames.remoteConfig.PAY_LATER_FOR_CAMPAIGN]: false,
  [firebaseNames.remoteConfig.PENDING_PAYMENT_TIMEOUT_IN_HOURS]: 12,
  [firebaseNames.remoteConfig.CAMPAIGN_SINGLE_CLUSTER_SELECTION]: false,
  [firebaseNames.remoteConfig.ALLOW_SELECTING_RUNNING_SCHEDULE]: true,
});
