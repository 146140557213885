import { RouteConfig } from 'vue-router';

import { USER_ROLES } from '@/app/shared/utilities/object-factory';

import CompanyMgmt from '@company/screens/CompanyProfile/CompanyMgmt.vue';
import routesNames from '@/app/shared/utilities/routes-names';

export default [
  {
    ...routesNames.COMPANY_MANAGEMENT,
    path: 'company-management',
    component: CompanyMgmt,
    meta: {
      roles: [
        USER_ROLES.ADVERTISER,
      ],
    },
  },
] as RouteConfig[];
