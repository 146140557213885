import {
  FirebaseAppAuth,
  FirebaseAppFirestore,
  FirebaseAppFunctions, FirebaseAppStorage,
  FirebaseSdkApp,
} from '@/app/shared/firebase/firebase-app';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import { CollectionReference } from '@firebase/firestore-types';
import { UploadToken, UserStorageStatus, CampaignMediaFile } from '@/app/shared/utilities/static-types';

import firebaseNames from '@/app/shared/utilities/firebase-names';
import firebase from 'firebase';
import storage = firebase.storage;

/**
 * Returns a reference for Image files collection that belong to current user.
 */
export const getFilesCollectionRefForCampaigns = async (): Promise<CollectionReference> => {
  const currentUser = await getCurrentUser();
  return FirebaseAppFirestore
    .collection(firebaseNames.MEDIA_FILES.VAL)
    .doc(currentUser!.uid)
    .collection(firebaseNames.MEDIA_FILES.FOR_CAMPAIGNS);
};

export const generateUploadToken = async (): Promise<UploadToken> => {
  const getUploadTokenFn = FirebaseAppFunctions
    .httpsCallable(firebaseNames.functions.GET_UPLOAD_TOKEN);
  const { data } = await getUploadTokenFn();
  return data;
};

export const getUserStorageStatus = async (): Promise<UserStorageStatus> => {
  const { token } = await generateUploadToken();
  await FirebaseAppAuth.signInWithCustomToken(token);
  const currentUser = await getCurrentUser();
  const {
    STORAGE_LIMIT_IN_BYTES,
    STORAGE_USED_IN_BYTES,
    STORAGE_LEFT_IN_BYTES,
  } = await currentUser!.getIdTokenResult().then(
    (tokenResult) => tokenResult.claims as UserStorageStatus,
  );

  return {
    STORAGE_LIMIT_IN_BYTES,
    STORAGE_USED_IN_BYTES,
    STORAGE_LEFT_IN_BYTES,
  };
};

/**
 * Delete campaign media file.
 * @param mediaFile CampaignMediaFile
 */
export const deleteMediaFileAction = async ({ FILE_NAME, STATIC_LINK }: CampaignMediaFile): Promise<[any, void]> => {
  const imageRef: storage.Reference = FirebaseAppStorage.refFromURL(STATIC_LINK);
  const currentUser = await getCurrentUser();
  const userId = currentUser!.uid;
  const mediaFileRef = FirebaseSdkApp!.firestore!()
    .collection(firebaseNames.MEDIA_FILES.VAL)
    .doc(userId)
    .collection(firebaseNames.MEDIA_FILES.FOR_CAMPAIGNS)
    .doc(FILE_NAME);
  return Promise.all([imageRef.delete(), mediaFileRef.delete()]);
};


