


































import {
  ClustersSchedulesRecord,
  ScreenCluster,
} from '@/app/shared/utilities/static-types';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({})
export default class ClustersAndSchedulesPreview extends Vue {
  @Prop() public loading!: boolean;
  @Prop({ required: true })
  public selectedClustersSchedules!: ClustersSchedulesRecord;
  @Prop({ required: true }) public screenClustersItems!: ScreenCluster[];

  public get appLocale() {
    return this.$root.$i18n.locale;
  }

  public colors: any = [
    {
      color1: '#0048db',
      color2: '#a1dcff',
    },
    {
      color1: '#db5900',
      color2: '#ffcaba',
    },
    {
      color1: '#b90069',
      color2: '#ffb3cd',
    },
  ];

  public colorSelect(index: number, type: string): string {
    const usedIndex = index <= this.colors.length - 1 ? index : 0;
    return this.colors[usedIndex][type];
  }

  public clusterName(id: string): string {
    const clusterItem = this.screenClustersItems.find((item) => item.ID === id);
    return clusterItem ? clusterItem.DISPLAY_NAME[this.appLocale] : '';
  }

  public clusterDescription(id: string): string {
    const clusterItem = this.screenClustersItems.find((item) => item.ID === id);
    return clusterItem ? clusterItem.DESCRIPTION[this.appLocale] : '';
  }
}
