import { CommonModule } from './common-module';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './RootState';
import { NavigationModule } from '@appLayout/shared/store/NavigationModule';
import { CampaignModule } from '@campaigns/shared/store/CampaignModule';
import { MyDeviceModule } from '@screenDevices/shared/store/MyDeviceModule';
import { LandingModule } from '@publicApp/shared/store/LandingModule';
import { CompanyProfileModule } from '@/app/screens/App/screens/CompanyProfile/shared/store/CompanyProfileModule';

Vue.use(Vuex);

const storeOpts: StoreOptions<RootState> = {
  state: {
    version: '1.0.0',
  },
  modules: {
    CommonModule,
    LandingModule,
    NavigationModule,
    CampaignModule,
    MyDeviceModule,
    CompanyProfileModule,
  },
};

export default new Vuex.Store(storeOpts);
