var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":_vm.$vuetify.breakpoint.mdAndUp ? '80%' : '100%'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"width":"100%"},attrs:{"color":"primary","block":_vm.$vuetify.breakpoint.smAndDown}},on),[_c('v-layout',{attrs:{"justify-space-around":"","align-center":""}},[_c('span',{staticStyle:{"flex-basis":"100%"}},[_vm._v(_vm._s(_vm.$t('screens')))]),_c('v-icon',{staticClass:"px-1",staticStyle:{"line-height":".5rem"},attrs:{"dark":""}},[_vm._v("add_to_queue")])],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('form-component',{attrs:{"form-name":"assign-screen","on-form-submission":_vm.assignScreenCluster,"on-component-created":_vm.initializeScreenClusters},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('assign_screens')))])]},proxy:true},{key:"fields",fn:function(ref){
var loading = ref.loading;
return [_c('v-select',{attrs:{"rules":_vm.screenClusterRules,"items":_vm.screenClusters,"label":_vm.$t('screen_cluster'),"item-text":"TEXT","item-value":"ID","return-object":"","readonly":_vm.readonly,"chips":""},model:{value:(_vm.selectedScreenCluster),callback:function ($$v) {_vm.selectedScreenCluster=$$v},expression:"selectedScreenCluster"}})]}},{key:"actions",fn:function(ref){
var valid = ref.valid;
var saving = ref.saving;
return [_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","flat":"","disabled":!_vm.selectedScreenCluster || _vm.readonly,"type":"submit"}},[_vm._v(_vm._s(_vm.$t('assign')))]),_c('v-btn',{attrs:{"color":"red darken-1","flat":""},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('cancel')))])]}}],null,false,1647838569)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }