import { Module } from 'vuex';
import { LandingState } from './LandingTypes';
import { RootState } from '@/app/shared/store/RootState';
import { mutations } from './LandingMutations';
import { getters } from './LandingGetters';

const state: LandingState = {
  savedCampaign: null,
};

export const LandingModule: Module<LandingState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
};
