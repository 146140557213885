


































import { Component, Vue } from 'vue-property-decorator';

import { MyDevice, Nullable, RegisteredPlayer } from '@/app/shared/utilities/static-types';
import { subscribeToRegisteredPlayers } from '@screenDevices/shared/actions';
import MyDevicesList from '@userApp/shared/components/ListView.vue';
import ClickableDeviceItem from '@userApp/shared/components/ScreenDeviceItem/ClickableDeviceItem.vue';
import ViewableDeviceItem from '@userApp/shared/components/ScreenDeviceItem/ViewableDeviceItem.vue';
import ViewablePlayerItem from '@adminDevices/screens/RegisteredPlayers/RegisteredPlayers/components/ViewablePlayerItem/ViewablePlayerItem.vue';
import { Unsubscribe } from '@firebase/util';

@Component({
  components: {
    ViewablePlayerItem,
    MyDevicesList,
    ViewableDeviceItem,
    ClickableDeviceItem,
  },
})
export default class StoreDevicesHome extends Vue {
  public loading = true;
  public registeredPlayers: RegisteredPlayer[] = [];
  public unsubscribeFromRegisteredPlayers: Nullable<Unsubscribe> = null;

  public async created() {
    try {
      this.loading = true;
      // TODO: Refactor the store's devices fetch, by denormalizing the DB
      this.unsubscribeFromRegisteredPlayers = await subscribeToRegisteredPlayers(async (players) => {
        this.registeredPlayers = await Promise.all(players
          .map(async (player) => {
            const { SCREEN_CODE, REGISTERED_PLAYER_ID } = (await player.DEVICE_REF.get()).data() as MyDevice;
            return {
              ...player,
              ID: REGISTERED_PLAYER_ID,
              DEVICE_SCREEN_CODE: SCREEN_CODE,
            } as RegisteredPlayer & { ID: string, DEVICE_SCREEN_CODE: string};
          }));
      });

    } finally {
      this.loading = false;
    }
  }

  public destroyed(): void {
    this.unsubscribeFromRegisteredPlayers && this.unsubscribeFromRegisteredPlayers();
  }
}
