import { FirebaseAppAuth } from './firebase-app';
import { User } from '@firebase/auth-types';

export const getCurrentUser = async (): Promise<User | null> => {
  return new Promise((resolve, reject) => {
    try {
      const authStateHandler = FirebaseAppAuth.onAuthStateChanged((user) => {
        resolve(user);
        authStateHandler && authStateHandler();
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const getUserInfo = async (): Promise<User | null> => {
  return new Promise((resolve, reject) => {
    try {
      const authStateHandler = FirebaseAppAuth.onAuthStateChanged((user) => {
        resolve(user);
        authStateHandler && authStateHandler();
      });
    } catch (error) {
      reject(error);
    }
  });
};
