import { languageSwitcher } from './app/shared/mixins/language-switcher';
import Vue from 'vue';
import Vuetify from 'vuetify';

import './assets/stylus/main.styl';
import './styles/global.sass';

import App from './App.vue';
import router from './router';
import store from './app/shared/store';
import './registerServiceWorker';
import i18n from './i18n';
// @ts-ignore
import VueClipboards from 'vue-clipboards';
// @ts-ignore
import VueNumber from 'vue-number-animation';
import VueFileAgent from 'vue-file-agent';
import 'vue-file-agent/dist/vue-file-agent.css';

Vue.use(VueFileAgent);
const mixins = [ languageSwitcher ];

Vue.use(VueClipboards);
Vue.use(VueNumber);

Vue.use(Vuetify, {
  theme: {
    primary: '#90278e',
    secondary: '#2b388f',
  },
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  mixins,
  render: (h) => h(App),
}).$mount('#app');
