






import { Component, Vue } from 'vue-property-decorator';
import { DB_STORAGE_APP_LOCALE } from '@/app/shared/utilities/object-factory';
import { getValueFromStorage } from '@/app/storage/storage-actions';

@Component
export default class App extends Vue {
  public async mounted() {
    try {
      const recentLocale = await this.getAppLocale();
      if (recentLocale) {
        this.$root.$i18n.locale = recentLocale;
      }
    } catch (err) {
      throw new Error(`Failed to load app locale from indexedDB ${err}`);
    }
  }

  public async getAppLocale() {
    return getValueFromStorage(DB_STORAGE_APP_LOCALE);
  }
}
