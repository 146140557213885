import { Module } from 'vuex';
import { MyDeviceState } from './MyDeviceTypes';
import { RootState } from '@/app/shared/store/RootState';
import { mutations } from './MyDeviceMutations';
import { getters } from './MyDeviceGetters';
import { actions } from './MyDeviceActions';

const state: MyDeviceState = {
  currentStoreDeviceRef: null,
};

export const MyDeviceModule: Module<MyDeviceState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
