






























































































import { Component, Vue } from 'vue-property-decorator';

import { ScreenCluster, AdSpace, MultiLanguage, Nullable } from '@/app/shared/utilities/static-types';
import {
  createScreenClusterAction,
  getAdSpacesAction,
} from '@adminDevices/shared/actions';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import AdSpaceSelectItem from '@/app/shared/components/AdSpaceSelectItem/AdSpaceSelectItem.vue';
import rules from '@/app/shared/validation-rules';

@Component({
  components: {
    FormComponent,
    AdSpaceSelectItem,
  },
  data: (vm: any) => ({
    requiredRules: [rules.required(vm.$i18n.t('field_required'))],
    screenClusterNumberRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.onlyNumbers(vm.$i18n.t('only_numbers_allowed')),
    ],
  }),
})
export default class CreateScreenCluster extends Vue {
  public clusterName: MultiLanguage = {
    ar: '',
    en: '',
  };
  public clusterDescription: MultiLanguage = {
    ar: '',
    en: '',
  };
  public timeSlots: Nullable<number> = null;
  public adSpacesArray: AdSpace[] = [];
  public selectedAdSpace: Nullable<AdSpace> = null;
  public zohoItemName = '';

  public async loadAdSpaces() {
    this.adSpacesArray = await getAdSpacesAction();
  }

  public get clusterPrice() {
    return this.selectedAdSpace ? this.selectedAdSpace.BASE_PRICE : 0;
  }

  public get freeAdSpaces(): AdSpace[] {
    const freeAdSpaces = this.adSpacesArray
      .filter((item: AdSpace) => !Boolean(item.SCREEN_CLUSTER_REF));
    return freeAdSpaces;
  }

  public selectAdSpace(adSpace: AdSpace & {disabled: boolean}) {
    this.selectedAdSpace = this.freeAdSpaces.find(
        (item) => item.ID === adSpace.ID,
      )!;
  }

  public async createScreenCluster() {
    const screenCluster: ScreenCluster = {
      DISPLAY_NAME: this.clusterName,
      PRICE: this.clusterPrice!,
      TIME_SLOTS: this.timeSlots,
      DESCRIPTION: this.clusterDescription,
      ZOHO_ITEM_NAME: this.zohoItemName,
    } as ScreenCluster;
    await createScreenClusterAction(screenCluster, this.selectedAdSpace!);
    await this.loadAdSpaces();
  }
}
