


























import { Component, Vue } from 'vue-property-decorator';
import ShashaPrivacyPolicy from './ShashaPrivacyPolicy.vue';
@Component({
  components: { ShashaPrivacyPolicy },
})
export default class PrivacyPolicy extends Vue {
  public dialog = false;
}
