







































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  AdSPaceScreenSaver,
  CampaignMedia,
} from '@/app/shared/utilities/static-types';
import PreviewThumbnail from '@campaigns/shared/components/PreviewThumbnail.vue';
import ViewAdSpaceScreenSaver from '@adSpaces/screens/StoreOwner/screens/AdSpaceNew/Components/ViewAdSpaceScreenSaver/ViewAdSpaceScreenSaver.vue';

@Component({
  name: 'AdSpaceScreenSaverItem',
  components: {
    PreviewThumbnail,
    ViewAdSpaceScreenSaver,
  },
})
export default class AdSpaceScreenSaverItem extends Vue {
  @Prop() public adSpaceScreenSaver!: AdSPaceScreenSaver;
  public showMedia = false;
  public media!: CampaignMedia;

  public created() {
    this.media = this.adSpaceScreenSaver.MEDIA_FILE;
  }

  public updateScreenSaverActive() {
    this.$emit('active-changed', this.adSpaceScreenSaver.MEDIA_FILE.MEDIA_FILE);
  }

  public adSpaceScreenSaverDeleted() {
    this.$emit(
      'ad-space-screen-saver-deleted',
      this.adSpaceScreenSaver.MEDIA_FILE.MEDIA_FILE,
    );
  }
}
