












































































































































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import PromotionLocations from './PromotionLocations.vue';
import {
  LocationData,
  Nullable,
  Promotion,
} from '@/app/shared/utilities/static-types';
import rules from '@/app/shared/validation-rules';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import { newPromotion } from '@/app/shared/utilities/object-factory';
import { Action, Getter } from 'vuex-class';
import namespace from '@/app/shared/store/namespace';
import { LoadLocations } from '@/app/shared/store/common-module/types';
import firebase from 'firebase';

@Component({
  components: {
    FormComponent,
    PromotionLocations,
  },
  data: (vm: any) => {
    const required = rules.required(vm.$i18n.t('field_required'));
    const onlyAlphanumeric = rules.onlyAlphanumeric(
      vm.$i18n.t('only_alphanumerics_allowed'),
    );
    const maxLength65 = rules.maxLength(
      65,
      vm.$i18n.t('max_length', { max: 65 }),
    );
    const maxLength45 = rules.maxLength(
      45,
      vm.$i18n.t('max_length', { max: 45 }),
    );

    return {
      required,
      maxLength45,
      maxLength65,
      onlyAlphanumeric,
    };
  },
})
export default class PromotionForm extends Vue {
  @Prop({ default: null, required: false }) public loadPromotion!:
    | (() => Promise<Promotion>)
    | null;
  @Prop({ required: true }) public submitPromotion!: (
    promotion: Promotion,
  ) => Promise<void>;
  @Prop({ default: false }) public disableSaveButton!: boolean;
  @Prop({ required: true }) public snackbarMsg!: string;
  @Prop({ default: false }) public resetForm!: boolean;
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ required: true }) public formName!: string;

  @Getter('locations', { namespace: namespace.CommonModule })
  public locations!: LocationData[];

  @Action('loadLocations', { namespace: namespace.CommonModule })
  public loadLocations!: LoadLocations;

  public isRequestingCheckout = false;
  public loading = false;

  public promotion: Promotion = {
    TYPE: {
      RATE: 'DISCOUNT',
    },
    PLATFORM_MESSAGE: {
      en: '',
      ar: '',
    },
    REDEEM_MESSAGE: {
      en: '',
      ar: '',
    },
  } as Promotion;
  public promotionsMainRoute: string | { name: string } =
    AdminRoutes.PROMOTIONS_LIST;

  public startDate: Nullable<string> = null;
  public endDate: Nullable<string> = null;

  public startDateMenu = false;
  public endDateMenu = false;

  public async onComponentCreated(): Promise<void> {
    await this.loadLocations();
    await this.initializePromotionData();
    this.startDate = this.dateFormat(this.promotion.START_DATE instanceof firebase.firestore.Timestamp ? this.promotion.START_DATE.toDate().toISOString() : this.promotion.START_DATE as string);
    this.endDate = this.dateFormat(this.promotion.END_DATE instanceof firebase.firestore.Timestamp ? this.promotion.END_DATE.toDate().toISOString() : this.promotion.END_DATE as string);
  }

  public async onFormSubmission(): Promise<void> {
    await this.submitPromotion(this.promotion);
  }

  public gotoPromotionsPage(): void {
    this.$router.push(this.promotionsMainRoute);
  }

  private async initializePromotionData(): Promise<void> {
    this.promotion = this.loadPromotion
      ? await this.loadPromotion!()
      : newPromotion();
  }

  private dateFormat = (date: string) =>
    new Date(date).toISOString().substr(0, 10)

  @Watch('startDate')
  private startDateChanged(val: string) {
    this.promotion.START_DATE = val && new Date(val).toISOString();
  }

  @Watch('endDate')
  private endDateChanged(val: string) {
    this.promotion.END_DATE = val && new Date(val).toISOString();
  }
}
