import { FirebaseAppFirestore, FirebaseAppAuth } from '@/app/shared/firebase/firebase-app';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import { AuthorizedUser } from '@/app/shared/utilities/static-types';

import firebaseNames from '@/app/shared/utilities/firebase-names';
import firebase from 'firebase';
import firestore = firebase.firestore;

/**
 * Login User using email & password.
 * @param email User email
 * @param password User password
 */
export const loginUserAction = async (email: string, password: string) => {
  const userCredentials = await FirebaseAppAuth.signInWithEmailAndPassword(email, password);
  return userCredentials;
};

/**
 * Send forget password reset email.
 * @param email User email
 */
export const sendPasswordResetEmailAction = async (email: string) => {
  return FirebaseAppAuth.sendPasswordResetEmail(email);
};

/**
 * Reset User's password.
 * @param code Firebase oob code
 * @param newPassword New User password
 */
export const verifyCodeAndResetPasswordAction = async (code: string, newPassword: string): Promise<void> => {
  const email = await FirebaseAppAuth.verifyPasswordResetCode(code);
  await FirebaseAppAuth.confirmPasswordReset(code, newPassword);
  await loginUserAction(email, newPassword);
};

/**
 * Change User's password
 * @param oldPassword Old User password
 * @param newPassword New User password
 */
export const changePasswordAction = async (oldPassword: string, newPassword: string) => {
  const currentUser = await getCurrentUser();
  const { email } = currentUser!;
  await loginUserAction(email!, oldPassword);
  return currentUser!.updatePassword(newPassword);
};

/**
 * Get a user friendly message for UI.
 * @param code error code
 */
export const getErrorMsgFromCode = (code: string) => {
  switch (code) {
    case 'auth/invalid-email':
    case 'auth/user-disabled':
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return 'Invalid username or password.';
    default:
      return 'Login failed.';
  }
};

/**
 * Adds last login timestamp.
 */
export const addLoginTimestamp = async () => {
  const currentUser = await getCurrentUser();
  await FirebaseAppFirestore.collection(firebaseNames.USERS_INFO).doc(currentUser!.uid).update({
    lastLogin: firestore.FieldValue.serverTimestamp(),
  });
};

/**
 * Register new user to the system.
 * @param email register email
 * @param password register password
 */
export const registerNewUser = async (email: string, password: string) => {
  const userCredentials = await FirebaseAppAuth.createUserWithEmailAndPassword(email, password);
  return userCredentials;
};

/**
 * Create related information for a user.
 * @param userInfo User information
 */
export const createUserInfo = async (userInfo: AuthorizedUser) => {
  await FirebaseAppFirestore.collection(firebaseNames.USERS_INFO).doc(userInfo.UID).set(userInfo);
};
