import { ActionTree, ActionContext } from 'vuex';
import { getClusteredAdSpaces } from '../../actions/AdspacesActions';
import { typeFromMime } from '../../utilities/files';
import { AdSpace, LocationData } from '../../utilities/static-types';
import { RootState } from '../RootState';
import { CommonState, LoadLocations } from './types';

export const actions: ActionTree<CommonState, RootState> = {
  loadLocations: async ({
    commit,
      dispatch,
    }: ActionContext<CommonState, RootState>,
  ): Promise<ReturnType<LoadLocations>> => {
    const locations: LocationData[] = (await getClusteredAdSpaces()).map(
      (adSpace: AdSpace) => ({
        ...adSpace,
        clusterId: adSpace.SCREEN_CLUSTER_REF!.id,
        mediaList: (adSpace.MEDIA || []).map(({ PATH, TYPE }) => ({
          path: PATH,
          type: typeFromMime(TYPE),
          mime: TYPE,
        })),
        media: {
          path: adSpace.MEDIA_FILE,
          type: typeFromMime(adSpace.MEDIA_TYPE!),
          mime: adSpace.MEDIA_TYPE,
        },
      }),
    );
    commit('setLocations', locations);
    return locations;
  },
};
