import { render, staticRenderFns } from "./ViewScreenSaver.vue?vue&type=template&id=cd454148&scoped=true&"
import script from "./ViewScreenSaver.vue?vue&type=script&lang=ts&"
export * from "./ViewScreenSaver.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd454148",
  null
  
)

export default component.exports