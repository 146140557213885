import { DB_STORAGE_NAME, DB_STORAGE_VERSION, DB_STORAGE_APP_DATA } from '../shared/utilities/object-factory';

let db: IDBDatabase | undefined;

/**
 * Return DB object of the IndexedDB.
 */
export const getStorageDB = (): Promise<IDBDatabase> => {
  if (db) {
    return Promise.resolve(db);
  } else {
    return new Promise((resolve, reject) => {
      try {
        const openRequest = indexedDB.open(DB_STORAGE_NAME, DB_STORAGE_VERSION);

        openRequest.onupgradeneeded = (event: any) => {
          db = event.target.result;
          const os = db!.createObjectStore(DB_STORAGE_APP_DATA);
          os.transaction.oncomplete = () => {
            resolve(db);
          };
          os.transaction.onerror = () => {
            reject(os.transaction.error);
          };
        };

        openRequest.onsuccess = () => {
          resolve(openRequest.result);
        };

        openRequest.onerror = () => {
          reject('Error loading IndexedDB.');
        };
      } catch (error) {
        reject(error);
      }
    });
  }
};
