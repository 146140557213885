







































import { Component, Vue, Prop } from 'vue-property-decorator';

import routesNames from '@/app/shared/utilities/routes-names';
import logoImage from '@/assets/images/Logo.png';
import landingLogo from '@/assets/images/landing-logo.png';
import LocaleSwitcher from '@/app/shared/components/LocaleSwitcher.vue';
import BottomNavigationBar from './components/BottomNavigationBar.vue';

@Component({
  components: {
    LocaleSwitcher,
    BottomNavigationBar,
  },
})
export default class ToolBar extends Vue {
  @Prop({ default: false, type: Boolean }) public landing!: boolean;
  @Prop({ default: true, type: Boolean }) public withButtons!: boolean;

  public logoImage = logoImage;
  public landingLogo = landingLogo;

  public navigationItems = [
    {
      title: 'login',
      icon: 'power_settings_new',
      color: 'secondary',
      to: routesNames.LOGIN,
    },
  ];

  public async redirectToEntryPage() {
    this.$router.push(routesNames.HOME);
  }
}
