






















































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { isAdminUserAction } from '@/app/shared/actions/UserRolesActions';
import { SystemFeature, Role } from '@/app/shared/utilities/static-types';
import namespace from '@/app/shared/store/namespace';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';

@Component
export default class SideDrawer extends Vue {
  @Getter('getSystemFeatures', { namespace: namespace.NavigationModule })
  public systemFeatures!: SystemFeature[];
  @Getter('getUserRoles', { namespace: namespace.NavigationModule })
  public userRoles!: Role[];

  public isOpened = false;

  public navigationItems: any[] | null = null;

  public currentUserEmail = '';
  public userLoggedIn = false;
  public userIsAdmin = false;

  public async created() {
    this.$root.$on('openSideDrawer', () => (this.isOpened = true));
    const user = await getCurrentUser();
    this.currentUserEmail = user!.email || '';
    this.userLoggedIn = Boolean(user);
    this.userIsAdmin = user ? await isAdminUserAction() : false;
    if (!this.systemFeatures) {
      await this.$store.dispatch(
        `${namespace.NavigationModule}/initializeSystemFeatures`,
      );
    }
    if (!this.userRoles) {
      await this.$store.dispatch(
        `${namespace.NavigationModule}/initializeUserRoles`,
      );
    }
    this.navigationItems = this.systemFeatures
      .filter((feature) => {
        return Boolean(
          this.userRoles.find((role) =>
            feature
              .ROLES!.map((featureRole) => featureRole.VAL)
              .includes(role.VAL),
          ),
        );
      })
      .map((feature) => ({
        title: feature.TITLE,
        to: feature.ROUTE_PATH,
        icon: feature.ICON,
        exact: !feature.HAS_CHILDREN,
      }));
  }
}
