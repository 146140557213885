import { Module } from 'vuex';
import { CompanyProfileState } from './CompanyProfileTypes';
import { RootState } from '@/app/shared/store/RootState';
import { mutations } from './CompanyProfileMutations';
import { getters } from './CompanyProfileGetters';
import { actions } from './CompanyProfileActions';

const state: CompanyProfileState = {
  currentCompanyProfileRef: null,
};

export const CompanyProfileModule: Module<CompanyProfileState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
