import { RouteConfig } from 'vue-router';

import Dashboard from '@userApp/screens/Dashboard/Dashboard.vue';
import AppLayout from '@appLayout/screens/MainLayout/AppLayout.vue';
import CompanyManagementRoutes from '@company/config/routes';
import AdSpacesRoutes from '@adSpaces/config/routes';
import FileManagementRoutes from '@files/config/routes';
import CampaignManagementRoutes from '@campaigns/config/routes';
import MyDevicesRoutes from '@/app/screens/App/screens/ScreenDevices/config/routes';
import AdminScreenRoutes from '@adminDevices/config/admin-screens-routes';
import AdminNavigationRoutes from '@admin/config/admin-nav-routes';
import AdminAdvertisersRoutes from '@adminAdvertisers/config/routes';
import AdminStoreRoutes from '@adminStores/config/routes';
const ChangePassword = () => import('@publicApp/screens/ChangePassword/ChangePassword.vue');

import routesNames from '@/app/shared/utilities/routes-names';
import { USER_ROLES } from '@/app/shared/utilities/object-factory';

export default [
  {
    path: '/',
    component: AppLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        ...routesNames.DASHBOARD,
        path: 'dashboard',
        component: Dashboard,
        meta: {
          roles: [
            USER_ROLES.ADVERTISER,
            USER_ROLES.SCREEN_OWNER,
            USER_ROLES.STORE_OWNER,
            USER_ROLES.SALES,
          ],
        },
      },
      {
        ...routesNames.CHANGE_PASSWORD,
        path: 'change-password',
        component: ChangePassword,
        meta: {
          roles: [
            USER_ROLES.ADVERTISER,
            USER_ROLES.SCREEN_OWNER,
            USER_ROLES.STORE_OWNER,
            USER_ROLES.SALES,
          ],
        },
      },
      ...CompanyManagementRoutes,
      ...FileManagementRoutes,
      ...CampaignManagementRoutes,
      ...MyDevicesRoutes,
      ...AdminNavigationRoutes,
      ...AdminScreenRoutes,
      ...AdminAdvertisersRoutes,
      ...AdminStoreRoutes,
      ...AdSpacesRoutes,
    ],
  },
] as RouteConfig[];
