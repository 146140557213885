






























import { Component, Vue } from 'vue-property-decorator';

import { isMobileUser } from '@/app/shared/utilities/helper-functions';
import { MyDevice } from '@/app/shared/utilities/static-types';
import { getDevicesByStatusAction } from '@screenDevices/shared/actions';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import MyDevicesList from '@userApp/shared/components/ListView.vue';
import ClickableDeviceItem from '@userApp/shared/components/ScreenDeviceItem/ClickableDeviceItem.vue';
import routesNames from '@/app/shared/utilities/routes-names';

@Component({
  components: {
    MyDevicesList,
    ClickableDeviceItem,
  },
})
export default class MyDeviceConnectHome extends Vue {
  public loading = false;
  public mobileUser = false;

  public deviceViewRoute = routesNames.MY_DEVICE_CONNECT;
  public devicesHome = routesNames.MY_DEVICES;
  public newDeviceColor = SYSTEM_STATUS.NEW.COLOR;
  public newDevices: MyDevice[] = [];

  public async created() {
    try {
      this.loading = true;
      // this.mobileUser = isMobileUser();
      // if (!this.mobileUser) {
      //   return;
      // }
      this.newDevices = await getDevicesByStatusAction(SYSTEM_STATUS.NEW.VAL);
    } finally {
      this.loading = false;
    }
  }
}
