
























































import { Component, Vue, Watch } from 'vue-property-decorator';

import { Role, SystemFeature } from '@/app/shared/utilities/static-types';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import rules from '@/app/shared/validation-rules';
import systemFeatureRules from '../shared/validation-rules';
import * as systemFeatureActions from '../shared/actions';
import { USER_ROLES } from '@/app/shared/utilities/object-factory';

@Component({
  components: {
    FormComponent,
  },
  data: (vm: any) => ({
    routeRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.shouldNotHaveSpaces(vm.$i18n.t('cannot_include_spaces')),
    ],
    requiredRules: [rules.required(vm.$i18n.t('field_required'))],
    roleRules: [rules.required(vm.$i18n.t('field_required')),
      rules.maxLength(1, vm.$i18n.t('max_role')),
    ],
  }),
})
export default class SystemFeatureNew extends Vue {
  public routePath = '';
  public routePathErrors: string | string[] = [];
  public routeName = '';
  public routeNameErrors: string | string[] = [];

  public title = '';
  public titleErrors: string | string[] = [];

  public icon = '';
  public roleValues: Record<string, Role> = USER_ROLES;
  public selectedRole: Role[] = [];
  public allRoles: Role[] = [];
  public hasChildren = false;
  public created() {
    this.allRoles = Object.keys(this.roleValues)
        .map((key) => this.roleValues[key]);
  }
  public async saveSystemFeature() {
    const feature: SystemFeature = {
      ROUTE_PATH: this.routePath.startsWith('/')
        ? this.routePath
        : `/${this.routePath}`,
      ROUTE_NAME: { name: this.routeName.toUpperCase() },
      TITLE: this.title,
      ICON: this.icon,
      HAS_CHILDREN: this.hasChildren,
      ROLES: this.selectedRole,
    };
    await systemFeatureActions.addNewSystemFeature(feature);
  }

  @Watch('routePath')
  public async onRoutePathChanged(val: string, oldVal: string) {
    this.routePathErrors = this.$t('checking').toString();
    this.routePathErrors = await systemFeatureRules.duplicatePathRoute(val);
  }

  @Watch('routeName')
  public async onRouteNameChanged(val: string, oldVal: string) {
    this.routeNameErrors = this.$t('checking').toString();
    this.routeNameErrors = await systemFeatureRules.duplicatePathName(val);
  }

  @Watch('title')
  public async onTitleChanged(val: string, oldVal: string) {
    this.titleErrors = this.$t('checking').toString();
    this.titleErrors = await systemFeatureRules.duplicateTitle(val);
  }
}
