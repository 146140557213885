import { render, staticRenderFns } from "./DeleteScreenSaver.vue?vue&type=template&id=32f1ef54&scoped=true&"
import script from "./DeleteScreenSaver.vue?vue&type=script&lang=ts&"
export * from "./DeleteScreenSaver.vue?vue&type=script&lang=ts&"
import style0 from "./DeleteScreenSaver.vue?vue&type=style&index=0&id=32f1ef54&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32f1ef54",
  null
  
)

export default component.exports