
































































import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { initial, tail } from 'lodash';
import {
  AdSpace,
  Campaign,
  PlaylistAdvertiserCampaign,
  PlaylistCampaign,
  ShashaAd,
  TimestampCallable,
} from '@/app/shared/utilities/static-types';
import PreviewThumbnail from '@campaigns/shared/components/PreviewThumbnail.vue';
import { downloadMedia } from '@/app/shared/utilities/helper-functions';
import ChangeCampaignMedia from '@campaigns/shared/components/ChangeCampaignMedia.vue';
import { deleteShashaAd } from '@adminDevices/shared/actions';

@Component({
  name: 'CampaignList',
  components: { PreviewThumbnail, ChangeCampaignMedia },
})
export default class CampaignList extends Vue {
  @Prop({ required: true }) public playlistCampaigns!: PlaylistCampaign[];
  @Prop({ required: true }) public adSpace!: AdSpace;
  @Prop({ required: true }) public sortByFrequency!: boolean;
  public firstItemId: PlaylistCampaign['ID'] = '';

  public created() {
    this.firstItemId = this.playlistCampaigns[0].ID;
  }
  public get getHeaders() {
    let headers = [
      {
        text: this.$t('thumbnail').toString(),
        value: 'MEDIA_FILE',
        sortable: false,
      },
      {
        text: this.$t('name').toString(),
        value: 'NAME',
        sortable: false,
      },
      {
        text: this.$t('frequency').toString(),
        value: 'FREQUENCY',
        sortable: false,
      },
      {
        text: this.$t('start_date').toString(),
        value: 'START_DATE',
        sortable: false,
      },
      {
        text: this.$t('end_date').toString(),
        value: 'END_DATE',
        sortable: false,
      },
      {
        text: this.$t('actions').toString(),
        value: 'ACTIONS',
        sortable: false,
      },
    ];
    if (this.sortByFrequency) {
      headers = initial(headers);
      headers = tail(headers);
    }
    return headers;
  }
  public formattedDate(timestamp: TimestampCallable) {
    return moment((timestamp.seconds || timestamp._seconds) * 1000).format('DD-MM-YYYY');
  }
  public downloadMediaFile(campaign: PlaylistAdvertiserCampaign, adSpace: AdSpace) {
    downloadMedia(campaign, adSpace);
  }
  public isAdvertiserCampaign(campaign: PlaylistCampaign) {
    return Boolean((campaign as Campaign).ADVERTISER_UID);
  }
  public isShashaAd(campaign: PlaylistCampaign) {
    return Boolean((campaign as ShashaAd).ADMIN_UID);
  }
  public campaignUpdated(campaign: PlaylistAdvertiserCampaign) {
    this.$emit('updated-campaign', campaign);
  }

  public async deleteShashaAd(shashaAdId: string) {
    await deleteShashaAd(shashaAdId);
    this.$emit('deleted-shasha-ad', shashaAdId);
  }
}
