var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.show,"max-width":_vm.$vuetify.breakpoint.mdAndUp? '60%' : '100%',"persistent":""}},[_c('v-card',[_c('v-card-title',{staticClass:"title",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.campaignName)+" "),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.show = false}}},[_vm._v("close")])],1),_c('v-card-text',{staticClass:"grey lighten-3"},[(!_vm.isChangeMediaRequest)?[(_vm.campaign && _vm.campaign.MEDIA_LIST && _vm.campaign.MEDIA_LIST.length)?_c('v-carousel',{attrs:{"cycle":true}},_vm._l((_vm.resolutionsMedia),function(ref,index){
var resolution = ref[0];
var media = ref[1];
return _c('v-carousel-item',{key:index+_vm.campaign.ID},[_c('div',{staticClass:"media-slide"},[_c('div',{staticClass:"resolution"},[_vm._v(" "+_vm._s(_vm.resolutionText(resolution))+" ")]),_c('div',{staticClass:"position-absolute d-flex align-center justify-center media-upload-container"}),_c('preview-thumbnail',{staticClass:"preview-thumbnail",attrs:{"playable":"","media":media ? media.MEDIA_FILE : null}})],1)])}),1):_c('preview-thumbnail',{attrs:{"media":'',"playable":""}})]:_vm._e(),(_vm.campaign && _vm.campaign.MEDIA_LIST && _vm.campaign.MEDIA_LIST.length && _vm.isChangeMediaRequest)?_c('div',{staticClass:"container grid-list-xs"},[_c('div',{staticClass:"layout row wrap"},[_c('div',{staticClass:"flex md5 xs12"},[_c('v-card-title',{staticClass:"headline"},[_vm._v("Old Media")]),(_vm.campaign && _vm.campaign.MEDIA_LIST && _vm.campaign.MEDIA_LIST.length)?_c('v-carousel',{attrs:{"cycle":true}},_vm._l((_vm.resolutionsMedia),function(ref,index){
var resolution = ref[0];
var media = ref[1];
return _c('v-carousel-item',{key:index+_vm.campaign.ID},[_c('div',{staticClass:"media-slide"},[_c('div',{staticClass:"resolution"},[_vm._v(" "+_vm._s(_vm.resolutionText(resolution))+" ")]),_c('div',{staticClass:"position-absolute d-flex align-center justify-center media-upload-container"}),_c('preview-thumbnail',{staticClass:"preview-thumbnail",attrs:{"playable":"","media":media ? media.MEDIA_FILE : null}})],1)])}),1):_vm._e()],1),_c('div',{staticClass:"flex offset-md2 md5 xs12"},[_c('v-card-title',{staticClass:"headline"},[_vm._v("New Media")]),(_vm.campaign && _vm.campaign.MEDIA_LIST && _vm.campaign.MEDIA_LIST.length)?_c('v-carousel',{attrs:{"cycle":true}},_vm._l((_vm.resolutionsMedia),function(ref,index){
var resolution = ref[0];
var media = ref[1];
return _c('v-carousel-item',{key:index+_vm.campaign.ID},[_c('div',{staticClass:"media-slide"},[_c('div',{staticClass:"resolution"},[_vm._v(" "+_vm._s(_vm.resolutionText(resolution))+" ")]),_c('div',{staticClass:"position-absolute d-flex align-center justify-center media-upload-container"}),_c('preview-thumbnail',{staticClass:"preview-thumbnail",attrs:{"playable":"","media":media && media.MEDIA_CHANGE_REQUEST ? media.MEDIA_CHANGE_REQUEST.MEDIA_FILE : media.MEDIA_FILE}})],1)])}),1):_vm._e()],1)])]):_vm._e(),_c('v-text-field',{attrs:{"value":_vm.campaignName,"label":_vm.$t('campaign_name'),"readonly":""}}),(_vm.campaign && _vm.campaign.START_DATE)?_c('v-text-field',{attrs:{"value":_vm.formattedStartDate,"label":_vm.$t('start_date'),"readonly":""}}):_vm._e(),(_vm.campaign && _vm.campaign.DURATION_IN_WEEKS)?_c('v-text-field',{attrs:{"label":_vm.$t('duration_in_weeks'),"readonly":""},model:{value:(_vm.campaign.DURATION_IN_WEEKS),callback:function ($$v) {_vm.$set(_vm.campaign, "DURATION_IN_WEEKS", $$v)},expression:"campaign.DURATION_IN_WEEKS"}}):_vm._e(),(_vm.campaign && _vm.campaign.COMPANY_NAME)?_c('v-text-field',{attrs:{"label":_vm.$t('company'),"readonly":""},model:{value:(_vm.campaign.COMPANY_NAME),callback:function ($$v) {_vm.$set(_vm.campaign, "COMPANY_NAME", $$v)},expression:"campaign.COMPANY_NAME"}}):_vm._e(),(_vm.showPrice)?_c('v-text-field',{attrs:{"label":_vm.$t('campaign_price'),"readonly":"","prefix":"SAR"},model:{value:(_vm.campaignPrice),callback:function ($$v) {_vm.campaignPrice=$$v},expression:"campaignPrice"}}):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }