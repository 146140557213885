
























import { CampaignListItem } from '@/app/shared/utilities/static-types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import CampaignsList from '@userApp/shared/components/ListView.vue';
import namespace from '@/app/shared/store/namespace';

@Component({
  components: { CampaignsList },
})
export default class CampaignsListView extends Vue {
  @Prop({ required: true, default: true }) public loading!: boolean;
  @Prop({ required: false, default: () => ([]) }) public campaignsList!: CampaignListItem[];

  public get emptyMessage() {
    return this.$t('no_campaigns');
  }

  public setCampaignRef(index: number) {
    const { REF } = this.campaignsList[index];
    this.$store.commit(
      `${namespace.CampaignModule}/setCurrentStoreCampaignRef`,
      REF,
    );
  }
}
