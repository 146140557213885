












import { Component, Prop, Vue } from 'vue-property-decorator';
import { Locales } from '@/app/shared/utilities/static-types';
import { updateValueInStorage } from '@/app/storage/storage-actions';
import { DB_STORAGE_APP_LOCALE } from '@/app/shared/utilities/object-factory';

@Component
export default class LocaleSwitcher extends Vue {
  @Prop({ default: false, type: Boolean }) public dark!: boolean;

  public locales: Locales = { en: 'English', ar: 'العربية' };
  public availableLocale: string = this.locales[
    Object
      .keys(this.locales)
      .find((locale) => locale !== this.$root.$i18n.locale)!
    ];

  public mounted() {
    this.updateLayoutDirection();
  }

  public async switchLocales() {
    this.availableLocale = this.locales[this.$root.$i18n.locale];
    this.$root.$i18n.locale = Object.keys(this.locales)
      .find((locale) => locale !== this.$root.$i18n.locale)!;

    this.updateLayoutDirection();

    try {
      await updateValueInStorage(
        DB_STORAGE_APP_LOCALE,
        this.$root.$i18n.locale,
      );
    } catch (err) {
      throw new Error(`Failed to save app locale to indexedDB ${err}`);
    }
  }

  public updateLayoutDirection() {
    switch (this.$root.$i18n.locale) {
      case 'ar': {
        this.$vuetify.rtl = true;
        break;
      }
      default:
        this.$vuetify.rtl = false;
        break;
    }
  }
}
