var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-component',{attrs:{"form-name":"adspcaces-playlists-form","snackbar-msg":"Changes have been saved!","on-component-created":_vm.loadAdSpaces},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',{attrs:{"id":"header-title"}},[_vm._v(_vm._s(_vm.$t('adspaces_playlist'))+" ")])],1)]},proxy:true},{key:"fields",fn:function(ref){
var loading = ref.loading;
return [(_vm.isPublishAllPlaylistLoading)?_c('v-progress-linear',{attrs:{"color":"primary","value":_vm.progress}}):_vm._e(),_c('div',{staticClass:"display-flex justify-content-end"},[_c('v-btn',{staticClass:"ma-0 mt-3 white--text",attrs:{"disabled":_vm.isPublishAllPlaylistLoading,"type":"button","color":"purple"},on:{"click":_vm.publishAllPlaylist}},[_c('v-icon',{attrs:{"color":"white","medium":""}},[_vm._v("publish")]),_vm._v(" "+_vm._s(_vm.$t('publish_all_playlist'))+" ")],1)],1),_c('v-combobox',{attrs:{"loading":loading,"rules":_vm.schedulesRules,"items":_vm.adSpaces,"label":"AdSpace","item-text":"NAME","item-value":"ID"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var adSpace = ref.item;
return [_c('ad-space-select-item',{attrs:{"ad-space":adSpace,"show-branch":"","show-resolution":""}})]}},{key:"selection",fn:function(ref){
var adSpace = ref.item;
return [_c('ad-space-select-item',{attrs:{"ad-space":adSpace,"show-branch":"","show-resolution":""}})]}}],null,true),model:{value:(_vm.selectedAdSpace),callback:function ($$v) {_vm.selectedAdSpace=$$v},expression:"selectedAdSpace"}}),(_vm.selectedAdSpace)?_c('v-menu',{ref:"startDateMenu",attrs:{"nudge-right":40,"close-on-content-click":false,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('date'),"persistent-hint":"","prepend-icon":"event"},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}},on))]}}],null,true)},[_c('v-date-picker',{attrs:{"no-title":""},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1):_vm._e(),(_vm.campaignList.length > 0)?[_c('div',{staticClass:"display-flex"},[_c('v-switch',{staticClass:"end mx-3",attrs:{"label":_vm.$t('sort_by_frequency')},model:{value:(_vm.sortByFrequency),callback:function ($$v) {_vm.sortByFrequency=$$v},expression:"sortByFrequency"}}),_c('v-btn',{staticClass:"ma-0 mt-3 white--text",attrs:{"type":"button","color":"green"},on:{"click":_vm.createShashaAd}},[_c('v-icon',{attrs:{"color":"white","medium":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t('create_shasha_ad'))+" ")],1)],1),(_vm.campaignListLoading)?_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}}):_vm._e(),(!_vm.campaignListLoading)?_c('campaign-list',{attrs:{"sort-by-frequency":_vm.sortByFrequency,"playlist-campaigns":_vm.campaignList,"ad-space":_vm.selectedAdSpace},on:{"updated-campaign":_vm.updateCampaignInList,"deleted-shasha-ad":_vm.deleteShashaAd}}):_vm._e()]:_vm._e(),(
        !_vm.loadCampaigns &&
          _vm.selectedAdSpace &&
          _vm.selectedDate &&
          _vm.campaignList.length === 0
      )?[_c('div',{staticClass:"display-flex justify-content-end"},[_c('v-btn',{staticClass:"ma-0 mt-3 white--text",attrs:{"type":"button","color":"green"},on:{"click":_vm.createShashaAd}},[_c('v-icon',{attrs:{"color":"white","medium":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t('create_shasha_ad'))+" ")],1)],1),_c('h2',[_vm._v(_vm._s(_vm.$t('cannot_find_campaigns')))])]:_vm._e(),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"top":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMsg)+" "),_c('v-btn',{attrs:{"color":"white","flat":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1),(_vm.showCreateAd)?_c('create-shasha-ad',{attrs:{"ad-space":_vm.selectedAdSpace,"selected-date":_vm.selectedDate,"is-modal-open":_vm.isModalOpen,"show-shasha-ad-created":_vm.showShashaAdCreated},on:{"update:isModalOpen":function($event){_vm.isModalOpen=$event},"update:is-modal-open":function($event){_vm.isModalOpen=$event},"update:showShashaAdCreated":function($event){_vm.showShashaAdCreated=$event},"update:show-shasha-ad-created":function($event){_vm.showShashaAdCreated=$event}}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }